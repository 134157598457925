import React, { useState, ReactNode } from "react"
import VisibilitySensor from "react-visibility-sensor"

import { Container, Indicators, IndicatorDot } from "./styled"

interface SliderProps {
  children: ReactNode
  hideIndicators?: boolean
}
export const Slider = ({
  children,
  hideIndicators,
}: SliderProps): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState(0)
  return (
    <>
      <Container className="slider-container">
        {React.Children.map(children, (child, i) => (
          <VisibilitySensor
            key={`slider-item-${i}`}
            scrollCheck
            onChange={(isVisible: boolean) => {
              if (isVisible) {
                setActiveIndex(i)
              }
            }}
          >
            {child}
          </VisibilitySensor>
        ))}
      </Container>
      {!hideIndicators && (
        <Indicators>
          {React.Children.map(children, (child, index) => {
            return (
              <IndicatorDot
                data-testid={
                  index === activeIndex
                    ? `indicator-active-${index}`
                    : `indicator-deactive-${index}`
                }
                isActive={index === activeIndex}
                key={`indicator-${index}`}
              />
            )
          })}
        </Indicators>
      )}
    </>
  )
}
