import GreenCalendar from "./assets/green-calendar.svg"
import BlueHeartHands from "./assets/blue-heart-hands.svg"
import YellowMessage from "./assets/yellow-message.svg"

export const copy = [
  {
    image: GreenCalendar,
    title: "Easily book sessions online",
    description:
      "Schedule, reschedule, or cancel your appointments anytime online — no phone calls or emails",
  },
  {
    image: BlueHeartHands,
    title: "Switch clinicians anytime",
    description:
      "Most clients find a therapist or prescriber they love on the first try — but if you don’t, it’s easy to switch anytime for any reason",
  },
  {
    image: YellowMessage,
    title: "Send us a message",
    description:
      "Message your therapist or our support team when you have questions between sessions or need help with your account",
  },
]
