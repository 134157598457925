import { Carousel, CarouselItem } from "../../../ui"
import { QuotesContainer, QuotesContentContainer, Name, Quote } from "./styled"
import PurpleQuoteIcon from "../../../shared/assets/quotes-landing-pages.svg"
import StarGroup from "../../../shared/assets/five-stars.svg"

export interface IQuotesCarouselProps {
  quotes: {
    quote: string
    name: string
  }[]
}

export const QuotesCarousel = ({ quotes }: IQuotesCarouselProps) => {
  return (
    <QuotesContainer>
      <Carousel
        indicatorStyles={{
          desktop: { x: "0", y: "40%" },
          mobile: { x: "64px", y: "80%" },
        }}
        startingIndex={0}
      >
        {quotes.map(({ quote, name }) => (
          <CarouselItem key={quote}>
            <QuotesContentContainer>
              <div>
                <img src={PurpleQuoteIcon} alt="" />
                <Quote>{quote}</Quote>
              </div>
              <div>
                <Name>{name}</Name>
                <img src={StarGroup} alt="5 Stars" />
              </div>
            </QuotesContentContainer>
          </CarouselItem>
        ))}
      </Carousel>
    </QuotesContainer>
  )
}
