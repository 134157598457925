import styled from "styled-components"
import { colors } from "../../../themes/colors"
import { OuterSectionWrapper } from "./Section/styled"

export const OurServicesWrapper = styled.section`
  position: relative;
  > * {
    font-family: Poppins;
  }
`
export const InsuranceLink = styled.a`
  color: ${colors.c_black[900]};
  text-decoration-line: underline;
`

export const HeaderSection = styled.div`
  background: linear-gradient(25deg, #eceffe -92%, #fef9ec 100%);
  text-align: center;
  padding: 64px 16px 0;

  h2 {
    font-size: 40px;
    font-weight: 600;
    line-height: 150%;
    margin-bottom: 8px;

    @media (max-width: 768px) {
      font-size: 28px;
    }
  }

  p {
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
`

export const Tabs = styled.div`
  max-width: 1024px;
  position: sticky;
  top: 0px;
  margin: 0 auto;
  display: flex;
  gap: 24px;
  justify-content: center;

  @media (max-width: 768px) {
    gap: 0;
  }
`

export const TabWrapper = styled.div`
  background: linear-gradient(135deg, #eceffe -60%, #fef9ec 100%);
  position: sticky;
  top: 124px;
  z-index: 3;
  padding-top: 24px;
`

export const Tab = styled.a<{ isActiveTab?: boolean }>`
  background-color: ${({ isActiveTab }) =>
    isActiveTab ? "white" : "transparent"};
  border-radius: 32px 32px 0 0;
  cursor: pointer;
  padding: 32px 0;
  width: 325.33px;
  font-size: 22px;
  font-weight: 600;
  line-height: 24px; /* 109.091% */
  text-decoration: none;
  color: ${colors.c_black[900]};
  text-align: center;
  ${({ isActiveTab }) =>
    isActiveTab
      ? `  box-shadow: 0px -8px 12px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.5s cubic-bezier(0.215, 0.61, 0.355, 1),
    background-color 0.5s;`
      : ""}

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 16px 0;
    width: 109px;
    border-radius: 16px 16px 0 0;
  }
`

export const TherapyMedsWrapper = styled.div`
  ${OuterSectionWrapper} {
    background: linear-gradient(135deg, #fef9ec 0%, #eef8f9 100%);
  }
`
