import styled from "styled-components"
import checkmarkSvg from "../../../images/checkmark.svg"

interface ContentContainerProps {
  flexDirection: string
  theme?: string
}

interface ContainerProps {
  theme?: string
  padding?: string
}

export const Container = styled.section<ContainerProps>`
  background: ${({ theme }) =>
    theme === "secondary" ? "transparent" : "#f3f5ff"};
  max-width: 1440px;
  margin: 0 auto;
  padding-right: 24px;
  padding-left: 24px;

  @media (max-width: 800px) {
    background: ${({ theme }) =>
      theme === "secondary"
        ? "transparent"
        : `linear-gradient(
      0deg,
      rgba(243, 245, 255, 1) 95%,
      rgba(255, 255, 255, 1) 100%
    );

    & > div {
      margin-bottom: 32px;
    }

    & > div:last-child {
      margin-bottom: 0;
    }`};
  }
`
export const ContentContainer = styled.div<ContentContainerProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  justify-content: space-between;
  align-items: center;
  max-width: 992px;
  margin: 0 auto;
  gap: 80px;
  margin-bottom: 40px;

  & > div {
    text-align: start;

    & > p {
      font-weight: 300;
      font-size: 16px;
      font-style: Poppins;
      color: #353a41;
      line-height: 24px;
      margin-top: 16px;
      max-width: 456px;
      max-height: 112px;
      margin-bottom: 16px;
    }
  }

  img {
    max-width: 456px;
    min-width: 327px;
    width: 100%;
    height: auto;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 24px;
  }
`

export const TextContainer = styled.div`
  margin-bottom: 16px;
  max-width: 456px;
  width: 100%;
  & > p {
    font-weight: 400;
    font-size: 16px;
    font-family: Poppins;
    line-height: 24px;
    margin-bottom: 8px;
    white-space: pre-line;
  }
  b {
    font-weight: 600;
  }
  i {
    font-style: italic;
  }
  h3 {
    color: var(--bn-w-black-900, #1c1e21);
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }
`
export const ListContainer = styled.ul<ContainerProps>`
  color: ${({ theme }) => (theme === "secondary" ? "#222128" : "#8682ab")};
  font-family: "Poppins";
  font-weight: ${({ theme }) => (theme === "secondary" ? "300" : "600")};
  font-size: ${({ theme }) => (theme === "secondary" ? "16px" : "14px")};
  line-height: 23px;
  max-width: 540px;
  margin-top: 16px;
  margin-left: 16px;
  list-style-image: url(${checkmarkSvg});
  & > li {
    margin-bottom: 8px;
  }
  & > li:last-child {
    margin-bottom: 0;
  }
  & > li img {
    margin-right: 8px;
  }
  @media (max-width: 834px) {
    font-size: 14px;
  }
`
export const Bullet = styled.span`
  position: relative;
  left: 8px;
`
