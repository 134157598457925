import React, { createContext, useContext, useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { edgeToPromo } from "./utils"
import Cookies from "js-cookie"
import { usStrings, usVariation } from "./localization/usVariations"

const defaultVariations = usVariation

const localizedStrings = usStrings

const VariationContext = createContext({})

const promoFormUrl = () => {
  const search = new URLSearchParams(
    typeof window !== "undefined" ? window.location.search : ""
  )
  const promo = search.get("promo")

  if (promo) {
    localStorage.setItem("promo", promo)
    return promo
  }

  if (search.has("promo")) {
    return "EMPTY"
  }

  return null
}

const promoFromStorage = () => {
  const promo =
    typeof window !== "undefined" ? localStorage.getItem("promo") : null

  if (promo) {
    return promo
  }

  return null
}

const getVariation = ({ variations, promo }) =>
  variations.some(v => v.variation.toLowerCase() === promo.toLowerCase())
    ? promo
    : "*"
const getLookupTable = ({ variations, variation }) =>
  variations.find(v => v.variation.toLowerCase() === variation.toLowerCase())

const defaultPromo = ({ variations }) => {
  const promo = promoFormUrl() || promoFromStorage()

  if (
    promo &&
    !variations.some(
      v => v.expired && v.variation.toLowerCase() === promo.toLowerCase()
    )
  ) {
    return promo
  }

  return "default"
}

const getAbtests = () => {
  const params = new URLSearchParams(
    typeof window !== "undefined" ? window.location.search : ""
  )
  let param = params.get("cabt") || Cookies.get("cabt")

  if (param) {
    try {
      try {
        param = window.atob(param)
      } catch (e) {}

      const pairs = param.split(",")

      const abtests = pairs.reduce((accumulator, pair) => {
        const [key, value] = pair.split(":")
        accumulator[key] = value
        return accumulator
      }, {})

      return abtests
    } catch (e) {
      console.error(`Error parsing abtest param`, params)
    }
  }

  return {}
}

const generatePromos = ({ allPrismicPromo }) => {
  const base = defaultVariations.find(v => v.variation === "default")
  const lang = "en-us"
  const promos = allPrismicPromo.nodes
    .filter(p => p.lang === `${lang}`)
    .map(edge => ({
      ...base,
      ...edgeToPromo({ edge }),
    }))

  return [...defaultVariations, ...promos]
}

const VariationProvider = ({ children }) => {
  const { allPrismicPromo } = useStaticQuery(graphql`
    query VariationsQuery {
      allPrismicPromo {
        nodes {
          data {
            banner_text {
              html
            }
            body {
              ... on PrismicPromoDataBodyTherapyPromo {
                id
                primary {
                  therapy_plan_price_strikethrough
                  therapy_plan_price {
                    text
                  }
                  therapy_plan_under_price_text {
                    text
                  }
                  therapy_under_cta {
                    text
                  }
                }
              }
              ... on PrismicPromoDataBodyMedicationTherapyPromo {
                id
                primary {
                  medication_therapy_plan_price {
                    text
                  }
                  medication_therapy_plan_price_strikethrough
                  medication_therapy_plan_under_price_text {
                    text
                  }
                  medication_therapy_under_cta {
                    text
                  }
                }
              }
              ... on PrismicPromoDataBodyCoachingPromo {
                id
                primary {
                  coaching_plan_price {
                    text
                  }
                  coaching_plan_price_strikethrough
                  coaching_plan_under_price_text {
                    text
                  }
                  coaching_under_cta {
                    text
                  }
                }
              }
              ... on PrismicPromoDataBodyMedicationCoachingPromo {
                id
                primary {
                  medication___coaching_plan_price {
                    text
                  }
                  medication___coaching_plan_price_strikethrough
                  medication___coaching_plan_under_price_text {
                    text
                  }
                  medication___coaching_under_cta {
                    text
                  }
                }
              }
            }
            cta_text {
              html
            }
            under_image_copy {
              html
            }
            text_under_price {
              html
            }
            strikethrough_price
            under_cta_copy {
              html
            }
            plan_price {
              html
            }
            plan_price_strikethrough
            plan_under_price_text {
              html
            }
            expired
          }
          lang
          uid
        }
      }
    }
  `)

  const [variations] = useState(generatePromos({ allPrismicPromo }))
  const [promo] = useState(defaultPromo({ variations }))
  const [variation] = useState(getVariation({ variations, promo }))
  const [defaultVariation] = useState(
    getVariation({ variations, promo: "default" })
  )
  const [defaultLookupTable] = useState(
    getLookupTable({ variations, variation: defaultVariation })
  )
  const [lookupTable] = useState(getLookupTable({ variations, variation }))
  const [abtests, setABtests] = useState(getAbtests({ promo }))

  const value = {
    promo,
    variation,
    lookupTable,
    defaultLookupTable,
    abtests,
    getFromDefault: key => defaultLookupTable[key],
    has: key => key in lookupTable,
    get: key => lookupTable[key],
  }

  return (
    <VariationContext.Provider value={value}>
      {children}
    </VariationContext.Provider>
  )
}

const Variation = ({ lookupKey, useDefault }) => {
  const [isClient, setIsClient] = useState(false)
  const { lookupTable = {}, defaultLookupTable = {} } =
    useContext(VariationContext)
  const value = useDefault
    ? defaultLookupTable[lookupKey]
    : lookupTable[lookupKey]

  useEffect(() => {
    setIsClient(true)
  }, [])

  if (isClient) {
    return <span key="client" dangerouslySetInnerHTML={{ __html: value }} />
  }

  return <span key="ssr" dangerouslySetInnerHTML={{ __html: value }} />
}

const ABTest = ({ id, map, props = undefined, propsMap = {} }) => {
  if (!("no_value" in map)) {
    throw new Error("ABTest comments needs a default variant named `no_value`")
  }

  const { abtests } = useContext(VariationContext)
  let test = "no_value"
  if (abtests && id in abtests) {
    test = abtests[id]
  }
  let Component = map[test]

  // cover our asses in case a test fails or something
  if (!Component && process.env.REACT_APP_NODE_ENV !== "development") {
    console.error(
      `Missing abtest component for test [${id}] and variation [${test}]`
    )
    Component = map.no_value
  }

  return <Component {...propsMap[test]} {...props} />
}

const fsaMedicationMonths =
  new Date().getMonth() !== 11
    ? Array(11)
        .fill(0)
        .map((e, i) => i + 2)
    : Array(12)
        .fill(0)
        .map((e, i) => i + 1)

const fsaMedicationTherapyMonths =
  new Date().getMonth() !== 11
    ? Array(5)
        .fill(0)
        .map((e, i) => i + 2)
    : Array(6)
        .fill(0)
        .map((e, i) => i + 1)

const fsaTherapyMonths =
  new Date().getMonth() !== 11
    ? Array(5)
        .fill(0)
        .map((e, i) => i + 2)
    : Array(6)
        .fill(0)
        .map((e, i) => i + 1)

export {
  Variation,
  VariationProvider,
  VariationContext,
  ABTest,
  localizedStrings,
  fsaMedicationMonths,
  fsaMedicationTherapyMonths,
  fsaTherapyMonths,
}
