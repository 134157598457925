import { useEffect } from "react"
import Cookies from "js-cookie"

export default function Acquisition() {
  useEffect(() => {
    Cookies.set("acquisition-string", window.location.href, {
      domain: process.env.GATSBY_COOKIE_DOMAIN,
      expires: 30,
    })
  }, [])

  return null
}
