import React, { useContext, useState, useEffect } from "react"
import { useRolloutFlag } from "../store/rolloutFlag"
import styled from "styled-components"
import { isRelativeUrl } from "../lib/utils"
import { VariationContext } from "../lib/variations"

interface LinkInterface {
  className?: string
  children?: React.ReactNode
  to: string
  target?: string
  rel?: string
  onClick?:
    | ((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void)
    | (() => void)
}
const Link = ({
  className = undefined,
  children,
  to,
  target = undefined,
  rel = undefined,
  onClick = undefined,
}: LinkInterface) => {
  const [mounted, setMounted] = useState(false)
  const { variation, promo } = useContext<{
    variation?: string
    promo?: string
  }>(VariationContext)
  const rollout = useRolloutFlag()

  useEffect(() => {
    setMounted(true)
  }, [])

  let href = to

  if (!mounted) {
    return (
      <a className={className} href={href} target={target} rel={rel}>
        {children}
      </a>
    )
  }

  if (variation !== "default") {
    if (isRelativeUrl({ to })) {
      const url = new URL(
        to,
        process.env.GATSBY_BASE_URL ?? process.env.STORYBOOK_GATSBY_BASE_URL
      )
      url.searchParams.append("promo", variation === "EMPTY" ? "" : promo || "")

      const hash = url.hash ? url.hash : ""
      href = url.pathname + hash + url.search
    } else {
      const url = new URL(to)
      url.searchParams.append("promo", variation === "EMPTY" ? "" : promo || "")
      href = url.href
    }
  }

  if (!isRelativeUrl({ to }) && rollout?.flag?.enable_partner_param) {
    const url = new URL(href)
    url.searchParams.append("partner", "cerebral")
    href = url.href
  }

  return (
    <a
      className={className}
      href={href}
      target={target}
      rel={rel}
      onClick={onClick}
    >
      {children}
    </a>
  )
}

export default styled(Link)``
