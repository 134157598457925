import React, { FC, HTMLAttributes, useState } from "react"
import styled from "styled-components"

const Wrapper = styled.div<{ checked: boolean; colorStyle?: string }>`
  *,
  *::before,
  *::after {
    box-sizing: content-box !important;
  }

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  div {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #1c1e21;
  }

  display: table;
  position: relative;
  padding-left: 26px;
  cursor: pointer;
  margin-bottom: 0.4rem;
  padding-top: 2px;

  .b-input {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background: #fff;
    transition: background 250ms;
    border: ${({ colorStyle }) => `1px solid ${colorStyle}`};
    border-radius: 4px;
    z-index: -1;
    background: ${({ checked, colorStyle }) => (checked ? colorStyle : "#fff")};
  }

  .b-input::after {
    content: "";
    position: absolute;
    display: ${({ checked }) => (checked ? "block" : "none")};
    left: 5px;
    top: 2px;
    width: 4px;
    height: 8px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    transition: background 250ms;
    transform: rotate(45deg);
  }
`

interface CheckboxProps {
  label?: string
  checked?: boolean
  onChange: (e: boolean) => void
  colorStyle: string
}

const CheckBox: FC<CheckboxProps> = ({
  label,
  checked,
  onChange,
  colorStyle,
}) => {
  return (
    <Wrapper
      checked={checked || false}
      onClick={() => onChange(!checked)}
      colorStyle={colorStyle}
    >
      <div>{label}</div>
      <div className="b-input" />
    </Wrapper>
  )
}

export default CheckBox
