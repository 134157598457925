import React, { useState, useEffect, useRef } from "react"

const OutsideClickHandler = ({
  children,
  onOutsideClick,
}: {
  children: React.ReactNode
  onOutsideClick: () => void
}) => {
  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        ref.current &&
        !(ref.current as Node).contains(event.target as Node)
      ) {
        onOutsideClick()
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [onOutsideClick])

  return <div ref={ref}>{children}</div>
}

export default OutsideClickHandler
