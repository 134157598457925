import { graphql, useStaticQuery } from "gatsby"
import SEO from "../SEO"
import { organizationSchemaMarkup } from "../../common/OrganizationSeoSchema"
import { Navigation } from "../ui/Navigation"
import HeaderWithInsuranceLogos from "../prismic/HeaderWithInsuranceLogos"
import Stats from "../prismic/Stats"
import { FAQSection } from "../shared"
import {
  AppFeatures,
  SuccessStories,
  TheCerebralWay,
  YourInsurancePersonalPath,
} from "../HomePage/sections"
import { useState } from "react"
import {
  appFeatures,
  cerebralWayCopy,
  insuranceSlice,
  personalPathPlans,
  statsSlice,
  successStories,
} from "./content"
import { InsuranceModal } from "./InsuranceModal"
import { RichText } from "components/shared/InsuranceWidget/utils"

const INSURANCE_PAGE_QUERY = graphql`
  query InsurancePageQuery {
    allPrismicFaqWidget(
      filter: { data: { page_type: { eq: "insurance-faq" } } }
    ) {
      edges {
        node {
          data {
            faqs {
              question {
                document {
                  ... on PrismicFaqRedesign {
                    data {
                      answer {
                        html
                      }
                      question {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

interface IInsuranceData {
  activeInsurances: RichText[]
}

export const InsurancePageBody = ({
  activeInsurances,
}: IInsuranceData): JSX.Element => {
  const response = useStaticQuery(INSURANCE_PAGE_QUERY)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const faqs = response.allPrismicFaqWidget.edges[0].node.data.faqs

  return (
    <div>
      <HeaderWithInsuranceLogos
        slice={insuranceSlice}
        activeInsurances={activeInsurances}
      />
      <Stats slice={statsSlice} />
      <YourInsurancePersonalPath
        headerText="What we offer"
        description="You can always switch or add services later"
        showCta
        plans={personalPathPlans}
        disableLinks
      />
      <TheCerebralWay
        copy={cerebralWayCopy}
        header="What to expect"
        description="Our intentional approach to mental healthcare helps you make better progress"
      />
      <InsuranceModal
        isModalOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
      />
      <AppFeatures
        headerText="Access all membership benefits for $30/mo"
        features={appFeatures}
        hideLinks
      >
        This price does not include other costs that may be required under your
        insurance plan — like copays, deductibles, medication, and coinsurance.{" "}
        <a
          href="/#"
          onClick={e => {
            e.preventDefault()
            setIsModalOpen(true)
          }}
        >
          What does this subscription cost include?
        </a>
      </AppFeatures>
      <FAQSection faqs={faqs} withInsuranceModal />
      <SuccessStories hideButton items={successStories} />
    </div>
  )
}

export const InsurancePage = ({
  activeInsurances,
}: IInsuranceData): JSX.Element => {
  return (
    <>
      <SEO
        title="Using Insurance with Cerebral"
        titleSuffix="Expert Online Therapy + Mental Health Care on Your Schedule"
        description="Are you looking for in-network coverage for your mental health needs? Cerebral partners with some of the largest insurance providers nationwide to provide more affordable care for you."
        schemaMarkup={organizationSchemaMarkup}
        meta={[
          {
            name: "keywords",
            content:
              "cerebral insurance, therapy insurance, magellan, blue cross blue shield, cigna, anthem, united healthcare",
          },
        ]}
      />
      <Navigation />
      <InsurancePageBody activeInsurances={activeInsurances} />
    </>
  )
}
