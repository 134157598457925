import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

export const Title = styled.h1`
  font-style: italic;
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  letter-spacing: -1px;
  color: #ffffff;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);
`

export const SubTitle = styled.h3`
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.375px;
  margin-top: 15px;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);
`

export const TextToAction = styled.h2`
  color: #ffffff;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  margin-top: 45px;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);
`

export const Copy = styled.p`
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.375px;
  margin-top: 15px;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);
`

export const ButtonSub = styled.p`
  color: #ffffff;
  margin: 10px 0 0;
  font-size: 12px;
  line-height: 24px;
  font-weight: 500;

  @media (min-width: 834px) {
    font-size: 14px;
  }
`

export const Texts = styled.div`
  text-align: center;

  @media (min-width: 600px) {
    margin: 0 0 0 30px;
    padding: 70px 0 100px;
    width: 45%;
    text-align: left;
  }
`

export const GetStarted = styled.div`
  display: inline-block;
  margin: 50px 0 0;

  @media (min-width: 600px) {
    text-align: center;
  }
`

export const GirlImg = styled(GatsbyImage)`
  position: absolute;
`

const GirlWrapperStyle = directDiv => `
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
  clip-path: ellipse(58% 72% at 50% 72%);
  margin: 50px auto 0px;

  & > div {
    ${directDiv}
  }

  picture img {
    object-position: center top !important;
  }

  @media (min-width: 600px) {
    margin: 0;
    position: absolute;
    padding-bottom: unset;
    height: 100%;
    right: 0;
    top: 0;
    width: 50%;
    clip-path: ellipse(59% 65% at 65% 50%);

    picture img {
      object-position: left center !important;
    }
  }
`

const DynamicWidthAndHeight = `
  position: absolute !important;
  height: unset !important;
  width: unset !important;
  min-width: 100%;
  min-height: 100%;
`

export const GirlWrapper = styled.div`
  ${GirlWrapperStyle(DynamicWidthAndHeight)}
`

export const StaticGirlWrapper = styled.div`
  ${GirlWrapperStyle(`
    max-height: 100% !important;
    max-width: 100% !important;
    position: absolute;
  `)}
`

export const Wrapper = styled.header`
  padding-top: 40px;
  position: relative;
  background: linear-gradient(190deg, #8ba3ff 19.5%, #a8baff 72.7%);

  @media (min-width: 834px) {
    background: linear-gradient(190deg, #8ba3ff 0%, #a8baff 100%);

    ${Title} {
      font-size: 40px;
      line-height: 55px;
    }

    ${SubTitle} {
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
    }
  }
`

export const MeditationWrapper = styled.header`
  ${Title} {
    color: #250044;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0;
    font-style: normal;
  }

  ${SubTitle} {
    color: #250044;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0;
  }

  ${Copy} {
    color: #250044;
    font-size: 16px;
    font-weight: 400;
    margin-top: 25px;
    line-height: 24px;
    word-break: initial !important;

    &.paragraph {
      margin-top: 15px;
    }
  }

  ${TextToAction} {
    color: #250044;
    font-size: 20px;
    font-weight: 600;
    text-transform: Uppercase;
  }

  @media (max-width: 570px) {
    overflow-x: hidden;
  }
`

export const ContainerMeditation = styled.div`
  display: flex;
  padding: 0 10%;
  margin: 0 auto;
  max-width: 1140px;
  align-items: center;
  flex-direction: row;
  background-color: #fff;
  justify-content: center;

  &.offer {
    flex-direction: column;
  }

  &.cta_last {
    padding: 35px 10%;
    max-width: none;
    background-color: #e9fbff;

    ${Copy} {
      font-size: 16px;
      font-weight: 400;
    }
  }

  @media (max-width: 570px) {
    padding: 0 5%;
    flex-direction: column;

    &.invert-col-responsive {
      flex-direction: column-reverse;
    }

    &.sponsors-wrapper {
      padding: 0;
    }

    &.title-centered-resp {
      ${Title} {
        text-align: center;
      }
    }

    ${Title} {
      font-size: 24px;
      line-height: 32px;
    }
  }
`

export const FlexTexts = styled.div`
  width: 50%;
  display: flex;
  text-align: left;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 570px) {
    width: 100%;
  }
`

export const ImageWrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  img {
    width: 100%;
  }

  &.cta-img-wrapper {
    width: 45%;
    margin-right: 5%;
  }

  @media (max-width: 570px) {
    width: 100%;
    img {
      margin: 40px 0;
    }

    &.overflow-img {
      width: 155%;
    }

    &.cta-img-wrapper {
    }
  }
`

export const SponsorsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 14vh 0 16vh 0;
  justify-content: space-around;

  img {
    width: auto;
    height: 40px;
  }

  @media (max-width: 570px) {
    justify-content: center;
    overflow-x: scroll;
    justify-content: flex-start;

    ::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }

    img {
      height: 50px;
      margin: 0 30px;
    }

    &.offers {
      flex-direction: column;
    }
  }
`

export const OfferWrapper = styled.div`
  width: 30%;
  display: flex;
  margin-bottom: 8vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  ${Title} {
    margin-bottom: 30px;
  }

  ${Copy} {
    padding: 0 25px;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    text-break: break-word;
    letter-spacing: 0.04rem;
    text-transform: uppercase;
  }

  img {
    width: auto;
    height: 200px;
  }

  @media (max-width: 570px) {
    width: 100%;
    img {
      height: 300px;
    }
  }
`

export const InputStyled = styled.input`
  width: 65%;
  color: #250044;
  font-size: 14px;
  margin-top: 25px;
  line-height: 18px;
  border-radius: 4px;
  padding: 18px 13px;
  background: #ffffff;
  font-family: Poppins;
  border: 1px solid #d3dbf8;
  box-shadow: 0px 0px 12px 4px #f6f6f9;

  &::placeholder {
    color: #250044;
  }

  &:focus {
    outline: none;
  }

  &.error {
    border-color: #eb5757;
  }
`

export const ErrorInputText = styled.span`
  color: #eb5757;
  font-size: 14px;
  margin-top: 10px;
  font-weight: 500;
  line-height: 18px;
  font-style: normal;
  font-family: Poppins;
  letter-spacing: -0.43px;
`

export const ButtonSubmit = styled.button`
  border: none;
  cursor: pointer;
  margin-top: 30px;
  padding: 16px 80px;
  width: fit-content;
  background: #250044;
  border-radius: 30px;
  box-shadow: 0px 7px 14px rgba(34, 34, 52, 0.13);

  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  font-style: normal;
  font-family: Poppins;

  @media (max-width: 570px) {
    align-self: center;
    width: 100%;
  }
`
export const CheckListItem = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

  ${Copy} {
    width: 300px;
    line-height: 24px;
    margin: 0 0 0 8px;
    font-size: 18px !important;
    font-weight: 600 !important;
  }

  @media (max-width: 570px) {
    ${Copy} {
      width: auto;
    }
  }
`
