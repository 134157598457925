import { H3 } from "../../../ui"
import { GetStartedWrapper } from "./styled"
import { GetStartedButton } from "../../../shared/GetStartedButton"
export const GetStarted = () => {
  return (
    <GetStartedWrapper>
      <H3>Give 70% off. Get $15.</H3>
      <GetStartedButton text="Start sharing" href="#top" width={400} />
    </GetStartedWrapper>
  )
}
