import styled from "styled-components"

export const WidgetContainerWrapper = styled.section`
  background: linear-gradient(135deg, #fef9ec 0%, #eceffe 100%);
  padding: 40px 16px 0px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 10%;

  @media (max-width: 834px) {
    padding: 0;
  }
`

export const Widget = styled.div`
  width: 432px;
`

export const HeroImage = styled.img`
  max-width: 472px;
  width: 40%;
  margin-bottom: 20px;
  margin-top: 32px;
  border-radius: 30px;
  @media (max-width: 834px) {
    display: none;
  }
`
