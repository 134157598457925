import { Link } from "gatsby"
import { QuotesCarousel } from "../QuotesCarousel"
import { H2 } from "../../ui"
import { ButtonContainer, TestimonialContainer } from "./styled"
import { GetStartedButton } from "../GetStartedButton"
import { quotes as defaultQuotes } from "./constants"
import { TrustPilotPhrase } from "../TrustPilotPhrase"

interface ITestimonialsSectionProps {
  quotes?: {
    quote: string
    name: string
  }[]
  hideButton?: boolean
}

export const TestimonialSection = ({
  quotes = defaultQuotes,
  hideButton = false,
}: ITestimonialsSectionProps) => {
  return (
    <TestimonialContainer>
      <H2>What clients are saying about&nbsp;us</H2>
      <TrustPilotPhrase />
      <QuotesCarousel quotes={quotes} />
      {!hideButton && (
        <>
          <Link to="https://www.trustpilot.com/review/cerebral.com">
            See more testimonials
          </Link>
          <ButtonContainer>
            <GetStartedButton />
          </ButtonContainer>
        </>
      )}
    </TestimonialContainer>
  )
}
