import {
  ExceptionalCareWrapper,
  HeadlineContainer,
  InnerContainer,
  TextColumn,
  ImageColumn,
  InnerImageColumn,
  ExceptionalCareText,
  Title,
  Description,
} from "./styled"
import { exceptionalCareBullets } from "./constants"
import phoneTasks from "./images/phone-tasks.png"
import { H2 } from "../../ui"

interface ExceptionalCareSectionProps {
  title: string
  description: string
}

const ExceptionalCareSection = ({
  title,
  description,
}: ExceptionalCareSectionProps) => (
  <ExceptionalCareText>
    {title === "Budget friendly" ? (
      <>
        <Title>{title}</Title>
        <Description>
          Our pricing is transparent, so you know exactly what to expect each
          month. Our services can cost up to{" "}
          <a
            href="https://www.healthline.com/health/how-much-does-therapy-cost-a-deep-dive-into-prices#faq"
            style={{ color: "#626874" }}
          >
            <u>50% less</u>
          </a>{" "}
          than in-person care.
        </Description>
      </>
    ) : (
      <>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </>
    )}
  </ExceptionalCareText>
)

export const ExceptionalCare = () => {
  return (
    <ExceptionalCareWrapper>
      <HeadlineContainer>
        <H2>Exceptional care. Exceptional&nbsp;experience.</H2>
        <p>Here's why our clients rate us so&nbsp;highly.</p>
      </HeadlineContainer>
      <InnerContainer>
        <TextColumn>
          {exceptionalCareBullets
            .filter((bullet, i) => i < 2)
            .map((bullet: { title: string; description: string }) => (
              <ExceptionalCareSection
                title={bullet.title}
                description={bullet.description}
              />
            ))}
        </TextColumn>
        <ImageColumn>
          <InnerImageColumn>
            <img src={phoneTasks} alt="list of tasks on a phone" />
          </InnerImageColumn>
        </ImageColumn>
        <TextColumn>
          {exceptionalCareBullets
            .filter((bullet, i) => i >= 2)
            .map((bullet: { title: string; description: string }) => (
              <ExceptionalCareSection
                title={bullet.title}
                description={bullet.description}
              />
            ))}
        </TextColumn>
      </InnerContainer>
    </ExceptionalCareWrapper>
  )
}
