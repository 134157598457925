import styled from "styled-components"

export default styled.h1`
  font-style: italic;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #250044;
  margin: 40px auto 0;

  @media (min-width: 834px) {
    font-size: 40px;
    line-height: 55px;
    letter-spacing: -0.4px;
    margin-top: 68px;
  }
`
