import { Splash } from "../../../../shared/Splash"
import CareTeamCards from "./images/care-team-cards.svg"

export const HeroSection = () => {
  return (
    <Splash
      titleText="Meet your care team"
      subtitleText="Working with a great therapist or prescriber is an important part of your mental health journey. We have caring and professional therapists and prescribers here to guide you."
      image={CareTeamCards}
      background="linear-gradient(141deg, #FEF9EC 0%, #ECEFFE 100%)"
      buttons={[
        { text: "Find a therapist", link: "/care-team/therapists" },
        { text: "Find a prescriber", link: "/care-team/prescribers" },
      ]}
      shouldHideMobileImage
    />
  )
}
