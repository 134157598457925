import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image"
import { CtaButton } from "./LandingPageStyles"

const Wrapper = styled.div`
  background: var(
    --Gradient-Zen,
    linear-gradient(135deg, #fef9ec 0%, #eceffe 100%)
  );
  width: 100%;
  display: flex;
  padding: 64px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    padding: 48px 0;
  }
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;
  max-width: 992px;

  @media (max-width: 1024px) {
    max-width: 327px;
    gap: 32px;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`

const Title = styled.div`
  color: #1c1e21;
  text-align: center;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;

  @media (max-width: 1024px) {
    font-size: 24px;
  }
`

const Text = styled.div`
  max-width: 688px;
  font-family: Poppins;
  text-align: center;
  color: var(--Grey-900, #353a41);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  @media (max-width: 1024px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
`

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;

  @media (max-width: 1024px) {
    gap: 32px;
  }
`

const ItemWrapper = styled.div<{ align: string }>`
  display: flex;
  height: 450px;
  justify-content: center;
  align-items: center;
  flex-direction: ${({ align }) => (align === "right" ? "row" : "row-reverse")};

  gap: 80px;

  @media (max-width: 1024px) {
    flex-direction: column;
    height: fit-content;
    gap: 32px;
  }
`

const Feature = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  max-width: 462px;

  @media (max-width: 1024px) {
    width: 100%;
  }
`

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
`
const Icon = styled(GatsbyImage)`
  width: 100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;

  @media (max-width: 1024px) {
    max-width: 327px;
    gap: 0px;
  }
`

const ItemTitle = styled.div`
  color: #1c1e21;

  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`

const ContentText = styled.div`
  p {
    color: #1c1e21;
    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);

    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 16px;
  }

  @media (max-width: 1024px) {
    p {
      font-size: 14px;
      margin-top: 8px;
    }
  }
`

const Image = styled(GatsbyImage)`
  border-radius: 16px;

  @media (max-width: 1024px) {
    width: calc(100vw - 48px) !important;
    height: 320px !important;
  }

  @media (max-width: 600px) {
    width: calc(100vw - 48px) !important;
    height: calc(100vw - 48px) !important;
  }
`

const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 48px 64px;
  border-radius: 16px;
  background: #ffffff;
  margin-top: -16px;

  @media (max-width: 1024px) {
    font-size: 14px;
    padding: 32px 24px;
    margin-top: 0px;
    width: calc(100vw - 48px);
  }
`
const BottomText = styled.div`
  color: #353a41;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  max-width: 768px;

  @media (max-width: 1024px) {
    font-size: 14px;
    line-height: 24px;
  }
`

interface ContentHubTabDiniInterface {
  slice: {
    primary: {
      tab_id?: string
      tab_title: {
        text: string
      }
      dini_title: {
        text: string
      }
      dini_description: {
        html: string
      }
      dini_header_section: {
        text: string
      }
      dini_cta_btn_link?: {
        url: string
      }
      dini_cta_btn_text?: {
        text: string
      }
    }
    items: {
      dini_section_icon: {
        gatsbyImageData: IGatsbyImageData
      }
      dini_section_img: {
        gatsbyImageData: IGatsbyImageData
      }
      dini_img_section_align: {
        text: string
      }
      dini_section_title: {
        text: string
      }
      dini_section_content: {
        html: string
      }
    }[]
  }
}

const ContentHubTabDini = ({ slice }: ContentHubTabDiniInterface) => {
  const {
    tab_id,
    dini_title,
    dini_description,
    dini_header_section,
    dini_cta_btn_link,
    dini_cta_btn_text,
  } = slice.primary
  const { items } = slice

  return (
    <Wrapper id={tab_id}>
      <Container>
        <HeaderContainer>
          <Title>{dini_title.text}</Title>
          <Text dangerouslySetInnerHTML={{ __html: dini_description.html }} />
        </HeaderContainer>
        <ListWrapper>
          {items.map(
            ({
              dini_section_icon,
              dini_section_img,
              dini_img_section_align,
              dini_section_title,
              dini_section_content,
            }) => (
              <ItemWrapper align={dini_img_section_align.text}>
                <Feature>
                  <IconWrapper>
                    <Icon
                      image={getImage(dini_section_icon) as IGatsbyImageData}
                      alt=""
                    />
                  </IconWrapper>
                  <Content>
                    <ItemTitle>{dini_section_title.text}</ItemTitle>
                    <ContentText
                      dangerouslySetInnerHTML={{
                        __html: dini_section_content.html,
                      }}
                    />
                  </Content>
                </Feature>
                <Image
                  image={getImage(dini_section_img) as IGatsbyImageData}
                  alt=""
                />
              </ItemWrapper>
            )
          )}
        </ListWrapper>
        <BottomContainer>
          <BottomText>{dini_header_section.text}</BottomText>
          <CtaButton
            to={dini_cta_btn_link?.url || "https://cerebral.com/app/welcome"}
            width="320px"
          >
            {dini_cta_btn_text?.text}
          </CtaButton>
        </BottomContainer>
      </Container>
    </Wrapper>
  )
}

export const query = graphql`
  fragment PageContentHubTabDiniSlice on PrismicPageDataBodyContentHubTabDini {
    id
    slice_type
    primary {
      tab_id
      tab_title {
        text
      }
      dini_title {
        text
      }
      dini_description {
        html
      }
      dini_header_section {
        text
      }
      dini_cta_btn_link {
        url
      }
      dini_cta_btn_text {
        text
      }
    }
    items {
      dini_section_icon {
        gatsbyImageData(placeholder: BLURRED, layout: FIXED)
      }
      dini_section_img {
        gatsbyImageData(
          placeholder: BLURRED
          layout: FIXED
          width: 450
          height: 450
        )
      }
      dini_img_section_align {
        text
      }
      dini_section_title {
        text
      }
      dini_section_content {
        html
      }
    }
  }
`

export default ContentHubTabDini
