import { useState } from "react"
import {
  FAQAnswer,
  FAQBlock,
  FAQInnerWrapper,
  FAQQuestion,
  FAQSectionWrapper,
} from "./styled"
import Plus from "./assets/u_plus.svg"
import Minus from "./assets/u_minus.svg"
import InsuranceModals from "../../InsuranceModals"

export type FaqType = {
  question: {
    document: {
      data: { question: { text: string }; answer: { html: string } }
    }
  }
}
interface IFAQSectionProps {
  faqs: FaqType[]
  title?: string
  id?: string
  noTitle?: boolean
  noPadding?: boolean
  margin?: string
  withInsuranceModal?: boolean
}

export const FAQSection = ({
  faqs,
  title,
  id,
  noTitle = false,
  noPadding = false,
  margin = "",
  withInsuranceModal,
}: IFAQSectionProps) => {
  const [openedFAQ, setOpenedFAQ] = useState<number | null>(null)

  const handleQuestionClick = (i: number) => {
    setOpenedFAQ(current => (current === i ? null : i))
  }
  return (
    <FAQSectionWrapper id={id} noPadding={noPadding} margin={margin}>
      <FAQInnerWrapper>
        {!noTitle && <h2>{title || "Frequently asked questions"}</h2>}
        {faqs.map((faqData, i) => {
          const data = faqData.question.document?.data
          const question = data?.question?.text
          const answer = data?.answer?.html
            .replaceAll("&lt;", "<")
            .replaceAll("&gt;", ">")

          return (
            <FAQBlock key={question}>
              <FAQQuestion
                onClick={() => handleQuestionClick(i)}
                id={`faq-${i}`}
              >
                <h3>{question}</h3>
                {i === openedFAQ ? (
                  <img src={Minus} alt="Close answer" />
                ) : (
                  <img src={Plus} alt="Open answer" />
                )}
              </FAQQuestion>
              <FAQAnswer
                isOpen={openedFAQ === i}
                dangerouslySetInnerHTML={{ __html: answer }}
              />
            </FAQBlock>
          )
        })}
      </FAQInnerWrapper>
      {withInsuranceModal ? (
        <div style={{ margin: "64px auto 0", textAlign: "center" }}>
          <InsuranceModals cta_text="Check my coverage" disableCTALink />
        </div>
      ) : null}
    </FAQSectionWrapper>
  )
}
