import CircleCheck from "../../images/fi_check-circle.svg"
import XCircle from "../../images/fi_x-circle.svg"

interface Column {
  id: number
  text?: string
  img?: string
  isDetailsElement?: boolean
  cssClassName?: string
  cssClassNameDetailsWrapper?: string
  description?: string
}

export const rows: Column[][] = [
  [
    {
      id: 1,
      text: "Affordable Pricing",
      description:
        "Our services are just as effective and half the cost of most traditional in-person care.",
    },
    {
      id: 2,
      description: "$82/week",
    },
    { id: 3, description: "$100+/week" },
  ],
  [
    {
      id: 1,
      text: "Convenient online sessions",
      description:
        "Connect when and where it’s easiest for you. Just as effective as in-person care — without the traffic.",
    },
    { id: 2, img: CircleCheck },
    { id: 3, img: XCircle },
  ],
  [
    {
      id: 1,
      text: "Same-week appointments",
      description:
        "Booking sessions is easy — and many of our therapists have availability to meet within 48 hours if you need to reschedule.",
    },
    { id: 2, img: CircleCheck },
    { id: 3, img: XCircle },
  ],
  [
    {
      id: 1,
      text: "Between session support",
      description:
        "Gain direct access to our support staff, who can help you manage appointments and stay on track with your care.",
    },
    { id: 2, img: CircleCheck },
    { id: 3, img: XCircle },
  ],
  [
    {
      id: 1,
      text: "Switch therapists anytime",
      description:
        "If you feel your therapist is not the right match, let us know and we’ll help you select someone new for no added charge.",
    },
    { id: 2, img: CircleCheck },
    { id: 3, img: XCircle },
  ],
]
