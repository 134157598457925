import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { StoreButtonsWrapper } from "./styled"

export const Stores = (): JSX.Element => {
  return (
    <StoreButtonsWrapper>
      <a
        href="https://apps.apple.com/us/app/cerebral-mental-health/id1537193671"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1276560000&h=7e7b68fad19738b5649a1bfb78ff46e9"
          alt="Download on the App Store"
          loading="lazy"
          style={{ width: 120, height: 40 }}
        />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.cerebral.cerebral"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
          alt="Get it on Google Play"
          loading="lazy"
          style={{ width: 150, height: 60 }}
        />
      </a>
    </StoreButtonsWrapper>
  )
}
