import React from "react"
import { H2, BodyRegular, TherapistCard, Button, Slider } from "../../ui"
import {
  Container,
  TherapistsDesktopContainer,
  TherapistsMobileContainer,
} from "./styled"
import { therapists } from "./constants"

interface ITherapistProps {
  variant: "clinicians" | "therapists" | "therapists and prescribers"
  background: string
  hideViewBio?: boolean
}

export const Therapists = ({
  variant,
  background,
  hideViewBio = false,
}: ITherapistProps): JSX.Element => {
  return (
    <Container background={background}>
      <H2>Find a great mental healthcare professional for you</H2>
      <BodyRegular>
        Our clinicians are highly trained and qualified to guide you toward your
        goals. We let you choose who to develop a great relationship with.
      </BodyRegular>

      <TherapistsDesktopContainer>
        {therapists.map(therapist => (
          <TherapistCard
            key={therapist.name}
            therapist={therapist}
            hideViewBio={hideViewBio}
          />
        ))}
      </TherapistsDesktopContainer>

      <TherapistsMobileContainer>
        <Slider hideIndicators>
          {therapists.map(therapist => (
            <TherapistCard
              key={therapist.name}
              therapist={therapist}
              hideViewBio={hideViewBio}
            />
          ))}
        </Slider>
      </TherapistsMobileContainer>
    </Container>
  )
}
