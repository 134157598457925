import {
  Container,
  SectionBody,
  Title,
  Section,
  SectionImage,
  SectionTitle,
  SectionDescription,
  ButtonContainer,
} from "./styled"
import { copy } from "./copy"
import { GetStartedButton } from "../../../shared"

export const SimpleScheduling = () => {
  return (
    <Container>
      <Title>Simple scheduling and support</Title>
      <SectionBody>
        {copy.map(c => (
          <Section>
            <SectionImage src={c.image} />
            <SectionTitle>{c.title}</SectionTitle>
            <SectionDescription>{c.description}</SectionDescription>
          </Section>
        ))}
      </SectionBody>
      <ButtonContainer>
        <GetStartedButton />
      </ButtonContainer>
    </Container>
  )
}
