interface StateName {
  shortName: string
  fullName: string
}

const getFullnameState = (shortName: string): string | undefined => {
  const states: StateName[] = [
    { shortName: "AL", fullName: "Alabama" },
    { shortName: "AK", fullName: "Alaska" },
    { shortName: "AZ", fullName: "Arizona" },
    { shortName: "AR", fullName: "Arkansas" },
    { shortName: "CA", fullName: "California" },
    { shortName: "CO", fullName: "Colorado" },
    { shortName: "CT", fullName: "Connecticut" },
    { shortName: "DE", fullName: "Delaware" },
    { shortName: "FL", fullName: "Florida" },
    { shortName: "GA", fullName: "Georgia" },
    { shortName: "HI", fullName: "Hawaii" },
    { shortName: "AS", fullName: "American Samoa" },
    { shortName: "ID", fullName: "Idaho" },
    { shortName: "IL", fullName: "Illinois" },
    { shortName: "IN", fullName: "Indiana" },
    { shortName: "IA", fullName: "Iowa" },
    { shortName: "KS", fullName: "Kansas" },
    { shortName: "KY", fullName: "Kentucky" },
    { shortName: "LA", fullName: "Louisiana" },
    { shortName: "ME", fullName: "Maine" },
    { shortName: "MD", fullName: "Maryland" },
    { shortName: "MA", fullName: "Massachusetts" },
    { shortName: "MI", fullName: "Michigan" },
    { shortName: "MN", fullName: "Minnesota" },
    { shortName: "MS", fullName: "Mississippi" },
    { shortName: "MO", fullName: "Missouri" },
    { shortName: "MT", fullName: "Montana" },
    { shortName: "NC", fullName: "North Carolina" },
    { shortName: "ND", fullName: "North Dakota" },
    { shortName: "NE", fullName: "Nebraska" },
    { shortName: "NV", fullName: "Nevada" },
    { shortName: "NH", fullName: "New Hampshire" },
    { shortName: "NJ", fullName: "New Jersey" },
    { shortName: "NM", fullName: "New Mexico" },
    { shortName: "NY", fullName: "New York" },
    { shortName: "OH", fullName: "Ohio" },
    { shortName: "OK", fullName: "Oklahoma" },
    { shortName: "OR", fullName: "Oregon" },
    { shortName: "PA", fullName: "Pennsylvania" },
    { shortName: "PR", fullName: "Puerto Rico" },
    { shortName: "RI", fullName: "Rhode Island" },
    { shortName: "SC", fullName: "South Carolina" },
    { shortName: "SD", fullName: "South Dakota" },
    { shortName: "TN", fullName: "Tennessee" },
    { shortName: "TX", fullName: "Texas" },
    { shortName: "UT", fullName: "Utah" },
    { shortName: "VT", fullName: "Vermont" },
    { shortName: "VA", fullName: "Virginia" },
    { shortName: "DC", fullName: "District of Columbia" },
    { shortName: "WA", fullName: "Washington" },
    { shortName: "WV", fullName: "West Virginia" },
    { shortName: "WI", fullName: "Wisconsin" },
    { shortName: "WY", fullName: "Wyoming" },
  ]
  return states.find(item => item.shortName === shortName)?.fullName
}

export interface RichText {
  type: string
  text: string
  spans: never[]
  direction: string
}

export const convertCSVToInsuranceMap = (rows: RichText[]) => {
  const insuranceMap = new Map()

  for (let i = 0; i < rows.length; i++) {
    const row = rows[i].text.split(",")
    const insurance = row[0]
    const state = getFullnameState(row[1])

    if (!insuranceMap.has(state)) {
      insuranceMap.set(state, [])
    }

    insuranceMap.get(state).push(insurance)
  }

  return insuranceMap
}
