import React from "react"
import { Container } from "./styled"
import { Dropdown, H3, BodyRegular } from "../../ui"

export interface ISelectLocationProps {
  stateSelected: string
  onChange: (value: string) => void
  carePersonType: string
}

export const SelectLocation = ({
  stateSelected,
  onChange,
  carePersonType,
}: ISelectLocationProps): JSX.Element => {
  return (
    <Container>
      <H3>Where do you live?</H3>
      <Dropdown
        stateSelected={stateSelected}
        onChange={onChange}
        carePersonType={carePersonType}
      />
      <BodyRegular>
        Your location helps us connect you with Cerebral {carePersonType}{" "}
        licensed to practice in your state.
      </BodyRegular>
    </Container>
  )
}
