import React, { Fragment } from "react"
import { H4, BodyRegular } from "../../ui"
import {
  TherapistCardContainer,
  GeneralInfoContainer,
  GeneralInfoTextContainer,
  GeneralInfoInnerTextContainer,
  BlueText,
} from "./styled"
import starSvg from "./assets/star.svg"

interface TherapistCardProps {
  therapist: {
    name: string
    type: string
    logo: string
    rating: string
    location: string
    specialties: string[]
    desc: string
    bio: { url: string }
  }
  hideViewBio?: boolean
}
export const TherapistCard = ({
  therapist,
  hideViewBio = false,
}: TherapistCardProps): JSX.Element => {
  return (
    <TherapistCardContainer>
      <GeneralInfoContainer>
        <img loading="lazy" src={therapist.logo} alt={therapist.name} />
        <GeneralInfoTextContainer>
          <H4>{therapist.name}</H4>
          <span>{therapist.type}</span>
          <GeneralInfoInnerTextContainer>
            <img loading="lazy" src={starSvg} alt="" />
            <span>
              <strong>{therapist.rating}</strong>/5
            </span>
            <span>{therapist.location}</span>
          </GeneralInfoInnerTextContainer>
        </GeneralInfoTextContainer>
      </GeneralInfoContainer>
      <H4>Specialties</H4>
      <div>
        {therapist.specialties.map((specialty, i) => (
          <Fragment key={specialty}>
            <BlueText>{specialty}</BlueText>
            {i !== therapist.specialties.length - 1 && ", "}
          </Fragment>
        ))}
      </div>
      <BodyRegular>{therapist.desc}</BodyRegular>
      {hideViewBio ? null : <a href={therapist.bio.url}>View bio</a>}
    </TherapistCardContainer>
  )
}
