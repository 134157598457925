import CheckImage from "./images/check_icon.svg"
import { IconImage } from "./styled"
import Receipt from "./images/receipt_alt.svg"
import MoneyWithDrawal from "./images/money_withdrawal.svg"

export const tabContents = [
  {
    titleContent:
      "Use your FSA or HSA card as your primary payment method to help cover the cost of eligible services",
    headerTitle: "Covered costs typically include:",
    tabMap: [
      {
        icon: <IconImage src={CheckImage} />,
        header: "Subscription plan",
      },
      {
        icon: <IconImage src={CheckImage} />,
        header: "Medication",
      },
      {
        icon: <IconImage src={CheckImage} />,
        header: "Out-of-network copays and deductibles",
      },
    ],
  },
  {
    titleContent:
      "After you’ve paid for your services, you can request an itemized invoice (superbill) from Cerebral and use it to submit an out-of-network claim to your insurance provider.",
    headerTitle: "Good to know:",
    tabMap: [
      {
        icon: <IconImage src={CheckImage} />,
        header:
          "Most of the time, you must meet your deductible before your insurance provider will start to reimburse you for out-of-network services",
      },
      {
        icon: <IconImage src={CheckImage} />,
        header:
          "Reimbursement will vary depending on your plan and insurance provider",
      },
    ],
  },
]

export const Links = {
  fsa: "https://www.healthcare.gov/have-job-based-coverage/flexible-spending-accounts/#:~:text=You%20can%20spend%20FSA%20funds,are%20allowed%20without%20a%20prescription",
  hsa: "https://www.healthcare.gov/high-deductible-health-plan/hdhp-hsa-work-together/",
}
export const Message = {
  fsa: "Learn how an FSA works",
  hsa: "Learn how an HSA works",
  Out_of_network:
    "Contact your insurance provider to find out how to submit an out-of-network claim",
}

export const tabHeaderContent = [
  {
    icon: MoneyWithDrawal,
    alt: "Money withdrawal icon",
    headerMobile: "FSA/HSA",
    header: "Use your FSA/HSA funds",
  },
  {
    icon: Receipt,
    alt: "receipt paper icon",
    headerMobile: "Superbill",
    header: "Submit a claim",
  },
]
