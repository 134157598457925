import styled from "styled-components"
import { colors } from "../../../themes/colors"
import { Link } from "gatsby"

export const CommunityWrapper = styled.div`
  margin-top: 32px;

  h6 {
    font-size: 14px;
    margin-bottom: 8px;
    color: ${colors.c_grey[700]};
    padding: 0 24px;
  }

  @media (min-width: 1024px) {
    h6 {
      padding: 0 40px;
    }
  }
`

export const ReferAFriend = styled(Link)`
  background: ${colors.c_secondary[50]};
  border-radius: 16px;
  margin: 0px 24px 40px;
  display: flex;
  align-items: center;
  padding: 16px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  text-decoration: none;
  color: ${colors.c_primary[900]};
  justify-content: space-between;

  img {
    width: 40px;
    height: 40px;
  }

  @media (min-width: 1024px) {
    margin: 8px 40px 40px;
  }

  &:hover {
    background: ${colors.c_secondary[100]};
  }

  &:focus {
    border-color: ${colors.c_secondary[600]};
    background: ${colors.c_secondary[100]};
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }
`

export const ReferAFriendArrowWrapper = styled.p`
  img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
`
