import styled from "styled-components"
import { colors } from "../../../themes/colors"

export const GridHeader = styled.div`
  display: flex;
  min-height: 72px;
  justify-content: center;

  p {
    padding-top: 24px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #353a41;
  }

  @media (width <= 700px) {
    p {
      font-size: 14px !important;
    }
  }
`

export const GridWrapper = styled.div<{ hasBorder?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 200px 200px;
  align-items: center;
  width: 992px;
  border-bottom: ${props =>
    props.hasBorder ? "1px solid rgb(225, 226, 232)" : ""};

  @media (width <= 992px) {
    width: 660px;
    grid-template-columns: 1fr 150px 150px;
  }

  @media (width <= 700px) {
    min-width: 327px;
    width: 100%;
    grid-template-columns: 2fr 1fr 1fr;

    p {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      text-align: left;
    }

    img {
      width: 20px;
      padding: 16px 0;
    }

    details {
      pointer-events: none;
    }

    details p {
      display: none;
    }
  }

  .even-column {
    background: #ffffff;
  }

  .rounded-upper {
    border-radius: 10px 10px 0 0;
  }

  .rounded-lower {
    border-radius: 0 0 10px 10px;
  }

  .row-separator {
    border-bottom: 1px solid #e1e2e8;
  }

  @media (width >= 700px) {
    details[open] > summary::after {
      transform: rotate(-180deg);
      vertical-align: -20%;
    }

    details[open] {
      height: 100%;
    }

    details p {
      max-height: 72px;
      margin-top: 10px;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
    }
  }

  p {
    text-align: center;
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  margin-bottom: 60px;

  h2 {
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    line-height: 32px;
  }

  span {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: ${colors.c_grey[900]};
    line-height: 24px;
  }

  @media (max-width: 992px) {
    h2 {
      font-size: 24px;
    }
  }
`

export const BulletText = styled.div`
  span.tooltip {
    position: relative;
    display: inline-block;
    text-decoration: underline;
  }

  span.tooltiptext {
    visibility: hidden;
    width: 246px;
    background-color: #fafafc;

    color: #1c1e21;
    text-align: left;
    border-radius: 6px;
    padding: 8px 16px;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: -10%;
    margin-left: -70px;
    margin-top: 10px;
    font-size: 12px;
    line-height: 16px;
  }

  span.tooltip:hover {
    span.tooltiptext {
      visibility: visible;
      border: 1px solid #d1d3d7;

      &::before {
        content: "";
        display: block;
        position: absolute;
        left: 120px;
        bottom: 100%;
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-bottom-color: #d1d3d7;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        left: 121px;
        bottom: 100%;
        width: 0;
        height: 0;
        border: 9px solid transparent;
        border-bottom-color: #fafafc;
      }
    }
  }

  &,
  p {
    text-align: left;
    color: #353a41;
    font-family: Poppins;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  @media (max-width: 700px) {
    display: none;
  }
`
export const ComparisonItemWrapper = styled.div<{
  minH: number
  bg?: string
  borderRadiusBottom?: string
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  min-height: ${props => `${props.minH}px`};
  background: ${props => props.bg || "transparent"};
  border-radius: ${props =>
    props.borderRadiusBottom
      ? `0 0 ${props.borderRadiusBottom} ${props.borderRadiusBottom}`
      : "none"};

  @media (max-width: 700px) {
    padding: 24px 0;
    font-size: 14px;
  }

  @media (max-width: 380px) {
    font-size: 12px;
  }
`

export const OfferringWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding: 24px;
  cursor: pointer;

  @media (max-width: 700px) {
    padding: 24px 0;

    svg {
      display: none;
    }
  }
`

export const OfferringTitleWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

export const OffringTitle = styled.div`
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: #353a41;
`
