import { SubItemList, SubMenuItem } from "../SubMenu/styled"
import RightChevron from "../assets/fi_chevron-right.svg"
import GiftIcon from "../assets/RAFicon-gift.svg"
import { Divider } from "../shared/styled"
import {
  CommunityWrapper,
  ReferAFriend,
  ReferAFriendArrowWrapper,
} from "./styled"
import { SubNavOptions } from "../types"

const communityItems: SubNavOptions[] = [
  {
    subMenuTitle: "LGBTQIA+",
    link: "https://get.cerebral.com/lgbtqia-mental-health/",
  },
  {
    subMenuTitle: "Military veterans",
    link: "https://get.cerebral.com/heroes/",
  },
]

export const Community = () => {
  return (
    <CommunityWrapper>
      <h6>Communities</h6>
      <Divider showOnDesktop />
      <SubItemList>
        {communityItems.map(item => (
          <a href={item.link}>
            <SubMenuItem key={item.subMenuTitle}>
              <button>{item.subMenuTitle}</button>
              <img src={RightChevron} alt="right chevron arrow" />
            </SubMenuItem>
          </a>
        ))}
      </SubItemList>
      <ReferAFriend to="/refer-v2/">
        <div>
          <img src={GiftIcon} alt="gift icon" />
          <p>Refer a friend</p>
        </div>
        <ReferAFriendArrowWrapper>
          <img src={RightChevron} alt="right chevron arrow" />
        </ReferAFriendArrowWrapper>
      </ReferAFriend>
    </CommunityWrapper>
  )
}
