import {
  BookButton,
  DescriptionText,
  DescriptionTitle,
  FooterText,
  UnavailabilityWrapper,
} from "./styled"

interface IFindSomeOneFooterProps {
  path: string
  text?: string
}

interface IUnavailabilityTextProps {
  name: string
  mt?: string
}

interface IUnderCalendarTypeFormPayerProps {
  external_source: string
  preferred_clinician: string
  widget_state: string
  widget_payer: string
}

export const SupportLinkFooter = () => {
  return (
    <FooterText>
      If you don't see a suitable time, <br />
      contact <a href="mailto:support@cerebral.com">support@cerebral.com</a>
    </FooterText>
  )
}

export const SelfPlanLinkFooter = () => {
  return (
    <FooterText>
      Don’t want to use insurance?
      <br /> You can{" "}
      <a href={`${process.env.GATSBY_BASE_URL}/app/pre-signup`}>
        try our self-pay plan
      </a>
    </FooterText>
  )
}

export const FindSomeOneFooter = ({
  path,
  text,
}: IFindSomeOneFooterProps): JSX.Element => {
  return (
    <FooterText>
      <a href={`${process.env.GATSBY_BASE_URL}/${path}`}>
        {text || "Let's find someone similar"}
      </a>
    </FooterText>
  )
}

export const UnavailabilityText = ({ name, mt }: IUnavailabilityTextProps) => {
  return (
    <UnavailabilityWrapper mt={mt}>
      <DescriptionTitle>{name} is unavailable</DescriptionTitle>
      <DescriptionText>
        Unfortunately {name} is unavailable <br />
        at the moment.
      </DescriptionText>
    </UnavailabilityWrapper>
  )
}

export const UnderCalendarTypeFormPayer = ({
  external_source,
  preferred_clinician,
  widget_state,
  widget_payer,
}: IUnderCalendarTypeFormPayerProps) => {
  return (
    <>
      <DescriptionText>
        Answer a few questions and we will <br />
        confirm to you in 24 hours if your
        <br /> insurance covers this clinician
      </DescriptionText>
      <BookButton
        onClick={() =>
          (window.location.href = `https://forms.cerebral.com/to/EtjRbfRz?external_source=${external_source}&preferred_clinician=${preferred_clinician}&widget_state=${widget_state}&widget_payer=${widget_payer}`)
        }
      >
        Get started
      </BookButton>
      <SelfPlanLinkFooter />
    </>
  )
}

export const UnderCalendarInsuranceNotCover = ({
  path,
}: IFindSomeOneFooterProps) => {
  return (
    <>
      <DescriptionText>
        Sorry your insurance does not cover
        <br /> this clinician
      </DescriptionText>
      <FindSomeOneFooter path={path} />
    </>
  )
}
