import { Navigation } from "../ui/Navigation"
import {
  WelcomeBackPageWrapper,
  WelcomeBackContainer,
  ImageContainer,
  WelcomeBackText,
  InsuranceHeader,
  HeadlineText,
  SignUpText,
  SignUpLink,
  SignUpContainer,
  ButtonContainer,
  SpinnerWrapper,
  InsuranceLogos,
} from "./styled"
import { headline, images } from "./constants"
import { useEffect, useState } from "react"
import { useWindowSize } from "../hooks"
import { Picture, Button } from "../ui"
import WelcomeBackDesktop from "./images/welcome-back-desktop.webp"
import Spinner from "../ui/Spinner"
import CignaIcon from "../../images/cigna.png"
import BCBSIcon from "../../images/blue-cross.png"
import UnitedHealthcareIcon from "../../images/united-healthcare.png"
import OptumIcon from "../../images/optum.png"
import { QueryToCookieConverter } from "../../lib/QueryToCookieConverter"

export const WelcomeBackPageBody = ({
  insurance = false,
}: {
  insurance?: boolean
}) => {
  const [headlineText, setHeadlineText] = useState("")
  const [imageType, setImageType] = useState<"default" | "insurance">("default")
  const [searchParams, setSearchParams] = useState("")
  const { isMobile } = useWindowSize()

  useEffect(() => {
    let headlineType
    let params = new URLSearchParams(window.location.search)
    if (insurance) {
      headlineType = "insurance"
      setImageType("insurance")
      params.append("ob_flow", "insurance_eligibility_check")
    } else {
      headlineType = params.get("headline") || "default"
      params.append("screenHint", "signup")
    }

    const fbclidCookie = new QueryToCookieConverter("fbclid").getCookieValue()
    const ttclidCookie = new QueryToCookieConverter("ttclid").getCookieValue()
    const gclidCookie = new QueryToCookieConverter("gclid").getCookieValue()

    const fbclidQuery = params.get("fbclid")
    const ttclidQuery = params.get("ttclid")
    const gclidQuery = params.get("gclid")

    fbclidCookie && !fbclidQuery && params.append("fbclid", fbclidCookie)
    ttclidCookie && !ttclidQuery && params.append("ttclid", ttclidCookie)
    gclidCookie && !gclidQuery && params.append("gclid", gclidCookie)

    setSearchParams(params.toString())
    setHeadlineText(headline[headlineType])
  }, [])

  if (headlineText === "") {
    return (
      <SpinnerWrapper>
        <Spinner className="" />
      </SpinnerWrapper>
    )
  }

  return (
    <WelcomeBackPageWrapper insurance={insurance}>
      <ImageContainer>
        {insurance && isMobile ? null : (
          <Picture
            images={images[imageType]}
            fallBack={WelcomeBackDesktop}
            alt="Welcome Back"
          />
        )}
      </ImageContainer>
      <WelcomeBackContainer insurance={insurance}>
        {insurance ? (
          <InsuranceHeader>
            Therapy and psychiatry <br />
            <b>{headlineText}</b>
          </InsuranceHeader>
        ) : (
          <>
            <WelcomeBackText>Welcome back</WelcomeBackText>
            <HeadlineText>{headlineText}</HeadlineText>
          </>
        )}
        <ButtonContainer>
          <Button
            text={insurance ? "Sign in" : "Get started"}
            to={`${process.env.GATSBY_LOGIN_URL}?${searchParams}`}
          />
        </ButtonContainer>
        <SignUpContainer>
          <SignUpText>Don't have an account?</SignUpText>
          <SignUpLink to={`${process.env.GATSBY_START_URL}?${searchParams}`}>
            Sign up
          </SignUpLink>
        </SignUpContainer>
        {insurance && (
          <InsuranceLogos>
            <img height={64} src={CignaIcon} />
            <img height={64} src={BCBSIcon} />
            <img height={64} src={UnitedHealthcareIcon} />
            <img height={64} src={OptumIcon} />
          </InsuranceLogos>
        )}
      </WelcomeBackContainer>
    </WelcomeBackPageWrapper>
  )
}

export const WelcomeBackPage = ({
  insurance = false,
}: {
  insurance?: boolean
}) => {
  return (
    <>
      <Navigation />
      <WelcomeBackPageBody insurance={insurance} />
    </>
  )
}
