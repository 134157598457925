import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { HeroHeaderTitle } from "./LandingPageStyles"

const SubMenuNavBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  &.fixed-top {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0 0px 4px 0px #250044;
    z-index: 100;
  }
`

const Section = styled.span`
  ${HeroHeaderTitle}
  cursor: pointer;
  color: #6786ff;
  font-size: 28px;
  letter-spacing: -1px;
  margin: 0 20px;

  @media (max-width: 1140px) {
    font-size: 20px;
    line-height: 42px;
  }
`

const sectionsMapping = {
  news_links: "News Coverage",
  press_release: "Press Release",
  data_and_research: "Data and Research",
  media_kit: "Media Kit",
}

let navBarOffsetHeight = 0

const scrollTo = (elementId: string) => {
  const element = document.getElementById(elementId)

  if (element) {
    window.scrollTo({
      top: element.offsetTop - navBarOffsetHeight,
      behavior: "smooth",
    })
  }
}

interface SubMenuNavBarInterface {
  slice: {
    id: string
    items: {
      section: string
    }[]
  }
  otherSlices: {
    id: string
    slice_type: keyof typeof sectionsMapping
  }[]
}

const SubMenuNavBar = ({ slice, otherSlices }: SubMenuNavBarInterface) => {
  const sectionTypes = slice.items.map(item => item.section)
  const sections = otherSlices
    .map(otherSlice => {
      if (sectionTypes.includes(sectionsMapping[otherSlice.slice_type])) {
        return {
          id: otherSlice.id,
          name: sectionsMapping[otherSlice.slice_type],
        }
      }
    })
    .filter(section => section != null)

  const [stickyClass, setStickyClass] = useState("")

  const stickNavbarWithOffset = (
    navBarOffsetTop: number,
    navBarOffsetHeight: number
  ) => {
    if (window !== undefined) {
      if (window.scrollY > navBarOffsetTop) {
        setStickyClass("fixed-top")
        document.body.style.paddingTop = `${navBarOffsetHeight}px`
      } else {
        setStickyClass("")
        document.body.style.paddingTop = "0"
      }
    }
  }

  useEffect(() => {
    const subMenuNavBar = document.getElementById(slice.id)
    const navBarOffsetTop = subMenuNavBar ? subMenuNavBar.offsetTop : 0
    navBarOffsetHeight = subMenuNavBar ? subMenuNavBar.offsetHeight : 0

    const stickNavbar = () =>
      stickNavbarWithOffset(navBarOffsetTop, navBarOffsetHeight)

    window.addEventListener("scroll", stickNavbar)

    return () => {
      window.removeEventListener("scroll", stickNavbar)
    }
  }, [])

  return (
    <SubMenuNavBarWrapper id={slice.id} className={stickyClass}>
      {sections.map((section, index) => (
        <Section onClick={() => scrollTo(section.id)} key={index}>
          {section.name}
        </Section>
      ))}
    </SubMenuNavBarWrapper>
  )
}

export const query = graphql`
  fragment PageSubMenuNavBarSlice on PrismicPageDataBodySubMenuNavBar {
    id
    slice_type
    items {
      section
    }
    primary {
      slice_id {
        text
      }
    }
  }
`

export default SubMenuNavBar
