import { useEffect, useState } from "react"
import { CSSTransition } from "react-transition-group"
import { StaticImage } from "gatsby-plugin-image"
import { ItemList, Item, SubMenuBackground, Logo } from "./styled"
import { MobileButtonContainer } from "../shared/MobileButtonContainer"
import CloseIconImg from "../assets/close-icon.svg"
import { Options } from "../types"
import { navigationItems } from "../constants"
import { SubMenu } from "../SubMenu"
import logoImage from "../../../../images/logo.svg"
import RightChevron from "../assets/fi_chevron-right.svg"
import {
  CloseIcon,
  Divider,
  MobileHeader,
  ItemListWrapper,
  DesktopMenuWrapper,
} from "../shared/styled"

interface MenuProps {
  isMobileMenuOpen: boolean
  setIsMobileMenuOpen: (isOpen: boolean) => void
  isBannerShown?: boolean
}

export const Menu = ({
  isMobileMenuOpen,
  setIsMobileMenuOpen,
  isBannerShown,
}: MenuProps) => {
  const [selectedOption, setSelectedOption] = useState<Options | null>(null)
  const [isTransitioning, setIsTransitioning] = useState(false)
  const [disableTransition, setDisableTransition] = useState(false)

  const handleMenuItemClick = (menuItemSelected: Options) => {
    if (selectedOption === menuItemSelected) {
      setSelectedOption(null)
      return
    }

    if (!selectedOption) {
      setSelectedOption(menuItemSelected)
      return
    }

    // the below block executes if a menu item is selected and the user clicks a different one
    setIsTransitioning(true)
    setSelectedOption(null)
    setTimeout(() => {
      setSelectedOption(menuItemSelected)
      setIsTransitioning(false)
    }, 300)
  }

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false)
    setSelectedOption(null)
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setSelectedOption(null)
      }
    }
    window.addEventListener("keydown", handleKeyDown)
    return () => window.removeEventListener("keydown", handleKeyDown)
  }, [])

  useEffect(() => {
    let resizeTimer: NodeJS.Timeout
    const resizeListener = () => {
      setDisableTransition(true)
      clearTimeout(resizeTimer)
      resizeTimer = setTimeout(() => {
        setDisableTransition(false)
      }, 400)
    }

    window.addEventListener("resize", resizeListener)

    return () => window.removeEventListener("resize", resizeListener)
  }, [])

  const renderItemList = () => {
    return (
      <ItemList>
        {navigationItems.map(item => (
          <Item
            key={item.mainMenuTitle}
            anySelectedItems={!!selectedOption}
            isSelected={selectedOption === item.mainMenuTitle}
            onClick={() => handleMenuItemClick(item.mainMenuTitle)}
          >
            <button>{item.mainMenuTitle}</button>
            <img src={RightChevron} alt="right chevron arrow" />
          </Item>
        ))}
      </ItemList>
    )
  }

  return (
    <>
      {/* mobile main menu */}

      <ItemListWrapper
        isOpen={isMobileMenuOpen}
        disableTransition={disableTransition}
      >
        <MobileHeader>
          <Logo to="/">
            <img src={logoImage} alt="Cerebral logo" />
          </Logo>
          <CloseIcon
            src={CloseIconImg}
            alt="close icon"
            onClick={() => closeMobileMenu()}
          />
        </MobileHeader>
        <Divider />
        {renderItemList()}
        {isMobileMenuOpen && <MobileButtonContainer />}
      </ItemListWrapper>

      {/* desktop main menu */}
      <DesktopMenuWrapper>{renderItemList()}</DesktopMenuWrapper>

      {navigationItems.map(item => (
        <SubMenu
          key={item.mainMenuTitle}
          closeMobileMenu={() => closeMobileMenu()}
          closeSubMenu={() => setSelectedOption(null)}
          subMenuData={item}
          isBannerShown={isBannerShown}
          isOpen={selectedOption === item.mainMenuTitle}
          disableTransition={disableTransition}
        />
      ))}

      {/* this background overlays the page as the submenu is open */}
      <CSSTransition
        in={!!selectedOption || isTransitioning}
        timeout={300}
        classNames="fade"
        unmountOnExit
      >
        <SubMenuBackground
          onClick={() => setSelectedOption(null)}
          isBannerShown={isBannerShown}
        />
      </CSSTransition>
    </>
  )
}
