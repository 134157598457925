import CareTeam from "./assets/care-team.svg"
import CouplesTherapy from "./assets/couples-therapy.svg"
import Form from "./assets/form.svg"

export const words = ["balanced", "uplifted", "focused", "hopeful"]

export type PlanType =
  | "therapy"
  | "therapy+medication"
  | "medication"
  | "couples-therapy"

export interface Plan {
  icon: string
  title: string
  description: string
  link: string
  linkText?: string
  chipText?: string
}

export const plans: Plan[] = [
  {
    icon: Form,
    title: "Medication",
    description: "Evaluation and ongoing guidance from a licensed prescriber",
    link: "/plans/medication",
    linkText: "Learn more",
  },
  {
    icon: CareTeam,
    title: "Individual Therapy",
    description: "1-on-1 sessions with a compassionate, qualified therapist",
    link: "/plans/therapy",
    linkText: "Learn more",
  },
  {
    icon: CouplesTherapy,
    title: "Couples Therapy",
    description:
      "Weekly therapy sessions with you, a therapist, and your partner",
    link: "/plans/couples-therapy",
    linkText: "Learn more",
    chipText: "New!",
  },
]
