import styled from "styled-components"

export const StoreButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  & > a {
    margin-right: 10px;
    display: flex;
    align-items: center;
  }
  & > a:last-child {
    margin-right: 0;
  }
  @media (max-width: 800px) {
    margin-bottom: 18px;
  }
`
