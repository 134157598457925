import { graphql } from "gatsby"
import { useEffect, useRef, useState } from "react"
import styled from "styled-components"

const Wrapper = styled.div<{ bannerHeight: number }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  position: sticky;
  top: ${props => `calc(48px + ${props.bannerHeight}px)`};
  z-index: 1;
  padding-bottom: 16px;

  @media (max-width: 1024px) {
    padding-left: 24px;
  }
`

const TabWrapper = styled.div`
  width: 100%;
  display: flex;
  overflow: auto;
  margin-top: 64px;

  @media (max-width: 1024px) {
    margin-top: 48px;
  }
`

const TabSection = styled.div`
  width: 992px;
  margin: auto;

  @media (max-width: 1024px) {
    padding-right: 24px;
  }
`

const TabContainer = styled.div<{ totalTabItem: number }>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(${props => props.totalTabItem}, 1fr);
  padding: 4px;
  gap: 4px;
  border-radius: 12px;
  background: #fafafc;
  border: 1px solid #f0f1f5;
  height: 40px;
  align-items: center;
  margin: 0 auto;

  @media (max-width: 600px) {
    display: flex;
  }
`

const TabItem = styled.button<{ isActive: boolean }>`
  color: #250044;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-decoration: none;

  border-radius: 8px;
  background: ${props => (props.isActive ? "#cbd8ff" : "#fafafc")};
  height: 100%;
  padding: 4px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border: none;
  cursor: pointer;
  transition: background 0.5s;

  @media (max-width: 600px) {
    min-width: calc(100vw / 3 - 25px);
  }
`

interface StickyTabProps {
  otherSlices: {
    primary: {
      tab_id?: string
      tab_title: {
        text: string
      }
    }
  }[]
}

const StickyTab = ({ otherSlices }: StickyTabProps): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState(0)
  const [bannerHeight, setBannerHeight] = useState(0)
  const scrollLeft = useRef(0)

  const getMinusOffset = (tabId?: string) => {
    if (tabId === "faq") {
      return 250
    }

    if (tabId === "faqs") {
      return 180
    }

    return 150 + bannerHeight
  }

  const tabs = otherSlices
    .filter(slice => Boolean(slice.primary?.tab_id))
    .map(slice => ({
      id: slice.primary?.tab_id,
      title:
        slice.primary?.tab_id === "faq"
          ? "FAQs"
          : slice.primary?.tab_title?.text,
      minusOffset: getMinusOffset(slice.primary.tab_id),
    }))

  const handleAutoScrollTab = (index: number) => {
    const tabElement = document?.getElementById("sticky-tab")
    const tabItemElement = document?.getElementById(`tab-${tabs[index]?.id}`)

    if (tabElement && tabItemElement) {
      const { offsetLeft } = tabItemElement

      if (tabElement.scrollLeft > scrollLeft.current) {
        //scrolling to the right
        tabElement.scrollTo({ left: offsetLeft - 40, behavior: "smooth" })
      } else {
        //scrolling to the left
        tabElement.scrollTo({
          left: offsetLeft - window.innerWidth / 2.7,
          behavior: "smooth",
        })
      }
    }
    setSelectedTab(index || 0)
    scrollLeft.current = tabElement?.scrollLeft || 0
  }

  const handleOnClick = (index: number) => {
    const tabSectionElement = document?.getElementById(tabs[index].id || "")

    handleAutoScrollTab(index)

    if (tabSectionElement) {
      const { offsetTop } = tabSectionElement

      window.scrollTo({
        top: offsetTop - tabs[index].minusOffset,
        behavior: "smooth",
      })
    }
  }

  const handleScroll = () => {
    const currentOffset = window.scrollY
    const bannerHeight =
      document?.getElementById("site-banner")?.clientHeight || 0
    setBannerHeight(bannerHeight)

    const mappingTabs = tabs.map((tab, index) => {
      const tabSectionElement = document?.getElementById(tabs[index].id || "")
      const tabSectionHeight = tabSectionElement?.clientHeight || 0
      const tabStart = tabSectionElement?.offsetTop || 0
      const tabEnd = tabStart + tabSectionHeight
      const minusOffset = tab.id === "faqs" ? 400 : 250

      return {
        ...tab,
        offset: {
          start: index === 0 ? 0 : tabStart - minusOffset,
          end: tabEnd - 250,
        },
      }
    })

    const activeTab = mappingTabs.find(tab => {
      return (
        currentOffset >= tab.offset.start && currentOffset <= tab.offset.end
      )
    })

    const activeTabIndex = mappingTabs.findIndex(
      tab => tab.id === activeTab?.id
    )

    handleAutoScrollTab(activeTabIndex)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <Wrapper bannerHeight={bannerHeight}>
      <TabWrapper id="sticky-tab">
        <TabSection>
          <TabContainer totalTabItem={tabs.length}>
            {tabs.map((tab, index) => (
              <TabItem
                isActive={selectedTab === index}
                onClick={() => handleOnClick(index)}
                id={`tab-${tab.id}`}
              >
                {tab.title}
              </TabItem>
            ))}
          </TabContainer>
        </TabSection>
      </TabWrapper>
    </Wrapper>
  )
}

export const query = graphql`
  fragment PageStickyTabSlice on PrismicPageDataBodyStickyTab {
    id
    slice_type
  }
`

export default StickyTab
