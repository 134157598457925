export default function LocationPoint({ fill = "#51459E" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M15 3.73318C13.6739 2.40709 11.8754 1.66211 10 1.66211C8.12466 1.66211 6.32611 2.40709 5.00002 3.73318C3.67394 5.05926 2.92896 6.85781 2.92896 8.73318C2.92896 10.6085 3.67394 12.4071 5.00002 13.7332L9.39169 18.1332C9.46916 18.2113 9.56133 18.2733 9.66288 18.3156C9.76442 18.3579 9.87335 18.3797 9.98336 18.3797C10.0934 18.3797 10.2023 18.3579 10.3038 18.3156C10.4054 18.2733 10.4976 18.2113 10.575 18.1332L15 13.6915C16.3206 12.3709 17.0624 10.5799 17.0624 8.71234C17.0624 6.8448 16.3206 5.05374 15 3.73318ZM13.8084 12.4998L10 16.3248L6.19169 12.4998C5.43931 11.7468 4.92709 10.7875 4.71977 9.74342C4.51245 8.69929 4.61935 7.61713 5.02694 6.63374C5.43454 5.65036 6.12454 4.80989 7.00971 4.21857C7.89489 3.62726 8.93551 3.31166 10 3.31166C11.0645 3.31166 12.1052 3.62726 12.9903 4.21857C13.8755 4.80989 14.5655 5.65036 14.9731 6.63374C15.3807 7.61713 15.4876 8.69929 15.2803 9.74342C15.073 10.7875 14.5607 11.7468 13.8084 12.4998ZM7.50002 6.17484C6.82728 6.84965 6.44952 7.76365 6.44952 8.71651C6.44952 9.66937 6.82728 10.5834 7.50002 11.2582C7.99982 11.7588 8.63635 12.1007 9.32973 12.241C10.0231 12.3813 10.7425 12.3137 11.3976 12.0466C12.0527 11.7796 12.6143 11.3251 13.0121 10.7401C13.4098 10.155 13.626 9.46557 13.6334 8.75818C13.6371 8.28585 13.5461 7.81756 13.3658 7.381C13.1854 6.94444 12.9194 6.54847 12.5834 6.21651C12.2531 5.87866 11.8593 5.60946 11.4245 5.4244C10.9898 5.23934 10.5228 5.14207 10.0504 5.1382C9.57791 5.13433 9.10935 5.22392 8.67167 5.40183C8.23398 5.57974 7.83579 5.84245 7.50002 6.17484ZM11.4084 10.0748C11.0926 10.3955 10.6752 10.5964 10.2276 10.6434C9.78006 10.6903 9.33008 10.5804 8.95461 10.3323C8.57915 10.0842 8.30152 9.71338 8.1692 9.28325C8.03687 8.85311 8.05806 8.39037 8.22915 7.97414C8.40024 7.5579 8.71061 7.21402 9.10718 7.00129C9.50375 6.78856 9.9619 6.72018 10.4033 6.80786C10.8447 6.89553 11.2419 7.13381 11.5271 7.48195C11.8123 7.8301 11.9677 8.26649 11.9667 8.71651C11.9546 9.2309 11.7388 9.71945 11.3667 10.0748H11.4084Z"
        fill={fill}
      />
    </svg>
  )
}
