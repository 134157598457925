import React from "react"
import styled from "styled-components"

const Wrapper = styled.ul`
  margin: 50px 0 0;
  text-align: left;
`
const File = styled.li``
const FileLink = styled.a``

const Mp3Player = ({ url }) => (
  <audio controls src={url}>
    Your browser does not support the<code>audio</code> element.
  </audio>
)

function Files({ className, files }) {
  if (!files) {
    return null
  }

  return (
    <Wrapper className={className}>
      {files.map(({ file }) =>
        file.link_type === "Media" ? (
          <Mp3Player url={file.url} />
        ) : (
          <File>
            • <FileLink href={file.url}>{file.raw.name}</FileLink>
          </File>
        )
      )}
    </Wrapper>
  )
}

export default styled(Files)``
