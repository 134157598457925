import convenientsvg from "./assets/convenient.svg"
import personalizedsvg from "./assets/personalized.svg"
import trustedsvg from "./assets/trusted.svg"

export const contents = [
  {
    id: "apt-content-1",
    title: "Trusted",
    desc: [],
    bullets: [
      "We’re selective—our therapists and prescribers must meet certain criteria to partner with us and care for our clients",
      "Patients rate our mental health experts 4.8/5.0 and we only continue working with those who maintain our standards.",
      "Cerebral and all our health care professionals are subject to HIPAA compliance and take your privacy seriously, so you can feel comfortable knowing your personal information will remain secure and confidential.",
    ],
    images: [
      {
        srcSet: trustedsvg,
        height: "300",
        width: "300",
        name: "apt-fit-info-desktop",
        media: "(min-width:835px)",
      },
      {
        srcSet: trustedsvg,
        height: "200",
        width: "200",
        name: "apt-fit-info-mobile",
        media: "(max-width:834px)",
      },
    ],
  },
  {
    id: "apt-content-2",
    title: "Personalized",
    desc: [],
    bullets: [
      "You choose a therapist or prescriber you want to work with.",
      "They’ll work with you to evaluate your symptoms and create a personalized treatment plan, if diagnosed.",
      "You’ll get monthly assessments to keep track of your progress and work with your clinician to make any necessary updates to your treatment.",
    ],
    images: [
      {
        srcSet: personalizedsvg,
        height: "300",
        width: "300",
        name: "apt-growing-info-desktop",
        media: "(min-width:835px)",
      },
      {
        srcSet: personalizedsvg,
        height: "200",
        width: "200",
        name: "apt-growing-info-mobile",
        media: "(max-width:834px)",
      },
    ],
  },
  {
    id: "apt-content-3",
    title: "Convenient & Flexible",
    desc: [],
    bullets: [
      "Online access makes it easy to keep up with your treatment — manage your appointments from your phone and meet in a comfortable location.",
      "See your clinician’s calendar and book any available session that works with your schedule.",
      "Free and fast shipping on meds is available through CerebralRx in most states.",
    ],
    images: [
      {
        srcSet: convenientsvg,
        height: "300",
        width: "300",
        name: "apt-growing-info-desktop",
        media: "(min-width:835px)",
      },
      {
        srcSet: convenientsvg,
        height: "200",
        width: "200",
        name: "apt-growing-info-mobile",
        media: "(max-width:834px)",
      },
    ],
  },
]
