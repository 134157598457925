import { colors } from "../../../themes/colors"
import styled, { css } from "styled-components"

export const AppFeaturesWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 96px 0 0;
  background: ${colors.c_secondary[50]};
  color: ${colors.c_black[50]};

  @media (max-width: 834px) {
    padding: 64px 0;
  }
`

export const DesktopContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  gap: 100px;
  margin-top: 64px;
  @media (max-width: 1000px) {
    gap: 32px;
  }

  @media (max-width: 834px) {
    display: none;
  }
`

export const MobileContainer = styled.div`
  display: none;
  width: 100%;
  position: relative;
  margin-top: 24px;
  @media (max-width: 834px) {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
`

export const FeaturesWrapper = styled.div`
  height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: start;
  gap: 24px;
  padding: 40px 0;

  @media (max-width: 1000px) {
    padding: 24px 0;
  }

  @media (max-width: 834px) {
    height: 560px;
    padding: 0;
  }
`

export const Feature = styled.div<{ active: boolean }>`
  width: 328px;
  display: flex;
  position: absolute;
  top: 0;
  left: calc(50% - 164px);
  flex-direction: column;
  align-items: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;

  ${props =>
    props.active &&
    css`
      opacity: 1;
    `}

  p {
    text-align: center;
    cursor: unset;
  }

  div {
    width: 320px;
  }
`

export const FeatureImg = styled.img`
  width: 416px;

  @media (max-width: 1000px) {
    width: 360px;
  }

  @media (max-width: 834px) {
    width: 298px;
  }
`

export const FeatureText = styled.p<{ active: boolean }>`
  padding: 24px;
  cursor: pointer;
  border-radius: 16px;
  background: ${colors.c_white[500]};
  box-shadow: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  transition: all 0.3s ease-in-out;
  font-family: Poppins;

  ${props =>
    props.active &&
    css`
      background: linear-gradient(135deg, #fef9ec 0%, #fdecec 100%);
      box-shadow: 0px 5px 24px 4px rgba(28, 30, 33, 0.1),
        0px 13px 19px 2px rgba(28, 30, 33, 0.04),
        0px 7px 8px -4px rgba(28, 30, 33, 0.02);
    `}

  &:hover {
    box-shadow: 0px 5px 24px 4px rgba(28, 30, 33, 0.1),
      0px 13px 19px 2px rgba(28, 30, 33, 0.04),
      0px 7px 8px -4px rgba(28, 30, 33, 0.02);
  }
`
export const AppCtasWrapper = styled.div`
  display: flex;
  gap: 8px;
  @media (max-width: 834px) {
    justify-content: center;
  }
`

export const AppCta = styled.img`
  height: 64px;

  @media (max-width: 834px) {
    height: 48px;
  }
`

export const CarrouselIndicatorsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 2px;
`

export const CarrouselIndicator = styled.div<{ active: boolean }>`
  width: 16px;
  height: 8px;

  div {
    width: 8px;
    height: 8px;
    margin: auto;
    border-radius: 4px;
    background: ${colors.c_grey[300]};

    transition: all 0.3s ease-in-out;

    ${props =>
      props.active &&
      css`
        width: 16px;
        background: ${colors.c_primary[600]};
      `}
  }
`
