import React, { useState } from "react"
import styled from "styled-components"
import Button from "./Button"

function ShowMoreButton({
  className,
  to,
  target,
  rel,
  onShow,
  enableSeeMore,
}: {
  className?: string
  to: string
  target?: string
  rel?: string
  onShow?: Function
  enableSeeMore?: Boolean
}) {
  const [isShown, setIsShown] = useState(false)
  if (enableSeeMore === undefined) enableSeeMore = true

  function showMore() {
    setIsShown(true)
    if (onShow) onShow({ isShown: true })
  }

  return (
    <>
      {!isShown ? (
        <div onClick={showMore}>
          <Button to={"#"} className={className} target={target} rel={rel}>
            Show more
          </Button>
        </div>
      ) : (
        enableSeeMore &&
        to && (
          <Button to={to} className={className} target={target} rel={rel}>
            See more
          </Button>
        )
      )}
    </>
  )
}

export default styled(ShowMoreButton)``
