import { graphql } from "gatsby"
import { IGatsbyImageData, ImageDataLike } from "gatsby-plugin-image"
import ImageWithListTextRight from "./ImageWithListTextRight"

interface ImageWithListTextRightProps {
  slice: {
    primary: {
      slice_id?: {
        text: string
      }
      title: {
        text: string
      }
      copy: {
        text: string
      }
      bullets: {
        html: string
      }
      subscript: {
        html: string
      }
      subscript_icon: ImageDataLike
      image: ImageDataLike
      mobile_image: ImageDataLike
      object_fit: {
        text: string
      }
    }
    items: {
      copy: {
        html: string
      }
      icon: IGatsbyImageData
    }[]
  }
}

const ImageWithListTextLeft = ({ slice }: ImageWithListTextRightProps) => {
  return <ImageWithListTextRight reversed slice={slice} />
}

export const query = graphql`
  fragment ImageWithListTextLeftSlice on PrismicLandingDataBodyImageWithListTextLeft {
    slice_type
    primary {
      copy {
        text
      }
      bullets {
        html
      }
      title {
        text
      }
      image {
        gatsbyImageData(placeholder: BLURRED)
      }
      subscript {
        html
      }
      subscript_icon {
        gatsbyImageData(placeholder: BLURRED)
      }
      mobile_image {
        gatsbyImageData(placeholder: BLURRED)
      }
      object_fit {
        text
      }
    }
  }

  fragment PageImageWithListTextLeftSlice on PrismicPageDataBodyImageWithListTextLeft {
    slice_type
    primary {
      copy {
        text
      }
      title {
        text
      }
      image {
        gatsbyImageData(placeholder: BLURRED)
      }
      mobile_image {
        gatsbyImageData(placeholder: BLURRED)
      }
      object_fit {
        text
      }
    }
    items {
      icon {
        gatsbyImageData
      }
      copy {
        html
      }
    }
  }
`
export default ImageWithListTextLeft
