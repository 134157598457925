import { useEffect } from "react"
import { useNavigate } from "@reach/router"
import Shield from "../assets/shield.svg"
import { DialogContainer } from "./styled"
import segment, { segmentEvents } from "../../../../lib/segment"
import { useRolloutFlag } from "../../../../store/rolloutFlag"
import { QueryToCookieConverter } from "../../../../lib/QueryToCookieConverter"

export const insuranceTypeformLink =
  "https://forms.cerebral.com/to/EtjRbfRz?promo=VQ3FQIQL&typeform-source=cerebral.com"

export const InsuranceSuccessDialog = ({
  state,
  carrier,
  externalSource,
  isRteEnabled,
  isOpenFromCarrierSelectV2,
}: {
  state: string
  carrier: string
  externalSource: string
  isRteEnabled?: boolean
  isOpenFromCarrierSelectV2?: boolean
}) => {
  const PERMITTED_INSURANCE_STATES = ["Texas"]
  const PERMITTED_INSURANCE_CARRIERS = [
    "Blue Cross and Blue Shield of Texas",
    "Cigna",
    "United Healthcare/Optum",
  ]

  const { flag, loadFlag } = useRolloutFlag()
  const navigate = useNavigate()
  const preSignupUrl = process.env.GATSBY_PRE_SIGNUP_URL

  useEffect(() => {
    loadFlag("insurance_rte_release_onboarding")
  }, [])

  const handleModalSubmit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault()
    segment.track(
      segmentEvents.INSURANCE_MODAL_CONTINUE_CLICK,
      {},
      { integrations: { All: false, Heap: true } }
    )

    const searchParams = new URLSearchParams(window.location.search)
    const fbclidCookie = new QueryToCookieConverter("fbclid").getCookieValue()
    const ttclidCookie = new QueryToCookieConverter("ttclid").getCookieValue()
    const gclidCookie = new QueryToCookieConverter("gclid").getCookieValue()

    const fbclidQuery = searchParams.get("fbclid")
    const ttclidQuery = searchParams.get("ttclid")
    const gclidQuery = searchParams.get("gclid")

    const freshSearchParams = new URLSearchParams("")
    const fbclidValue = fbclidQuery ?? fbclidCookie
    const ttclidValue = ttclidQuery ?? ttclidCookie
    const gclidValue = gclidQuery ?? gclidCookie

    fbclidValue && freshSearchParams.append("fbclid", fbclidValue)
    ttclidValue && freshSearchParams.append("ttclid", ttclidValue)
    gclidValue && freshSearchParams.append("gclid", gclidValue)

    if (
      (PERMITTED_INSURANCE_STATES.includes(state) &&
        PERMITTED_INSURANCE_CARRIERS.includes(carrier) &&
        flag?.insurance_rte_release_onboarding &&
        !isOpenFromCarrierSelectV2) ||
      (isOpenFromCarrierSelectV2 && isRteEnabled)
    ) {
      navigate(
        preSignupUrl +
          `?external_source=${externalSource}&ob_flow=insurance_eligibility_check${
            freshSearchParams.size ? `&${freshSearchParams.toString()}` : ""
          }`
      )
    } else {
      navigate(
        insuranceTypeformLink +
          `&external_source=${externalSource}&widget_state=${state}&widget_payer=${carrier}${
            freshSearchParams.size ? `&${freshSearchParams.toString()}` : ""
          }`
      )
    }
  }

  return (
    <DialogContainer>
      <img src={Shield} alt="" />
      <h2>Good news! We work with your insurance provider</h2>
      <p>Next, we'll verify your insurance details</p>
      <br />
      <button onClick={handleModalSubmit}>Continue</button>
    </DialogContainer>
  )
}
