import styled from "styled-components"

interface ContainerProps {
  showPlant?: boolean
}
export const Container = styled.section<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ showPlant }) => (showPlant ? "0 16px 142px" : "0 16px 72px")};
  color: #626262;
  position: relative;
  & > img {
    position: absolute;
    bottom: 0;
    right: 5%;
  }
  @media (max-width: 900px) {
    & > img {
      max-width: 73px;
    }
  }
`

export const RatingsContainer = styled.div`
  max-width: max-content;
  display: flex;
  align-items: center;
  & > div {
    margin-right: 88px;
  }
  & > div:last-child {
    margin-right: 0;
  }
  @media (max-width: 500px) {
    & > div {
      margin-right: 32px;
    }
  }
`

export const RatingContainer = styled.div`
  display: flex;
  align-items: center;
  & > div {
    display: flex;
    align-items: center;
    & > h3 {
      font-size: 33px;
      line-height: 25px;
      color: #626262;
    }
    strong {
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px;
    }
  }
  & > span {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin-left: 12px;
  }

  @media (max-width: 834px) {
    flex-direction: column;
    & > span {
      font-weight: 400;
      font-size: 10px;
      line-height: 23px;
      text-align: center;
      & > br {
        display: none;
      }
    }
  }

  @media (max-width: 500px) {
    & > div > h3 {
      font-size: 19px;
      line-height: 25px;
    }
  }
`
export const ForbesRatingContainer = styled(RatingContainer)`
  @media (max-width: 834px) {
    & > span {
      max-width: 100px;
      line-height: 12px;
    }
  }
`

export const FiveHundredKContainer = styled(RatingContainer)`
  @media (max-width: 834px) {
    & > span {
      margin-left: 0;
    }
  }
`

export const Star = styled.img`
  max-height: 27px;
  max-width: 27px;
  margin-right: 0.5ch;
  @media (max-width: 500px) {
    max-height: 19px;
    max-width: 19px;
  }
`

export const Forbes = styled.img`
  height: 28px;
  width: 97px;
  @media (max-width: 500px) {
    height: 18px;
    width: 63px;
    margin-bottom: 6.79px;
  }
`
