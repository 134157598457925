import React from "react"
import { Container, Text } from "./styled"

export type ExternalLinkProps = {
  id?: string
  text?: string
  leftIcon?: Object
  rightIcon?: Object
  href: string
  shadow?: boolean
  theme?: "primary" | "secondary" | "tertiary"
  paddingHorizontal?: number
  height?: number
  borderWidth?: number
  fontSize?: number
  children?: JSX.Element | string
  width?: number
  onClick?: () => void
}

export const ExternalLink = ({
  id,
  text,
  shadow,
  href,
  theme = "primary",
  paddingHorizontal,
  height,
  borderWidth,
  fontSize,
  width,
  children,
  onClick,
}: ExternalLinkProps): JSX.Element => {
  return (
    <Container
      id={id}
      href={href}
      theme={theme}
      paddinghorizontal={paddingHorizontal}
      shadow={shadow}
      height={height}
      $borderWidth={borderWidth}
      width={width}
      onClick={onClick}
    >
      <Text theme={theme} fontSize={fontSize}>
        {text}
        {children}
      </Text>
    </Container>
  )
}
