import { GetStartedButton } from "../../../../components/shared"
import { ButtonWrapper, GettingStartedWrapper } from "../GettingStarted/styled"
import { GridWrapper, Header, GridHeader } from "./styled"
import { rows as definedRows } from "./rows"
import { OfferingDetail } from "./OfferingDetail"

export interface OfferingDetail {
  id: number
  text?: string
  description?: string
  img: string
}

interface OfferingComparisonProps {
  title?: string
  description?: string
  offeringTitles?: string[]
  offeringDetails?: OfferingDetail[][]
  bgColor?: string
  btnMaxWidth?: string
  btnLink?: string
  btnText?: string
  padding?: string
}

export const OfferingComparison = ({
  title,
  description,
  offeringTitles,
  offeringDetails,
  bgColor,
  btnMaxWidth,
  btnLink,
  btnText,
  padding,
}: OfferingComparisonProps) => {
  const rows = offeringDetails || definedRows

  const headers = [
    { id: 1 },
    {
      id: 2,
      text: offeringTitles ? offeringTitles[0] : "Cerebral",
      cssClassName: "rounded-upper even-column",
    },
    { id: 3, text: offeringTitles ? offeringTitles[1] : "In-person" },
  ].map(x => (
    <GridHeader key={x.id} className={x.cssClassName}>
      <p>{x.text}</p>
    </GridHeader>
  ))

  return (
    <GettingStartedWrapper bgColor={bgColor} padding={padding} gap="0">
      <Header>
        <h2>{title || "Cerebral vs. In-person couples therapy"}</h2>
        {description ?? (
          <span>
            {description || "We offer more benefits than In-person providers"}
          </span>
        )}
      </Header>
      <GridWrapper>{headers}</GridWrapper>
      {rows.map((row, index) => (
        <OfferingDetail
          details={row as OfferingDetail[]}
          isLastItem={index === rows.length - 1}
          key={row[0].description}
        />
      ))}
      <ButtonWrapper maxWidth={btnMaxWidth} mt="60px">
        <GetStartedButton
          text={btnText || "Get started"}
          height={56}
          href={btnLink}
        />
      </ButtonWrapper>
    </GettingStartedWrapper>
  )
}
