import { PopularLocations } from "../../../shared"
import { therapistCities } from "../../../constants"

export const TherapistsPopularCities = () => {
  return (
    <PopularLocations
      carePersonType="therapists"
      locations={therapistCities}
      title="Find a therapist in your city"
    />
  )
}
