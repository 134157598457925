import styled from "styled-components"
import { colors } from "../themes/colors"
import { sizes, spacing, borderRadius } from "../themes/tokens"
import { GatsbyImage } from "gatsby-plugin-image"

export const Posts = styled.a`
  text-decoration: none;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  padding: ${spacing.md};
  background: ${colors.c_secondary[50]};
  border-radius: ${borderRadius.lg};
  display: flex;
  gap: ${spacing.md};
  grid-template-columns: 76px 1fr;
  margin: ${spacing.sm} 0;

  &:hover {
    background: ${colors.c_secondary[100]};
  }

  &:focus {
    border-color: ${colors.c_secondary[600]};
    background: ${colors.c_secondary[100]};
  }

  @media (max-width: 1023px) {
    margin: 0;
  }
`
export const PostPreviewImage = styled(GatsbyImage)`
  img {
    width: 60px !important;
    height: 60px !important;
    object-fit: cover;
    @media (min-width: 1024px) {
      width: ${sizes.sl} !important;
      height: ${sizes.sl} !important;
    }
  }
`

export const PostContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const DateTagsWrapper = styled.div`
  display: flex;
  margin-bottom: ${spacing.sm};
  gap: ${spacing.sm};
  flex-wrap: wrap;
  width: 100%;
  @media (max-width: 1024px) {
    display: none;
  }
`
export const DateWrapper = styled.p`
  color: ${colors.c_grey[900]};
  padding-top: ${spacing.xs};
  padding-bottom: ${spacing.xs};
  font-family: Poppins;
  font-size: ${sizes.smd};
  font-weight: 400;
  line-height: ${sizes.md};
`

export const Tag = styled.p`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: ${spacing.xs};
  border-radius: ${borderRadius.md};
  background: ${colors.c_secondary[200]};
  font-family: Poppins;
  font-size: 11px;
  font-weight: 600;
  line-height: 14px;
  color: ${colors.c_primary[900]};
`
export const PostTitle = styled.h5`
  color: ${colors.c_black[900]};
  font-family: Poppins;
  font-size: ${sizes.md};
  font-weight: 600;
  line-height: ${sizes.mlg};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`
export const PostTestTitle = styled.a`
  text-decoration: none;
  color: ${colors.c_black[900]};
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: ${sizes.lg};
  @media (max-width: 1024px) {
    display: none;
  }
`
export const PostsWrapper = styled.div`
  padding: 0 24px;

  @media (min-width: 1024px) {
    padding: 0 40px;
  }
`
