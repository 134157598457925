import {
  Container,
  ContentContainer,
  FinePrint,
  Stat,
  StatDescription,
  StatSection,
  TextSection,
  TitleSection,
  TitleText,
} from "./styled"
import SquigglyLine from "./assets/squiggly-line.svg"
import { copy } from "./copy"

export const QualityCare = () => {
  return (
    <Container>
      <ContentContainer>
        <TitleSection>
          <img src={SquigglyLine} alt="" />
          <TitleText>
            Quality care.
            <br />
            Real results.
          </TitleText>
        </TitleSection>
        <TextSection>
          {copy.map(c => {
            return (
              <StatSection>
                <Stat>{c.stat}</Stat>
                <StatDescription>{c.copy}</StatDescription>
              </StatSection>
            )
          })}
          <FinePrint>
            *based on Cerebral clients with moderate to severe anxiety and/or
            depression from 2020-2023.
          </FinePrint>
        </TextSection>
      </ContentContainer>
    </Container>
  )
}
