import { ICarePerson } from "../CarePersonPage/types"

export enum PageCategory {
  CITY = "city",
  STATE = "state",
  SPECIALTY = "specialty",
  CITY_SPECIALTY = "citySpecialty",
  STATE_SPECIALTY = "stateSpecialty",
}

export interface SpecializationData {
  name: string
  url: string
  linkTitle?: string
}

export interface AssociatedLocations {
  cities_with_specialization?: SpecializationData[]
  cities?: SpecializationData[]
  specialization_in_this_city?: SpecializationData[]
  specialization_in_this_state?: SpecializationData[]
  specializations?: SpecializationData[]
  states?: SpecializationData[]
  states_with_specialization?: SpecializationData[]
}

export interface LocationPageRawData {
  associated_locations?: AssociatedLocations
  city_name?: string
  state_name?: string
  show_page: boolean
  specialty?: string
  carePersons: ICarePerson[]
  carePersonType: "therapist" | "prescriber"
  url: string
}

export interface LocationPageBodyData {
  associatedLocations?: AssociatedLocations
  pageType?: PageCategory
  city?: string
  state?: string
  showPage: boolean
  specialty?: string
  carePersons: ICarePerson[]
  totalCarePersons: number
  carePersonType?: "therapist" | "prescriber"
}

export interface LocationPageSEOData {
  page_slug?: string
  top_section_text?: {
    html: string
  }
  bottom_section_text?: {
    html: string
  }
  include_cta_section?: boolean
  cta_section_copy?: {
    html: string
  }
  left_cta_link?: {
    url: string
  }
  right_cta_link?: {
    url: string
  }
  left_cta_copy?: string
  right_cta_copy?: string
}
