import styled from "styled-components"
import { colors } from "../../themes/colors"

export const Container = styled.div`
  background: ${colors.c_white[500]};
  padding: 24px 64px;
  height: 252px;
  width: 434px;
  border: 1px solid transparent;
  border-radius: 30px;
  & > p {
    font-family: Poppins;
    font-color: rgba(98, 104, 116, 1);
    font-size: 12px;
  }
  & > div {
    margin: 16px 0px;
  }

  @media (max-width: 500px) {
    max-width: 321px;
    max-height: fit-content;
    margin: 0px;
    padding: 24px 24px;
    text-align: left;
    justify-content: left;
    & h3 {
      font-size: 22px;
    }
  }
`
