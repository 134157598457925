import { MouseEventHandler } from "react"
import styled from "styled-components"
import { colors } from "../../themes/colors"

interface PlanCardWrapperProps {
  border: boolean
  onMouseEnter: MouseEventHandler<HTMLDivElement>
}
export const PlanCardWrapper = styled.div<PlanCardWrapperProps>`
  white-space: normal;
  box-shadow: 0px 0px 12px rgba(81, 69, 158, 0.16);
  border-radius: 16px;
  background-color: ${colors.c_white[500]};
  max-width: 302px;
  height: 544px;
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  border: ${({ border }) =>
    border ? `2px solid ${colors.c_secondary[700]}` : ""};

  &:hover {
    border: 2px solid ${colors.c_secondary[700]};
    transform: scale(1.02);
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
  }
  & > h3 {
    text-align: center;
    font-size: 20px;
    line-height: 29px;
    color: #222128;
    margin-bottom: 8px;
  }
  @media (max-width: 1022px) {
    &:hover {
      transform: none;
    }
  }
`

export const PlanTitle = styled.p<{ banner: boolean }>`
  font-family: "Poppins";
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: ${colors.c_grey[900]};

  padding: ${({ banner }) => (banner ? "32px 0" : "32px 0")};
`

export const PlanInformation = styled.div<{ banner: boolean }>`
  padding: ${({ banner }) => (banner ? "0px 24px 32px" : "32px 24px 0")};
  text-align: center;
`

export const Banner = styled.div`
  border-radius: 16px 16px 0px 0px;
  padding: 8px 16px;
  display: flex;
  background-color: ${colors.c_secondary[100]};

  & > img {
    margin-right: 8px;
  }
  & > p {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    color: ${colors.c_secondary[800]};
  }
`

export const PlanDesc = styled.p`
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: ${colors.c_black[500]};
  margin-bottom: 24px;
`

export const PlanPrice = styled.p`
  font-family: "Poppins";
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: ${colors.c_secondary[700]};
  padding: 16px;

  margin-top: auto;
`

export const PlanBullet = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
  & > img {
    margin-top: 6px;
    margin-right: 16px;
  }
  & > p {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    color: ${colors.c_black[500]};
    max-width: 224px;
  }
`

export const PlanFooterText = styled.p`
  font-weight: 300;
  font-size: 14px;
  line-height: 23px;
  color: ${colors.c_black[500]};
  margin-top: auto;
  & > b {
    font-weight: 700;
  }
`

export const PlanLink = styled.a`
  font-weight: 300;
  font-size: 12px;
  line-height: 23px;
  color: ${colors.c_secondary[600]};
  font-style: italic;
`

export const StrongStartBanner = styled.div`
  background: ${colors.c_yellow[50]};
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0 0 16px 16px;
  margin-top: 32px;
`

export const StrongStartText = styled.span`
  font-family: "Poppins";
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: ${colors.c_grey[900]};
`

export const StrongStartPrice = styled.span`
  background: ${colors.c_yellow[300]};
  border-radius: 12px;
  padding: 4px 8px;

  font-family: "Poppins";
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  color: ${colors.c_grey[900]};
`

export const ButtonContainer = styled.div`
  width: 216px;
  align-self: center;
`
