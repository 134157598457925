import React from "react"

import {
  EmergencyInfoWrapper,
  EmergencyInfoInnerWrapper,
  BodyWrapper,
} from "./styled"

export const EmergencyInformation = (): JSX.Element => {
  return (
    <EmergencyInfoWrapper>
      <EmergencyInfoInnerWrapper>
        <BodyWrapper>
          <p>
            Call <strong>911</strong> if you’re having a <br />
            mental health emergency
          </p>
        </BodyWrapper>
        <BodyWrapper>
          <p>
            Text <strong>Home</strong> to <strong>741-741</strong> if you're in
            emotional <br />
            distress and need immediate support
          </p>
        </BodyWrapper>
        <BodyWrapper>
          <p>
            Call <strong>988</strong> For National Suicide <br />
            Prevention Hotline
          </p>
        </BodyWrapper>
      </EmergencyInfoInnerWrapper>
    </EmergencyInfoWrapper>
  )
}
