import React, { useState } from "react"
import closeIcon from "./images/close-icon.svg"
import checkedIcon from "./images/checked-icon.svg"
import {
  PopupWrapper,
  CloseButton,
  LeftBox,
  RightBox,
  Title,
  Description,
  InputContainer,
  EnvelopeImage,
  Input,
  Button,
  Term,
  TermLink,
  TermWrapper,
  ConfirmPolicy,
  SubscribeButtonWrapper,
  InputLineWrapper,
  FormWrapper,
  LeftText,
  ImageDescription,
  TermWrapperCheckBox,
  BackDrop,
} from "./styled"
import SelectBox from "./Select"
import CheckBox from "./Checkbox"
import Envelope from "./images/envelope.svg"
import Heart from "./images/heart.svg"
import HappyWomanImage from "./images/happy-woman.png"
import segment, { segmentEvents } from "../../../lib/segment"
import { useEmailCollectionPopup } from "../BlogRepo/useEmailCollectionPopup"

interface LeadCapturePopupProps {
  experiment?: string
  variant?: string
  storageKey?: string
  segmentKey: segmentEvents
  url?: string
  image?: {
    url: string
    icon: string
    backgroundSize: string
    text: string
  }
}

export const LeadCapturePopup = ({
  experiment,
  variant,
  storageKey,
  url,
  image,
  segmentKey,
}: LeadCapturePopupProps): JSX.Element => {
  const DEFAULT_EXPERIMENT_NAME = "Show lead capture popup"
  const DEFAULT_VARIANT_NAME = "SHOW"
  const DEFAULT_KEY = "leadCaptureCollection"

  const [termConfirm, setTermConfirm] = useState(false)
  const [email, setEmail] = useState("")
  const { showModal, setShowModal } = useEmailCollectionPopup(
    experiment || DEFAULT_EXPERIMENT_NAME,
    variant || DEFAULT_VARIANT_NAME,
    storageKey || DEFAULT_KEY
  )
  const [careResources, setCareResources] = useState<string[]>([])
  const [finished, setFinished] = useState(false)

  const closePopup = () => {
    setShowModal(false)
  }

  const submit = () => {
    segment.addUserToCollection({
      segmentKey,
      email,
      careResources,
      url: url || "",
    })

    setFinished(true)
  }

  const changeCareSource = (e: string[]) => {
    setCareResources(e)
  }

  const infoNotValid = () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return !emailPattern.test(email) || !termConfirm
  }

  return showModal ? (
    <>
      <BackDrop />
      <PopupWrapper>
        <CloseButton onClick={() => closePopup()}>
          <img alt="" src={closeIcon} />
        </CloseButton>
        <LeftBox style={{ backgroundImage: `url(${HappyWomanImage})` }}>
          <ImageDescription>
            <img src={image?.icon || Heart} width="32px" height="32px"></img>
            <span>{image?.text || "Getting personalized care"}</span>
          </ImageDescription>
        </LeftBox>
        {finished ? (
          <RightBox>
            <LeftText>
              <img
                alt=""
                src={checkedIcon}
                width="56px"
                height="auto"
                style={{ marginBottom: "24px" }}
              />
              <Title>You’re on our list</Title>
              <Description>
                Thanks for joining! An introductory email is on its way. Your
                dedication to understanding mental health better makes a
                difference.
              </Description>
            </LeftText>
            <FormWrapper>
              <SubscribeButtonWrapper>
                <Button onClick={closePopup}>Done</Button>
              </SubscribeButtonWrapper>
            </FormWrapper>
          </RightBox>
        ) : (
          <RightBox>
            <Title>Get the latest offers and insights</Title>
            <Description>
              Sign up and be the first to know about exclusive promotions, free
              mental health resources, upcoming expert-led events, and important
              mental health news.
            </Description>
            <FormWrapper>
              <InputLineWrapper>
                <InputContainer>
                  <EnvelopeImage src={Envelope} alt="" />
                  <Input
                    placeholder="Enter your email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </InputContainer>
                <InputContainer>
                  <SelectBox onChange={changeCareSource} />
                </InputContainer>
              </InputLineWrapper>
              <TermWrapperCheckBox>
                <ConfirmPolicy>
                  <CheckBox
                    colorStyle="#51459e"
                    onChange={e => {
                      setTermConfirm(!termConfirm)
                    }}
                    checked={termConfirm}
                  />
                  <Term
                    style={{ height: "18px", alignContent: "center" }}
                    onClick={() => {
                      setTermConfirm(!termConfirm)
                    }}
                  >
                    I accept the{" "}
                    <TermLink
                      href="https://cerebral.com/hipaa-marketing-authorization"
                      target="_blank"
                    >
                      Cerebral HIPAA Marketing Authorization
                    </TermLink>
                  </Term>
                </ConfirmPolicy>
              </TermWrapperCheckBox>
              <TermWrapper>
                <Term>
                  By subscribing, you agree to Cerebral’s{" "}
                  <TermLink
                    href="https://cerebral.com/terms-and-conditions"
                    target="_blank"
                  >
                    Terms of Use
                  </TermLink>{" "}
                  and{" "}
                  <TermLink
                    href="https://cerebral.com/privacy-policy"
                    target="_blank"
                  >
                    Privacy Policy
                  </TermLink>
                </Term>
              </TermWrapper>
              <SubscribeButtonWrapper>
                <Button onClick={submit} disabled={infoNotValid()}>
                  Subscribe
                </Button>
              </SubscribeButtonWrapper>
            </FormWrapper>
          </RightBox>
        )}
      </PopupWrapper>
    </>
  ) : (
    <></>
  )
}
