import styled from "styled-components"
import { colors } from "../../themes/colors"

export const TimeSlotsContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 320px;
`

const determineButtonColor = (selected: boolean) => {
  if (selected) {
    return `
      color: ${colors.c_white[500]};
      border: 1px solid #51459e;
      background-color: #51459e;
    `
  }
  return `
    color: #49356e;
    border: 1px solid #98b1ff;
    background-color: ${colors.c_white[500]};
  `
}

export const TimeSlotButton = styled.button<{
  selected: boolean
}>`
  height: 32px;
  border-radius: 50px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  font-family: "Poppins";
  width: 96px;
  ${({ selected }) => determineButtonColor(selected)}
  &:hover {
    border-color: ${colors.c_primary[600]};
  }
`
