import { BodyRegular, H3 } from "../../ui"
import { StatsContainer, Stat, Disclaimer, StatsWrapper } from "./styled"

export const Stats = () => {
  return (
    <StatsWrapper>
      <StatsContainer>
        <Stat>
          <H3>83%</H3>
          <BodyRegular>
            of clients are able to meet within 3 days of signing up
          </BodyRegular>
        </Stat>
        <Stat>
          <H3>74%</H3>
          <BodyRegular>
            of clients reported reduced depression in 3 months*
          </BodyRegular>
        </Stat>
        <Stat>
          <H3>72%</H3>
          <BodyRegular>
            of clients reported reduced anxiety in 3 months*
          </BodyRegular>
        </Stat>
      </StatsContainer>
      <Disclaimer>
        <span>
          *based on Cerebral clients with moderate to severe anxiety and/or
          depression from 2020-2023.
        </span>
      </Disclaimer>
    </StatsWrapper>
  )
}
