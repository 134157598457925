import { Splash } from "../Splash"

interface IHeroSectionProps {
  title: string
  description: string
  image: string
  buttons: {
    text: string
    link: string
    onClick?: () => void
    theme?: "primary" | "secondary" | "tertiary"
  }[]
  buttonsDirection?: "row" | "column"
  highlightText?: string
}

export const HeroSection = ({
  title,
  description,
  image,
  buttons,
  buttonsDirection,
  highlightText,
}: IHeroSectionProps) => {
  return (
    <Splash
      titleText={title}
      highlightText={highlightText}
      subtitleText={description}
      image={image}
      buttons={buttons}
      buttonsDirection={buttonsDirection}
      background="linear-gradient(135deg, #ECEFFE 0%, #FEF9EC 100%)"
      shouldHideMobileImage
      isHeroImage
    />
  )
}
