import styled from "styled-components"
import { colors } from "../../themes/colors"

interface BluredContainerProps {
  layout?: "vertical" | "horizontal"
  background?: string
}
export const BluredContainer = styled.section<BluredContainerProps>`
  display: flex;
  flex-direction: ${({ layout }) => (layout === "vertical" ? "column" : "row")};
  padding: 96px 0;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  background: ${({ background }) => background || colors.c_yellow[50]};
  & > * {
    position: relative;
  }
  @media (max-width: 834px) {
    padding: 5.62em 0;
  }
`
