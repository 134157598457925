import { graphql } from "gatsby"

import Layout from "../../components/Layout"
import CarePersonPage from "../../components/CarePersonPage"
import {
  ICarePerson,
  IInsuranceAccepted,
} from "../../components/CarePersonPage/types"
import { useEffect, useState } from "react"
import { createApiInstance } from "@cerebral-inc/api"

interface CarePersonInterface {
  data: {
    allSitePage: {
      edges: {
        node: {
          pageContext: ICarePerson
        }
      }[]
    }
  }
}

const CarePerson = ({ data }: CarePersonInterface) => {
  //pass down a single therapist to the page component
  const [carePersonData, setCarePersonData] = useState(
    data.allSitePage.edges[0].node.pageContext
  )

  const api = createApiInstance({
    baseURL: process.env.GATSBY_API_SERVER_URL,
  })

  const fetchInsuranceAccepted = async () => {
    try {
      const { data } = await api.post<IInsuranceAccepted[]>(
        "/api/public/clinician_bio/insurance_accepted",
        {
          clinicians: [carePersonData.email],
          role: carePersonData.role.toLowerCase(),
        }
      )

      setCarePersonData({
        ...carePersonData,
        accepting_new_client: data[0].accepting_new_client || false,
        insurance_accepted: data[0].insurance_accepted || {},
        cash_pay: data[0].cash_pay || [],
      })
    } catch (error) {}
  }

  useEffect(() => {
    fetchInsuranceAccepted()
  }, [])

  return (
    <Layout>
      <CarePersonPage carePersonData={carePersonData} />
    </Layout>
  )
}

export const query = graphql`
  query ($path: String!) {
    allSitePage(filter: { path: { eq: $path } }) {
      edges {
        node {
          id
          path
          pageContext
        }
      }
    }
  }
`

export default CarePerson
