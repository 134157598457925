import PeopleHugging from "./assets/people-hugging.webp"
import { HeroImage, WidgetContainerWrapper, Widget } from "./styled"

export const WidgetContainer = ({ widgetId }: { widgetId?: string }) => {
  return (
    <WidgetContainerWrapper>
      <Widget id={widgetId} />
      <HeroImage src={PeopleHugging} />
    </WidgetContainerWrapper>
  )
}
