import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  ImageDataLike,
} from "gatsby-plugin-image"

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 12px 32px;
  overflow: hidden;

  @media (min-width: 834px) {
    padding: 158px 60px 52px;
  }
`

const TextContainer = styled.div`
  max-width: 600px;
  z-index: 1;
`

const Header = styled.div`
  * {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    color: white;

    @media (min-width: 834px) {
      font-size: 80px;
      line-height: 88px;
    }
  }
`

const SubHeader = styled.div`
  * {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: white;
  }
  p:not(:last-child) {
    margin-bottom: 16px;
  }
`

const ImageContainer = styled.div<{ backgroundColor: string }>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;

  img {
    overflow: hidden;
    object-position: center center !important;
    object-fit: cover;
    filter: blur(4px);
    background-color: ${({ backgroundColor }) => backgroundColor || "#250044"};
  }

  * {
    width: 100%;
    height: 100%;
  }

  ::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: ${({ backgroundColor }) => backgroundColor || "#250044"};
    opacity: 60%;
  }
`

interface HeroWithBackgroundImageProps {
  slice: {
    primary: {
      slice_id?: {
        text: string
      }
      header: {
        html: string
      }
      sub_header: {
        html: string
      }
      background_image: ImageDataLike
      background_color: string
    }
  }
}

const HeroWithBackgroundImage = ({ slice }: HeroWithBackgroundImageProps) => {
  const { header, sub_header, background_image, background_color } =
    slice.primary

  const images = getImage(background_image) as IGatsbyImageData
  return (
    <Wrapper id={slice.primary.slice_id?.text || ""}>
      <TextContainer>
        <Header dangerouslySetInnerHTML={{ __html: header.html }}></Header>
        <SubHeader
          dangerouslySetInnerHTML={{ __html: sub_header.html }}
        ></SubHeader>
      </TextContainer>
      <ImageContainer backgroundColor={background_color}>
        <GatsbyImage image={images} alt="" loading="eager" />
      </ImageContainer>
    </Wrapper>
  )
}

export const query = graphql`
  fragment PageHeroWithBackgroundImageSlice on PrismicPageDataBodyHeroWithBackgroundImage {
    id
    slice_type
    primary {
      slice_id {
        text
      }
      header {
        html
      }
      sub_header {
        html
      }
      background_image {
        gatsbyImageData
      }
      background_color
    }
  }
`

export default HeroWithBackgroundImage
