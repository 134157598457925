import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { FlexWrapper } from "./LandingPageStyles"
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  ImageDataLike,
} from "gatsby-plugin-image"

const Wrapper = styled(FlexWrapper)`
  background-color: #e6eaf9;
`

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;

  picture img {
    object-position: center center !important;
  }

  width: 400px;
  height: 200px;
  margin: 20px;

  @media (min-width: 834px) {
    margin: 50px;
    width: 760px;
    height: 430px;
  }
`

interface ImageInterface {
  slice: {
    primary: {
      image: ImageDataLike
      slice_id: { text: string }
    }
  }
}

const Image = ({ slice }: ImageInterface) => {
  const { image } = slice.primary

  const images = getImage(image) as IGatsbyImageData

  return (
    <Wrapper id={slice.primary.slice_id?.text || ""}>
      <ImageContainer>
        <GatsbyImage image={images} loading="eager" alt="" />
      </ImageContainer>
    </Wrapper>
  )
}

export const query = graphql`
  fragment ImageSlice on PrismicLandingDataBodyImage {
    id
    slice_type
    primary {
      image {
        gatsbyImageData(width: 760)
      }
    }
  }
  fragment PageImageSlice on PrismicPageDataBodyImage {
    id
    slice_type
    primary {
      slice_id {
        text
      }
      image {
        gatsbyImageData(width: 760)
      }
    }
  }
  fragment PostImageSlice on PrismicPostDataBodyImage {
    id
    slice_type
    primary {
      image {
        gatsbyImageData(width: 760)
      }
    }
  }
  fragment ProviderImageSlice on PrismicProviderDataBodyImage {
    id
    slice_type
    primary {
      image {
        gatsbyImageData(width: 760)
      }
    }
  }
  fragment CounselorImageSlice on PrismicCounselorDataBodyImage {
    id
    slice_type
    primary {
      image {
        gatsbyImageData(width: 760)
      }
    }
  }
  fragment TherapistImageSlice on PrismicTherapistDataBodyImage {
    id
    slice_type
    primary {
      image {
        gatsbyImageData(width: 760)
      }
    }
  }
`

export default Image
