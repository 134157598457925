import { QuotesCarousel } from "../../../shared/QuotesCarousel"
import { quotes } from "./constants"
import { Wrapper } from "./styled"
import { GetStartedButton } from "../../../shared"
import { TrustPilotPhrase } from "../../../shared/TrustPilotPhrase/"

export const TestimonialsPageQuotes = () => {
  return (
    <Wrapper>
      <QuotesCarousel quotes={quotes} />
      <TrustPilotPhrase />
      <GetStartedButton
        href="https://www.trustpilot.com/review/cerebral.com"
        text="Read more"
        width={320}
      />
    </Wrapper>
  )
}
