import Shield from "./icons/shield.svg"
import Heart from "./icons/heart.svg"
import LifePreserver from "./icons/life-preserver.svg"
import Book from "./icons/book.svg"
import Medal from "./icons/medal.svg"
import People from "./icons/people.svg"

const sections = [
  {
    id: "treatment-content-1",
    title: "Rigorous screening",
    icon: Shield,
    content:
      "Quality of care is our highest priority at Cerebral. We approve every therapist and prescriber before joining us — and each of them must meet and maintain certain criteria while employed.",
  },
  {
    id: "treatment-content-3",
    title: "Diverse clinician base",
    icon: People,
    content:
      "Our clinicians have a range of backgrounds and experience so you can meet with someone who understands your needs.",
  },
  {
    id: "treatment-content-5",
    title: "Ongoing education",
    icon: Book,
    content:
      "We provide continuous training so our clinicians are always improving their skills in treating patients.",
  },
  {
    id: "treatment-content-2",
    title: "LGBTQIA+ experience",
    icon: Heart,
    content:
      "Many Cerebral clinicians are experienced in providing supportive and ethical care to members of the LGBTQIA+ community.",
  },
  {
    id: "treatment-content-4",
    title: "Monthly evaluations",
    icon: Medal,
    content:
      "Cerebral clinicians undergo evaluations each month to ensure they maintain our high standards.",
  },
  {
    id: "treatment-content-6",
    title: "Knowledge sharing",
    icon: LifePreserver,
    content:
      "Our clinicians have access to subject matter experts who can lend a helping hand when treating particularly challenging conditions.",
  },
]

const header = "Why choose Cerebral?"

export const content = {
  sections,
  header,
}
