import { ReactNode, useEffect, useState } from "react"
import { useSwipeable } from "react-swipeable"
import {
  AppCta,
  AppCtasWrapper,
  AppFeaturesWrapper,
  CarrouselIndicator,
  CarrouselIndicatorsWrapper,
  DesktopContainer,
  Feature,
  FeatureImg,
  FeatureText,
  FeaturesWrapper,
  MobileContainer,
} from "./styled"
import appSc1 from "./assets/app_screenshot_1.webp"
import appSc2 from "./assets/app_screenshot_2.webp"
import appSc3 from "./assets/app_screenshot_3.webp"
import appSc4 from "./assets/app_screenshot_4.webp"
import appSc5 from "./assets/app_screenshot_5.webp"
import appleAppCTA from "./assets/available_on_apple.svg"
import googleAppCTA from "./assets/available_on_google.svg"
import { Header, Subheader } from "../YourPersonalPath/styled"

const defaultFeatures = [
  {
    text: "Choose a clinician you want to work with",
    img: appSc1,
  },
  {
    text: "See their schedule and book a session online",
    img: appSc2,
  },
  {
    text: "See upcoming appointments + reminders",
    img: appSc3,
  },
  {
    text: "Get detailed summaries of each session",
    img: appSc4,
  },
  {
    text: "Track progress and goals over time",
    img: appSc5,
  },
]

const AppCtas = ({ hideLinks = false }: { hideLinks?: boolean }) => (
  <AppCtasWrapper>
    {hideLinks ? (
      <>
        <AppCta src={appleAppCTA} alt="Download on the App Store" />
        <AppCta src={googleAppCTA} alt="Available on the Google Play" />
      </>
    ) : (
      <>
        <a
          href="https://apps.apple.com/us/app/cerebral-mental-health/id1537193671"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AppCta src={appleAppCTA} alt="Download on the App Store" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.cerebral.cerebral"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AppCta src={googleAppCTA} alt="Available on the Google Play" />
        </a>
      </>
    )}
  </AppCtasWrapper>
)

export const AppFeatures = ({
  headerText = "Easy appointment scheduling and medication management",
  features = defaultFeatures,
  hideLinks = false,
  children,
}: {
  features?: { text: string; img: string }[]
  headerText?: string
  hideLinks?: boolean
  children?: ReactNode
}): JSX.Element => {
  const [activeFeature, setActiveFeature] = useState(0)

  const previousFeature = () => {
    setActiveFeature(
      activeFeature === 0 ? features.length - 1 : activeFeature - 1
    )
  }

  const nextFeature = () => {
    setActiveFeature(
      activeFeature === features.length - 1 ? 0 : activeFeature + 1
    )
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => nextFeature(),
    onSwipedRight: () => previousFeature(),
  })

  useEffect(() => {
    const activeFeatureInterval = setInterval(() => {
      nextFeature()
    }, 2000)

    return () => clearInterval(activeFeatureInterval)
  }, [activeFeature])

  return (
    <AppFeaturesWrapper>
      <Header>{headerText}</Header>
      {children ? <Subheader>{children}</Subheader> : null}
      <DesktopContainer>
        <FeatureImg src={features[activeFeature].img} alt="" />
        <div>
          <FeaturesWrapper>
            {features.map((feature, i) => (
              <FeatureText
                key={feature.text}
                active={i === activeFeature}
                onClick={() => setActiveFeature(i)}
              >
                {feature.text}
              </FeatureText>
            ))}
          </FeaturesWrapper>
          <AppCtas hideLinks={hideLinks} />
        </div>
      </DesktopContainer>

      <MobileContainer>
        <FeaturesWrapper>
          {features.map((feature, i) => (
            <Feature
              key={feature.text}
              active={i === activeFeature}
              {...handlers}
            >
              <FeatureImg src={feature.img} alt="" />
              <FeatureText active={true}>{feature.text}</FeatureText>
            </Feature>
          ))}
        </FeaturesWrapper>

        <CarrouselIndicatorsWrapper>
          {[...Array(features.length)].map((_, i) => (
            <CarrouselIndicator
              key={i}
              active={i === activeFeature}
              onClick={() => setActiveFeature(i)}
            >
              <div />
            </CarrouselIndicator>
          ))}
        </CarrouselIndicatorsWrapper>
        <AppCtas hideLinks={hideLinks} />
      </MobileContainer>
    </AppFeaturesWrapper>
  )
}
