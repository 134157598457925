import styled from "styled-components"
import { colors } from "../../themes/colors"

export const Container = styled.div<{ background: string }>`
  background: ${({ background }) => background};
  display: flex;
  justify-content: center;
  padding: 64px 24px;

  @media (max-width: 834px) {
    padding: 32px 24px;
  }
`
export const InnerContainer = styled.div<{
  shouldHideMobileImage: boolean
}>`
  display: grid;
  margin: 0 auto;
  grid-template-columns: 1fr 1fr;
  max-width: 992px;
  align-items: center;
  gap: 80px;
  @media (max-width: 834px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr ${({ shouldHideMobileImage }) =>
        shouldHideMobileImage ? "" : "1fr"};
    margin: 0 auto;
    gap: 0;
  }
`

export const LeftSide = styled.div`
  h1 {
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 32px;
    color: ${colors.c_grey[900]};
  }

  @media (max-width: 834px) {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    h1 {
      font-size: 28px;
    }
  }
`

export const PhoneImage = styled.img<{
  shouldHideMobileImage: boolean
  shouldFlipMobileSections: boolean
  isHeroImage?: boolean
}>`
  width: 100%;
  @media (max-width: 834px) {
    ${({ shouldHideMobileImage }) =>
      shouldHideMobileImage ? "display: none;" : ""}
    ${({ isHeroImage }) =>
      isHeroImage ? "max-width: 326px;" : "padding-top: 36px;"}
    transform: none;
    margin: 0 auto;
    ${({ shouldFlipMobileSections }) =>
      shouldFlipMobileSections ? "grid-row-start: 1;" : ""}
  }
`

export const ButtonWrapper = styled.div<{ direction?: "row" | "column" }>`
  display: flex;
  flex-direction: ${({ direction }) => direction || "row"};
  gap: ${({ direction }) => (direction === "column" ? "8px" : "24px")};
  a {
    height: 56px;
    max-width: 250px;
    width: 100%;
  }
  @media (max-width: 834px) {
    align-items: center;
    flex-direction: column;
    a {
      width: 100%;
      max-width: none;
    }
  }
`
