import {
  ChooseServiceWrapper,
  DesktopButtonContainer,
  Header,
  HeaderWrapper,
  MobileButtonContainer,
  PlanCard,
  PlanCardContent,
  PlanCardFooter,
  PlanCardHeader,
  PlanCardsWrapper,
  PlanCardPill,
} from "./styles"
import { GetStartedButton } from "../GetStartedButton"

interface ChooseServiceProps {
  plansInfo: Array<PlanInfo>
  title?: string
  description?: string | JSX.Element
  id?: string
  onGetStarted?: () => void
}

export interface PlanInfo {
  icon: any
  title: string
  planName: string
  planDescription: string
  weeklyPrice: number
  monthlyPrice: number
  threeMonthSavings: number
}

export const ChooseService = ({
  plansInfo,
  title,
  description,
  id,
  onGetStarted,
}: ChooseServiceProps) => {
  const getDescription = () => {
    if (description) {
      return <p>{description}</p>
    }

    return (
      <p>
        Therapy and medication often work together to help reduce symptoms and
        build lifelong skills to manage them.
        <br />
        <br />
        Choose the service you want to start with&nbsp;— you can always add or
        switch services later.
      </p>
    )
  }
  return (
    <ChooseServiceWrapper id={id}>
      <HeaderWrapper>
        <Header>
          <h2>{title || "Choose a service"}</h2>
          {getDescription()}
        </Header>
        <DesktopButtonContainer>
          <GetStartedButton width={152} height={56} onClick={onGetStarted} />
        </DesktopButtonContainer>
      </HeaderWrapper>
      <PlanCardsWrapper>
        {plansInfo.map((planInfo, index) => (
          <PlanCard key={index}>
            <PlanCardHeader>
              <planInfo.icon size={16} />
              <h4>{planInfo.title}</h4>
            </PlanCardHeader>

            <PlanCardContent>
              <h3>{planInfo.planName}</h3>
              <p>{planInfo.planDescription}</p>

              <PlanCardFooter>
                <p>
                  <b>${planInfo.monthlyPrice}/mo</b>
                </p>
                <PlanCardPill>
                  <span>
                    Save ${planInfo.threeMonthSavings}/mo with a 3 month package
                  </span>
                </PlanCardPill>
              </PlanCardFooter>
            </PlanCardContent>
          </PlanCard>
        ))}
      </PlanCardsWrapper>
      <MobileButtonContainer>
        <GetStartedButton height={56} onClick={onGetStarted} />
      </MobileButtonContainer>
    </ChooseServiceWrapper>
  )
}
