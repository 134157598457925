import { Carousel, CarouselItem } from "../../ui"
import { QuotesContainer, QuotesContentContainer, Name, Quote } from "./styled"
import { Source } from "../ContentHub/types"
import { StaticImage } from "gatsby-plugin-image"

const PurpleQuoteIcon = "../../../images/2-quotation-marks-purple.svg"
const FourStars = "../../../images/star-group.svg"
const FiveStars = "../../../images/five-stars.svg"
export interface Quote {
  quote: string
  name: string
  source?: Source
}

export interface IQuotesCarouselProps {
  quotes: Quote[]
  isPrismic?: boolean
}

export const QuotesCarousel = ({ quotes, isPrismic }: IQuotesCarouselProps) => {
  return (
    <QuotesContainer isPrismic={isPrismic}>
      <Carousel
        indicatorStyles={{
          desktop: { x: "0", y: "32%" },
          mobile: { x: "32px", y: "70%" },
        }}
        startingIndex={1}
      >
        {quotes.map(({ quote, name, source }) => (
          <CarouselItem key={quote}>
            <QuotesContentContainer isPrismic={isPrismic}>
              <StaticImage src={PurpleQuoteIcon} alt="" />
              <Quote>{quote}</Quote>
              <Name>- {name}</Name>
              {source !== "5stars" && (
                <StaticImage src={FourStars} alt="4 stars" />
              )}
              {source === "5stars" && (
                <StaticImage src={FiveStars} alt="5 stars" />
              )}
            </QuotesContentContainer>
          </CarouselItem>
        ))}
      </Carousel>
    </QuotesContainer>
  )
}
