import { useContext } from "react"
import {
  CardContainer,
  CarePersonImageContainer,
  AcceptingNewClients,
  MobileWrapper,
  CarePersonBioContainer,
  CarePersonDetailsContainer,
  CarePersonDetail,
  CarePersonName,
  CarePersonBoldText,
} from "./styled"
import { BodyRegular } from "../Body/styled"
import gendersvg from "../../../images/Therapists-bio/gender-nuetral.svg"
import { Button } from "../Button"
import { CarePersonDetails, CarePersonNearMeCardProps } from "./types"
import { getCarePersonDetails, createCarePersonBio } from "./helpers"
import { LocationPageContext } from "../../LocationPage/LocationPageProvider"
import capitalize from "lodash/capitalize"
import { IInsuranceAcceptedItem } from "components/CarePersonPage/sections/Availability/Availability"

const CarePersonDetailSection = ({
  carePersonDetail,
}: {
  carePersonDetail: CarePersonDetails
}) => {
  return (
    <CarePersonDetail>
      <CarePersonBoldText>{carePersonDetail.title}</CarePersonBoldText>
      <BodyRegular>{carePersonDetail.content.join(", ")}</BodyRegular>
    </CarePersonDetail>
  )
}

export const CarePersonNearMeCard = ({
  carePerson,
}: CarePersonNearMeCardProps): JSX.Element | null => {
  if (!carePerson) return null
  const { city, state, pageType } = useContext(LocationPageContext)
  const statesToDisplay =
    carePerson.available_states.length === 0
      ? carePerson.licensed_states
      : carePerson.available_states

  const carePersonBio = createCarePersonBio(carePerson, city, state, pageType)
  const carePersonDetails = getCarePersonDetails(
    statesToDisplay,
    carePerson.specialties,
    carePerson.degrees,
    carePerson.insurance_accepted as Record<string, IInsuranceAcceptedItem[]>,
    carePerson.role
  )

  return (
    <CardContainer>
      <CarePersonImageContainer>
        <img
          alt=""
          src={
            carePerson.profile_photo === null || carePerson.profile_photo === ""
              ? gendersvg
              : carePerson.profile_photo
          }
          height={130}
          width={130}
          loading="lazy"
        />
        <MobileWrapper>
          <CarePersonName>{carePerson.name}</CarePersonName>
          <CarePersonBoldText>{capitalize(carePerson.role)}</CarePersonBoldText>
          {carePerson.accepting_new_client && (
            <AcceptingNewClients>Accepting new clients</AcceptingNewClients>
          )}
        </MobileWrapper>
      </CarePersonImageContainer>
      <CarePersonBioContainer id="care-person-bio">
        <CarePersonName>{carePerson.name}</CarePersonName>
        <CarePersonBoldText>{capitalize(carePerson.role)}</CarePersonBoldText>
        <BodyRegular>{carePersonBio}</BodyRegular>
        <Button
          text="Full bio and availability"
          to={`${process.env.GATSBY_BASE_URL}/${carePerson.url}`}
          paddingHorizontal={24}
        />
      </CarePersonBioContainer>
      <CarePersonDetailsContainer>
        {carePersonDetails.map(carePersonDetail => (
          <CarePersonDetailSection
            carePersonDetail={carePersonDetail}
            key={carePersonDetail.title}
          />
        ))}
      </CarePersonDetailsContainer>
    </CardContainer>
  )
}
