import styled from "styled-components"
import { colors } from "../../themes/colors"

export const BodyRegular = styled.p<{ fontWeight?: number }>`
  font-weight: ${props => props.fontWeight || 300};
  font-size: 16px;
  line-height: 24px;
  white-space: pre-wrap;
  font-family: Poppins;
  @media (max-width: 400px) {
    font-size: 14px;
    line-height: 22px;
  }
  a {
    /* Body/Large/Semibold */
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: ${colors.c_secondary[600]};
    text-decoration: none;
  }
`
