import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import {
  TestimonialWrapper,
  TestimonialCard,
  TestimonialName,
  TestimonialText,
  NavDrawerWrapper,
} from "./styled"
import { singleTestimonial } from "./constants"

export interface ServiceProps {
  servicePlan: "therapy" | "medication"
}

export const NavDrawerTestimonial = ({
  servicePlan,
}: ServiceProps): JSX.Element => {
  const testimonials = singleTestimonial[servicePlan]
  const baseURL = process.env.GATSBY_BASE_URL

  return (
    <NavDrawerWrapper>
      <TestimonialWrapper>
        {testimonials.map((testimonial, index) => (
          <TestimonialCard key={index} to={"/testimonials"}>
            <TestimonialText>{testimonial.quote}</TestimonialText>
            <TestimonialName>{testimonial.name}</TestimonialName>
            <StaticImage
              src="../../../../images/five-stars.svg"
              alt="5 stars"
            />
          </TestimonialCard>
        ))}
      </TestimonialWrapper>
    </NavDrawerWrapper>
  )
}
