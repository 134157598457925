export const therapistsStateNames = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
]

const stateNameToUrl = (state: string) => {
  return state.replace(/\s+/g, "-").toLowerCase()
}

export const therapistCities = [
  { name: "New York City", linkEnd: "new-york/new-york" },
  { name: "Los Angeles", linkEnd: "california/los-angeles" },
  { name: "Chicago", linkEnd: "illinois/chicago" },
  { name: "Houston", linkEnd: "texas/houston" },
  { name: "Phoenix", linkEnd: "arizona/phoenix" },
  { name: "Philadelphia", linkEnd: "pennsylvania/philadelphia" },
  { name: "San Antonio", linkEnd: "texas/san-antonio" },
  { name: "San Diego", linkEnd: "california/san-diego" },
  { name: "Dallas", linkEnd: "texas/dallas" },
  { name: "San Jose", linkEnd: "california/san-jose" },
  { name: "Austin", linkEnd: "texas/austin" },
  { name: "Jacksonville", linkEnd: "florida/jacksonville" },
  { name: "Fort Worth", linkEnd: "texas/fort-worth" },
  { name: "Columbus", linkEnd: "ohio/columbus" },
  { name: "Charlotte", linkEnd: "north-carolina/charlotte" },
  { name: "Indianapolis", linkEnd: "indiana/indianapolis" },
  { name: "San Francisco", linkEnd: "california/san-francisco" },
  { name: "Seattle", linkEnd: "washington/seattle" },
  { name: "Denver", linkEnd: "colorado/denver" },
  { name: "Nashville", linkEnd: "tennessee/nashville" },
  { name: "Oklahoma City", linkEnd: "oklahoma/oklahoma-city" },
  { name: "Boston", linkEnd: "massachusetts/boston" },
  { name: "El Paso", linkEnd: "texas/el-paso" },
  { name: "Las Vegas", linkEnd: "nevada/las-vegas" },
  { name: "Memphis", linkEnd: "tennessee/memphis" },
  { name: "Detroit", linkEnd: "michigan/detroit" },
  { name: "Baltimore", linkEnd: "maryland/baltimore" },
  { name: "Milwaukee", linkEnd: "wisconsin/milwaukee" },
  { name: "Fresno", linkEnd: "california/fresno" },
  { name: "Tucson", linkEnd: "arizona/tucson" },
  { name: "Sacramento", linkEnd: "california/sacramento" },
  { name: "Mesa", linkEnd: "arizona/mesa" },
  { name: "Kansas City", linkEnd: "missouri/kansas-city" },
  { name: "Atlanta", linkEnd: "georgia/atlanta" },
  { name: "Colorado Springs", linkEnd: "colorado/colorado-springs" },
  { name: "Raleigh", linkEnd: "north-carolina/raleigh" },
  { name: "Long Beach", linkEnd: "california/long-beach" },
  { name: "Virginia Beach", linkEnd: "virginia/virginia-beach" },
  { name: "Oakland", linkEnd: "california/oakland" },
  { name: "Miami", linkEnd: "florida/miami" },
  { name: "Minneapolis", linkEnd: "minnesota/minneapolis" },
  { name: "Bakersfield", linkEnd: "california/bakersfield" },
  { name: "Tulsa", linkEnd: "oklahoma/tulsa" },
  { name: "Aurora", linkEnd: "colorado/aurora" },
  { name: "Arlington", linkEnd: "virginia/arlington" },
  { name: "Wichita", linkEnd: "kansas/wichita" },
  { name: "Cleveland", linkEnd: "ohio/cleveland" },
  { name: "Louisville", linkEnd: "kentucky/louisville" },
  { name: "New Orleans", linkEnd: "louisiana/new-orleans" },
  { name: "Tampa", linkEnd: "florida/tampa" },
]

export const prescriberStateNames = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
]

export const prescriberStates = prescriberStateNames.map(state => {
  const linkEnd = stateNameToUrl(state)
  return { name: state, linkEnd }
})

export const therapistsStates = therapistsStateNames.map(state => {
  const linkEnd = stateNameToUrl(state)
  return { name: state, linkEnd }
})

export const prescriberCities = [
  { name: "New York City", linkEnd: "new-york/new-york" },
  { name: "Los Angeles", linkEnd: "california/los-angeles" },
  { name: "Chicago", linkEnd: "illinois/chicago" },
  { name: "Houston", linkEnd: "texas/houston" },
  { name: "Phoenix", linkEnd: "arizona/phoenix" },
  { name: "Philadelphia", linkEnd: "pennsylvania/philadelphia" },
  { name: "San Antonio", linkEnd: "texas/san-antonio" },
  { name: "San Diego", linkEnd: "california/san-diego" },
  { name: "Dallas", linkEnd: "texas/dallas" },
  { name: "San Jose", linkEnd: "california/san-jose" },
  { name: "Austin", linkEnd: "texas/austin" },
  { name: "Jacksonville", linkEnd: "florida/jacksonville" },
  { name: "Fort Worth", linkEnd: "texas/fort-worth" },
  { name: "Columbus", linkEnd: "ohio/columbus" },
  { name: "Charlotte", linkEnd: "north-carolina/charlotte" },
  { name: "Indianapolis", linkEnd: "indiana/indianapolis" },
  { name: "San Francisco", linkEnd: "california/san-francisco" },
  { name: "Seattle", linkEnd: "washington/seattle" },
  { name: "Denver", linkEnd: "colorado/denver" },
  { name: "Nashville", linkEnd: "tennessee/nashville" },
  { name: "Oklahoma City", linkEnd: "oklahoma/oklahoma-city" },
  { name: "Boston", linkEnd: "massachusetts/boston" },
  { name: "El Paso", linkEnd: "texas/el-paso" },
  { name: "Portland", linkEnd: "oregon/portland" },
  { name: "Las Vegas", linkEnd: "nevada/las-vegas" },
  { name: "Memphis", linkEnd: "tennessee/memphis" },
  { name: "Detroit", linkEnd: "michigan/detroit" },
  { name: "Baltimore", linkEnd: "maryland/baltimore" },
  { name: "Milwaukee", linkEnd: "wisconsin/milwaukee" },
  { name: "Albuquerque", linkEnd: "new-mexico/albuquerque" },
  { name: "Fresno", linkEnd: "california/fresno" },
  { name: "Tucson", linkEnd: "arizona/tucson" },
  { name: "Sacramento", linkEnd: "california/sacramento" },
  { name: "Mesa", linkEnd: "arizona/mesa" },
  { name: "Kansas City", linkEnd: "missouri/kansas-city" },
  { name: "Atlanta", linkEnd: "georgia/atlanta" },
  { name: "Omaha", linkEnd: "nebraska/omaha" },
  { name: "Colorado Springs", linkEnd: "colorado/colorado-springs" },
  { name: "Raleigh", linkEnd: "north-carolina/raleigh" },
  { name: "Long Beach", linkEnd: "california/long-beach" },
  { name: "Virginia Beach", linkEnd: "virginia/virginia-beach" },
  { name: "Oakland", linkEnd: "california/oakland" },
  { name: "Miami", linkEnd: "florida/miami" },
  { name: "Minneapolis", linkEnd: "minnesota/minneapolis" },
  { name: "Bakersfield", linkEnd: "california/bakersfield" },
  { name: "Tulsa", linkEnd: "oklahoma/tulsa" },
  { name: "Aurora", linkEnd: "colorado/aurora" },
  { name: "Arlington", linkEnd: "virginia/arlington" },
  { name: "Wichita", linkEnd: "kansas/wichita" },
  { name: "New Orleans", linkEnd: "louisiana/new-orleans" },
]
