import React, { FC, HTMLAttributes, useState } from "react"
import styled from "styled-components"

const getBackgroundColor = ({
  checked,
  variant,
}: {
  checked: boolean
  variant: "secondary" | "primary"
}): string => {
  const bgMapWhenChecked = {
    primary: "#250044",
    secondary: "#fff",
  }

  const bgMapWhenUnchecked = {
    primary: "#fff",
    secondary: "#fff",
  }
  return checked ? bgMapWhenChecked[variant] : bgMapWhenUnchecked[variant]
}

const getBorderColor = ({
  variant,
}: {
  variant: "secondary" | "primary"
}): string => {
  const borderMaps = {
    primary: "#fff",
    secondary: "#51459E",
  }

  return `solid ${borderMaps[variant]}`
}

const Wrapper = styled.div<{
  checked: boolean
  variant: "secondary" | "primary"
}>`
  *,
  *::before,
  *::after {
    box-sizing: content-box !important;
  }

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  label {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #250044;
  }

  display: table;
  position: relative;
  padding-left: 2rem;
  cursor: pointer;
  margin-bottom: 0.4rem;

  .b-input {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background: #fff;
    transition: background 250ms;
    border: 1px solid #859eff;
    border-radius: 4px;
    background: ${getBackgroundColor};
    border-color: ${({ checked }) => (checked ? "#250044" : "#859eff")};
  }

  .b-input::after {
    content: "";
    position: absolute;
    display: ${({ checked }) => (checked ? "block" : "none")};
    left: 5px;
    top: 2px;
    width: 4px;
    height: 8px;
    border: ${getBorderColor};
    border-width: 0 2px 2px 0;
    transition: background 250ms;
    transform: rotate(45deg);
  }
`

interface CheckboxProps {
  label?: string
  checked?: boolean
  onChange: () => void
  variant?: "secondary" | "primary"
}

const CheckBox: FC<CheckboxProps> = ({
  label,
  checked,
  onChange,
  variant = "primary",
}) => {
  return (
    <Wrapper checked={checked || false} onClick={onChange} variant={variant}>
      <label htmlFor={label}>
        <input
          id={label}
          type="checkbox"
          name={`${label}-checkbox`}
          checked={checked}
          onChange={onChange}
        />
        {label}
      </label>
      <div className="b-input" />
    </Wrapper>
  )
}

export default CheckBox
