import {
  SupportingText,
  ListSupportingText,
  CheckItems,
  HyperLinkWrap,
  Frame,
  HyperLinkContainer,
  BottomWrap,
  BottomContainer,
  ArrowIconImage,
  Tabs,
  TabGroup,
  Linked,
  ContentContainer,
} from "./styled"
import ArrowUpRight from "./images/arrow_up_right.svg"
import { tabContents, Links, Message } from "./constant"

export const TabContent = ({ currentTab }: { currentTab: number }) => {
  const { tabMap, titleContent, headerTitle } = tabContents[currentTab]

  return (
    <ContentContainer tab={currentTab}>
      <SupportingText>{titleContent}</SupportingText>
      <TabGroup>
        <Tabs>
          <ListSupportingText>{headerTitle}</ListSupportingText>
          {tabMap.map(({ header, icon }) => (
            <CheckItems key={header}>
              <Frame>{icon}</Frame>
              <SupportingText>{header}</SupportingText>
            </CheckItems>
          ))}
        </Tabs>
        {currentTab === 0 ? (
          <HyperLinkWrap>
            <HyperLinkContainer>
              <Linked to={Links.fsa}>{Message.fsa}</Linked>
              <ArrowIconImage src={ArrowUpRight} alt="arrow" />
            </HyperLinkContainer>
            <HyperLinkContainer>
              <Linked to={Links.hsa}>{Message.hsa}</Linked>
              <ArrowIconImage src={ArrowUpRight} alt="arrow" />
            </HyperLinkContainer>
          </HyperLinkWrap>
        ) : (
          <BottomWrap>
            <BottomContainer>{Message.Out_of_network}</BottomContainer>
          </BottomWrap>
        )}
      </TabGroup>
    </ContentContainer>
  )
}
