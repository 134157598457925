import { TestimonialContent } from "./types"

export const singleTestimonial: TestimonialContent = {
  therapy: [
    {
      quote:
        "Cerebral has done everything they can to make sure my experience is easy and talking to someone from home is much better for myself with my anxiety. I love this company from the time I started using it in 2020 and continue to do so.",
      name: "Michelle",
      url: "",
    },
  ],
  medication: [
    {
      quote:
        "My medical team is professional, responsive, easy to reach, and I feel confident that my mental health issues will be managed properly. I have hope and don’t feel like like “just another case.” They see me as a person.",
      name: "Jacqueline",
      url: "",
    },
  ],
}
