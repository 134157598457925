import {
  Card,
  DektopCardContainer,
  MobileCardContainer,
  QuoteName,
  QuoteText,
  SuccessStoriesWrapper,
  TestimonialsButton,
  QuoteTitle,
} from "./styled"

import QuoteIcon from "../../../shared/assets/quotes-primary-300.svg"
import FiveStars from "../../../../images/five-stars.svg"
import { Slider } from "../../../ui"

const testimonials = [
  {
    quote:
      "I have been cared for by 4 telemedicine companies. Cerebral is the best. Their technology is amazing and this is important. Cerebral emails, texts and my care provider truly cares about my mental wellbeing.  I finally was properly diagnosed and am treated every month.",
    name: "E.G.",
  },
  {
    quote:
      "My overall experience with Cerebral has been exceptional - their customer service is great, responsive and always helpful..and my therapist has been life-changing!",
    name: "Leanna",
  },
  {
    quote:
      "My medical team is professional, responsive, easy to reach, and I feel confident that my mental health issues will be managed properly. I have hope and don’t feel like like “just another case.” They see me as a person.",
    name: "Jacqueline",
  },
]

export const SuccessStories = ({
  items = testimonials,
  hideButton = false,
  variant,
}: {
  items?: { quote: string; name: string; title?: string }[]
  hideButton?: boolean
  variant?: "secondary"
}) => {
  return (
    <SuccessStoriesWrapper variant={variant}>
      <h2>Success stories</h2>
      <MobileCardContainer>
        <Slider>
          {items.map(testimonial => (
            <Card key={testimonial.quote}>
              <div>
                <img height="24px" src={QuoteIcon} alt="" />
                {testimonial.title && (
                  <QuoteTitle>{testimonial.title}</QuoteTitle>
                )}
                <QuoteText className="quote">{testimonial.quote}</QuoteText>
              </div>
              <div>
                <QuoteName className="name">{testimonial.name}</QuoteName>
                <img src={FiveStars} alt="5 stars" />
              </div>
            </Card>
          ))}
        </Slider>
      </MobileCardContainer>

      <DektopCardContainer>
        {items.map(testimonial => (
          <Card key={testimonial.quote}>
            <div>
              <img height="24px" src={QuoteIcon} alt="" />
              {testimonial.title && (
                <QuoteTitle>{testimonial.title}</QuoteTitle>
              )}
              <QuoteText className="quote">{testimonial.quote}</QuoteText>
            </div>
            <div>
              <QuoteName className="name">{testimonial.name}</QuoteName>
              <img src={FiveStars} alt="5 stars" />
            </div>
          </Card>
        ))}
      </DektopCardContainer>
      {!hideButton && (
        <TestimonialsButton to="/testimonials" variant={variant}>
          See what else people are saying
        </TestimonialsButton>
      )}
    </SuccessStoriesWrapper>
  )
}
