import { UilUser } from "@iconscout/react-unicons"
import { UilHeartMedical } from "@iconscout/react-unicons"
import { UilCalendarAlt } from "@iconscout/react-unicons"
import {
  Card,
  CardsWrapper,
  GettingStartedWrapper,
  Header,
  IconWrapper,
  ButtonWrapper,
} from "./styled"
import { GetStartedButton } from "../GetStartedButton"

const Cards = [
  {
    title: "Create an account",
    description:
      "Create your account with some basic information — like name, email, phone number, and some context about your experience with mental health care.",
    icon: <UilUser color="#250044" />,
  },
  {
    title: "Choose a service",
    description:
      "Learn a bit more about therapy and medication and then choose the right service to support you. You can add or switch services later for any reason.",
    icon: <UilHeartMedical color="#250044" />,
  },
  {
    title: "Choose a clinician + book a session",
    description:
      "See all available clinicians in your area and filter by age, gender, experience, and more. You can read their bios, see their availability, and book your first session. ",
    icon: <UilCalendarAlt color="#250044" />,
  },
]

interface GettingStartedProps {
  description: string
  hideGetStartedBtn?: boolean
}

export const GettingStarted = ({
  description,
  hideGetStartedBtn,
}: GettingStartedProps) => {
  return (
    <GettingStartedWrapper>
      <Header>
        <h2>Getting started</h2>
        <p>{description}</p>
      </Header>
      <CardsWrapper>
        {Cards.map((card, index) => (
          <Card key={index}>
            <IconWrapper>{card.icon}</IconWrapper>
            <div>
              <h4>{card.title}</h4>
              <p>{card.description}</p>
            </div>
          </Card>
        ))}
      </CardsWrapper>
      {!hideGetStartedBtn && (
        <ButtonWrapper maxWidth="160px">
          <GetStartedButton text="Create my plan" height={56} />
        </ButtonWrapper>
      )}
    </GettingStartedWrapper>
  )
}
