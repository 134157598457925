import React from "react"

import { Picture, Image, H2, H3, H1 } from "../../ui"
import {
  Container,
  ContentContainer,
  TextContainer,
  ListContainer,
  Bullet,
} from "./styled"

interface AlternatingImageTextsContent {
  title: string
  desc: string[]
  bullets: string[]
  images: Image[]
  id: string
}

interface AlternatingImageTextsProps {
  contents: AlternatingImageTextsContent[]
  theme?: "primary" | "secondary"
  padding?: string
  headerText?: string
}

export const AlternatingImageTexts = ({
  contents,
  theme,
  headerText,
}: AlternatingImageTextsProps): JSX.Element => {
  return (
    <Container theme={theme}>
      {headerText && <H2>{headerText}</H2>}
      {contents.map((content, i) => {
        return (
          <ContentContainer
            flexDirection={i % 2 === 0 ? "row-reverse" : "row"}
            key={content.id}
            theme={theme}
          >
            <Picture
              images={content.images}
              fallBack={content.images[0].srcSet}
              alt=""
            />
            <TextContainer>
              <H3>{content.title}</H3>
              {content.desc.map(paragraph => (
                <p
                  key={paragraph}
                  dangerouslySetInnerHTML={{
                    __html: paragraph,
                  }}
                />
              ))}
              {content.bullets.length > 0 && (
                <ListContainer theme={theme}>
                  {content.bullets.map(bullet => (
                    <li key={bullet}>
                      <Bullet>{bullet}</Bullet>
                    </li>
                  ))}
                </ListContainer>
              )}
            </TextContainer>
          </ContentContainer>
        )
      })}
    </Container>
  )
}
