import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { CtaButton } from "./LandingPageStyles"

const Section = styled.div`
  padding: 96px;
  padding-top: 0;
  display: flex;
  justify-content: center;

  @media (max-width: 1216px) {
    padding: 0 24px;
  }

  @media (max-width: 834px) {
    padding: 0px 24px 64px;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1216px;
  background: linear-gradient(135deg, #eeecf5 0%, #c3cffc 100%);
  padding: 64px;
  border-radius: 16px;

  h2,
  span {
    font-family: Poppins;
    text-align: center;
  }

  a {
    margin-top: 32px;
  }

  span {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  span.subscript {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  h2 {
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    color: #1c1e21;
    margin-top: 12px;
    margin-bottom: 20px;
  }

  @media (max-width: 834px) {
    flex-direction: column;
    height: 100%;
    padding: 40px 24px;

    h2 {
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }
  }
`

const Content = styled.div`
  font-family: Poppins;
  color: #353a41;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 768px;
`

interface TextButtonProps {
  slice: {
    primary: {
      text: {
        text: string
      }
      cta_link: {
        url: string
      }
      cta_text: {
        text: string
      }
    }
  }
}

const TextButton = ({ slice }: TextButtonProps) => {
  const { text, cta_link, cta_text } = slice.primary

  return (
    <Section>
      <Container>
        <Content dangerouslySetInnerHTML={{ __html: text.text }} />

        <CtaButton to={cta_link.url || process.env.GATSBY_START_URL}>
          {cta_text.text}
        </CtaButton>
      </Container>
    </Section>
  )
}

export const query = graphql`
  fragment TextButtonSlice on PrismicLandingDataBodyTextButton {
    slice_type
    primary {
      text {
        text
      }
      cta_link {
        url
      }
      cta_text {
        text
      }
    }
  }
`
export default TextButton
