export const testimonials = [
  {
    id: "testimonial-1",
    content: `Increased energy and overall happiness`,
  },
  {
    id: "testimonial-2",
    content: `I honestly didn't think I could feel this good`,
    center: true,
  },
  {
    id: "testimonial-3",
    content: `I'm so happy I took a leap of faith and signed up`,
  },
]
