import styled from "styled-components"

export const Footer = styled.footer`
  background: #51459e;
  display: flex;
  flex-direction: column;
  padding: 5em 5em 2em 5em;
  @media (max-width: 800px) {
    padding: 2em 1.26em;
  }
`

export const StoresWrapper = styled.div`
  display: flex;
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    margin: 0;
  }
`
