import { GetStartedButton } from "../../../../shared"
import { ExternalLink } from "../../../ExternalLink"
import { MobileButtonContainerWrapper } from "./styled"

export const MobileButtonContainer = () => {
  return (
    <MobileButtonContainerWrapper>
      <GetStartedButton />
      <ExternalLink
        href={`${process.env.GATSBY_LOGIN_URL}`}
        text="Log In"
        theme="secondary"
      />
    </MobileButtonContainerWrapper>
  )
}
