import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  ImageDataLike,
} from "gatsby-plugin-image"

const FullWidth = styled.div`
  width: 100%;
  background-color: #e1f9fe;
`

const QuoteContainer = styled.div`
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  display: flex;

  @media (max-width: 834px) {
    flex-direction: column;
  }
`
const Quote = styled.span`
  padding-top: 100px;
  font-family: EB Garamond;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 55px;
  color: #250044;
  transform: skewX(20deg);

  @media (max-width: 834px) {
    font-size: 20px;
    line-height: 30px;
    transform: skewX(0deg);
  }
`

const QuoteCharacter = styled.div`
  font-family: Poppins;
  padding-bottom: 100px;
  padding-top: 100px;
  font-style: normal;
  font-weight: 900;
  font-size: 144px;
  line-height: 55px;
  color: #aabaf3;
  margin-right: -20px;
  transform: skewX(20deg);

  @media (max-width: 834px) {
    font-size: 70px;
    line-height: 30px;
    margin-right: -10px;
    transform: skewX(0deg);
  }
`

const ImageContainer = styled.div`
  overflow: hidden;
  width: 600px;
  margin: 0 auto;
  picture img {
    object-position: center center !important;
  }

  @media (max-width: 834px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`

const AdjacentContainer = styled.div`
  transform: skewX(-20deg);
  background-color: black;
  margin-left: -100px;
  padding-left: 200px;
  padding-right: 40px;
  width: 100%;
  display: flex;
  background-color: #e1f9fe;

  @media (max-width: 834px) {
    padding-left: 100px;
    margin-left: -100px;
    font-size: 70px;
    line-height: 30px;
    margin-right: -10px;
    transform: skewX(0deg);
  }
`

interface QuoteWithImageInterface {
  slice: {
    primary: {
      slice_id: {
        text: string
      }
      quote: {
        text: string
      }
      image: ImageDataLike
    }
  }
}

const QuoteWithImage = ({ slice }: QuoteWithImageInterface) => {
  const { quote, image } = slice.primary
  const images = getImage(image) as IGatsbyImageData

  return (
    <FullWidth id={slice.primary.slice_id?.text || ""}>
      <QuoteContainer>
        <ImageContainer>
          <GatsbyImage image={images} loading="eager" alt="" />
        </ImageContainer>
        <AdjacentContainer>
          <QuoteCharacter>“</QuoteCharacter>
          <Quote>{quote.text}</Quote>
        </AdjacentContainer>
      </QuoteContainer>
    </FullWidth>
  )
}

export const query = graphql`
  fragment QuoteWithImageSlice on PrismicLandingDataBodyQuoteWithImage {
    id
    slice_type
    primary {
      quote {
        text
      }
      image {
        gatsbyImageData(width: 435)
      }
    }
  }
  fragment PageQuoteWithImageSlice on PrismicPageDataBodyQuoteWithImage {
    id
    slice_type
    primary {
      slice_id {
        text
      }
      quote {
        text
      }
      image {
        gatsbyImageData(width: 435)
      }
    }
  }
  fragment PostQuoteWithImageSlice on PrismicPostDataBodyQuoteWithImage {
    id
    slice_type
    primary {
      quote {
        text
      }
      image {
        gatsbyImageData(width: 435)
      }
    }
  }
  fragment ProviderQuoteWithImageSlice on PrismicProviderDataBodyQuoteWithImage {
    id
    slice_type
    primary {
      quote {
        text
      }
      image {
        gatsbyImageData(width: 435)
      }
    }
  }
  fragment CounselorQuoteWithImageSlice on PrismicCounselorDataBodyQuoteWithImage {
    id
    slice_type
    primary {
      quote {
        text
      }
      image {
        gatsbyImageData(width: 435)
      }
    }
  }
  fragment TherapistQuoteWithImageSlice on PrismicTherapistDataBodyQuoteWithImage {
    id
    slice_type
    primary {
      quote {
        text
      }
      image {
        gatsbyImageData(width: 435)
      }
    }
  }
`

export default QuoteWithImage
