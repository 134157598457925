import React from "react"
import { graphql } from "gatsby"
import MultiColumn from "./MultiColumn"

const ThreeColumns = ({ slice }) => {
  return <MultiColumn slice={slice} cols={3} />
}

export const query = graphql`
  fragment ThreeColumnsSlice on PrismicLandingDataBody3Columns {
    slice_type
    items {
      copy {
        html
      }
      image {
        gatsbyImageData(width: 360, height: 150)
      }
    }
    primary {
      copy {
        html
      }
      title {
        text
      }
    }
  }
  fragment PageThreeColumnsSlice on PrismicPageDataBody3Columns {
    slice_type
    items {
      copy {
        html
      }
      image {
        gatsbyImageData(width: 360, height: 150)
      }
    }
    primary {
      slice_id {
        text
      }
      copy {
        html
      }
      title {
        text
      }
    }
  }
  fragment PostThreeColumnsSlice on PrismicPostDataBody3Columns {
    slice_type
    items {
      copy {
        html
      }
      image {
        gatsbyImageData(width: 360, height: 150)
      }
    }
    primary {
      copy {
        html
      }
      title {
        text
      }
    }
  }
  fragment ProviderThreeColumnsSlice on PrismicProviderDataBody3Columns {
    slice_type
    items {
      copy {
        html
      }
      image {
        gatsbyImageData(width: 360, height: 150)
      }
    }
    primary {
      copy {
        html
      }
      title {
        html
      }
    }
  }
  fragment CounselorThreeColumnsSlice on PrismicCounselorDataBody3Columns {
    slice_type
    items {
      copy {
        html
      }
      image {
        gatsbyImageData(width: 360, height: 150)
      }
    }
    primary {
      copy {
        html
      }
      title {
        html
      }
    }
  }
  fragment TherapistThreeColumnsSlice on PrismicTherapistDataBody3Columns {
    slice_type
    items {
      copy {
        html
      }
      image {
        gatsbyImageData(width: 360, height: 150)
      }
    }
    primary {
      copy {
        html
      }
      title {
        html
      }
    }
  }
`

export default ThreeColumns
