import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image"
import { CtaButton } from "./LandingPageStyles"

const HeadingH1 = styled.div`
  color: #1c1e21;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 16px;

  @media (max-width: 834px) {
    font-size: 28px;
    line-height: 30px;
  }
`
const HeaderDescription = styled.div`
  color: #1c1e21;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`

const Container = styled.div<{ background: string }>`
  background: ${({ background }) => background};
  display: flex;
  justify-content: center;
  padding: 64px 24px;

  @media (max-width: 834px) {
    padding: 48px 24px;
  }
`
const InnerContainer = styled.div<{
  shouldHideMobileImage: boolean
}>`
  display: grid;
  margin: 0 auto;
  grid-template-columns: 1fr 1fr;
  max-width: 992px;
  align-items: center;
  gap: 80px;
  @media (max-width: 834px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr ${({ shouldHideMobileImage }) =>
        shouldHideMobileImage ? "" : "1fr"};
    margin: 0 auto;
    gap: 0;
  }
`

const LeftSide = styled.div`
  h1 {
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 32px;
  }

  @media (max-width: 834px) {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    h1 {
      font-size: 28px;
    }
  }
`

const HeaderImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const HeaderImage = styled(GatsbyImage)<{ objectFit?: string }>`
  width: 407px !important;
  height: 250px !important;
  border-radius: 16px;
  @media (max-width: 834px) {
    display: none !important;
    max-width: 326px;
    transform: none;
    margin: 0 auto;
    grid-row-start: 1;
  }
`

interface ContentHubHeaderInterface {
  slice: {
    primary: {
      ch_header_title: {
        text: string
      }
      ch_header_description: {
        html: string
      }
      ch_cta_text: {
        text: string
      }
      ch_cta_link?: {
        url: string
      }
      ch_header_img: {
        gatsbyImageData: IGatsbyImageData
      }
      ch_header_image_object_fit: {
        text:
          | "fill"
          | "none"
          | "inherit"
          | "initial"
          | "-moz-initial"
          | "revert"
          | "revert-layer"
          | "unset"
          | "contain"
          | "cover"
          | "scale-down"
          | undefined
      }
    }
  }
}

const ContentHubHeader = ({ slice }: ContentHubHeaderInterface) => {
  const {
    ch_header_title,
    ch_header_description,
    ch_cta_text,
    ch_cta_link,
    ch_header_img,
    ch_header_image_object_fit,
  } = slice.primary

  return (
    <Container
      background="linear-gradient(135deg, #ECEFFE 0%, #FEF9EC 100%)"
      className="take-control-variant"
    >
      <InnerContainer shouldHideMobileImage={true}>
        <LeftSide>
          <HeadingH1>{ch_header_title.text}</HeadingH1>
          <HeaderDescription
            dangerouslySetInnerHTML={{
              __html: ch_header_description.html,
            }}
          />
          <CtaButton
            to={ch_cta_link?.url || process.env.GATSBY_START_URL}
            width="250px"
            boxShadow="none"
          >
            {ch_cta_text.text}
          </CtaButton>
        </LeftSide>

        <HeaderImageWrapper>
          <HeaderImage
            image={getImage(ch_header_img) as IGatsbyImageData}
            alt=""
            objectFit={ch_header_image_object_fit?.text || "cover"}
          />
        </HeaderImageWrapper>
      </InnerContainer>
    </Container>
  )
}

export const query = graphql`
  fragment PageContentHubHeaderSlice on PrismicPageDataBodyContentHubHeader {
    id
    slice_type
    primary {
      ch_header_title {
        text
      }
      ch_header_description {
        html
      }
      ch_cta_text {
        text
      }
      ch_cta_link {
        url
      }
      ch_header_img {
        gatsbyImageData(placeholder: BLURRED, layout: FIXED)
      }
      ch_header_image_object_fit {
        text
      }
    }
  }
`

export default ContentHubHeader
