import styled from "styled-components"
import { colors } from "../../../themes/colors"

export const Wrapper = styled.section`
  background: linear-gradient(135deg, #eceffe 0%, #fef9ec 100%);
  padding: 96px 0;

  @media (max-width: 1050px) {
    padding: 64px 24px;
  }
`

export const HomeWrapper = styled.section`
  background: linear-gradient(135deg, #eceffe 0%, #fef9ec 100%);
  padding: 64px 0 0;
`

export const Header = styled.h1`
  font-family: Poppins;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  padding: 0 24px;
  max-width: 1200px;
  margin: auto;

  @media (max-width: 834px) {
    font-size: 24px;
    line-height: 28px;
  }
`
export const Subheader = styled.p`
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  max-width: 768px;
  margin: auto;
  padding: 16px 24px 0;

  @media (max-width: 834px) {
    font-size: 14px;
    padding-top: 24px;
  }
`

export const Pill = styled.span`
  background: #cbd8ff;
  border-radius: 100px;
  padding: 8px 16px 8px 16px;
  margin-top: 8px;
  color: ${colors.c_primary[800]};
  display: inline-block;
  width: 188px;
`

export const PlansContainer = styled.div<{ gridCount: number }>`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  margin: 0 auto;
  padding: 40px 0 0;
  max-width: 100%;

  @media (max-width: 992px) {
    display: none;
  }
`

export const InsurancePlansContainer = styled.div<{ gridCount: number }>`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  margin: 0 auto;
  padding: 40px 0 0;
  max-width: 100%;
`

export const PillHeader = styled.h4`
  font-family: Poppins;
  font-size: 18px;
  text-align: left;
`

export const CardContainer = styled.a<{ disableLinks?: boolean }>`
  pointer-events: ${({ disableLinks }) => (disableLinks ? "none" : "auto")};
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  text-align: center;
  padding: 24px;
  border-radius: 16px;
  min-width: 248px;
  width: 320px;
  border: 2px solid ${colors.c_white[500]};
  cursor: pointer;
  color: ${colors.c_black[900]};
  text-decoration: none;

  &:hover {
    border: 2px solid ${colors.c_primary[700]};
    box-shadow: 0 5px 24px 4px #1c1e211a;
  }
`

export const CardIcon = styled.img`
  width: 40px;
  height: 40px;
`
export const ArrowIcon = styled.img`
  width: 24px;
  height: 24px;
  margin: 8px;
`

export const CardHeader = styled.h3`
  font-family: Poppins;
  font-size: 22px;
  line-height: 24px;
  padding-bottom: 8px;
  padding-top: 8px;
`

export const CardText = styled.p`
  padding-left: 8px;
  padding-right: 8px;
  font-family: Poppins;
  font-size: 14px;
  line-height: 24px;
  padding-bottom: 20px;
  white-space: pre-wrap;
`

export const LearnMore = styled.p`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: #51459e;
  text-decoration: none;
`
export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`

export const Chip = styled.div<{ position?: string }>`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 12px;
  background-color: #51459e;
  font-size: 11px;
  font-weight: 600;
  line-height: 14px;
  color: white;
  position: ${({ position }) => position || "absolute"};
  left: ${({ position }) => (position ? 0 : "10px")};
  top: ${({ position }) => (position ? 0 : "10px")};

  @media (max-width: 1050px) {
    position: relative;
    left: 0;
    top: 0;
  }
`

export const InsuranceTitle = styled.div`
  color: #1c1e21;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0px auto 16px;

  @media (max-width: 712px) {
    margin: 24px auto 16px;
  }
`

export const InsuranceBrands = styled.div`
  padding-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  column-gap: 94px;
  flex-wrap: wrap;

  img {
    height: 64px;
  }

  @media (max-width: 712px) {
    padding-bottom: 0;
    column-gap: 30px;
    row-gap: 16px;
    img {
      object-fit: contain;
      height: 56px;
    }
  }
`

export const InsuranceLink = styled.a`
  color: #385ade;
  text-decoration-line: underline;
`

export const InsuranceWrapper = styled.div`
  padding-top: 24px;

  @media (max-width: 992px) {
    padding-top: 0;
  }
`

export const PillContainer = styled.a`
  background-color: white;
  text-align: center;
  border-radius: 16px;
  display: flex;
  cursor: pointer;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
`

export const PillLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-grow: 1;
  flex-basis: 0;
`

export const PlansContainerMobile = styled.div`
  display: none;
  @media (max-width: 992px) {
    gap: 12px;
    display: flex;
    flex-direction: column;
    padding: 24px;
  }
`

export const InsuranceLogoWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 992px;
  margin-bottom: 8px;

  @media (max-width: 700px) {
    display: flex;
    flex-wrap: wrap;
    max-width: 420px;
  }
`

export const LogoImage = styled.img`
  height: 64px;
`

export const InsuranceLogoContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  self-align: center;
  width: 100%;
`

export const InsuranceText = styled.p`
  text-align: center;
  padding-bottom: 64px;
  font-family: Poppins;
  font-size: 14px;
  line-height: 24px;
`
