import styled from "styled-components"
import { colors } from "../../../themes/colors"

export const OurServicesWrapper = styled.div`
  background: #f2f5ff80;
  padding: 80px 24px;
  align-items: center;
  h1 {
    color: ${colors.c_black[900]};
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 8px;
  }
  h5 {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 8px;
  }
  h3 {
    margin-top: 80px;
    margin-bottom: 32px;
  }
  @media (max-width: 834px) {
    padding: 32px 24px;
    h1 {
      font-size: 28px;
    }
    h2 {
      font-size: 24px;
    }
    h3 {
      margin-top: 32px;
    }
  }
`

export const FSAHSAText = styled.div`
  color: ${colors.c_grey[700]};
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
`
interface CWAlternatingImageTextsProps {
  index: number
}
export const CWAlternatingImageTextContainer = styled.div<CWAlternatingImageTextsProps>`
  max-width: 992px;
  margin: 0 auto;
  display: flex;
  flex-direction: ${({ index }) => (index % 2 === 0 ? "row" : "row-reverse")};
  justify-content: center;
  text-align: left;
  align-items: center;
  margin-top: 80px;
  gap: 80px;
  @media (max-width: 834px) {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 32px;
    margin-bottom: 0;
    max-width: 600px;
  }
`

export const CWAlternatingImageTextContent = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    line-height: 32px;
    margin-bottom: 24px;
  }
  h3 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 8px;
  }

  p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    text-color: ${colors.c_grey[800]};
  }
  @media (max-width: 834px) {
    h2 {
      margin-bottom: 16px;
    }
  }
`
export const CallOutContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
  background: ${colors.c_secondary[100]};
  border-radius: 8px;
  gap: 16px;
  p,
  a {
    color: ${colors.c_primary[600]};
  }
`

export const PricingContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 40px;
  align-items: center;
  @media (max-width: 834px) {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
`

export const GetStartedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  a {
    margin: 0 auto;
  }
  @media (max-width: 834px) {
    max-width: 600px;
    margin: auto;
  }
`

export const Popover = styled.div`
  position: relative;
  display: inline-block;
  img {
    vertical-align: middle;
    cursor: pointer;
  }
  .popover-content {
    display: none;
  }

  &:hover .popover-content,
  &:focus .popover-content {
    position: absolute;
    transform: translate(-160px, 8px);
    display: block;
    border-radius: 4px;
    background: ${colors.c_grey[50]};
    width: 320px;
    box-shadow: 0px 1px 0px 0px #d1d3d7 inset, 0px -1px 0px 0px #d1d3d7 inset,
      1px 0px 0px 0px #d1d3d7 inset, -1px 0px 0px 0px #d1d3d7 inset;
    padding: 8px 16px;
    p {
      text-align: left;
      color: ${colors.c_primary[900]};
      font-family: Poppins;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
    }
    @media (max-width: 834px) {
      width: 215px;
    }
  }
`

export const TriangleContainer = styled.div`
  position: absolute;
  transform: translate(0, 0);
  top: -5px;
  left: 162px;
  right: 0;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #d1d3d7;

  .inner-triangle {
    position: relative;
    right: 5px;
    top: 1px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid ${colors.c_grey[50]};
  }
`

export const FSAHSATextContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 2px;
  align-items: center;
`
interface ButtonContainerProps {
  desktopWidth?: string
}
export const ButtonContainer = styled.div<ButtonContainerProps>`
  width: ${({ desktopWidth }) => desktopWidth || "240px"};
  height: 56px;
  @media (max-width: 834px) {
    width: 100%;
  }
`
