import { navigate } from "gatsby"
import { FaqType } from "../../FaqPages/shared/types"
import { Container, TopicLink, TopicsContainer, Column } from "./styled"
import { convertToKebabCase } from "../../FaqPages/shared/utils"
import { H2, H3 } from "../../ui"
import { PopularTopic } from "../../CareTeamPages/shared/types"
import segment, { segmentEvents } from "../../../lib/segment"

export const FaqTopics = ({
  title,
  topics,
  modifiedHeader,
}: {
  title: string
  topics: FaqType[] | PopularTopic[]
  modifiedHeader?: boolean
}) => {
  const middle = Math.ceil(topics.length / 2)
  const columns = [topics.slice(0, middle), topics.slice(middle)]

  const handleTopicClick = (topic: PopularTopic) => {
    segment.track(
      segmentEvents.FAQ_CLICKED,
      {
        question: `${topic.node.data.categories[0].category} - ${topic.node.uid}`,
      },
      {
        integrations: {
          All: false,
          Iterable: true,
          Heap: true,
        },
      }
    )

    navigate(
      `/faqs/${convertToKebabCase(topic.node.data.categories[0].category)}#${
        topic.node.uid
      }`
    )
  }

  return (
    <Container modifiedHeader={modifiedHeader}>
      {modifiedHeader ? <H3>{title}</H3> : <H2>{title}</H2>}
      <TopicsContainer>
        {columns?.map((column, index) => (
          <Column key={`column${index}`}>
            {column.map(topic => (
              <TopicLink
                key={topic.node.uid}
                onClick={() => handleTopicClick(topic)}
              >
                {topic.node.data.question.text}
              </TopicLink>
            ))}
          </Column>
        ))}
      </TopicsContainer>
    </Container>
  )
}
