import styled from "styled-components"
import { colors } from "../themes/colors"

export const CarePersonBody = styled.main`
  display: flex;
  background: ${colors.c_white[500]};
  background: linear-gradient(
    180deg,
    rgba(242, 245, 255, 1) 26%,
    rgba(255, 255, 255, 1) 33%
  );
  @media (max-width: 600px) {
    flex-direction: column;
    background: linear-gradient(
      180deg,
      rgba(242, 245, 255, 1) 18%,
      rgba(255, 255, 255, 1) 23%
    );
  }
`

export const CarePersonInfo = styled.div`
  display: flex;
  margin: 120px auto 40px;
  width: 100%;
  max-width: 1200px;
  justify-content: space-between;
  gap: 80px;

  @media (max-width: 600px) {
    margin-top: 0px;
  }

  @media (max-width: 1248px) {
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (max-width: 1024px) {
    padding: 0;
    flex-direction: column;
    gap: 0;
  }
`

export const LeftColumn = styled.div`
  width: 692px;

  @media (max-width: 1100px) {
    width: 100%;
    padding: 32px;
  }
`

export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

interface InnerContainerProps {
  showBorder?: boolean
}
export const InnerContentContainer = styled.div<InnerContainerProps>`
  display: flex;
  margin: 0;
  box-sizing: border-box;
  border-bottom: ${({ showBorder }) =>
    showBorder ? "1px solid #D2D5E7" : "0"};
  width: 100%;
  @media (max-width: 600px) {
    margin: 0 auto;
  }
`

export const PlantContainer = styled.div`
  position: relative;
  min-height: 268px;
  display: flex;
  justify-content: center;
`

export const PlantContent = styled.div`
  width: 1200px;
  position: relative;

  & > img {
    position: absolute;
    bottom: 80px;
    right: 32px;
  }
`
