import styled from "styled-components"

export const SocialMediaLinksWrapper = styled.div`
  margin: 20px 0 20px 0;
  & > a {
    display: inline-block;
    max-width: fit-content;
    margin-right: 24px;
  }
  & > a:last-child {
    margin-right: 0;
  }

  @media (max-width: 800px) {
    text-align: center;
  }
`
