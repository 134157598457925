import React from "react"
import prescriber1 from "./assets/prescriber1.webp"
import prescriber2 from "./assets/prescriber2.webp"
import prescriber3 from "./assets/prescriber3.webp"
import therapy1 from "./assets/therapy1.webp"
import therapy2 from "./assets/therapy2.webp"
import therapy3 from "./assets/therapy3.webp"

const therapistContents = [
  {
    id: "therapy-content-1",
    title: "Convenient and effective online therapy",
    desc: [
      "Meet 1-on-1 with a qualified, compassionate therapist —  only <b>$295/mo</b> for weekly sessions.",
      `<i>In-person therapy can cost an average of $200/session.</i>`,
    ],
    images: [
      {
        name: "therapy-image-01",
        srcSet: therapy1,
        width: "456px",
        height: "380px",
        alt: "therapist mobile",
        media: "(min-width:456px)",
      },
    ],
    bullets: [],
  },
  {
    id: "therapy-content-2",
    title: "Meaningful connections",
    desc: [
      "Our therapists have a wide range of life and professional experience to help you find someone you can truly connect with.",
      "Many of our therapists come from minority backgrounds and specialize in supporting LGBTQIA+ clients.",
    ],
    images: [
      {
        name: "therapy-image-02",
        srcSet: therapy2,
        width: "456px",
        height: "314px",
        alt: "therapist ratings",
        media: "(min-width:456px)",
      },
    ],
    bullets: [],
  },
  {
    id: "therapy-content-3",
    title: "Choose your own therapist",
    desc: [
      "See all available therapists in your area and filter results by gender, specialty, experience, and more.",
      "You can view their schedules and book any session that works for you. Switch therapists at any time for any reason.",
    ],
    images: [
      {
        name: "therapy-image-03",
        srcSet: therapy3,
        width: "456px",
        height: "380px",
        alt: "therapist calendar",
        type: "",
        media: "(min-width:456px)",
      },
    ],
    bullets: [],
  },
]

const prescriberContents = [
  {
    id: "prescriber-content-1",
    title: "Online access to a prescriber",
    desc: [
      "<b>Get a complete mental health evaluation,</b> medication (if prescribed), and ongoing guidance from a licensed prescriber.",
      "Just <b>$95/mo</b>",
    ],
    images: [
      {
        name: "prescriber-image-1",
        srcSet: prescriber1,
        width: "456px",
        height: "380px",
        alt: "prescriber mobile",
        media: "(min-width:456px)",
      },
    ],
    bullets: [],
  },
  {
    id: "prescriber-content-2",
    title: "Support when you need it",
    desc: [
      "Your prescriber will meet with you regularly to check in about your treatment, renew your prescription if prescribed, or discuss any changes to your plan.",
      "And you can message us anytime between sessions if you need more support.",
    ],
    images: [
      {
        name: "prescriber-image-2",
        srcSet: prescriber2,
        width: "456px",
        height: "362px",
        alt: "prescriber appointment",
        media: "(min-width:456px)",
      },
    ],
    bullets: [],
  },
  {
    id: "prescriber-content-3",
    title: "Choose your own prescriber",
    desc: [
      "You’ll see all available prescribers in your area and see their schedules. Then you can book any session that works for you. Switch prescribers at any time for any reason.",
    ],
    images: [
      {
        name: "prescriber-image-3",
        srcSet: prescriber3,
        width: "456px",
        height: "380px",
        alt: "prescriber calendar",
        media: "(min-width:456px)",
      },
    ],
    bullets: [],
  },
]

export const contents = {
  therapist: therapistContents,
  prescriber: prescriberContents,
}
