import { NavigationItem } from "./types"
import headphonewomen from "../../../images/headphone-women.webp"
import anxiety from "../../../images/anxiety.webp"
import { IPostPillProps } from "../../../components/BlogPostPill/BlogPostPill"

export const MEDICATION = "Medication"
export const THERAPY = "Therapy"
export const WHY_CEREBRAL = "Why Cerebral?"
export const RESOURCES = "Resources"
export const BLOG = "Blog"
export const TESTS = "Free mental health tests"
export const TESTIMONIALS = "Testimonials"
export const SELF_TESTS = "Free mental health tests"

export const navBlogPost: IPostPillProps = {
  date: "2024-05-13T21:36:50.674Z",
  tag: ["Depression", "in the News"],
  title: "The Differences Between Bipolar Disorder and ADHD",
  image: {
    layout: "constrained",
    width: 64,
    height: 64,
    images: {
      fallback: {
        src: headphonewomen,
      },
    },
  },
  url: `${process.env.GATSBY_BASE_URL}/blog/bipolar-disorder-vs-adhd`,
}

export const navigationItems: NavigationItem[] = [
  {
    mainMenuTitle: MEDICATION,
    subMenuOptions: [
      {
        subMenuTitle: "Medication",
        link: "/plans/medication",
      },
      {
        subMenuTitle: "Find a prescriber",
        link: "/find-a-prescriber",
      },
      {
        subMenuTitle: "Prescription delivery",
        link: "/plans/prescription-delivery",
      },
      {
        subMenuTitle: TESTIMONIALS,
        link: "/testimonials",
        additionalComponent: {
          type: "Testimonial",
          testimonialType: "medication",
        },
      },
    ],
  },
  {
    mainMenuTitle: THERAPY,
    subMenuOptions: [
      {
        subMenuTitle: "Individual Therapy",
        link: "/plans/therapy",
      },
      {
        subMenuTitle: "Couples Therapy",
        link: "/plans/couples-therapy",
      },
      {
        subMenuTitle: "Find a therapist",
        link: "/find-a-therapist",
      },
      {
        subMenuTitle: TESTIMONIALS,
        link: "/testimonials",
        additionalComponent: {
          type: "Testimonial",
          testimonialType: "therapy",
        },
      },
    ],
  },
  {
    mainMenuTitle: WHY_CEREBRAL,
    subMenuOptions: [
      {
        subMenuTitle: "The program",
        link: "/blog/introducing-cerebral-way",
      },
      {
        subMenuTitle: "Our clinicians",
        link: "/care-team",
      },
      {
        subMenuTitle: "Pricing",
        link: "/plans",
      },
      {
        subMenuTitle: "Insurance",
        link: "/insurance-partners",
      },
    ],
  },
  {
    mainMenuTitle: RESOURCES,
    subMenuOptions: [
      {
        subMenuTitle: BLOG,
        link: "/blog",
        additionalComponent: {
          type: "Pill",
          pillProps: navBlogPost,
        },
      },
    ],
  },
]
