import { useStaticQuery, graphql, Link } from "gatsby"
import { IAllPrismicSitebannerQueryResponse } from "./types"
import { BannerContainer, BannerText } from "./styled"
import { usePromoCode } from "./hooks/usePromoCode"
import { DEFAULT_BANNER } from "./constants"
import { useLocation, navigate } from "@reach/router"
import Cookies from "js-cookie"
import { Dispatch, SetStateAction, useEffect, useState } from "react"

const PRISMIC_BANNER_QUERY = graphql`
  query SiteBannerQuery {
    allPrismicSitebanner {
      nodes {
        data {
          banner_name
          text {
            html
          }
          banner_link {
            url
          }
        }
      }
    }

    allPrismicPage {
      nodes {
        data {
          banner_text {
            text
          }
          banner_url {
            url
          }
        }
        uid
      }
    }
  }
`

const checkInfluencer = () => {
  const influencerName = Cookies.get("influencer")
  if (influencerName) {
    return (
      <BannerContainer
        id="site-banner"
        onClick={() => navigate(process.env.GATSBY_START_URL)}
      >
        <BannerText>{`Use code ${influencerName}15 for 15% off your first month on all monthly plans`}</BannerText>
      </BannerContainer>
    )
  }
}

export const SiteBanner = ({
  setIsBannerLoading,
}: {
  setIsBannerLoading: Dispatch<SetStateAction<boolean>>
}) => {
  const [bannerContent, setBannerContent] = useState<string>()
  const [bannerUrl, setBannerUrl] = useState<string>()
  const [bannerType, setBannerType] = useState<"page" | "site_banner" | null>(
    null
  )
  const influencerBanner = checkInfluencer()

  const promoCode = usePromoCode()
  const { pathname } = useLocation()

  const { allPrismicSitebanner, allPrismicPage } =
    useStaticQuery<IAllPrismicSitebannerQueryResponse>(PRISMIC_BANNER_QUERY)

  useEffect(() => {
    const variant = promoCode ?? DEFAULT_BANNER

    const splitedPathname = pathname.split("/")
    const pageUid = splitedPathname[splitedPathname.length - 1]

    const bannerDocument = allPrismicSitebanner.nodes.find(
      banner => banner.data.banner_name === variant
    )

    const pageBannerData = allPrismicPage.nodes.find(
      page => page.uid === pageUid
    )?.data

    const bannerData = bannerDocument?.data

    setIsBannerLoading(false)
    if (pageBannerData?.banner_text.text) {
      setBannerContent(pageBannerData?.banner_text.text)
      setBannerContent(pageBannerData?.banner_text.text)
      setBannerUrl(pageBannerData?.banner_url.url)
      setBannerType("page")
    } else if (bannerData?.text?.html) {
      setBannerType("site_banner")
      setBannerContent(bannerData?.text?.html)
      setBannerContent(bannerData?.text?.html)
      setBannerUrl(bannerData?.banner_link.url)
    } else {
      setBannerUrl("#")
    }
  }, [pathname, promoCode, allPrismicSitebanner, allPrismicPage])

  const BannerContent = () => {
    if (bannerType === "page" && bannerContent) {
      return <BannerText>{bannerContent}</BannerText>
    } else if (bannerType === "site_banner" && bannerContent) {
      return <BannerText dangerouslySetInnerHTML={{ __html: bannerContent }} />
    } else return null
  }

  if (influencerBanner) return influencerBanner

  return (
    <BannerContainer
      id="site-banner"
      onClick={() => (bannerUrl ? navigate(bannerUrl) : null)}
    >
      <BannerContent />
    </BannerContainer>
  )
}
