import { graphql, useStaticQuery } from "gatsby"
import SEO from "../SEO"
import { Navigation } from "../ui/Navigation"
import { FindAClinicianPageBody } from "./styled"
import { organizationSchemaMarkup } from "../../common/OrganizationSeoSchema"
import { SeoData, EndCapTitleData } from "./constants"
import { EndCap, FAQSection, StatsBlueBackground } from "../shared"
import { SuccessStories } from "../../components/HomePage/sections"
import { OnlineAccessConvenience } from "./sections/OnlineAccessConvenience"
import { ClinicianSearchLoader } from "./sections/ClinicianSearchLoader/ClinicianSearchLoader"
import { SimpleScheduling } from "./sections/SimpleScheduling"
import { QualityCare } from "./sections/QualityCare"
import { useRolloutFlag } from "../../store/rolloutFlag"

export interface FindAClinicianPageProps {
  id: number
  name: string
  degrees: string[]
  languages: string[]
  specialties: string[]
  role: "prescriber" | "therapist"
  bio: string
}

const FIND_A_CLINICIAN_PAGE_QUERY = graphql`
  query FindAClinicianPageQuery {
    therapyFaqs: allPrismicFaqWidget(
      filter: { data: { page_type: { eq: "therapy" } } }
    ) {
      edges {
        node {
          data {
            faqs {
              question {
                document {
                  ... on PrismicFaqRedesign {
                    data {
                      answer {
                        html
                      }
                      question {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    medicationFaqs: allPrismicFaqWidget(
      filter: { data: { page_type: { eq: "medication" } } }
    ) {
      edges {
        node {
          data {
            faqs {
              question {
                document {
                  ... on PrismicFaqRedesign {
                    data {
                      answer {
                        html
                      }
                      question {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const FindAClinicianBody = ({
  role,
}: {
  role: "therapist" | "prescriber"
}): JSX.Element => {
  const findAClinicianPageResponse = useStaticQuery(FIND_A_CLINICIAN_PAGE_QUERY)
  const faqResponse =
    role === "prescriber"
      ? findAClinicianPageResponse.medicationFaqs
      : findAClinicianPageResponse.therapyFaqs
  const faqs = faqResponse.edges[0].node.data.faqs
  const endCapTitle = EndCapTitleData[role] ?? EndCapTitleData.therapist

  const { flag, loadFlag } = useRolloutFlag()

  return (
    <FindAClinicianPageBody>
      <ClinicianSearchLoader
        serviceName="ClinicianSearch"
        serviceHost="https://main.d36dd80ip6ib6q.amplifyapp.com"
        props={{ role, featureFlags: flag }}
      />
      <>
        <StatsBlueBackground />
        <SimpleScheduling />
        <QualityCare />
      </>
      <OnlineAccessConvenience role={role} />
      <SuccessStories />
      <EndCap title={endCapTitle} />
      <FAQSection faqs={faqs} />
    </FindAClinicianPageBody>
  )
}

export const FindAClinicianPage = ({
  role,
}: {
  role: "therapist" | "prescriber"
}): JSX.Element => {
  return (
    <>
      <SEO {...(SeoData[role] ?? {})} schemaMarkup={organizationSchemaMarkup} />
      <Navigation />
      <FindAClinicianBody role={role} />
    </>
  )
}
