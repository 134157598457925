import styled from "styled-components"
import { colors } from "../../themes/colors"

export const Container = styled.section<{ modifiedHeader?: boolean }>`
  background: ${colors.c_white[500]};
  padding: ${({ modifiedHeader }) => (modifiedHeader ? "0" : "0 12% 8%")};
  margin: ${({ modifiedHeader }) => (modifiedHeader ? "0 auto" : "0")};
  max-width: ${({ modifiedHeader }) => (modifiedHeader ? "1200px" : "2000px")};
  & > h3 {
    margin: 40px 0 32px;
    @media (max-width: 834px) {
      font-size: 22px;
      margin: 32px 0;
    }
  }
  @media (max-width: 1024px) {
    margin-left: ${({ modifiedHeader }) => (modifiedHeader ? "24px" : "0")};
  }
  @media (max-width: 499px) {
    padding: ${({ modifiedHeader }) =>
      modifiedHeader ? "0 0 48px" : "0 16px 48px"};
  }
`

export const TopicLink = styled.button`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  color: ${colors.c_secondary[600]};
  cursor: pointer;
  background: none;
  border: none;
  text-align: left;
  padding: 0;
  padding-bottom: 24px;
`
export const TopicsContainer = styled.div`
  display: flex;
  gap: 112px;
  margin-top: 24px;

  @media (max-width: 834px) {
    flex-direction: column;
    gap: 0;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`
