import TrustPilot from "../../../images/trustpilot.png"
import { TrustPilotText, TrustPilotTextWrapper } from "./styled"

export const TrustPilotPhrase = () => {
  return (
    <TrustPilotTextWrapper>
      <TrustPilotText>
        5-star reviews are gathered from Cerebral clients on
      </TrustPilotText>
      <img src={TrustPilot} alt="TrustPilot" />
    </TrustPilotTextWrapper>
  )
}
