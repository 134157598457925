import styled from "styled-components"
import { colors } from "../../themes/colors"

export const RatingsContainer = styled.div`
  margin-top: 44px;
  max-width: max-content;
  display: flex;
  align-items: center;
  gap: 32px;
  flex-wrap: wrap;
  @media (max-width: 834px) {
    margin: 44px auto 0;
    justify-content: center;
  }
`

export const RatingContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.c_grey[600]};
  div {
    display: flex;
    align-items: center;
    strong {
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px;
    }
  }
  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin-left: 12px;
  }
`

export const Forbes = styled.img`
  height: 28px;
  width: 97px;
  @media (max-width: 500px) {
    height: 18px;
    width: 63px;
    margin-bottom: 6.79px;
  }
`

export const ForbesRatingContainer = styled(RatingContainer)`
  @media (max-width: 834px) {
    span {
      max-width: 100px;
      line-height: 12px;
    }
  }
`
