import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  ImageDataLike,
} from "gatsby-plugin-image"

interface BackgroundColorProps {
  from?: string
  to?: string
  type: string
  color?: string
}

const Wrapper = styled.div<BackgroundColorProps>`
  background-color: ${({ color }) => (color ? color : "white")};
  background-image: linear-gradient(
    135deg,
    ${props =>
        props.type === "gradient"
          ? props.from
            ? props.from
            : "rgba(207, 242, 240, 0.4)"
          : "rgba(0,0,0,0)"}
      0%,
    ${props =>
        props.type === "gradient"
          ? props.to
            ? props.to
            : "rgba(199, 191, 255, 0.4)"
          : "rgba(0,0,0,0)"}
      100%
  );
  background-color: white;
  display: flex;
  gap: 42px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 16px;

  @media (min-width: 834px) {
    padding: 40px;
    gap: 60px;
  }
`

const AvatarHeader = styled.h3`
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
  padding-bottom: 8px;
  text-align: left;
  color: #6786ff;
`

const AvatarSubHeader = styled.h4`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  padding-bottom: 16px;

  @media (min-width: 834px) {
    padding-bottom: 28px;
  }
`

const AvatarCopy = styled.div`
  font-size: 14px;
  line-height: 24px;
  text-align: left;

  p:not(:last-child) {
    margin-bottom: 16px;
  }
`

const AvatarContainer = styled.div`
  max-width: 896px;
  display: flex;
  gap: 16px;
  padding-bottom: 36px;
  flex-direction: column;
  :not(:last-child) {
    border-bottom: 3px solid #e1e7ff;
  }

  @media (min-width: 834px) {
    flex-direction: row;
  }
`

const AvatarImageContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  height: 212px;
  flex-shrink: 0;
  picture img {
    object-fit: contain;
    object-position: center center !important;
  }
`

const AvatarTextWrapper = styled.div``

interface AvatarWithBioProps {
  slice: {
    primary: {
      slice_id?: {
        text: string
      }
      background_type: string
      background_color?: string
      background_gradient_from?: string
      background_gradient_to?: string
    }
    items: Array<Item>
  }
}

interface Item {
  name: {
    text: string
  }
  role: {
    text: string
  }
  bio: {
    html: string
  }
  avatar: ImageDataLike
}

const AvatarWithBio = ({ slice }: AvatarWithBioProps) => {
  const { primary, items } = slice

  return (
    <Wrapper
      type={primary.background_type}
      color={primary.background_color}
      from={primary.background_gradient_from}
      to={primary.background_gradient_to}
      id={primary.slice_id?.text || ""}
    >
      {items.map(item => {
        return (
          <AvatarContainer key={item.name.text}>
            <AvatarImageContainer>
              <GatsbyImage
                image={getImage(item.avatar) as IGatsbyImageData}
                alt=""
                loading="eager"
              />
            </AvatarImageContainer>
            <AvatarTextWrapper>
              <AvatarHeader>{item.name.text}</AvatarHeader>
              <AvatarSubHeader>{item.role.text}</AvatarSubHeader>
              <AvatarCopy dangerouslySetInnerHTML={{ __html: item.bio.html }} />
            </AvatarTextWrapper>
          </AvatarContainer>
        )
      })}
    </Wrapper>
  )
}

export const query = graphql`
  fragment PageAvatarWithBioSlice on PrismicPageDataBodyAvatarWithBio {
    id
    slice_type
    primary {
      slice_id {
        text
      }
      background_type
      background_color
      background_gradient_from
      background_gradient_to
    }
    items {
      name {
        text
      }
      role {
        text
      }
      bio {
        html
      }
      avatar {
        gatsbyImageData
      }
    }
  }
`

export default AvatarWithBio
