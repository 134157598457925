import styled from "styled-components"

interface BannerProps {
  mobileBackground: string
  desktopBackground: string
  padding?: string
  mobileHeight?: string
}
export const Banner = styled.section<BannerProps>`
  background-image: ${({ desktopBackground }) => `url(${desktopBackground})`};
  background-size: cover;
  background-repeat: no-repeat;
  padding: ${({ padding }) => padding || "72px"};
  min-height: 494px;
  display: flex;
  @media (max-width: 800px) {
    min-height: ${({ mobileHeight }) => mobileHeight};
    padding: 72px 24px;
    background-image: ${({ mobileBackground }) => `url(${mobileBackground})`};
  }
`
