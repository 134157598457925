import { useEffect, useState } from "react"
import { Placeholder } from "./styled"

interface IClinicianSearchLoaderProps {
  serviceName: string
  serviceHost: string
  props?: any
}

export const ClinicianSearchLoader = ({
  serviceName,
  serviceHost,
  props,
}: IClinicianSearchLoaderProps) => {
  const containerId = `microservice-${serviceName}`
  const manifestUrl = `${serviceHost}/asset-manifest.json`
  const [isLoading, setIsLoading] = useState(true)

  const fetchManifest = () => {
    return fetch(manifestUrl)
      .then(res => res.json())
      .catch(error => {
        console.error("Error fetching MFE:", error)
        return {}
      })
  }

  const renderMicroService = (
    serviceContainerId: string,
    callback: () => void,
    props: any
  ) => {
    ;(window as { [key: string]: any })[`render${serviceName}`] &&
      (window as { [key: string]: any })[`render${serviceName}`](
        serviceContainerId,
        serviceHost,
        props
      )
    callback()
  }

  const handleLoadingComplete = () => {
    setIsLoading(false)
  }

  const isValidPath = (path: string) => {
    if (typeof path !== "string") return false
    const regex = /[<>())]/
    return !regex.test(path) && !path.includes("javascript:")
  }

  const sanitizeUrl = (base: string, path: string) => {
    if (!isValidPath(path)) {
      console.error("Invalid path detected:", path)
      return ""
    }
    return `${base}${path}`
  }

  const loadScript = async () => {
    const manifest = await fetchManifest()
    const scriptId = `${containerId}-script`
    const cssId = `${containerId}-css`

    const scriptSrc = sanitizeUrl(serviceHost, manifest.files["main.js"])

    if (scriptSrc) {
      const script = document.createElement("script")
      script.id = scriptId
      script.src = scriptSrc
      script.onload = () => {
        renderMicroService(containerId, handleLoadingComplete, props)
      }
      document.head.appendChild(script)
    } else {
      handleLoadingComplete()
    }

    if (manifest.files["main.css"]) {
      const linkHref = sanitizeUrl(serviceHost, manifest.files["main.css"])
      if (linkHref) {
        const link = document.createElement("link")
        link.id = cssId
        link.rel = "stylesheet"
        link.type = "text/css"
        link.media = "all"
        link.href = linkHref
        document.head.appendChild(link)
      }
    }
  }

  const handleUnmount = () => {
    // Clean up dynamically added script and link tags
    const script = document.getElementById(`${containerId}-script`)
    if (script) {
      script.remove()
    }

    const link = document.getElementById(`${containerId}-css`)
    if (link) {
      link.remove()
    }
  }

  useEffect(() => {
    loadScript()

    return () => handleUnmount()
  }, [serviceName, serviceHost, props])

  return (
    <>
      {isLoading && <Placeholder />}
      <section id={containerId} />
    </>
  )
}
