import React from "react"
import { contents } from "./constants"
import { AlternatingImageTextsContent } from "./types"
import { AlternatingImageTexts } from "../../../shared"
export interface RoleProps {
  role: "therapist" | "prescriber"
}

export const OnlineAccessConvenience = ({ role }: RoleProps): JSX.Element => {
  const roles = Object.keys(contents) as (keyof AlternatingImageTextsContent)[]

  return <AlternatingImageTexts contents={contents[role]} theme="secondary" />
}
