import styled from "styled-components"
import { colors } from "../../../themes/colors"

export const QuotesContainer = styled.div`
  max-width: 992px;
  @media (max-width: 834px) {
    max-width: 100%;
  }
`

export const QuotesContentContainer = styled.div`
  white-space: normal;
  text-align: center;
  max-width: 827px;
  padding: 40px;
  margin: 24px 0;
  border-radius: 16px;
  background-color: ${colors.c_white[500]};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media (max-width: 834px) {
    padding: 24px;
  }
`

export const Quote = styled.p`
  color: ${colors.c_black[500]};
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 24px;
  @media (max-width: 834px) {
    width: fit-content;
  }
`

export const Name = styled.p`
  color: ${colors.c_black[500]};
  font-size: 16px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: 16px 0px;
`
