import { useState, useEffect } from "react"
import { CustomDropdown } from "../../../shared/CustomDropdown"
import { SelectGroupWrapper } from "./styled"
import LocationPoint from "./assets/LocationPoint"
import PostCard from "./assets/PostCard"

interface IInsuranceAcceptedItem {
  payer: string
  rte_enabled: boolean
}

interface StateSelectGroupProps {
  showPayerInput?: boolean
  states: string[]
  payers: Record<string, IInsuranceAcceptedItem[]>
  onChange?: ({ state, payer }: { state: string; payer: string }) => void
  disabled?: boolean
}

export const StateSelectGroup = ({
  showPayerInput = true,
  states,
  onChange,
  payers,
  disabled = false,
}: StateSelectGroupProps): JSX.Element => {
  const [payersByState, setPayerByState] = useState<string[]>([])
  const [currentState, setCurrentState] = useState("")
  const [currentPayer, setCurrentPayer] = useState("")

  useEffect(() => {
    onChange?.({ state: currentState, payer: currentPayer })
  }, [currentState, currentPayer])

  const handleCurrentStateChange = (state: string) => {
    setCurrentState(state)
    setPayerByState(
      ((payers[state] as IInsuranceAcceptedItem[]) || [])
        .map(({ payer }) => payer)
        .concat(["Cash pay"])
    )
    setCurrentPayer("")
  }

  return showPayerInput ? (
    <SelectGroupWrapper direction="column">
      <CustomDropdown
        options={states}
        onSelect={handleCurrentStateChange}
        onClear={() => handleCurrentStateChange("")}
        LeftIcon={LocationPoint}
        value={currentState}
        placeholder="Your state"
        isDisabled={disabled}
      />
      <CustomDropdown
        options={payersByState}
        onSelect={setCurrentPayer}
        onClear={() => setCurrentPayer("")}
        LeftIcon={PostCard}
        value={currentPayer}
        placeholder="Your insurance carrier"
        isDisabled={!currentState || disabled}
      />
    </SelectGroupWrapper>
  ) : (
    <SelectGroupWrapper direction="none">
      <CustomDropdown
        options={states}
        onSelect={handleCurrentStateChange}
        onClear={() => handleCurrentStateChange("")}
        LeftIcon={LocationPoint}
        value={currentState}
        placeholder="Your state"
        isDisabled={disabled}
      />
    </SelectGroupWrapper>
  )
}
