import { useState, useEffect, useContext } from "react"
import { createApiInstance } from "@cerebral-inc/api"
import { ICarePerson, IInsuranceAccepted } from "../../../CarePersonPage/types"
import { CarePersonNearMeCard } from "../../../ui"
import { LocationPageContext } from "../../LocationPageProvider"
import Pagination from "../../../Pagination"
import { Container } from "./styled"
import Spinner from "../../../../components/ui/Spinner"

export const CarePersonsList = () => {
  const { carePersons, carePersonType } = useContext(LocationPageContext)
  const carePersonsPerPage = 30
  const [currentCarePersons, setCurrentCarePersons] =
    useState<ICarePerson[]>(carePersons)
  const [shouldShow, setShouldShow] = useState<boolean>(false)
  const api = createApiInstance({
    baseURL: process.env.GATSBY_API_SERVER_URL,
  })

  const updatePagePost = (page: number) => {
    const lastPost = Math.min(
      page * carePersonsPerPage + carePersonsPerPage,
      carePersons.length
    )
    const pagePosts = [...carePersons].slice(
      page * carePersonsPerPage,
      lastPost
    )
    setCurrentCarePersons(pagePosts)
  }

  const resetCarePersonProperties = async (
    currentCarePersons: ICarePerson[]
  ) => {
    const { data } = await api.post<IInsuranceAccepted[]>(
      "/api/public/clinician_bio/insurance_accepted",
      {
        clinicians: currentCarePersons.map(carePerson => carePerson.email),
        role: carePersonType,
      }
    )

    const modifiedCarePersons = currentCarePersons.map(carePerson => {
      const additionalProperties = data.find(
        item => item.email === carePerson.email
      )
      if (additionalProperties) {
        carePerson.accepting_new_client =
          additionalProperties.accepting_new_client
        carePerson.insurance_accepted =
          additionalProperties["insurance_accepted"]
      }
      return carePerson
    })

    modifiedCarePersons.sort((x, y) => (x.accepting_new_client ? -1 : 1))

    setCurrentCarePersons(modifiedCarePersons)
    setShouldShow(true)
  }

  useEffect(() => {
    resetCarePersonProperties(currentCarePersons)
  }, [])

  return (
    <Container>
      {shouldShow ? (
        currentCarePersons.map((carePerson, i) => (
          <CarePersonNearMeCard
            carePerson={carePerson}
            key={`care-person-card-${i}`}
          />
        ))
      ) : (
        <Spinner className="" />
      )}
      <Pagination
        itemsCount={carePersons.length}
        itemsPerPage={30}
        onPageChange={page => {
          updatePagePost(page)
        }}
      />
    </Container>
  )
}
