import StateInsurancePopup from "./StateInsurancePopup/StateInsurancePopup"
import { CtaButton } from "../prismic/LandingPageStyles"
import { useEffect, useState, MouseEvent } from "react"
import { useRolloutFlag } from "../../store/rolloutFlag"
import { QueryToCookieConverter } from "../../lib/QueryToCookieConverter"

const InsuranceModals = ({
  cta_text,
  cta_link = "https://forms.cerebral.com/to/EtjRbfRz",
  disableCTALink = false,
}: {
  cta_text?: string
  cta_link?: string
  disableCTALink?: boolean
}) => {
  const [isBCBModalOpen, setBCBModalOpen] = useState(false)

  const rollout = useRolloutFlag()

  useEffect(() => {
    rollout.loadFlag("insurance_rte_release_onboarding")
  }, [])

  const handleOpenModal = (e: MouseEvent) => {
    e.preventDefault()
    if (disableCTALink) {
      window.scrollTo(0, 0)
      return
    }

    setBCBModalOpen(true)
  }

  const [url, setUrl] = useState<string>("")

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)

    const fbclidCookie = new QueryToCookieConverter("fbclid").getCookieValue()
    const ttclidCookie = new QueryToCookieConverter("ttclid").getCookieValue()
    const gclidCookie = new QueryToCookieConverter("gclid").getCookieValue()

    const fbclidQuery = searchParams.get("fbclid")
    const ttclidQuery = searchParams.get("ttclid")
    const gclidQuery = searchParams.get("gclid")

    fbclidCookie && !fbclidQuery && searchParams.append("fbclid", fbclidCookie)
    ttclidCookie && !ttclidQuery && searchParams.append("ttclid", ttclidCookie)
    gclidCookie && !gclidQuery && searchParams.append("gclid", gclidCookie)

    setUrl(
      (cta_link || process.env.GATSBY_START_URL) + `?${searchParams.toString()}`
    )
  }, [cta_link])

  const navigateToCTA = () => {
    window.open(url, "_self")
  }

  return (
    <>
      {cta_text && (
        <CtaButton
          width="320px"
          to={!disableCTALink ? url : ""}
          onClick={
            rollout?.flag?.insurance_rte_release_onboarding
              ? handleOpenModal
              : undefined
          }
        >
          {cta_text}
        </CtaButton>
      )}
      {rollout?.flag?.insurance_rte_release_onboarding ? (
        <StateInsurancePopup
          isBCBModalOpen={isBCBModalOpen}
          setIsBCBModalOpen={setBCBModalOpen}
          onBCBNoClick={navigateToCTA}
        />
      ) : null}
    </>
  )
}

export default InsuranceModals
