import { graphql } from "gatsby"
import { OfferingComparison } from "../../components/CouplesTherapyPage/sections"

interface OfferingComparisonProps {
  slice: {
    primary: {
      offering_description: {
        text: string
      }
      offering_title: {
        text: string
      }
      offering_titles: {
        text: string
      }
      cta_text: {
        text: string
      }
      cta_link: {
        url: string
      }
    }
    items: {
      detail_description: {
        html: string
      }
      detail_title: {
        text: string
      }
      left_check: {
        url: string
      }
      right_check: {
        url: string
      }
    }[]
  }
}

const OfferingComparisonSlice = ({
  slice,
}: OfferingComparisonProps): JSX.Element => {
  const {
    offering_description,
    offering_title,
    offering_titles,
    cta_link,
    cta_text,
  } = slice.primary
  const mappingOfferingTitles = offering_titles.text.split(",")
  const mappingOfferingDescriptions = slice.items.map(item => [
    {
      id: 1,
      text: item.detail_title.text,
      description: item.detail_description.html,
    },
    {
      id: 2,
      img: item.left_check.url,
    },
    { id: 3, img: item.right_check.url },
  ])

  return (
    <OfferingComparison
      title={offering_title.text}
      description={offering_description.text || ""}
      offeringTitles={mappingOfferingTitles}
      offeringDetails={mappingOfferingDescriptions as any}
      bgColor="linear-gradient(135deg, #FEF9EC 0%, #ECEFFE 100%)"
      btnMaxWidth="250px"
      btnLink={cta_link.url}
      btnText={cta_text.text}
      padding="64px 24px"
    />
  )
}

export const query = graphql`
  fragment PageOfferingComparisonSlice on PrismicPageDataBodyOfferingComparison {
    id
    items {
      detail_description {
        html
      }
      detail_title {
        text
      }
      left_check {
        url
      }
      right_check {
        url
      }
    }
    primary {
      offering_description {
        text
      }
      offering_title {
        text
      }
      offering_titles {
        text
      }
      cta_text {
        text
      }
      cta_link {
        url
      }
    }
    slice_type
  }
`

export default OfferingComparisonSlice
