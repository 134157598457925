import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { FlexWrapper, ContentSection } from "./LandingPageStyles"
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  ImageDataLike,
} from "gatsby-plugin-image"
import { TextContentStyle } from "./Text"

const Wrapper = styled(FlexWrapper)`
  background-color: #e1f9fe;
`

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;

  picture img {
    object-position: center center !important;
  }

  margin: 20px;

  @media (min-width: 834px) {
    margin: 50px;
  }
`

const FirstSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 5;
  height: 100%:
  width: 100%;

  @media (max-width: 834px) {
    margin-bottom: 20px;
    width: 100%;
  }
`

const SecondSection = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}
`

const TextContainer = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;

  color: #250044;
  ${TextContentStyle}

  @media (max-width: 834px) {
    font-size: 14px;
  }
`

interface ImageWithTextInterface {
  slice: {
    primary: {
      slice_id: {
        text: string
      }
      image: ImageDataLike
      copy: {
        html: string
      }
    }
  }
}

const ImageWithText = ({ slice }: ImageWithTextInterface) => {
  const { image, copy } = slice.primary

  const images = getImage(image) as IGatsbyImageData

  return (
    <Wrapper id={slice.primary.slice_id?.text || ""}>
      <ContentSection>
        <FirstSection>
          <ImageContainer>
            <GatsbyImage
              image={images}
              alt=""
              style={{ width: "100%" }}
              loading="eager"
            />
          </ImageContainer>
        </FirstSection>

        <SecondSection>
          <TextContainer dangerouslySetInnerHTML={{ __html: copy.html }} />
        </SecondSection>
      </ContentSection>
    </Wrapper>
  )
}

export const query = graphql`
  fragment ImageWithTextSlice on PrismicLandingDataBodyImageWithText {
    id
    slice_type
    primary {
      image {
        gatsbyImageData(width: 760)
      }
      copy {
        html
      }
    }
  }
  fragment PageImageWithTextSlice on PrismicPageDataBodyImageWithText {
    id
    slice_type
    primary {
      slice_id {
        text
      }
      image {
        gatsbyImageData(width: 760)
      }
      copy {
        html
      }
    }
  }
  fragment PostImageWithTextSlice on PrismicPostDataBodyImageWithText {
    id
    slice_type
    primary {
      image {
        gatsbyImageData(width: 760)
      }
      copy {
        html
      }
    }
  }
  fragment CounselorImageWithTextSlice on PrismicCounselorDataBodyImageWithText {
    id
    slice_type
    primary {
      image {
        gatsbyImageData(width: 760)
      }
      copy {
        html
      }
    }
  }
  fragment ProviderImageWithTextSlice on PrismicProviderDataBodyImageWithText {
    id
    slice_type
    primary {
      image {
        gatsbyImageData(width: 760)
      }
      copy {
        html
      }
    }
  }
  fragment TherapistImageWithTextSlice on PrismicTherapistDataBodyImageWithText {
    id
    slice_type
    primary {
      image {
        gatsbyImageData(width: 760)
      }
      copy {
        html
      }
    }
  }
`

export default ImageWithText
