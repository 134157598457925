import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import EmailCollectionSection from "../../components/shared/Blog/EmailCollectionSection"
import { FAQSection, StatsBlueBackground } from "../../components/shared"
import MentalHeathSupport from "../../components/shared/Blog/MentalHeathSupport"
import LatestPost from "../../components/shared/Blog/LatestPost"
import { Post } from "../../components/shared/Blog/types"
import { IGatsbyImageData } from "gatsby-plugin-image"
import ContentHubTestimonials from "../../components/shared/ContentHub/ContentHubTestimonials"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const END_CAP_SLICE_QUERY = graphql`
  query EndCapSliceQuery {
    allPrismicFaqWidget {
      edges {
        node {
          data {
            page_type
            faqs {
              question {
                document {
                  ... on PrismicFaqRedesign {
                    id
                    data {
                      question {
                        text
                      }
                      answer {
                        html
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

interface ContentHubEndCapInterface {
  slice: {
    primary: {
      tab_id: string
      tab_title: {
        text: string
      }
      cta_title: {
        text: string
      }
      cta_description: {
        text: string
      }
      cta_text: {
        text: string
      }
      cta_link: {
        url: string
      }
      related_resource_cta_text: {
        text: string
      }
      related_resource_cta_link: {
        url: string
      }
      end_cap_img?: {
        gatsbyImageData: IGatsbyImageData
      }
      page_type: string
    }
    items: {
      section_key: string
      main_section: boolean
      section_title?: { text: string }
    }[]
    posts: Post[]
  }
}

const ContentHubEndCap = ({ slice }: ContentHubEndCapInterface) => {
  const {
    cta_title,
    cta_description,
    cta_text,
    cta_link,
    end_cap_img,
    page_type,
    related_resource_cta_text,
    related_resource_cta_link,
  } = slice.primary
  const { posts, items } = slice
  const response = useStaticQuery(END_CAP_SLICE_QUERY)

  const data = response.allPrismicFaqWidget.edges.filter(
    (edge: any) => edge.node.data.page_type === page_type
  )

  const faqs = data[0]?.node.data.faqs || []

  const mapQuestionToComponent = (key: string, title?: string) => {
    const componentMap: { [key: string]: React.ReactElement } = {
      "email-collection": <EmailCollectionSection />,
      stats: <StatsBlueBackground bgColor="#FFFFFF" />,
      "related-resources": (
        <LatestPost
          posts={posts}
          title={title}
          id="related-resources"
          ctaLink={related_resource_cta_link.url}
          ctaText={related_resource_cta_text.text}
        />
      ),
      faqs: <FAQSection faqs={faqs} id="faqs" />,
      testimonials: <ContentHubTestimonials />,
      "end-cap": (
        <MentalHeathSupport
          title={cta_title.text}
          subtitle={cta_description.text}
          cta_link={cta_link.url}
          cta_text={cta_text.text}
          maxW="456px"
          image={end_cap_img?.gatsbyImageData}
          mobileGap="32px"
          paddingTop="0px"
        />
      ),
    }
    return componentMap[key]
  }
  return (
    <Wrapper>
      {items.map(({ section_key, section_title }) =>
        mapQuestionToComponent(section_key, section_title?.text)
      )}
    </Wrapper>
  )
}

export const query = graphql`
  fragment PageContentHubEndCapSlice on PrismicPageDataBodyCouplesTherapyEndCap {
    id
    slice_type
    primary {
      tab_id
      tab_title {
        text
      }
      cta_title {
        text
      }
      cta_description {
        text
      }
      cta_text {
        text
      }
      cta_link {
        url
      }
      related_resource_cta_text {
        text
      }
      related_resource_cta_link {
        url
      }
      end_cap_img {
        gatsbyImageData(
          placeholder: BLURRED
          layout: FIXED
          width: 456
          height: 436
        )
      }
      page_type
    }
    items {
      section_title {
        text
      }
      section_key
      main_section
    }
  }
`

export default ContentHubEndCap
