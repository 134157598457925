import {
  InnerContainer,
  MedicationsWeOfferContainer,
  DesktopMedicationsGrid,
  HeaderSection,
  MobileMedicationsGrid,
  DisclaimerText,
  ButtonContainer,
  MedicationCarouselWrapper,
  GetStartedButtonCustom,
} from "./styled"
import { MedicationBlock } from "./components/MedicationBlock"
import { Carousel } from "../../../ui"
import { GetStartedButton } from "../../../shared"
import { useRef, useEffect } from "react"
import { useLocation } from "@reach/router"

export const MedicationsWeOffer = ({
  medicationsCopy,
  onGetStarted,
}: {
  medicationsCopy: { header: string; medicationList: string }[]
  onGetStarted?: () => void
}) => {
  const sectionRef = useRef<HTMLDivElement>(null)

  const { hash } = useLocation()

  useEffect(() => {
    if (hash === "#medications-we-offer" && sectionRef.current) {
      window.scrollTo({
        top: sectionRef.current?.offsetTop - 120,
        behavior: "smooth",
      })
    }
  }, [hash, sectionRef?.current])

  return (
    <MedicationsWeOfferContainer id="medicatios-we-offer" ref={sectionRef}>
      <InnerContainer>
        <HeaderSection>
          <h2>Medications we offer</h2>
          <p>
            We can prescribe hundreds of medications. You’ll work with your
            prescriber to find a clinically appropriate treatment option for
            you.
          </p>
        </HeaderSection>
        <DesktopMedicationsGrid>
          {medicationsCopy.map(medication => (
            <MedicationBlock
              header={medication.header}
              medicationList={medication.medicationList
                .replaceAll("&lt;", "<")
                .replaceAll("&gt;", ">")}
              key={medication.header}
            />
          ))}
        </DesktopMedicationsGrid>
        <MobileMedicationsGrid>
          <MedicationCarouselWrapper>
            <Carousel>
              {medicationsCopy.map(medication => (
                <MedicationBlock
                  header={medication.header}
                  medicationList={medication.medicationList
                    .replaceAll("&lt;", "<")
                    .replaceAll("&gt;", ">")}
                  key={medication.header}
                />
              ))}
            </Carousel>
          </MedicationCarouselWrapper>
        </MobileMedicationsGrid>
        <DisclaimerText>
          We do not prescribe controlled substances (like Adderall or Xanax) but
          we do offer other medications that typically alleviate symptoms of
          common mental health conditions.
        </DisclaimerText>
        <ButtonContainer>
          {onGetStarted ? (
            <GetStartedButtonCustom onClick={onGetStarted}>
              Get started
            </GetStartedButtonCustom>
          ) : (
            <GetStartedButton paddingHorizontal={24} />
          )}
        </ButtonContainer>
      </InnerContainer>
    </MedicationsWeOfferContainer>
  )
}
