import styled from "styled-components"

function ItalicHeadingDynamic({
  className,
  text,
}: {
  className?: string
  text: string
}) {
  return (
    <h2 className={className}>
      {text.split(" ").map((word, index) => (
        <span key={index}>{word}</span>
      ))}
    </h2>
  )
}

export default styled(ItalicHeadingDynamic)`
  color: #ffffff;
  font-size: 28px;
  text-align: center;
  margin: 6px auto 0;
  font-weight: 600;
  padding: 0;
  span {
    display: inline-block;
    width: min-content;
    background-color: #aabaf9;
    transform: skewX(-15deg);
    padding: 6px;
    white-space: nowrap;
    margin-top: 6px;
    margin-left: -2px;
  }
  @media (min-width: 600px) {
    font-size: 40px;
    span {
      padding: 10px;
    }
  }
`
