import React from "react"
import { Button } from "components/ui"
import styled from "styled-components"
import { graphql } from "gatsby"
import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image"
import { CtaButton } from "./LandingPageStyles"

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px 0;

  @media (max-width: 1024px) {
    padding: 48px 24px;
  }
`

const Container = styled.div`
  max-width: 992px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
`

const Title = styled.div`
  color: #1c1e21;
  text-align: center;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 32px;
`

const ListWrapper = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: column;
  margin-top: 32px;
`

const ContentWrapper = styled.div`
  display: flex;
  gap: 32px;
`

const ListIconWrapper = styled.div`
  width: 40px;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #f6f4ff;
`

const Icon = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-family: Poppins;

  p {
    color: #4d515a;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    @media (max-width: 1024px) {
      font-size: 14px;
    }
  }

  p:not:first-child {
    margin-top: 8px;
  }

  strong {
    color: #1c1e21;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);
    color: var(--bn-w-black-900, #1c1e21);
  }

  a {
    color: var(--Secondary-600, #385ade);
    font-weight: 600;
  }

  strong.price {
    font-size: 16px;
    color: var(--Primary-600, #51459e);
    font-weight: 600;
    line-height: 20px;
  }
`

interface ContentHubTabCostInterface {
  slice: {
    primary: {
      tab_id?: string
      cost_description: {
        html: string
      }
      cost_title: {
        html: string
      }
      tab_title: {
        text: string
      }
      cost_cta_btn_link: {
        url: string
      }
      cost_cta_btn_text: {
        text: string
      }
    }
    items: {
      cost_section_content: {
        html: string
      }
      cost_section_icon: {
        gatsbyImageData: IGatsbyImageData
      }
    }[]
  }
}

const ContentHubTabCost = ({ slice }: ContentHubTabCostInterface) => {
  const { items } = slice

  const convertTextToHtml = (text: string) => {
    return text.replaceAll("&lt;", "<").replaceAll("&gt;", ">")
  }

  return (
    <Wrapper id={slice.primary.tab_id}>
      <Container>
        <Title>
          <Text
            dangerouslySetInnerHTML={{
              __html: slice.primary.cost_title.html,
            }}
          />
        </Title>
        <Text
          dangerouslySetInnerHTML={{
            __html: slice.primary.cost_description.html,
          }}
        />
        <ListWrapper>
          {items.map(({ cost_section_content, cost_section_icon }) => (
            <ContentWrapper>
              <ListIconWrapper>
                <Icon
                  image={getImage(cost_section_icon) as IGatsbyImageData}
                  alt=""
                />
              </ListIconWrapper>
              <Text
                dangerouslySetInnerHTML={{
                  __html: convertTextToHtml(cost_section_content.html),
                }}
              />
            </ContentWrapper>
          ))}
        </ListWrapper>
        <ButtonWrapper>
          <CtaButton to={slice.primary.cost_cta_btn_link.url} width="320px">
            {slice.primary.cost_cta_btn_text.text}
          </CtaButton>
        </ButtonWrapper>
      </Container>
    </Wrapper>
  )
}

export const query = graphql`
  fragment PageContentHubTabCostSlice on PrismicPageDataBodyContentHubTabCost {
    id
    slice_type
    primary {
      tab_id
      cost_description {
        html
      }
      tab_title {
        text
      }
      cost_title {
        html
      }
      cost_cta_btn_text {
        text
      }
      cost_cta_btn_link {
        url
      }
    }
    items {
      cost_section_icon {
        gatsbyImageData(placeholder: BLURRED, layout: FIXED)
      }
      cost_section_content {
        html
      }
    }
  }
`
export default ContentHubTabCost
