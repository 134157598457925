import { useEffect, useState } from "react"
import { useClipboard } from "use-clipboard-copy"
import {
  UilFacebookF,
  UilTwitter,
  UilCopy,
  UilLinkedin,
  UilCheck,
} from "@iconscout/react-unicons"
import { sanitizeUrl } from "../../../lib/utils"
import {
  CopyLink,
  ExpandedSocialButton,
  SocialLink,
  SocialSharingWrapper,
  SocialTitle,
  SocialWrapper,
} from "./styled"

interface SocialSharingProps {
  isBanner?: boolean
}

const SocialSharing = ({ isBanner }: SocialSharingProps) => {
  const clipboard = useClipboard({ copiedTimeout: 600 })
  const [url, setUrl] = useState("")
  const [isCopied, setIsCopied] = useState(false)

  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`
  const twitterUrl = `https://twitter.com/intent/tweet?url=${url}`
  const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`

  const onCopyClick = () => {
    setIsCopied(true)
    clipboard.copy(url)
    setTimeout(() => setIsCopied(false), 600)
  }

  useEffect(() => {
    setUrl(window.location.href)
  }, [])

  return (
    <SocialSharingWrapper isBanner={isBanner}>
      <SocialWrapper isBanner={isBanner} hasTitle>
        <CopyLink onClick={onCopyClick} copied={clipboard.copied}>
          <ExpandedSocialButton>
            {!isBanner && (
              <SocialTitle>{isCopied ? "Copied" : "Copy link"}</SocialTitle>
            )}
            {isCopied ? (
              <UilCheck size={20} color="#51459E" />
            ) : (
              <UilCopy size={20} color="#51459E" />
            )}
          </ExpandedSocialButton>
        </CopyLink>
      </SocialWrapper>

      <SocialWrapper isBanner={isBanner}>
        <SocialLink
          className="twitter"
          href={sanitizeUrl(twitterUrl)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <UilTwitter size={20} color="#51459E" />
        </SocialLink>
      </SocialWrapper>

      <SocialWrapper isBanner={isBanner}>
        <SocialLink
          className="facebook"
          href={sanitizeUrl(facebookUrl)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <UilFacebookF size={20} color="#51459E" />
        </SocialLink>
      </SocialWrapper>

      <SocialWrapper isBanner={isBanner}>
        <SocialLink
          className="twitter"
          href={sanitizeUrl(linkedInUrl)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <UilLinkedin size={20} color="#51459E" />
        </SocialLink>
      </SocialWrapper>
    </SocialSharingWrapper>
  )
}

export default SocialSharing
