import styled from "styled-components"

export const DetailsText = styled.div`
  font-size: 15px;
  line-height: 150%;
  color: #ffffff;
  margin-bottom: 10px;
  max-width: 255px;

  @media (max-width: 800px) {
    text-align: center;
  }
`
export const Submit = styled.button`
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 500px;
  line-height: 20px;
  color: #51459e;
  background-color: #ffffff;
  box-shadow: 0px 0px 12px rgba(81, 69, 158, 0.16);
  border-radius: 30px;
  margin-top: 14px;
  border: 0;
  width: 160px;
  height: 44px;
  font-weight: 500;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }

  @media (max-width: 800px) {
    text-align: center;
  }
`

export const Input = styled.input`
  border: 1px solid #aabaf9;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #51459e;
  color: #ffffff;
  font-size: 13px;
  line-height: 30px;
  letter-spacing: 0.1px;
  width: 213px;
  height: 42px;
  margin-top: 5px;
  padding-left: 14px;

  @media (max-width: 800px) {
    margin-top: 9px;
    padding-left: 16px;
    width: 100%;
  }

  &::placeholder {
    color: white;
  }
`

export const StayInTouchText = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: -0.022em;
  color: #ffffff;
  margin-bottom: 5px;
`
export const StayInTouchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 800px) {
    align-items: center;
    text-align: center;
    order: 4;
  }
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 800px) {
    align-items: center;
    width: 100%;
  }
`

export const ErrorMessage = styled.div`
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  margin-top: 6px;
`

export const SuccessMessage = styled.div`
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.022em;
  color: #ffffff;
  max-width: 170px;
`

export const HipaaWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
  position: relative;
  width: 256px;

  @media (max-width: 800px) {
    width: 100%;
    justify-content: left;
  }

  & > div:first-child {
    position: static;
    margin-bottom: 0;
    padding-left: 20px;
  }

  & > div:first-child .b-input {
    border: 1px solid #51459e;
  }
`
export const HipaaContent = styled.div`
  color: white;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  @media (max-width: 834px) {
    font-size: 11px;
  }

  a {
    color: white;
    text-decoration-line: underline;
  }
`
