import { useState } from "react"
import { Container, TestimonialsHeader } from "./styled"

import React from "react"
export const TrustPilot = () => {
  const ref = React.useRef(null)
  const [hydrated, setHydrated] = useState(false)
  React.useEffect(() => {
    setHydrated(true)
    if (hydrated && window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [hydrated])

  return (
    <Container>
      <TestimonialsHeader>Testimonials</TestimonialsHeader>
      {!hydrated ? null : (
        <div
          ref={ref}
          className="trustpilot-widget"
          data-locale="en-US"
          data-template-id="539ad60defb9600b94d7df2c"
          data-businessunit-id="6171b1d16359eeffdfeeddd5"
          data-style-height="800px"
          data-style-width="100%"
          data-theme="light"
          data-stars="4,5"
          data-review-languages="en"
          data-font-family="Poppins"
          data-text-color="#222128"
        >
          <a
            href="https://www.trustpilot.com/review/example.com"
            target="_blank"
            rel="noopener"
          >
            Trustpilot
          </a>
        </div>
      )}
    </Container>
  )
}
