import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { CtaButton } from "./LandingPageStyles"

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #caeaec 0%, #f6e9fe 100%);
  padding: 48px 64px;
  border-radius: 16px;
  margin-top: 48px;
  margin-bottom: 16px;

  @media (max-width: 834px) {
    padding: 48px 24px;
  }

  @media (max-width: 744px) {
    border-radius: 0;
  }
`

const Title = styled.div`
  color: #1c1e21;
  text-align: center;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 16px;

  @media (max-width: 834px) {
    font-size: 20px;
  }
`

const SubTitle = styled.div`
  color: #353a41;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 24px;
`

interface BlogCtaButtonWithTextProps {
  slice: {
    primary: {
      blog_cta_link: {
        url: string
      }
      blog_cta_subtitle: {
        text: string
      }
      blog_cta_text: {
        text: string
      }
      blog_cta_title: {
        text: string
      }
    }
  }
}

const BlogCtaButtonWithText = ({ slice }: BlogCtaButtonWithTextProps) => {
  const { blog_cta_link, blog_cta_subtitle, blog_cta_text, blog_cta_title } =
    slice.primary

  return (
    <Wrapper>
      <Title>{blog_cta_title.text}</Title>
      <SubTitle>{blog_cta_subtitle.text}</SubTitle>
      <CtaButton to={blog_cta_link.url || "https://cerebral.com/app/welcome"}>
        {blog_cta_text.text}
      </CtaButton>
    </Wrapper>
  )
}

export const query = graphql`
  fragment PostBlogCtaButtonWithTextSlice on PrismicPostDataBodyBlogCtaButtonWithText {
    slice_type
    primary {
      blog_cta_link {
        url
      }
      blog_cta_subtitle {
        text
      }
      blog_cta_text {
        text
      }
      blog_cta_title {
        text
      }
    }
  }
`
export default BlogCtaButtonWithText
