import styled from "styled-components"
import { colors } from "../../themes/colors"

export const TestimonialContainer = styled.div`
  text-align: center;
  padding: 64px 0 0;
  a {
    color: ${colors.c_secondary[600]};
    font-size: 14px;
    line-height: 23px;
  }

  @media (max-width: 834px) {
    h2 {
      font-size: 24px;
      font-weight: 600;
      line-height: 28px; /* 116.667% */
    }
  }
`

export const ButtonContainer = styled.div`
  a {
    max-width: 245px;
    height: 56px;
    margin: 32px auto 64px;
  }
`
