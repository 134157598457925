import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { SocialMediaLinksWrapper } from "./styled"

export const SocialMedia = (): JSX.Element => {
  return (
    <SocialMediaLinksWrapper>
      <a
        href="https://www.facebook.com/cerebral"
        target="_blank"
        rel="noopener noreferrer"
      >
        <StaticImage
          src="../../images/facebook.svg"
          alt="facebook link"
          placeholder="blurred"
          layout="fixed"
        />
      </a>

      <a
        href="https://www.instagram.com/cerebral/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <StaticImage
          src="../../images/instagram.svg"
          alt="instagram link"
          placeholder="blurred"
          layout="fixed"
        />
      </a>
      <a
        href="https://www.linkedin.com/company/cerebral-inc/?viewAsMember=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <StaticImage
          src="../../images/linkin.svg"
          alt="linkedin link"
          placeholder="blurred"
          layout="fixed"
        />
      </a>
    </SocialMediaLinksWrapper>
  )
}
