import styled from "styled-components"

export const FindYourPathWrapper = styled.section`
  h1 {
    font-size: 28px;
  }
  p {
    margin-bottom: 30px;
  }
  @media (max-width: 834px) {
    p {
      margin-top: 10px;
      margin-bottom: 30px;
    }
  }
`
