import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  ImageDataLike,
} from "gatsby-plugin-image"
import {
  BlueCtaButton,
  LeftItalicHeading,
  SubTitle,
  FlexWrapper,
  ContentSection,
  ReversedContentSection,
} from "./LandingPageStyles"
import { TextContentStyle } from "./Text"

const FirstSection = styled.div`
  flex: 5;
  display: flex;
  flex-direction: column;
  align-content: center;

  @media (max-width: 834px) {
    justify-content: center;
    margin-top: 20px;
    align-items: center;
  }
`

const SecondSection = styled.div`
  display: flex;
  justify-content: center;
  flex: 3;

  @media (max-width: 834px) {
    padding: 0;
    margin-top: 20px;
  }
`

interface FeatureTextLeftInterface {
  slice: {
    primary: {
      slice_id: { text: string }
      image: ImageDataLike
      title: { text: string }
      copy: { html: string }
      cta_link: { url: string }
      cta_text: { text: string }
    }
  }
  reversed: boolean
}

const FeatureTextLeft = ({ slice, reversed }: FeatureTextLeftInterface) => {
  const { image, title, copy, cta_link, cta_text } = slice.primary
  const images = getImage(image) as IGatsbyImageData

  const Content = styled(reversed ? ReversedContentSection : ContentSection)`
    column-gap: 30px;
  `

  const SubTitleWrap = styled(SubTitle)`
    ${TextContentStyle}
  `

  return (
    <FlexWrapper id={slice.primary.slice_id?.text || ""}>
      <Content>
        <FirstSection>
          <LeftItalicHeading text={title.text} />

          <SubTitleWrap dangerouslySetInnerHTML={{ __html: copy.html }} />

          <BlueCtaButton to={cta_link.url || process.env.GATSBY_START_URL}>
            {cta_text.text}
          </BlueCtaButton>
        </FirstSection>

        <SecondSection>
          <GatsbyImage image={images} alt={title.text || ""} loading="eager" />
        </SecondSection>
      </Content>
    </FlexWrapper>
  )
}

export const query = graphql`
  fragment FeatureTextLeftSlice on PrismicLandingDataBodyFeatureTextLeft {
    id
    slice_type
    primary {
      copy {
        html
      }
      cta_link {
        url
      }
      cta_text {
        text
      }
      image {
        gatsbyImageData(width: 285, height: 285)
      }
      title {
        text
      }
    }
  }
  fragment PageFeatureTextLeftSlice on PrismicPageDataBodyFeatureTextLeft {
    id
    slice_type
    primary {
      slice_id {
        text
      }
      copy {
        html
      }
      cta_link {
        url
      }
      cta_text {
        text
      }
      image {
        gatsbyImageData(width: 405)
      }
      title {
        text
      }
    }
  }
  fragment PostFeatureTextLeftSlice on PrismicPostDataBodyFeatureTextLeft {
    id
    slice_type
    primary {
      copy {
        html
      }
      cta_link {
        url
      }
      cta_text {
        text
      }
      image {
        gatsbyImageData(width: 405)
      }
      title {
        text
      }
    }
  }
  fragment ProviderFeatureTextLeftSlice on PrismicProviderDataBodyFeatureTextLeft {
    id
    slice_type
    primary {
      copy {
        html
      }
      cta_link {
        url
      }
      cta_text {
        text
      }
      image {
        gatsbyImageData(width: 285, height: 285)
      }
      title {
        text
      }
    }
  }
  fragment CounselorFeatureTextLeftSlice on PrismicCounselorDataBodyFeatureTextLeft {
    id
    slice_type
    primary {
      copy {
        html
      }
      cta_link {
        url
      }
      cta_text {
        text
      }
      image {
        gatsbyImageData(width: 285, height: 285)
      }
      title {
        text
      }
    }
  }
  fragment TherapistFeatureTextLeftSlice on PrismicTherapistDataBodyFeatureTextLeft {
    id
    slice_type
    primary {
      copy {
        html
      }
      cta_link {
        url
      }
      cta_text {
        text
      }
      image {
        gatsbyImageData(width: 285, height: 285)
      }
      title {
        text
      }
    }
  }
`

export default FeatureTextLeft
