import React from "react"
import { graphql } from "gatsby"
import MultiColumn from "./MultiColumn"
import { IGatsbyImageData } from "gatsby-plugin-image"

interface FourColumnsInterface {
  slice: {
    slice_type: string
    items: {
      copy: {
        html: string
      }
      image: IGatsbyImageData
    }[]
    primary: {
      slice_id: {
        text: string
      }
      copy: {
        html: string
      }
      title: {
        text: string
      }
    }
  }
}

const FourColumns = ({ slice }: FourColumnsInterface) => {
  return <MultiColumn slice={slice} cols={4} />
}

export const query = graphql`
  fragment FourColumnsSlice on PrismicLandingDataBody4Columns {
    slice_type
    items {
      copy {
        html
      }
      image {
        gatsbyImageData(width: 360, height: 150)
      }
    }
    primary {
      copy {
        text
      }
      title {
        text
      }
    }
  }
  fragment PageFourColumnsSlice on PrismicPageDataBody4Columns {
    slice_type
    items {
      copy {
        html
      }
      image {
        gatsbyImageData(width: 360, height: 150)
      }
    }
    primary {
      slice_id {
        text
      }
      copy {
        html
      }
      title {
        text
      }
    }
  }
  fragment PostFourColumnsSlice on PrismicPostDataBody4Columns {
    slice_type
    items {
      copy {
        html
      }
      image {
        gatsbyImageData(width: 360, height: 150)
      }
    }
    primary {
      copy {
        html
      }
      title {
        text
      }
    }
  }
  fragment ProviderFourColumnsSlice on PrismicProviderDataBody4Columns {
    slice_type
    items {
      copy {
        html
      }
      image {
        gatsbyImageData(width: 360, height: 150)
      }
    }
    primary {
      copy {
        text
      }
      title {
        text
      }
    }
  }
  fragment CounselorFourColumnsSlice on PrismicCounselorDataBody4Columns {
    slice_type
    items {
      copy {
        html
      }
      image {
        gatsbyImageData(width: 360, height: 150)
      }
    }
    primary {
      copy {
        text
      }
      title {
        text
      }
    }
  }
  fragment TherapistFourColumnsSlice on PrismicTherapistDataBody4Columns {
    slice_type
    items {
      copy {
        html
      }
      image {
        gatsbyImageData(width: 360, height: 150)
      }
    }
    primary {
      copy {
        text
      }
      title {
        text
      }
    }
  }
`

export default FourColumns
