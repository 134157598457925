import React from "react"
import { graphql } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"
import FeatureTextLeft from "./FeatureTextLeft"
import { ImageDataLike } from "gatsby-plugin-image"

interface FeatureTextRightInterface {
  slice: {
    id: string
    slice_type: string
    primary: {
      slice_id: {
        text: string
      }
      copy: {
        html: string
      }
      cta_link: {
        url: string
      }
      cta_text: {
        text: string
      }
      image: IGatsbyImageData
      title: {
        text: string
      }
    }
  }
}

const FeatureTextRight = ({ slice }: FeatureTextRightInterface) => {
  return <FeatureTextLeft slice={slice} reversed={true} />
}

export const query = graphql`
  fragment FeatureTextRightSlice on PrismicLandingDataBodyFeatureTextRight {
    id
    slice_type
    primary {
      copy {
        html
      }
      cta_link {
        url
      }
      cta_text {
        text
      }
      image {
        gatsbyImageData(
          placeholder: BLURRED
          layout: FIXED
          width: 285
          height: 285
        )
      }
      title {
        text
      }
    }
  }
  fragment PageFeatureTextRightSlice on PrismicPageDataBodyFeatureTextRight {
    id
    slice_type
    primary {
      slice_id {
        text
      }
      copy {
        html
      }
      cta_link {
        url
      }
      cta_text {
        text
      }
      image {
        gatsbyImageData(width: 405)
      }
      title {
        text
      }
    }
  }
  fragment PostFeatureTextRightSlice on PrismicPostDataBodyFeatureTextRight {
    id
    slice_type
    primary {
      copy {
        html
      }
      cta_link {
        url
      }
      cta_text {
        text
      }
      image {
        gatsbyImageData(width: 405)
      }
      title {
        text
      }
    }
  }
  fragment ProviderFeatureTextRightSlice on PrismicProviderDataBodyFeatureTextRight {
    id
    slice_type
    primary {
      copy {
        html
      }
      cta_link {
        url
      }
      cta_text {
        text
      }
      image {
        gatsbyImageData(
          placeholder: BLURRED
          layout: FIXED
          width: 285
          height: 285
        )
      }
      title {
        text
      }
    }
  }
  fragment CounselorFeatureTextRightSlice on PrismicCounselorDataBodyFeatureTextRight {
    id
    slice_type
    primary {
      copy {
        html
      }
      cta_link {
        url
      }
      cta_text {
        text
      }
      image {
        gatsbyImageData(
          placeholder: BLURRED
          layout: FIXED
          width: 285
          height: 285
        )
      }
      title {
        text
      }
    }
  }
  fragment TherapistFeatureTextRightSlice on PrismicTherapistDataBodyFeatureTextRight {
    id
    slice_type
    primary {
      copy {
        html
      }
      cta_link {
        url
      }
      cta_text {
        text
      }
      image {
        gatsbyImageData(
          placeholder: BLURRED
          layout: FIXED
          width: 285
          height: 285
        )
      }
      title {
        text
      }
    }
  }
`

export default FeatureTextRight
