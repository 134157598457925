import styled from "styled-components"
import { colors } from "../../../themes/colors"

export const Container = styled.section`
  max-width: 992px;
  margin: 64px auto;
  text-align: center;

  * {
    font-family: Poppins;
  }
`

export const Title = styled.h2`
  color: ${colors.c_black[900]};
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 28px;
  }
`

export const SectionBody = styled.div`
  margin: 48px auto;
  display: flex;
  align-items: center;
  gap: 48px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const Section = styled.div`
  width: 299px;
  @media (max-width: 768px) {
    width: 100%;
    max-width: 375px;
  }
`

export const SectionImage = styled.img``

export const SectionTitle = styled.h3`
  margin: 8px 0;
  line-height: 24px;
  font-weight: 600;
  font-size: 16px;
`

export const SectionDescription = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  a {
    width: 250px;

    @media (max-width: 768px) {
      width: 100%;
      max-width: 375px;
    }
  }
`
