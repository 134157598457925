import {
  GoodDaysWrapper,
  Header,
  Text,
  IconOptionsWrapper,
  IconOptionCard,
  IconWrapper,
  IconOptionText,
  ButtonWrapper,
} from "./styled"
import { IconOptions } from "./constants"
import { GetStartedButton } from "../GetStartedButton"

export const MoreGoodDays = () => {
  return (
    <GoodDaysWrapper>
      <Header>More good days are on the way</Header>
      <Text>
        Most clients are able to meet with a prescriber or therapist within 3
        days of signing up
      </Text>
      <IconOptionsWrapper>
        {IconOptions.map((option, index) => (
          <IconOptionCard key={index}>
            <IconWrapper>{<option.icon />}</IconWrapper>
            <IconOptionText>{option.title}</IconOptionText>
          </IconOptionCard>
        ))}
      </IconOptionsWrapper>
      <ButtonWrapper>
        <GetStartedButton />
      </ButtonWrapper>
    </GoodDaysWrapper>
  )
}
