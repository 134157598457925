import styled from "styled-components"
import { colors } from "../../../themes/colors"
import { Link } from "gatsby"

interface ActiveProps {
  active: boolean
}
export const Container = styled.section`
  background: ${colors.c_secondary[200]};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 24px;
  gap: var(--base-S5, 40px);
`
export const Heading = styled.h2`
  color: ${colors.c_black[900]};
  height: auto;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  @media (max-width: 768px) {
    font-size: 28px;
    line-weight: 125%; /* 35px */
  }
`
export const TabGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`
export const Tabs = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  gap: 24px;
`
export const SupportingText = styled.p`
  color: ${colors.c_grey[800]};
  font-family: Poppins;
  max-width: 800px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`
export const CheckItems = styled.div`
  display: flex;
  padding-left: 16px;
  gap: 12px;
`
export const Frame = styled.div`
  border-radius: 100px;
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px;
  align-items: center;
  background: ${colors.c_secondary[200]};
`
export const ListSupportingText = styled.h5`
  color: ${colors.c_black[900]};
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`
export const BlocTab = styled.div`
  display: flex;
  height: auto;
  justify-content: center;
  align-items: center;
  width: 100%;
`
export const TabWrapper = styled.div<ActiveProps>`
  display: flex;
  flex-basis: 100%;
  height: auto;
  cursor: pointer;
  padding: 24px;
  align-items: center;
  gap: 20px;
  border-radius: 16px 16px 0px 0px;
  background-color: ${props => (props.active ? "#FFF" : "#cbd8ff")};
  @media (max-width: 768px) {
    align-items: center;
    flex-direction: column;
    padding: 16px;
    width: 100%;
  }
`
export const Header4 = styled.h4`
  color: ${colors.c_black[900]}
  front-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`
export const IconWrap = styled.div`
  display: flex;
  width: 48px;
  height: 48px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: ${colors.c_secondary[100]};

  img {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
`

export const IconImage = styled.img`
  width: 16px;
  height: 16px;
`
export const ArrowIconImage = styled.img`
  width: 20px;
  height: 20px;
`

export const HyperLinkWrap = styled.div`
  display: flex;
  gap: 16px;
  padding-top: 24px;
  @media (max-width: 768px) {
    align-self: baseline;
    flex-direction: column;
  }
`
export const Linked = styled(Link)`
  color: ${colors.c_primary[600]};
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-decoration-line: underline;
`
export const HyperLinkContainer = styled.div`
  display: flex;
  height: 40px;
  min-width: 80px;
  padding: 16px;
  gap: 8px;
`
export const BottomWrap = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  background: ${colors.c_secondary[50]};
  padding-top: 24px;
`
export const BottomContainer = styled.p`
  color: ${colors.c_black[900]};
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`

export const ContentContainer = styled.div<{ tab: number }>`
  display: flex;
  gap: 32px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  text-align: stretch;
  height: auto;
  background: ${colors.c_white[900]};
  border-radius: ${props =>
    props.tab === 0 ? "0px 16px 16px 16px" : "16px 0px 16px 16px"};
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const Folder = styled.div`
  max-width: 800px;
`
