import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { FlexWrapper, ContentSection, MintCtaButton } from "./LandingPageStyles"
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  ImageDataLike,
} from "gatsby-plugin-image"

const Wrapper = styled(FlexWrapper)`
  background-color: #e1f9fe;
  height: 500px;
`

const ImageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  picture img {
    object-position: center center !important;
  }
`

const Title = styled.div`
  font-family: Poppins;
  font-style: italic;
  font-weight: 600;
  font-size: 40px;
  line-height: 55px;
  letter-spacing: -1px;
  color: #ffffff;
  max-width: 470px;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);
  padding: 20px;
`

const Copy = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #ffffff;
  max-width: 470px;
  width: 100%;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);
  padding: 20px;
  overflow: hidden;
`

const TextContainer = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  align-items: flex-start;
  max-width: 1140px;
  width: 100%;
  color: #250044;
  padding: 20px;
  overflow: hidden;

  @media (max-width: 834px) {
    text-align: center;
    align-items: center;
  }
`

const Button = styled(MintCtaButton)`
  margin: 20px;
`

interface FullImageWithTextOverlayInterface {
  slice: {
    primary: {
      slice_id: {
        text: string
      }
      image: ImageDataLike
      copy: { html: string }
      title: { text: string }
      cta_text: { text: string }
      cta_link: { url: string }
    }
  }
}

const FullImageWithTextOverlay = ({
  slice,
}: FullImageWithTextOverlayInterface) => {
  const { image, copy, title, cta_text, cta_link } = slice.primary

  const images = getImage(image) as IGatsbyImageData

  return (
    <Wrapper id={slice.primary.slice_id?.text || ""}>
      <ImageContainer>
        <GatsbyImage
          alt=""
          image={images}
          style={{ width: "100%", height: "100%" }}
          loading="eager"
        />
      </ImageContainer>
      <TextContainer>
        <Title>{title.text}</Title>
        <Copy dangerouslySetInnerHTML={{ __html: copy.html }} />
        <Button to={cta_link.url || process.env.GATSBY_START_URL}>
          {cta_text.text}
        </Button>
      </TextContainer>
    </Wrapper>
  )
}

export const query = graphql`
  fragment FullImageWithTextOverlaySlice on PrismicLandingDataBodyFullImageWithTextOverlay {
    id
    slice_type
    primary {
      copy {
        html
      }
      image {
        gatsbyImageData
      }
      cta_text {
        text
      }
      cta_link {
        url
      }
      title {
        text
      }
    }
  }
  fragment PageFullImageWithTextOverlaySlice on PrismicPageDataBodyFullImageWithTextOverlay {
    id
    slice_type
    primary {
      slice_id {
        text
      }
      copy {
        html
      }
      image {
        gatsbyImageData
      }
      cta_text {
        text
      }
      cta_link {
        url
      }
      title {
        text
      }
    }
  }
  fragment PostFullImageWithTextOverlaySlice on PrismicPostDataBodyFullImageWithTextOverlay {
    id
    slice_type
    primary {
      copy {
        html
      }
      image {
        gatsbyImageData
      }
      cta_text {
        text
      }
      cta_link {
        url
      }
      title {
        text
      }
    }
  }
  fragment ProviderFullImageWithTextOverlaySlice on PrismicProviderDataBodyFullImageWithTextOverlay {
    id
    slice_type
    primary {
      copy {
        html
      }
      image {
        gatsbyImageData
      }
      cta_text {
        text
      }
      cta_link {
        url
      }
      title {
        text
      }
    }
  }
  fragment CounselorFullImageWithTextOverlaySlice on PrismicCounselorDataBodyFullImageWithTextOverlay {
    id
    slice_type
    primary {
      copy {
        html
      }
      image {
        gatsbyImageData
      }
      cta_text {
        text
      }
      cta_link {
        url
      }
      title {
        text
      }
    }
  }
  fragment TherapistFullImageWithTextOverlaySlice on PrismicTherapistDataBodyFullImageWithTextOverlay {
    id
    slice_type
    primary {
      copy {
        html
      }
      image {
        gatsbyImageData
      }
      cta_text {
        text
      }
      cta_link {
        url
      }
      title {
        text
      }
    }
  }
`

export default FullImageWithTextOverlay
