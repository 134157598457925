import { Helmet } from "react-helmet-async"
import "./layout.css"
import { SegmentPageTracker } from "../lib/SegmentPageTracker"
import { getVariantByName } from "../lib/utils"

export const Head = ({ useKameleoon = false }) => {
  return (
    <>
      <SegmentPageTracker />
      <Helmet>
        <link rel="preconnect" href="https://cerebral.prismic.io" />
        <link rel="preconnect" href="https://data.kameleoon.io" />
        <link rel="preconnect" href="https://utt.impactcdn.com" />
        <link rel="preconnect" href="https://fast.ssqt.io" />
        <link rel="preconnect" href="https://cdn.segment.com" />
        <link rel="preconnect" href="https://3zutpy0bjn.kameleoon.eu" />
        <link rel="preconnect" href="https://cdn.heapanalytics.com" />
        <link rel="preconnect" href="https://images.prismic.io" crossorigin />
        <link rel="dns-prefetch" href="https://images.prismic.io" />
        <link
          rel="preconnect"
          href="https://rum.browser-intake-datadoghq.com"
        />
        <script type="text/javascript">{`
                  if (sessionStorage.getItem("gatsby-reload-compilation-hash-match") === "1" && !window.location.href.includes("?nocache=")) {
                    window.location.href =
                      window.location.href.split("?")[0] + "?nocache=" + new Date().getTime()
                  }
              `}</script>
      </Helmet>
      <Helmet>
        <script async type="text/javascript">
          {`(function(a,b,c,d,e,f,g){e['ire_o']=c;e[c]=e[c]||function(){(e[c].a=e[c].a||[]).push(arguments)};f=d.createElement(b);g=d.getElementsByTagName(b)[0];f.async=1;f.src=a;g.parentNode.insertBefore(f,g);})('https://utt.impactcdn.com/A2435171-ef85-4e75-a435-a531a42fbce31.js','script','ire',document,window);`}
        </script>
      </Helmet>

      {useKameleoon && (
        <Helmet>
          <script async>
            {`
                var kameleoonLoadingTimeout = 2000;
                window.kameleoonQueue = window.kameleoonQueue || [];
                window.kameleoonStartLoadTime = new Date().getTime();
                if (! document.getElementById("kameleoonLoadingStyleSheet") && ! window.kameleoonDisplayPageTimeOut)
                {
                    var kameleoonS = document.getElementsByTagName("script")[0];
                    window.kameleoonDisplayPage = function(fromEngine)
                    {
                        if (!fromEngine)
                        {
                            window.kameleoonTimeout = true;
                        }
                    };
                    window.kameleoonDisplayPageTimeOut = window.setTimeout(window.kameleoonDisplayPage, kameleoonLoadingTimeout);
                }
              `}
          </script>
          <script async type="text/javascript">{`
                // Change the value of this URL to point to your own URL, where the iFrame is hosted
                window.kameleoonIframeURL = '${process.env.GATSBY_PATH_FILE_IFRAME_KAMELEOON}';
                window.kameleoonLightIframe = false;
                var kameleoonIframeOriginElement = document.createElement("a");
                kameleoonIframeOriginElement.href = kameleoonIframeURL;
                window.kameleoonIframeOrigin = kameleoonIframeOriginElement.origin || (kameleoonIframeOriginElement.protocol + "//" + kameleoonIframeOriginElement.hostname);
                if (location.href.indexOf(window.kameleoonIframeOrigin) != 0)
                {
                    window.kameleoonLightIframe = true;
                    var kameleoonProcessMessageEvent = function(event)
                    {
                        if (window.kameleoonIframeOrigin == event.origin && event.data.slice && event.data.slice(0,9) == "Kameleoon")
                        {
                            window.removeEventListener("message", kameleoonProcessMessageEvent);
                            window.kameleoonExternalIFrameLoaded = true;
                            if (window.Kameleoon)
                            {
                                eval(event.data);
                                Kameleoon.Analyst.load();
                            }
                            else
                            {
                                window.kameleoonExternalIFrameLoadedData = event.data;
                            }
                        }
                    };
                    if (window.addEventListener)
                    {
                        window.addEventListener("message", kameleoonProcessMessageEvent, false);
                    }
                    var iframeNode = document.createElement("iframe");
                    iframeNode.src = kameleoonIframeURL;
                    iframeNode.id = "kameleoonExternalIframe";
                    iframeNode.style = "float: left !important; opacity: 0.0 !important; width: 0px !important; height: 0px !important;";
                    document.head.appendChild(iframeNode);
                }
                `}</script>
          <script
            type="text/javascript"
            src={`//${process.env.GATSBY_SITE_CODE_KAMELEOON}.kameleoon.eu/kameleoon.js`}
            async
          />
        </Helmet>
      )}
      {getVariantByName("Chatbot Widget") === "Active" && (
        <Helmet>
          <script
            id="ze-snippet"
            src="https://static.zdassets.com/ekr/snippet.js?key=82b7f91c-3f85-42e6-b425-54c88f8cca28"
          ></script>
        </Helmet>
      )}
    </>
  )
}

export default Head
