import styled from "styled-components"

export const Placeholder = styled.div`
  display: flex;
  justify-content: center;
  background: linear-gradient(135deg, #eceffe 0%, #fef9ec 100%);
  height: 301px;
  width: 100%;

  @media (max-width: 660px) {
    height: 349px;
  }
  @media (max-width: 380px) {
    height: 397px;
  }
`
