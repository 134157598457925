import therapySvg from "./assets/therapy.svg"
import therapyMobileSvg from "./assets/therapy-m.svg"
import medicationSvg from "./assets/medication.svg"
import medicationMobileSvg from "./assets/medication-m.svg"

export const learnMoreContent = [
  {
    title: "Therapy",
    desc: "Many people find working with a supportive and qualified therapist is beneficial to their mental health.",
    images: [
      {
        srcSet: therapySvg,
        width: "275",
        height: "207",
        name: "therapy-desktop",
        media: "(min-width:834px)",
      },
      {
        srcSet: therapyMobileSvg,
        width: "109",
        height: "143",
        name: "therapy-mobile",
        media: "(max-width:833px)",
      },
    ],
    fallback: therapySvg,
    alt: "Handicapable woman managing her cerebral account.",
    to: "/plans/therapy",
  },
  {
    title: "Medication",
    desc: "The right medication helps many people manage symptoms of their mental health conditions.",
    images: [
      {
        srcSet: medicationSvg,
        width: "275",
        height: "207",
        name: "medication-desktop",
        media: "(min-width:834px)",
      },
      {
        srcSet: medicationMobileSvg,
        width: "109",
        height: "143",
        name: "medication-mobile",
        media: "(max-width:833px)",
      },
    ],
    fallback: medicationSvg,
    alt: "Therapist taking notes on clip board.",
    to: "/plans/medication",
  },
]
