import { IAlternatingSplashesCopy } from "../shared/AlternatingSplashes/AlternatingSplashes"
import Medal from "./images/Medal.svg"
import Writing from "./images/Writing.svg"
import LifePreserver from "./images/LifePreserver.svg"
import Check from "./images/Check.svg"
import PhoneMeds from "./images/phone-meds.webp"
import SchedulePrescriber from "./images/schedule-prescriber.webp"
import { UilThumbsUp } from "@iconscout/react-unicons"
import { UilStar } from "@iconscout/react-unicons"
import { PlanInfo } from "../shared/ChooseService/ChooseService"

export const alternatingSplashCopy: IAlternatingSplashesCopy = {
  carePersonTitle: "prescriber",
  topSection: {
    image: SchedulePrescriber,
    heading: "Highest standards in mental health",
    body: [
      {
        heading: "Rigorous screening",
        description:
          "Our prescribers must meet a certain standard of licensing and qualifications to work with our clients.",
        icon: Medal,
      },
      {
        heading: "Ongoing education",
        description:
          "Our clinicians have access to a team of subject matter experts and ongoing education resources to keep their knowledge up to date.",
        icon: Writing,
      },
      {
        heading: "Treating the whole person",
        description:
          "Our prescribers are qualified to address additional lifestyle changes beyond medication — including diet and exercise — to help you make the most meaningful improvements possible.",
        icon: LifePreserver,
      },
    ],
  },
  bottomSection: {
    image: PhoneMeds,
    heading: "Easy medication management",
    body: [
      {
        heading: "Low prices",
        description:
          "Clients who get their meds delivered by Cerebral only spend an average of $20/mo on medications.",
        icon: Check,
      },
      {
        heading: "Free and fast shipping",
        description:
          "Refills are shipped directly to you as soon as they’re ready. Or you can choose to pick them up at a local pharmacy.",
        icon: Check,
      },
      {
        heading: "Easy reminders",
        description:
          "Check your refills and shipping status — or book a session to renew your prescription right in your account.",
        icon: Check,
      },
    ],
  },
}

export const chooseServicePlans: Array<PlanInfo> = [
  {
    icon: UilThumbsUp,
    title: "Individual Service",
    planName: "Medication",
    planDescription:
      "Comprehensive mental health evaluation and medication guidance from a licensed prescriber.",
    weeklyPrice: 24,
    monthlyPrice: 95,
    threeMonthSavings: 20,
  },
  {
    icon: UilStar,
    title: "Bundle and save $70/mo",
    planName: "Medication + Therapy",
    planDescription:
      "45-minute therapy sessions with a therapist and monthly medication check-ins with a prescriber. Includes initial evaluation and dedicated treatment plans, if diagnosed.",
    weeklyPrice: 92,
    monthlyPrice: 365,
    threeMonthSavings: 70,
  },
]
