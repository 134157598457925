import SEO from "../SEO"
import { Navigation } from "../ui/Navigation"
import { AboutPageBody } from "./styled"
import {
  OnlineMentalHealth,
  HelpingMorePeople,
  Quotation,
  QualityDefinesUs,
  HealthcareExperts,
} from "./sections"
import { AboutPageProps } from "./types"
import { TestimonialSection } from "../shared/TestimonialSection"
import { organizationSchemaMarkup } from "../../common/OrganizationSeoSchema"

export const AboutBody = ({ data }: { data: AboutPageProps }): JSX.Element => (
  <AboutPageBody>
    <HelpingMorePeople />
    <OnlineMentalHealth />
    <Quotation />
    <QualityDefinesUs />
    {data?.healthcare_experts?.length > 0 && (
      <HealthcareExperts healthcareExperts={data.healthcare_experts} />
    )}
    <TestimonialSection />
  </AboutPageBody>
)

export const AboutPage = ({ data }: { data: AboutPageProps }): JSX.Element => {
  return (
    <>
      <SEO
        title="About Cerebral | Cerebral"
        description="Cerebral offers online therapy, mental health assessments and expert care on your schedule. Includes clinician visits for anxiety, depression, insomnia and more."
        url="about-cerebral/"
        schemaMarkup={organizationSchemaMarkup}
      />
      <Navigation />
      <AboutBody data={data} />
    </>
  )
}
