import {
  PlanCardWrapper,
  PlanDesc,
  PlanPrice,
  PlanBullet,
  PlanFooterText,
  PlanLink,
  ButtonContainer,
  Banner,
  PlanInformation,
  PlanTitle,
  StrongStartBanner,
  StrongStartPrice,
  StrongStartText,
} from "./styled"
import checkmarkSvg from "./assets/checkmark-light.svg"
import starSvg from "./assets/star-outline-purple.svg"
import { GetStartedButton } from "../../shared"

interface PlanLink {
  text: string
  to: string
}

interface PlanProps {
  plan: PlanType
  setHover?: (planId: number | null) => void
  hover: boolean
  signupTitle?: string
}

export type PlanType = {
  id: number
  banner?: string
  title: string
  desc?: string
  price: string
  bullets: string[]
  footerText: string
  strongStartPrice: string
  showCheckMarks: boolean
  links: PlanLink[]
  utm: string
  planSlug: "therapy" | "medication" | "medication-therapy"
}

export const Plan = ({
  plan,
  hover,
  setHover,
  signupTitle,
}: PlanProps): JSX.Element => {
  return (
    <PlanCardWrapper
      border={hover}
      onMouseEnter={() => setHover?.(plan.id)}
      onMouseLeave={() => setHover?.(null)}
    >
      {plan.banner && (
        <Banner>
          <img src={starSvg} alt="" />
          <p>{plan.banner}</p>
        </Banner>
      )}
      <PlanInformation banner={plan.banner !== undefined}>
        <PlanTitle banner={plan.banner !== undefined}>{plan.title}</PlanTitle>
        {plan.desc && <PlanDesc>{plan.desc}</PlanDesc>}
        {plan.bullets.length > 0 && (
          <ul style={{ textAlign: "left" }}>
            {plan.bullets.map((bullet, i) => {
              return (
                <PlanBullet key={`bullet-${i}`}>
                  <img src={checkmarkSvg} alt="" />
                  <p>{bullet}</p>
                </PlanBullet>
              )
            })}
          </ul>
        )}
        {plan.footerText && (
          <PlanFooterText
            dangerouslySetInnerHTML={{ __html: plan.footerText }}
          />
        )}
        {plan.links.length > 0 &&
          plan.links.map(link => (
            <PlanLink key={link.to} href={link.to} target="_blank">
              {link.text}
            </PlanLink>
          ))}
      </PlanInformation>
      <PlanPrice>{plan.price}</PlanPrice>
      <ButtonContainer>
        <GetStartedButton />
      </ButtonContainer>
      <StrongStartBanner>
        <StrongStartText>
          Save with
          <br /> Strong Start
        </StrongStartText>
        <StrongStartPrice>{plan.strongStartPrice}</StrongStartPrice>
      </StrongStartBanner>
    </PlanCardWrapper>
  )
}
