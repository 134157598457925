import styled from "styled-components"
import { colors } from "../../themes/colors"
import { Link } from "gatsby"

export const NavBar = styled.div`
  background-color: ${colors.c_white[50]};
  padding: 0 40px;
  height: 90px;

  * {
    font-family: Poppins;
  }

  @media (max-width: 1023px) {
    padding: 0 24px;
    height: 72px;
  }
`

export const InnerWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  height: 100%;
`

export const MobileMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

export const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  max-width: 462px;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 0;

  @media (min-width: 1024px) {
    width: 100%;

    span {
      font-size: 14px;
    }

    a {
      height: 56px;
      padding: 10px 24px;
    }
  }
`

export const LoginButton = styled.a`
  height: 56px;
  padding: 24px;
  display: flex;
  align-items: center;
  color: ${colors.c_primary[600]};
  font-weight: 600;
  line-height: 16px;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;

  @media (max-width: 1023px) {
    display: none;
  }
`

export const SubMenu = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 88px;
  left: 0;
  background-color: ${colors.c_white[50]};
  width: 33%;
  height: calc(100vh - 88px);
  left: ${({ isOpen }) => (isOpen ? "0" : "-33%")};
  transition: left 0.3s ease-in-out;
  z-index: 999;
  border: 1px solid black;
`

export const LogoWrapper = styled(Link)`
  text-align: left;

  img {
    width: 110px;
  }

  @media (min-width: 1024px) {
    img {
      width: 160px;
    }
  }
`

export const HamburgerIconWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  cursor: pointer;

  &:active {
    background: ${colors.c_secondary[100]};
    border-radius: 50%;
  }

  img {
    width: 24px;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`

export const NavWrapper = styled.nav`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: ${colors.c_white[50]};
`
