import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { FlexWrapper, ContentSection } from "./LandingPageStyles"
import StyledCta from "../ui/StyledCta"
import { scrollToElement } from "../../lib/utils"

const CtaWrapper = styled.div`
  position: relative;
  display: flex;

  width: 100%;
  * {
    flex-grow: 1;
  }

  @media (min-width: 834px) {
    width: 100%;
    * {
      flex-grow: 0;
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  max-width: 896px;
  margin: auto;
`

interface CtaButtonProps {
  slice: {
    primary: {
      slice_id?: {
        text: string
      }
      cta_text: {
        text: string
      }
      cta_link?: {
        url?: string
      }
      cta_style: string
      location: string
    }
  }
}

const CtaButton = ({ slice }: CtaButtonProps) => {
  const { cta_link, cta_text, location, cta_style, slice_id } = slice.primary
  const [justifyContent, setJustifyContent] = useState("")

  const getCtaLink = (): string => {
    if (cta_link?.url?.includes("https://#")) {
      return cta_link.url.replace("https://#", "#")
    }
    return cta_link?.url || ""
  }

  useEffect(() => {
    switch (location) {
      case "Left":
        setJustifyContent("flex-start")
        break
      case "Right":
        setJustifyContent("flex-end")
        break
      case "Center":
        setJustifyContent("center")
        break
      default:
        setJustifyContent("center")
    }
  }, [location])

  return (
    <Wrapper id={slice_id?.text || ""}>
      <CtaWrapper style={{ justifyContent }}>
        <StyledCta
          style={cta_style}
          link={cta_link?.url || ""}
          text={cta_text.text}
          onClick={() => scrollToElement(getCtaLink().replace("#", ""))}
        />
      </CtaWrapper>
    </Wrapper>
  )
}

export const query = graphql`
  fragment CtaButtonSlice on PrismicLandingDataBodyCtaButton {
    id
    slice_type
    primary {
      cta_link {
        url
      }
      cta_text {
        text
      }
      location
    }
  }

  fragment PageCtaButtonSlice on PrismicPageDataBodyCtaButton {
    id
    slice_type
    primary {
      slice_id {
        text
      }
      cta_link {
        url
      }
      cta_text {
        text
      }
      cta_style
      location
    }
  }
  fragment PostCtaButtonSlice on PrismicPostDataBodyCtaButton {
    id
    slice_type
    primary {
      cta_link {
        url
      }
      cta_text {
        text
      }
      location
    }
  }
  fragment ProviderCtaButtonSlice on PrismicProviderDataBodyCtaButton {
    id
    slice_type
    primary {
      cta_link {
        url
      }
      cta_text {
        text
      }
      location
    }
  }
  fragment CounselorCtaButtonSlice on PrismicCounselorDataBodyCtaButton {
    id
    slice_type
    primary {
      cta_link {
        url
      }
      cta_text {
        text
      }
      location
    }
  }
  fragment TherapistCtaButtonSlice on PrismicTherapistDataBodyCtaButton {
    id
    slice_type
    primary {
      cta_link {
        url
      }
      cta_text {
        text
      }
      location
    }
  }
`

export default CtaButton
