import {
  Posts,
  PostPreviewImage,
  PostContent,
  DateTagsWrapper,
  PostTitle,
  Tag,
  DateWrapper,
  PostsWrapper,
} from "./styled"
import { graphql, useStaticQuery } from "gatsby"

export const formatDate = (date: string) => {
  return new Date(date).toLocaleDateString("en-us", {
    year: "numeric",
    month: "short",
    day: "numeric",
  })
}

export const Postquery = graphql`
  query PostyQuery {
    allPrismicPost(sort: { fields: data___date, order: DESC }, limit: 1) {
      edges {
        node {
          data {
            date(formatString: "MM/DD/YYYY")
            previewimage {
              gatsbyImageData(layout: CONSTRAINED)
            }
            title {
              text
            }
          }
          uid
        }
      }
    }
  }
`
export const BlogPostPillBody = () => {
  const data = useStaticQuery(Postquery)
  const post = data.allPrismicPost.edges[0].node
  const postData = post.data
  const postUrl = post.uid
  const permanentTag = "New"

  const { title, date, previewimage } = postData

  return (
    <PostsWrapper>
      <Posts href={`${process.env.GATSBY_BASE_URL}/blog/${postUrl}`}>
        <PostPreviewImage
          image={previewimage.gatsbyImageData}
          alt="gatsbyImage"
        />
        <PostContent>
          <DateTagsWrapper>
            <DateWrapper>{formatDate(date)}</DateWrapper>
            <Tag>{permanentTag}</Tag>
          </DateTagsWrapper>
          <PostTitle>{title.text}</PostTitle>
        </PostContent>
      </Posts>
    </PostsWrapper>
  )
}
