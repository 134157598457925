import styled from "styled-components"
import { graphql } from "gatsby"
import { CtaButton, LeftTitle } from "./LandingPageStyles"
import { getImage, getSrcSet, ImageDataLike } from "gatsby-plugin-image"

const Wrapper = styled.div<{ bgColor?: string }>`
  background: linear-gradient(135deg, #fef9ec 0%, #eceffe 100%);
  padding: 96px 0;
  display: flex;
  justify-content: center;

  @media (max-width: 834px) {
    padding: 64px 0;
  }
`

const SecoundSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  width: calc(50% - 96px / 2);

  @media (max-width: 1023px) {
    width: calc(50% - 64px / 2);
  }

  @media (max-width: 834px) {
    width: 100%;
  }
`

const Container = styled.div<{ maxWidth: number }>`
  display: flex;
  flex-direction: row;
  gap: 96px;
  width: 100%;
  max-width: ${props => props.maxWidth}px;
  height: 100%;
  padding: 0;

  @media (max-width: 1216px) {
    padding: 0 24px;
  }

  @media (max-width: 1023px) {
    gap: 64px;
  }

  @media (max-width: 834px) {
    flex-direction: column;
    gap: 64px;
  }
`

const FirstSection = styled.div<{ objectFit?: string; heightWrapper?: string }>`
  display: flex;
  justify-content: ${props => (props.objectFit ? `flex-end` : "center")};
  width: 50%;
  max-height: 640px;

  img {
    object-fit: ${props =>
      props.objectFit ? `${props.objectFit} !important` : ""};
    object-position: ${props => (props.objectFit ? `right !important` : "")};

    @media (max-width: 992px) {
      object-position: center !important;
    }
  }

  @media (max-width: 834px) {
    width: 100%;
    height: 400px !important;
  }

  @media (max-width: 600px) {
    height: 240px !important;
  }

  & > div {
    width: ${props =>
      props.objectFit ? `456px !important` : "100% !important"};
    height: ${props =>
      props.heightWrapper ? `${props.heightWrapper} !important` : ""};
    min-height: ${props => (props.heightWrapper ? `314px` : "")};

    @media (max-width: 992px) {
      min-height: unset;
      width: 100% !important;
    }

    @media (max-width: 834px) {
      height: 400px !important;
    }

    @media (max-width: 600px) {
      height: 240px !important;
    }
  }
`

const Description = styled.div`
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #353a41;
  margin-top: 20px;
  text-align: left;

  @media (max-width: 600px) {
    font-size: 14px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: start;
`

const ButtonWrapper = styled.div`
  margin-top: 32px;
  width: 100%;
`

interface ImageTextRightProps {
  slice: {
    primary: {
      slice_id?: {
        text: string
      }
      title: {
        text: string
      }
      copy: {
        html: string
      }
      cta_text: {
        text: string
      }
      cta_link: {
        url: string
      }
      bg_color: {
        text: string
      }
      image: ImageDataLike
      height_image_wrapper: string
      image_object_fit: string
      max_width?: number
    }
  }
}

const ImageTextRight = ({ slice }: ImageTextRightProps) => {
  const {
    image,
    title,
    copy,
    cta_link,
    cta_text,
    bg_color,
    image_object_fit,
    height_image_wrapper,
    max_width,
  } = slice.primary
  const images = getImage(image)
  const srcSet = getSrcSet(image)
  return (
    <Wrapper bgColor={bg_color.text}>
      <Container maxWidth={max_width || 1216}>
        <FirstSection
          objectFit={image_object_fit}
          heightWrapper={height_image_wrapper}
        >
          <div>
            <img
              srcSet={srcSet}
              alt={title.text}
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
            />
          </div>
        </FirstSection>

        <SecoundSection>
          <TextWrapper>
            <LeftTitle>{title.text}</LeftTitle>

            <Description dangerouslySetInnerHTML={{ __html: copy.html }} />

            {cta_text.text && (
              <ButtonWrapper>
                <CtaButton to={cta_link.url || process.env.GATSBY_START_URL}>
                  {cta_text.text}
                </CtaButton>
              </ButtonWrapper>
            )}
          </TextWrapper>
        </SecoundSection>
      </Container>
    </Wrapper>
  )
}

export const query = graphql`
  fragment ImageTextRightSlice on PrismicLandingDataBodyImageTextRight {
    slice_type
    primary {
      copy {
        html
      }
      cta_link {
        url
      }
      cta_text {
        text
      }
      title {
        text
      }
      image {
        gatsbyImageData(placeholder: BLURRED, width: 576, height: 640)
      }
      bg_color {
        text
      }
    }
  }

  fragment PageImageTextRightSlice on PrismicPageDataBodyImageTextRight {
    slice_type
    primary {
      copy {
        html
      }
      cta_link {
        url
      }
      cta_text {
        text
      }
      title {
        text
      }
      image {
        gatsbyImageData(placeholder: BLURRED, width: 576, height: 640)
      }
      bg_color {
        text
      }
      image_object_fit
      height_image_wrapper
      max_width
    }
  }
`
export default ImageTextRight
