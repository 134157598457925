import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 744px) {
    padding: 0 24px;
  }
`

const Content = styled.div`
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: #353a41;
  line-height: 24px;
  font-family: Poppins;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 32px;
    margin-bottom: 16px;
    font-weight: 600;
    color: #1c1e21;
    margin-bottom: 16px;
  }

  h1 {
    font-size: 32px;
    line-height: 38px;

    @media (max-width: 600px) {
      font-size: 28px;
      line-height: 34px;
    }
  }

  h2 {
    font-size: 28px;
    line-height: 34px;

    @media (max-width: 600px) {
      font-size: 24px;
      line-height: 30px;
    }
  }

  h3 {
    font-size: 22px;
    line-height: 24px;

    @media (max-width: 600px) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  h4 {
    font-size: 18px;
    line-height: 24px;

    @media (max-width: 600px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  h5 {
    font-size: 16px;
    line-height: 24px;
  }

  h6 {
    font-size: 14px;
    line-height: 24px;
  }

  span {
    display: inline-block !important;
  }

  span.align-left {
    text-align: left;
  }

  span.align-center {
    text-align: center;
    width: 100%;
  }

  span.align-right {
    text-align: right;
  }

  span.strikethrough {
    text-decoration: line-through;
  }

  span.underline {
    text-decoration: underline;
  }

  p {
    line-height: 24px;
    margin-top: 32px;

    @media (max-width: 834px) {
      font-size: 14px;
      line-height: 24px;
      margin-top: 24px;
    }
  }

  a {
    color: #385ade !important;
    text-decoration-line: underline;

    :visited {
      color: #385ade !important;
    }
  }

  strong {
    font-weight: 600 !important;
  }

  ol {
    list-style-type: decimal;
    margin: 32px 0px 0px 16px;
    text-align: left;

    @media (max-width: 834px) {
      margin: 24px 0 0px 16px;
      font-size: 14px;
    }
  }

  ul {
    list-style: disc;
    margin: 32px 0px 0px 32px;
    text-align: left;
    line-height: 32px;

    @media (max-width: 834px) {
      margin: 24px 0 0px 32px;
      font-size: 14px;
      line-height: 24px;
    }
  }

  b,
  strong {
    font-weight: 600;
  }

  img {
    width: 100%;
    border-radius: 16px;
    height: 480px;
    margin-top: 24px;
    margin-bottom: 16px;

    @media (max-width: 600px) {
      height: 240px;
    }
  }

  pre {
    background: #f4f4f4;
    border: 1px solid #ddd;
    color: #666;
    page-break-inside: avoid;
    font-family: Poppins;
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 1.6em;
    max-width: 100%;
    overflow: auto;
    padding: 1em 1.5em;
    display: block;
    word-wrap: break-word;
    border-radius: 4px;
  }

  em {
    font-style: italic;
  }

  li {
    line-height: 24px !important;
    margin-top: 16px;
  }
`

interface BlogTextProps {
  slice: {
    primary: {
      text: {
        html: string
      }
    }
  }
}

const BlogText = ({ slice }: BlogTextProps) => {
  const { text } = slice.primary

  return (
    <Wrapper>
      <Content
        dangerouslySetInnerHTML={{
          __html: text.html.replaceAll("<br />", ""),
        }}
      ></Content>
    </Wrapper>
  )
}

export const query = graphql`
  fragment PostBlogTextSlice on PrismicPostDataBodyBlogText {
    id
    slice_type
    primary {
      text {
        html
      }
    }
  }
`
export default BlogText
