import React, { FC, useState } from "react"
import styled from "styled-components"
import DownArrow from "./images/down-arrow.svg"
import CheckBox from "./Checkbox"
import OutsideClickHandler from "./OutsideClickHandler"

const Wrapper = styled.div`
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid #e1e2e8;
  background: rgb(255, 255, 255);
  cursor: pointer;
  color: #626874;
  height: 56px;
  align-content: center;
  padding: 0px 16px !important;
`

const SelectLabel = styled.div`
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
`

const OptionsWrapper = styled.div`
  margin-top: 4px;
  padding: 16px !important;
  background: white;
  border-radius: 16px;
  box-shadow: 0px 7px 14px rgba(34, 34, 52, 0.13);
  height: 210px;
  overflow: auto;
  position: absolute;
  width: 100%;

  &::-webkit-scrollbar {
    width: 8px;
    height: 27px !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e5ebff;
    border-radius: 16px;
  }

  &::before {
    content: "";
    width: 1.4rem;
    height: 1.4rem;
    clip-path: polygon(
      20% 0%,
      0% 20%,
      30% 50%,
      0% 80%,
      20% 100%,
      50% 70%,
      80% 100%,
      100% 80%,
      70% 50%,
      100% 20%,
      80% 0%,
      50% 30%
    );
    transform: scale(0);
    background-color: green;
  }

  &::checked {
    transform: scale(1);
  }

  &:hover {
    color: black;
  }

  @media (max-width: 768px) {
    height: 160px;
  }
`

const OptionItem = styled.div`
  padding: 8px;
  position: sticky;
  cursor: pointer;
  margin-top: 8px;
  &:first-child {
    margin-top: 0px;
  }
`

const TextWrapper = styled.div`
  display: inline-block;
  max-width: calc(100% - 50px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  top: 21px;
  left: 16px;
`

const DrowDownImage = styled.img`
  position: absolute;
  right: 16px;
  top: 24px !important;
`

interface SelectBoxProps {
  onChange: (e: string[]) => void
}

const SelectBox: FC<SelectBoxProps> = ({ onChange }: SelectBoxProps) => {
  const resources = [
    { key: "join_cerebral", value: "Join Cerebral" },
    { key: "therapy", value: "Therapy" },
    { key: "medication", value: "Medication" },
    { key: "anxiety", value: "Anxiety" },
    { key: "bipolar_disorder", value: "Bipolar Disorder" },
    { key: "depression", value: "Depression" },
    { key: "insomnia", value: "Insomnia" },
    { key: "ptsd", value: "PTSD" },
    { key: "couple_therapy", value: "Couple Therapy" },
  ]
  const [openDropDown, setOpenDropDown] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])

  const openSelectDropDown = () => {
    setOpenDropDown(!openDropDown)
  }

  const changeResources = (key: string) => {
    let newSelectedOptions
    if (selectedOptions.includes(key)) {
      newSelectedOptions = selectedOptions.filter(item => item !== key)
    } else {
      newSelectedOptions = [...selectedOptions, key]
    }

    setSelectedOptions(newSelectedOptions)
    onChange?.(newSelectedOptions)
  }

  const handleOutsideClick = () => {
    setOpenDropDown(false)
  }

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <Wrapper onClick={openSelectDropDown}>
        <SelectLabel>
          <TextWrapper>
            {selectedOptions.length === 0
              ? "What can we help with"
              : selectedOptions
                  .map(key => resources.find(item => item.key === key)?.value)
                  .join(", ")}
          </TextWrapper>{" "}
          <DrowDownImage src={DownArrow} />
        </SelectLabel>
      </Wrapper>
      {openDropDown && (
        <OptionsWrapper>
          {resources.map(({ key, value }) => {
            return (
              <OptionItem key={key} onClick={() => changeResources(key)}>
                <CheckBox
                  colorStyle="#51459E"
                  label={value}
                  onChange={() => {}}
                  checked={selectedOptions.includes(key)}
                />
              </OptionItem>
            )
          })}
        </OptionsWrapper>
      )}
    </OutsideClickHandler>
  )
}

export default SelectBox
