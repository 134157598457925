import styled from "styled-components"

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-bottom: 80px;
`
export const TrustPilotText = styled.p`
  font-family: Poppins;
  line-height: 24px;
`

export const TrustPilotTextWrapper = styled.span`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  margin-top: 8px;
  justify-content: center;
  gap: 4px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`
