import Cookies from "js-cookie"

export enum segmentEvents {
  BOOK_SESSION_BIO_PAGE = "Click - Book a session in bio page",
  NEWSLETTER_OPTED_IN = "Newsletter Opted In",
  FAQ_CLICKED = "FAQ Clicked",
  COUPLES_THERAPY_WAITLIST = "Couples Therapy Waitlist",
  LEAD_CAPTURE_RESOURCES_COLLECTION = "Lead Capture Care Resources Collection",
  LEAD_CAPTURE_MEDICATION_COLLECTION = "Lead Capture Medications Collection",
  INSURANCE_WIDGET_SUBMIT = "Insurance Widget Submit",
  INSURANCE_MODAL_CONTINUE_CLICK = "Insurance Modal Continue Click",
  INSURANCE_MODAL_LEAD = "Insurance Modal Lead",
  INSURANCE_MODAL_NOT_LEAD = "Insurance Modal Not Lead",
  INSURANCE_MODAL_BCBS_TEXAS = "Insurance Modal BCBS Texas",
  INSURANCE_MODAL_NO_BCBS_TEXAS = "Insurance Modal No BCBS Texas",
  MEDICATION_AFFORDABLE_COLLECTION = "Medication Affordable Collection",
  CLICK_GET_STARTED_CONTROL_LP = "Click - Get Started CTA in the Control LP",
  CLICK_GET_STARTED_TEST_LP = "Click - Get Started CTA in the Test LP",
  CLICK_OPTION_MINI_WIDGET = "Click - Option in Mini Widget",
  LEGAL_BANNER_CLICKED = "Click - Legal Banner",
}

interface ObjectProduct {
  [key: string]: string | number
}

export interface TrackingParams {
  [key: string]:
    | string
    | number
    | ObjectProduct[]
    | null
    | undefined
    | Date
    | unknown
}

export interface SegmentContext {
  context?: {
    device: {
      type: string | string[]
      advertisingId: string | string[]
    }
  }
  integrations: {
    [key: string]: boolean
  }
}

interface IcollectionItem {
  segmentKey: segmentEvents
  email: string
  careResources: string[]
  url: string
}
interface ICouplesWaitlist {
  email: string
  name: string
  stateCode: string
  phone: string
}

const setImpactClickID = () => {
  const params = new URL(document.location.toString()).searchParams
  const irClickID = params.get("irclickid")
  if (irClickID) {
    localStorage.setItem("irclickid", irClickID)
  }
}

const isProduction = () => {
  return (
    process.env.NODE_ENV === "production" &&
    !window.location.href.includes("cerebral-development") &&
    !window.location.href.includes("app-staging") &&
    !window.location.href.includes("app-dev") &&
    !window.location.href.includes("app-release") &&
    !window.location.href.includes("app-fe-release") &&
    !window.location.href.includes("app-insurance") &&
    !window.location.href.includes("preprod")
  )
}

const isTrackingEnabled = () => {
  if (Cookies.get("enable_segment_track") !== undefined) return true

  return isProduction()
}

const track = (
  event: segmentEvents,
  opts?: Partial<TrackingParams>,
  context?: SegmentContext | null
): void => {
  if (!isTrackingEnabled() || !window.analytics) return

  window.analytics?.track(event, opts, context)
}

const addUserToIterableNewsletter = (
  email: string,
  newsletterFirstName: string,
  newsletterLastName: string
) => {
  if (!isTrackingEnabled() || !window.analytics) return

  window.analytics.identify(
    {
      email,
    },
    {
      integrations: {
        All: false,
        Iterable: true,
      },
    }
  )

  setTimeout(() => {
    track(
      segmentEvents.NEWSLETTER_OPTED_IN,
      { email, newsletterFirstName, newsletterLastName },
      {
        integrations: {
          All: false,
          Iterable: true,
        },
      }
    )
  }, 1000)
}

const addUserToCouplesTherapyWaitlist = ({
  email,
  name,
  phone,
  stateCode,
}: ICouplesWaitlist): void => {
  if (!isTrackingEnabled() || !window.analytics) return

  window.analytics.identify(
    {
      email,
      name,
      phone,
    },
    {
      integrations: {
        All: false,
        Iterable: true,
        Heap: true,
      },
    }
  )

  setTimeout(() => {
    track(
      segmentEvents.COUPLES_THERAPY_WAITLIST,
      { email, name, phone, stateCode },
      {
        integrations: {
          All: false,
          Iterable: true,
          Heap: true,
        },
      }
    )
  }, 1000)
}

const addUserToCollection = ({
  segmentKey,
  email,
  careResources,
  url,
}: IcollectionItem): void => {
  if (!isTrackingEnabled() || !window.analytics) return

  window.analytics.identify(
    {
      email,
    },
    {
      integrations: {
        All: false,
        Iterable: true,
        Heap: false,
      },
    }
  )

  setTimeout(() => {
    track(
      segmentKey,
      { email, metadata: careResources, url },
      {
        integrations: {
          All: false,
          Iterable: true,
        },
      }
    )
  }, 1000)
}

const addUserToIterableAffordable = (email: string, name: string) => {
  if (!isTrackingEnabled() || !window.analytics) return

  window.analytics.identify(
    {
      email,
      name,
    },
    {
      integrations: {
        All: false,
        Iterable: true,
      },
    }
  )

  setTimeout(() => {
    track(
      segmentEvents.MEDICATION_AFFORDABLE_COLLECTION,
      { email, name },
      {
        integrations: {
          All: false,
          Iterable: true,
        },
      }
    )
  }, 1000)
}

export default {
  track,
  setImpactClickID,
  addUserToIterableNewsletter,
  addUserToCouplesTherapyWaitlist,
  addUserToCollection,
  addUserToIterableAffordable,
}
