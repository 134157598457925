import React, { useState } from "react"
import { CustomDropdown } from "../../../shared/CustomDropdown"
import { conditions } from "../../constants"
import { UilHeartMedical, UilSearch } from "@iconscout/react-unicons"
import {
  Container,
  ContentContainer,
  Header,
  SubText,
  ConditionText,
  TextContainer,
  SearchButton,
  DropdownContainer,
  FormWrapper,
} from "./styled"

export const ConditionsWeTreat = ({
  onSubmit,
}: {
  onSubmit: (condition: string | null) => void
}): JSX.Element => {
  const [conditionFormError, setConditionFormError] = useState<string>()
  const [selectedCondition, setSelectedCondition] = useState<string | null>(
    null
  )

  const handleSelect = (e: string) => {
    setSelectedCondition(e)
    setConditionFormError(undefined)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault()
      if (!selectedCondition) {
        throw new Error("FormError:ConditionNotSelected")
      }
      onSubmit(
        conditions.find(c => c.display === selectedCondition)?.value || null
      )
    } catch (e) {
      if (e instanceof Error) {
        if (e.message === "FormError:ConditionNotSelected") {
          setConditionFormError("Please select a condition")
        }
      }
    }
  }

  return (
    <Container>
      <ContentContainer>
        <TextContainer>
          <Header>
            Conditions <b>we treat</b>
          </Header>
          <SubText>
            Cerebral offers therapy and medication options that can help manage
            nearly 500 conditions. These are some of the most common conditions
            we work with.
          </SubText>

          <FormWrapper onSubmit={handleSubmit}>
            <ConditionText>Find condition</ConditionText>
            <DropdownContainer>
              <CustomDropdown
                options={conditions.map(c => c.display)}
                onSelect={handleSelect}
                LeftIcon={UilHeartMedical}
                value={selectedCondition}
                error={conditionFormError}
                placeholder="Select a condition"
                onClear={() => {
                  setSelectedCondition(null)
                  onSubmit(null)
                }}
              />
              <SearchButton>
                <UilSearch />
              </SearchButton>
            </DropdownContainer>
          </FormWrapper>
        </TextContainer>
      </ContentContainer>
    </Container>
  )
}

export default ConditionsWeTreat
