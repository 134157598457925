import WelcomeBackDesktop from "./images/welcome-back-desktop.webp"
import WelcomeBackMobile from "./images/welcome-back-mobile.webp"
import InsuranceWelcomeBack from "./images/insurance-welcome-back.webp"

export const headline: { [key: string]: string } = {
  insurance: "Now accepting insurance",
  therapy: "Find a therapist who gets you",
  medication: "Meds management made easy",
  default: "Book your first session",
}

export const images = {
  default: [
    {
      srcSet: WelcomeBackDesktop,
      height: "100%",
      width: "100%",
      minWidth: "400px",
      name: "welcome-back-desktop",
      media: "(min-width: 835px)",
    },
    {
      srcSet: WelcomeBackMobile,
      height: "100%",
      width: "100%",
      name: "welcome-back-mobile",
      media: "(max-width: 834px)",
    },
  ],
  insurance: [
    {
      srcSet: InsuranceWelcomeBack,
      height: "100%",
      width: "100%",
      minWidth: "400px",
      name: "insurance-welcome-back",
      media: "(min-width: 835px)",
    },
  ],
}
