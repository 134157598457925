import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image"

export const Wrapper = styled.div`
  margin: 48px 0;

  @media (max-width: 744px) {
    margin: 32px 24px;
  }
`

const Image = styled(GatsbyImage)`
  object-fit: cover;
  border-radius: 16px;
  width: 100%;
  height: 480px;

  @media (max-width: 600px) {
    height: 240px !important;
  }
`

interface BlogImageInterface {
  slice: {
    primary: {
      blog_image: IGatsbyImageData
    }
  }
}

const BlogImage = ({ slice }: BlogImageInterface) => {
  const { blog_image } = slice.primary

  const image = getImage(blog_image)

  return (
    <Wrapper>
      <Image image={image as IGatsbyImageData} loading="lazy" alt="" />
    </Wrapper>
  )
}

export const query = graphql`
  fragment PostBlogImageSlice on PrismicPostDataBodyBlogImage {
    id
    slice_type
    primary {
      blog_image {
        gatsbyImageData
      }
    }
  }
`

export default BlogImage
