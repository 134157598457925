import { PopularLocations } from "../../../shared"
import { therapistsStates } from "../../../constants"

export const TherapistsPopularStates = () => {
  return (
    <PopularLocations
      carePersonType="therapists"
      locations={therapistsStates}
      title="Find a therapist in your state"
    />
  )
}
