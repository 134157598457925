import {
  Container,
  Heading,
  TabWrapper,
  BlocTab,
  Header4,
  IconWrap,
  Folder,
} from "./styled"
import { useState } from "react"
import { useWindowSize } from "../../../hooks"
import { TabContent } from "./TabContent"
import { tabHeaderContent } from "./constant"

const Tab = ({
  tabNumber,
  setActiveTab,
  activeTab,
  content,
}: {
  tabNumber: number
  setActiveTab: (tabNumber: number) => void
  activeTab: number
  content: { icon: string; alt: string; headerMobile: string; header: string }
}) => {
  const { isMobile } = useWindowSize(768)

  return (
    <TabWrapper
      onClick={() => setActiveTab(tabNumber)}
      active={activeTab === tabNumber}
    >
      <IconWrap>
        <img src={content.icon} alt={content.alt} />
      </IconWrap>
      <Header4>{isMobile ? content.headerMobile : content.header}</Header4>
    </TabWrapper>
  )
}

export const OtherWaysToPay = () => {
  const [activeTab, setActiveTab] = useState(0)
  return (
    <Container>
      <Heading>Other ways to pay</Heading>
      <Folder>
        <BlocTab>
          {tabHeaderContent.map((content, index) => (
            <Tab
              tabNumber={index}
              setActiveTab={() => setActiveTab(index)}
              activeTab={activeTab}
              content={content}
            />
          ))}
        </BlocTab>
        <TabContent currentTab={activeTab} />
      </Folder>
    </Container>
  )
}
