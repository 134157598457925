import React from "react"

import { H1 } from "../../../ui"
import { SubText, Container } from "./styled"
import { Picture } from "../../../ui"
import splashsvg from "./assets/splash.svg"
import { paragraphs } from "./constants"

const images = [
  {
    srcSet: splashsvg,
    height: "215",
    width: "330",
    name: "about-splash-desktop",
    media: "(min-width:835px)",
  },
  {
    srcSet: splashsvg,
    height: "175",
    width: "245",
    name: "about-splash-mobile",
    media: "(max-width:834px)",
  },
]

export const HelpingMorePeople = (): JSX.Element => {
  return (
    <Container>
      <Picture images={images} fallBack={splashsvg} alt="" />
      <H1>Helping more people access high quality mental healthcare</H1>
      {paragraphs.map(paragraph => (
        <SubText key={paragraph.key}>{paragraph.text}</SubText>
      ))}
    </Container>
  )
}
