import { useEffect, useState } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import {
  GatsbyImage,
  getImage,
  getSrcSet,
  IGatsbyImageData,
  ImageDataLike,
} from "gatsby-plugin-image"
import Check from "../shared/assets/check.svg"
import { useWindowSize } from "../../components/hooks"

const Wrapper = styled.div<{ hasItems: boolean }>`
  padding: ${props => (props.hasItems ? "64px 0px 32px 0px" : "0 0 96px 0")};
  display: flex;
  justify-content: center;
  background: ${props =>
    props.hasItems ? "linear-gradient(135deg, #FEF9EC 0%, #ECEFFE 100%)" : ""};

  @media (max-width: 834px) {
    padding: ${props => (props.hasItems ? "24px 0" : "0 0 64px 0")};
  }
`

const SecondSection = styled.div<{ reversed: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 64px;
  padding-bottom: 64px;
  padding-left: ${({ reversed }) => (reversed ? "0" : "64px")};
  padding-right: ${({ reversed }) => (reversed ? "64px" : "0")};

  @media (max-width: 1189px) {
    padding-right: ${({ reversed }) => (reversed ? "64px" : "24px")};
    align-items: center;
  }

  @media (max-width: 1005px) {
    padding-left: ${({ reversed }) => (reversed ? "24px" : "")};
  }

  @media (max-width: 834px) {
    width: 100%;
    padding: 0;
  }
`

const Container = styled.div<{ reversed: boolean; hasItems: boolean }>`
  display: flex;
  flex-direction: ${props => (props.reversed ? "row-reverse" : "row")};
  width: 100%;
  max-width: 1216px;
  height: 576px;

  @media (max-width: 1216px) {
    padding: 0 24px;
  }

  @media (max-width: 834px) {
    flex-direction: ${props =>
      props.reversed && props.hasItems ? "column-reverse" : "column"};
    height: 100%;
  }
`

const FirstSection = styled.div<{ objectFit: string; hasItems: boolean }>`
  display: flex;
  justify-content: center;
  width: 50%;
  & > div > img {
    object-fit: ${props => props.objectFit} !important;
  }

  @media (max-width: 834px) {
    width: 100%;
    margin-bottom: 48px;
  }

  & > div {
    height: auto !important;

    @media (max-width: 834px) {
      width: 100% !important;
      height: ${props =>
        props.hasItems ? "400px !important" : "490px !important"};
    }

    @media (max-width: 600px) {
      height: ${props =>
        props.hasItems ? "400px !important" : "342px !important"};
    }

    @media (max-width: 450px) {
      height: ${props =>
        props.hasItems ? "400px !important" : "244px !important"};
    }

    @media (max-width: 424px) {
      height: ${props =>
        props.hasItems ? "400px !important" : "234px !important"};
    }
  }
`
const Title = styled.div`
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: #1c1e21;

  @media (max-width: 600px) {
    font-size: 24px;
    line-height: 28px;
  }
`

const Description = styled.div<{ hasMargin: boolean }>`
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #353a41;
  margin-top: ${props => (props.hasMargin ? "12px" : "0")};
  text-align: left;
`

const TextWrapper = styled.div<{ hasItems: boolean }>`
  display: flex;
  flex-direction: column;
  max-width: 496px;
  align-items: center;
  align-items: start;
  padding-left: ${({ hasItems }) => (hasItems ? "82px" : "0")};

  @media (max-width: 1189px) {
    padding-left: 0;
  }

  @media (max-width: 834px) {
    max-width: none;
    width: 100%;
  }
`

const IconWrapper = styled.div<{ hasBg: boolean }>`
  display: flex;
  height: 24px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${props => (props.hasBg ? "#eceaff" : "none")};

  div {
    width: ${props => (props.hasBg ? "16px !important" : "24px")};
    height: ${props => (props.hasBg ? "16px !important" : "24px")};
  }
`
const BulletContainer = styled.div<{ hasItems: boolean }>`
  padding: 32px 0;
  padding-left: ${props => (props.hasItems ? "0" : "16px")};
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 100%;

  @media (max-width: 834px) {
    padding: 32px 0;
  }
`

const BulletWrapper = styled.div`
  display: flex;
  gap: 12px;

  strong {
    font-size: 16px;
    font-weight: 600;
    color: #1c1e21;
  }

  b {
    font-weight: 600;
  }
`

const BulletText = styled.div`
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #353a41;

  & > span.tooltip {
    position: relative;
    display: inline-block;
    & > strong {
      border-bottom: 1px solid #4d515a;
      color: #4d515a;
      font-size: 14px;
    }
  }

  & > span.tooltip > span.tooltiptext {
    visibility: hidden;
    width: 246px;
    background-color: #fafafc;

    color: #1c1e21;
    text-align: left;
    border-radius: 6px;
    padding: 8px 16px;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: -10%;
    margin-left: -70px;
    margin-top: 10px;
    font-size: 12px;
    line-height: 16px;
  }

  & > span.tooltip:hover > span.tooltiptext {
    visibility: visible;
    border: 1px solid #d1d3d7;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 120px;
      bottom: 100%;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-bottom-color: #d1d3d7;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 121px;
      bottom: 100%;
      width: 0;
      height: 0;
      border: 9px solid transparent;
      border-bottom-color: #fafafc;
    }
  }
`

const SubscriptWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 20px;
  border-radius: 8px;
  background: linear-gradient(135deg, #caeaec 0%, #f6e9fe 100%);
  width: 100%;
`

const IconSubscriptWrapper = styled.div`
  & > div {
    width: 24px;
  }
`

const SubscriptText = styled.div`
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
  color: #250044;

  strong {
    font-weight: 600;
  }
`

interface ImageWithListTextRightItemProps {
  copy: {
    html: string
  }
  icon: IGatsbyImageData
}

interface ImageWithListTextRightProps {
  reversed: boolean
  slice: {
    primary: {
      slice_id?: {
        text: string
      }
      title: {
        text: string
      }
      copy: {
        text: string
      }
      bullets: {
        html: string
      }
      subscript: {
        html: string
      }
      subscript_icon: ImageDataLike
      image: ImageDataLike
      mobile_image: ImageDataLike
      object_fit: {
        text: string
      }
    }
    items: ImageWithListTextRightItemProps[]
  }
}

const ImageWithListTextRight = ({
  reversed,
  slice,
}: ImageWithListTextRightProps) => {
  const {
    image,
    title,
    copy,
    bullets,
    subscript,
    subscript_icon,
    mobile_image,
    object_fit,
  } = slice.primary
  const { isMobile } = useWindowSize()
  const images = getImage(image)
  const mobileImages = getImage(mobile_image)
  const [mainImage, setMainImage] = useState(images)
  const items = slice.items
  const hasItems = (items && items.length > 0) || false

  const icon = getImage(subscript_icon)
  const details = bullets?.html
    .replace(/<\/?ul>|<li>/g, "")
    .replaceAll("&lt;", "<")
    .replaceAll("&gt;", ">")
    .split("</li>")
    .filter(Boolean)
  const renderDetail = (text: string, order: number) => {
    return (
      <BulletWrapper key={text}>
        <IconWrapper hasBg={true}>
          <img src={Check} alt={text} />
        </IconWrapper>
        <BulletText dangerouslySetInnerHTML={{ __html: text }} />
      </BulletWrapper>
    )
  }

  const renderItem = (item: ImageWithListTextRightItemProps, index: number) => {
    const html = item.copy.html
      .replace(/<\/?ul>|<li>/g, "")
      .replaceAll("&lt;", "<")
      .replaceAll("&gt;", ">")
    return (
      <BulletWrapper key={index}>
        <IconWrapper hasBg={false}>
          <GatsbyImage
            image={getImage(item.icon) as IGatsbyImageData}
            alt=""
            loading="eager"
          />
        </IconWrapper>
        <BulletText dangerouslySetInnerHTML={{ __html: html }} />
      </BulletWrapper>
    )
  }

  useEffect(() => {
    if (isMobile && mobileImages) {
      setMainImage(mobileImages)
    } else {
      setMainImage(images)
    }
  }, [isMobile])

  const srcSet = getSrcSet(mainImage as IGatsbyImageData)

  return (
    <Wrapper hasItems={hasItems}>
      <Container reversed={reversed} hasItems={hasItems}>
        <FirstSection
          objectFit={object_fit.text || "cover"}
          hasItems={hasItems}
        >
          <div>
            <img
              srcSet={srcSet}
              alt={title.text}
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
            />
          </div>
        </FirstSection>

        <SecondSection reversed={reversed}>
          <TextWrapper hasItems={hasItems}>
            <Title>{title.text}</Title>

            <Description hasMargin={!!copy.text}>{copy.text}</Description>

            <BulletContainer hasItems={hasItems}>
              {hasItems
                ? items.map((item, index) => renderItem(item, index))
                : details.map((item, index) => renderDetail(item, index))}
            </BulletContainer>

            {!hasItems && (
              <SubscriptWrapper>
                <IconSubscriptWrapper>
                  <GatsbyImage
                    image={icon as IGatsbyImageData}
                    alt={title.text}
                    loading="eager"
                  />
                </IconSubscriptWrapper>
                <SubscriptText
                  dangerouslySetInnerHTML={{ __html: subscript.html }}
                />
              </SubscriptWrapper>
            )}
          </TextWrapper>
        </SecondSection>
      </Container>
    </Wrapper>
  )
}

export const query = graphql`
  fragment ImageWithListTextRightSlice on PrismicLandingDataBodyImageWithListTextRight {
    slice_type
    primary {
      copy {
        text
      }
      bullets {
        html
      }
      title {
        text
      }
      image {
        gatsbyImageData(placeholder: BLURRED)
      }
      subscript {
        html
      }
      subscript_icon {
        gatsbyImageData(placeholder: BLURRED)
      }
      mobile_image {
        gatsbyImageData(placeholder: BLURRED)
      }
      object_fit {
        text
      }
    }
  }
`
export default ImageWithListTextRight
