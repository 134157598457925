import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image"
import { CtaButton } from "./LandingPageStyles"

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--Secondary-50, #f2f5ff);
  padding: 64px 0;

  @media (max-width: 1024px) {
    padding: 48px 24px;
  }
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 798px;
`

const Title = styled.div`
  color: #1c1e21;
  text-align: center;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;

  @media (max-width: 1024px) {
    font-size: 24px;
  }
`

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
`

const Text = styled.div`
  max-width: 688px;
  font-family: Poppins;
  text-align: center;
  color: #1c1e21;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 29px;

  @media (max-width: 1024px) {
    font-size: 14px;
    margin-top: 32px;
    max-width: 100%;
  }
`

const ListWrapper = styled.div`
  display: flex;
  max-width: 798px;
  justify-content: center;
  align-items: center;
  gap: 8.857px;
  flex-wrap: wrap;
  margin-top: 29px;

  @media (max-width: 1024px) {
    margin-top: 32px;
    max-width: 100%;
    gap: 6.351px;
    white-space: nowrap;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 24px;
  background: var(--Background-default, #fff);

  @media (max-width: 1024px) {
    padding: 4px 8px;
    border-radius: 12px;
  }
`

const Icon = styled(GatsbyImage)`
  width: 16px;
  height: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`

const ItemTextWrapper = styled.div`
  color: var(--Primary-900, var(--primary-primary-900, #250044));
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;

  @media (max-width: 1024px) {
    font-size: 14px;
    line-height: 16px;
  }
`
const CtaButtonWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: center;
`

interface ContentHubTabBenefitsInterface {
  slice: {
    primary: {
      tab_id?: string
      tab_title: {
        text: string
      }
      benefits_title: {
        text: string
      }
      benefits_description: {
        text: string
      }
      benefits_btn_cta_link?: {
        url: string
      }
      benefits_btn_cta_text?: {
        text: string
      }
      benefits_item_icon: {
        gatsbyImageData: IGatsbyImageData
      }
    }
    items: {
      benefits: {
        html: string
      }
    }[]
  }
}

const ContentHubTabBenefits = ({ slice }: ContentHubTabBenefitsInterface) => {
  const {
    tab_id,
    tab_title,
    benefits_description,
    benefits_btn_cta_text,
    benefits_btn_cta_link,
    benefits_item_icon,
    benefits_title,
  } = slice.primary
  const { items } = slice

  return (
    <Wrapper id={tab_id}>
      <Container>
        <Title>{benefits_title.text}</Title>
        <TextContainer>
          <Text>{benefits_description.text}</Text>
        </TextContainer>
        <ListWrapper>
          {items.map(({ benefits }) => (
            <ContentWrapper>
              <Icon
                image={getImage(benefits_item_icon) as IGatsbyImageData}
                alt=""
              />
              <ItemTextWrapper
                dangerouslySetInnerHTML={{
                  __html: benefits.html,
                }}
              />
            </ContentWrapper>
          ))}
        </ListWrapper>
        <CtaButtonWrapper>
          <CtaButton
            to={benefits_btn_cta_link?.url || process.env.GATSBY_START_URL}
            width="320px"
          >
            {benefits_btn_cta_text?.text}
          </CtaButton>
        </CtaButtonWrapper>
      </Container>
    </Wrapper>
  )
}

export const query = graphql`
  fragment PageContentHubTabBenefitsSlice on PrismicPageDataBodyContentHubTabBenefits {
    id
    slice_type
    primary {
      tab_id
      tab_title {
        text
      }
      benefits_title {
        text
      }
      benefits_description {
        text
      }
      benefits_item_icon {
        gatsbyImageData(placeholder: BLURRED, layout: FIXED)
      }
      benefits_btn_cta_link {
        url
      }
      benefits_btn_cta_text {
        text
      }
    }
    items {
      benefits {
        html
      }
    }
  }
`

export default ContentHubTabBenefits
