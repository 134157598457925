import styled from "styled-components"

export const CopyRight = styled.div`
  font-weight: 300;
  font-size: 13px;
  line-height: 150%;
  letter-spacing: -0.022em;
  color: #ffffff;
  display: flex;
  align-items: flex-end;
  margin-left: auto;
  @media (max-width: 800px) {
    margin: 0;
    flex-direction: column;
    align-items: flex-start;
  }
  span {
    padding: 8px;
  }
`
