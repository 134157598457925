import {
  ChooseServiceWrapper,
  DesktopButtonContainer,
  Header,
  HeaderWrapper,
  MobileButtonContainer,
  PlanCard,
  PlanCardContent,
  PlanCardFooter,
  PlanCardHeader,
  PlanCardsWrapper,
} from "./styles"
import { GetStartedButton } from "../../../shared/GetStartedButton"

interface ChooseServiceProps {
  plansInfo: Array<PlanInfo>
}

export interface PlanInfo {
  icon: any
  title: string
  planName: string
  planDescription: string
  weeklyPrice: number
  monthlyPrice: number
}

export const ChooseService = ({ plansInfo }: ChooseServiceProps) => {
  return (
    <ChooseServiceWrapper>
      <HeaderWrapper>
        <Header>
          <h2>Sessions and pricing</h2>
          <p>
            Our plans are just as effective — and about half the cost — of
            traditional in-person couples therapy
          </p>
        </Header>
        <DesktopButtonContainer>
          <GetStartedButton width={214} height={56} />
        </DesktopButtonContainer>
      </HeaderWrapper>
      <PlanCardsWrapper>
        {plansInfo.map((planInfo, index) => (
          <PlanCard key={index}>
            <PlanCardHeader>
              <planInfo.icon size={16} />
              <h4>{planInfo.title}</h4>
            </PlanCardHeader>

            <PlanCardContent>
              <h3>{planInfo.planName}</h3>
              <p>{planInfo.planDescription}</p>

              <PlanCardFooter>
                <p>
                  <b>${planInfo.weeklyPrice}/week</b> billed as{" "}
                  <b>${planInfo.monthlyPrice}/mo</b>
                </p>
                <span>Options to save more at checkout</span>
              </PlanCardFooter>
            </PlanCardContent>
          </PlanCard>
        ))}
      </PlanCardsWrapper>
      <MobileButtonContainer>
        <GetStartedButton height={56} />
      </MobileButtonContainer>
    </ChooseServiceWrapper>
  )
}
