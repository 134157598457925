import styled from "styled-components"

export const EndCapWrapper = styled.section<{ isMedsOrTherapy?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 32px;
  gap: 32px;
  text-align: center;
  white-space: nowrap;
  background: linear-gradient(135deg, #eeecf5 0%, #c3cffc 100%);
  h2 {
    font-size: 32px;
    line-height: 40px;
  }

  @media (max-width: 834px) {
    h2 {
      line-height: 30px;
      white-space: ${({ isMedsOrTherapy }) =>
        isMedsOrTherapy ? "nowrap" : "normal"};
      font-size: ${({ isMedsOrTherapy }) =>
        isMedsOrTherapy ? "24px" : "28px"};
    }
  }
`

export const ButtonContainer = styled.div<{ maxWidth?: string }>`
  max-width: ${props => props.maxWidth || "320px"};
  margin: 0 auto;
  height: 56px;
  width: 100%;
  @media (max-width: 834px) {
    max-width: 600px;
  }
`

export const GetStartedButtonCustom = styled.button`
  display: flex;
  width: 100%;
  height: 56px;
  padding: 12px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 28px;
  background: #51459e;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  border: none;
  cursor: pointer;
`
