import Envelope from "./assets/envelope.svg"
import Hands from "./assets/hands.svg"
import Pills from "./assets/pills.svg"
import Sun from "./assets/sun.svg"

import CoupleOnCouch from "./assets/couple-on-couch.webp"
import ManOnTablet from "./assets/man-on-tablet.webp"
import WomanOnPhone from "./assets/woman-on-phone.webp"
import WomanOnComputer from "./assets/woman-on-computer.webp"

export const copy = {
  medication: {
    title: "Medication",
    price: "$95",
    savings: "Save $20/mo with a 3 month package",
    fsa: true,
    description:
      "Get a complete mental health evaluation, initial diagnosis and medication prescription (if appropriate), and ongoing guidance from a licensed prescriber.",
    getStartedLink: "cerebral.com/app",
    learnMoreLink: "/plans/medication",
    image: WomanOnPhone,
    icon: Pills,
    imageText: "<b>Your next refill has been shipped</b>",
    link: "medication",
  },
  individualTherapy: {
    title: "Individual Therapy",
    price: "$295",
    savings: "Save $30/mo with a 3 month package",
    fsa: true,
    description:
      "Meet with a therapist 1-on-1 to build lifelong skills that can help you manage every day life, work through past experiences, and understand yourself better.",
    getStartedLink: "cerebral.com/app",
    learnMoreLink: "/plans/therapy",
    image: WomanOnComputer,
    icon: Envelope,
    imageText: "<b>Your therapist sent you a new message</b>",
    link: "individualTherapy",
  },
  couplesTherapy: {
    title: "Couples Therapy",
    price: "$325",
    savings: "Save up to $130 on your first 2 months",
    fsa: false,
    description:
      "Work with a therapist and your partner to better understand each other, learn how to communicate, and navigate life as a team.",
    getStartedLink: "cerebral.com/app",
    learnMoreLink: "/plans/couples-therapy",
    image: CoupleOnCouch,
    icon: Hands,
    imageText: "<b>Your therapy plan is ready to review</b>",
    link: "couplesTherapy",
  },
  therapyAndMedication: {
    title: "Therapy + Medication",
    price: "$365",
    savings: "Save $70/mo with a 3 month package",
    fsa: true,
    description:
      "Combining therapy and medication may increase the effectiveness of your treatment. Both work together to help you feel better over time and develop long-term skills to cope with your symptoms.",
    getStartedLink: "cerebral.com/app",
    image: ManOnTablet,
    icon: Sun,
    imageText:
      "<strong>80% of people avoided a recurrence of depression</strong> when they combined both therapy and medication",
    link: "therapyAndMedication",
    stat: true,
    statLink:
      "https://www.health.harvard.edu/staying-healthy/medication-or-therapy-for-depression-or-both",
  },
}
