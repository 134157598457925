import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

export const TextContentStyle = `
  & > p {
    margin-bottom: 1em;
  }

  b, strong {
    font-weight: 800;
  }

  em, i {
    font-style: italic;
  }

  p {
    font-size: 14px;
    line-height: 25px;
  }

  ul {
    list-style: disc;
    padding: 0 0 0 20px;
    margin-left: 15px;
  }

  ol {
    list-style-type: decimal;
    padding: 10px 0 0 20px;
  }

  ul, ol {
    text-align: left;
    font-size: 14px;
    line-height: 30px;
  }

  p, ul, ol, h2, h3, h4 {
    margin-bottom: 30px;
  }

  a {
    color: #6786ff;
  }

  li {
    margin: 0 0 20px;
  }

  .block-img {
    img {
      width: 100%;
    }
  }

  span {
    display: block;
    &.center-align {
      text-align: center;
    }
    &.right-align {
      text-align: right;
    }
  }

  @media (min-width: 834px) {

    p {
      font-size: 16px;
      line-height: 30px;
    }

    ul, ol {
      margin-left: 30px;
      font-size: 16px;
    }
  }
`

interface WrapperProps {
  from?: string
  to?: string
  type: string
  color?: string
}

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: middle;
  padding: 96px 24px;
  background-color: ${({ color }) => (color ? color : "white")};
  background-image: linear-gradient(
    135deg,
    ${props =>
        props.type === "gradient"
          ? props.from
            ? props.from
            : "rgba(207, 242, 240, 0.4)"
          : "rgba(0,0,0,0)"}
      0%,
    ${props =>
        props.type === "gradient"
          ? props.to
            ? props.to
            : "rgba(199, 191, 255, 0.4)"
          : "rgba(0,0,0,0)"}
      100%
  );
  background-color: white;

  @media (max-width: 834px) {
    padding: 64px 24px;
  }
`

const Copy = styled.div<{ align: string; textColor: string }>`
  max-width: 768px;
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  color: ${props => (props.textColor ? props.textColor : "#250044")};
  text-align: ${props =>
    props.align == "Right"
      ? "right"
      : props.align === "Center"
      ? "center"
      : "left"};

  @media (max-width: 320px) {
    max-width: 320px;
    padding: 0 12px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
  }

  h1 {
    font-size: 32px;
    line-height: 40px;

    @media (max-width: 600px) {
      font-size: 28px;
      line-height: 30px;
    }
  }

  h2 {
    font-size: 28px;
    line-height: 32px;
  }

  h3 {
    font-size: 20px;
    line-height: 24px;
  }

  h4 {
    font-size: 18px;
    line-height: 24px;
  }

  h5 {
    font-size: 16px;
    line-height: 24px;
  }

  h6 {
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }

  span {
    display: inline-block !important;
  }

  span.align-left {
    text-align: left;
  }

  span.align-center {
    text-align: center;
    width: 100%;
  }

  span.align-right {
    text-align: right;
  }

  span.strikethrough {
    text-decoration: line-through;
  }

  span.underline {
    text-decoration: underline;
  }

  span.caption {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    width: 100%;
    display: block !important;
    clear: both;
  }

  span.disclaimer {
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    width: 100%;
  }

  span.subheading {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #353a41;
    margin-bottom: 12px;
  }

  span.supporting {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #353a41;
    margin-top: 20px;
  }

  p {
    margin: 0 !important;
  }

  ${TextContentStyle}
`

interface TextProps {
  slice: {
    primary: {
      slice_id?: {
        text: string
      }
      background_type: string
      background_color?: string
      background_gradient_from?: string
      background_gradient_to?: string
      text_align: string
      text_color: string
      copy: {
        html: string
      }
    }
  }
}

const Text = ({ slice }: TextProps) => {
  const {
    background_type,
    background_color,
    background_gradient_from,
    background_gradient_to,
    text_align,
    text_color,
    copy,
  } = slice.primary

  const convertTextToHtml = (text: string) => {
    return text.replaceAll("&lt;", "<").replaceAll("&gt;", ">")
  }
  return (
    <Wrapper
      from={background_gradient_from}
      to={background_gradient_to}
      color={background_color}
      type={background_type}
      id={slice.primary.slice_id?.text || ""}
    >
      <Copy
        align={text_align}
        textColor={text_color}
        dangerouslySetInnerHTML={{
          __html: convertTextToHtml(copy.html),
        }}
      ></Copy>
    </Wrapper>
  )
}

export const query = graphql`
  fragment LandingTextSlice on PrismicLandingDataBodyText {
    id
    slice_type
    primary {
      copy {
        html
      }
      text_align
    }
  }
  fragment PostTextSlice on PrismicPostDataBodyText {
    id
    slice_type
    primary {
      copy {
        html
      }
    }
  }
  fragment PageTextSlice on PrismicPageDataBodyText {
    id
    slice_type
    primary {
      slice_id {
        text
      }
      copy {
        html
      }
      text_align
      text_color
      background_type
      background_color
      background_gradient_from
      background_gradient_to
    }
  }
`
export default Text
