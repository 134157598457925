import styled from "styled-components"
import { graphql } from "gatsby"
import {
  GatsbyImage,
  IGatsbyImageData,
  ImageDataLike,
  getImage,
} from "gatsby-plugin-image"
import Check from "../shared/assets/check.svg"
import ArrowUpRight from "../shared/assets/arrow-up-right.svg"

const Section = styled.div`
  padding: 96px;
  padding-top: 0;
  display: flex;
  justify-content: center;

  @media (max-width: 1216px) {
    padding: 48px 24px;
  }

  @media (max-width: 834px) {
    padding: 64px 32px;
  }
`

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1216px;

  @media (max-width: 834px) {
    flex-direction: column;
  }
`

const FlexItem = styled.div<{
  isFirst?: boolean
  isSecond?: boolean
}>`
  padding-right: ${({ isFirst }) => (isFirst ? "80px" : 0)};
  padding-left: ${({ isSecond }) => (isSecond ? "80px" : 0)};
  position: relative;
  flex: 1;

  &:not(:last-child)::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: #e1e2e8;
  }

  @media (max-width: 834px) {
    padding-right: 0;
    padding-left: 0;
    padding-bottom: ${({ isFirst }) => (isFirst ? "52px" : 0)};
    padding-top: ${({ isSecond }) => (isSecond ? "52px" : 0)};

    &:not(:last-child)::after {
      display: none;
    }

    &:not(:last-child)::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: #e1e2e8;
    }
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 1216px;
  padding: 64px 64px 0 64px;
  border-radius: 16px;
  justify-content: space-between;

  @media (max-width: 834px) {
    padding: 0px;
  }
`

const TitleSection = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 96px;

  @media (max-width: 834px) {
    margin-bottom: 64px;
  }
`

const Title = styled.h2`
  color: #1c1e21;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 16px;

  @media (max-width: 834px) {
    font-size: 24px;
    line-height: 28px;
    text-align: left;
  }
`

const SubTitle = styled.div`
  color: #353a41;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */

  @media (max-width: 834px) {
    width: 100%;
    max-width: fit-content;
    margin-top: 24px;
    text-align: left;
  }
`

const BlockTitle = styled.h3`
  color: #1c1e21;

  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: 16px 0;

  @media (max-width: 834px) {
    font-size: 20px;
    line-height: 24px;
    text-align: left;
  }
`

const BlockContent = styled.div`
  color: var(--Grey-800, #4d515a);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 24px;
  min-height: 100px;

  @media (max-width: 834px) {
    width: 100%;
    max-width: fit-content;
  }
`

const BlockSubTitle = styled.h5`
  color: #1c1e21;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;

  @media (max-width: 834px) {
    width: 100%;
    max-width: fit-content;
  }
`

const BulletContainer = styled.div`
  padding: 32px 0;
  padding-left: 16px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 100%;

  @media (max-width: 834px) {
    padding: 32px 0;
  }
`

const BulletWrapper = styled.div`
  display: flex;
  gap: 12px;

  strong {
    font-size: 16px;
    font-weight: 600;
    color: #1c1e21;
  }

  b {
    font-weight: 600;
  }
`

const BulletText = styled.div`
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #353a41;
`

const IconWrapper = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #eceaff;

  div {
    width: 16px !important;
    height: 16px !important;
  }
`

const SubscriptWrapper = styled.div`
  padding: 20px;
  border-radius: 8px;
  background: #f2f5ff;
  width: 100%;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
  color: #250044;

  strong {
    font-weight: 600;
  }

  > p {
    color: #1c1e21;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
`

const LinkContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 40px;
  margin-top: 24px;
  align-items: center;
  @media (max-width: 834px) {
    flex-direction: column;
    gap: 24px;
  }
`

const Link = styled.a`
  display: flex;
  justify-content: space-between;
  gap: 6px;
  align-items: center;
  color: #51459e;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-decoration: underline;
  cursor: pointer;

  @media (max-width: 834px) {
    justify-content: flex-start;
    width: 100%;
  }
`

const renderDetail = (text: string) => {
  return (
    <BulletWrapper key={text}>
      <IconWrapper>
        <img src={Check} alt={text} />
      </IconWrapper>
      <BulletText dangerouslySetInnerHTML={{ __html: text }} />
    </BulletWrapper>
  )
}

interface HeaderWithTwoContentBlocksProps {
  slice: {
    primary: {
      heading: {
        text: string
      }
      copy: {
        text: string
      }
      right_block_text: {
        html: string
      }
      left_block_link_url_1: {
        url: string
      }
      left_block_link_text_1: {
        text: string
      }
      left_block_link_url_2: {
        url: string
      }
      left_block_link_text_2: {
        text: string
      }
    }
    items: Array<{
      block_title: {
        text: string
      }
      block_content: {
        text: string
      }
      block_sub_title: {
        text: string
      }
      block_bullets: {
        html: string
      }
      icon: ImageDataLike
    }>
  }
}

const renderFooterItem = (
  index: number,
  {
    left_block_link_url_1: { url: leftBlockCtaLink1 },
    left_block_link_url_2: { url: leftBlockCtaLink2 },
    left_block_link_text_1: { text: leftBlockCtaText1 },
    left_block_link_text_2: { text: leftBlockCtaText2 },
    right_block_text: { html: rightBlockText },
  }: {
    left_block_link_url_1: { url: string }
    left_block_link_url_2: { url: string }
    left_block_link_text_1: { text: string }
    left_block_link_text_2: { text: string }
    right_block_text: { html: string }
  }
) => {
  if (index === 0)
    return (
      <LinkContainer>
        <Link
          href={leftBlockCtaLink1}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>{leftBlockCtaText1}</span>
          <img src={ArrowUpRight} alt={leftBlockCtaText1} />
        </Link>
        <Link
          href={leftBlockCtaLink2}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>{leftBlockCtaText2}</span>
          <img src={ArrowUpRight} alt={leftBlockCtaText2} />
        </Link>
      </LinkContainer>
    )
  return (
    <SubscriptWrapper dangerouslySetInnerHTML={{ __html: rightBlockText }} />
  )
}

const HeaderWithTwoContentBlocks = ({
  slice,
}: HeaderWithTwoContentBlocksProps) => {
  const {
    heading,
    copy,
    left_block_link_url_1,
    left_block_link_url_2,
    left_block_link_text_1,
    left_block_link_text_2,
    right_block_text,
  } = slice.primary

  const itemList = slice.items.map((item, index) => {
    const bullets = item.block_bullets.html
      .replace(/<\/?ul>|<li>/g, "")
      .replaceAll("&lt;", "<")
      .replaceAll("&gt;", ">")
      .split("</li>")
      .filter(Boolean)

    return {
      ...item,
      bullets,
    }
  })

  return (
    <Section>
      <Container>
        <TitleSection>
          <Title>{heading.text}</Title>
          <SubTitle>{copy.text}</SubTitle>
        </TitleSection>

        <FlexContainer>
          {itemList.map((item, index) => (
            <FlexItem key={index} isFirst={index === 0} isSecond={index === 1}>
              <GatsbyImage
                image={getImage(item.icon) as IGatsbyImageData}
                alt={heading.text}
                loading="eager"
              />
              <BlockTitle>{item.block_title.text}</BlockTitle>
              <BlockContent>{item.block_content.text}</BlockContent>
              <BlockSubTitle>{item.block_sub_title.text}</BlockSubTitle>
              <BulletContainer>
                {item.bullets.map(item => renderDetail(item))}
              </BulletContainer>
              {renderFooterItem(index, {
                left_block_link_url_1,
                left_block_link_url_2,
                left_block_link_text_1,
                left_block_link_text_2,
                right_block_text,
              })}
            </FlexItem>
          ))}
        </FlexContainer>
      </Container>
    </Section>
  )
}

export const query = graphql`
  fragment PageHeaderWithTwoContentBlocksSlice on PrismicPageDataBodyHeaderWithTwoContentBlocks {
    slice_type
    primary {
      copy {
        text
      }
      left_block_link_text_1 {
        text
      }
      left_block_link_text_2 {
        text
      }
      left_block_link_url_1 {
        url
      }
      left_block_link_url_2 {
        url
      }
      right_block_text {
        html
      }
      heading {
        text
      }
    }
    items {
      block_title {
        text
      }
      block_content {
        text
      }
      block_sub_title {
        text
      }
      block_bullets {
        html
      }
      icon {
        gatsbyImageData(placeholder: BLURRED, layout: FIXED)
      }
    }
  }
`
export default HeaderWithTwoContentBlocks
