import { colors } from "../../../themes/colors"
import styled from "styled-components"

export const ChooseServiceWrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 80px;
  padding: 64px 24px;
  color: ${colors.c_black[50]};
  background: linear-gradient(135deg, #fef9ec 0%, #eceffe 100%);

  * {
    font-family: Poppins;
  }

  & > div {
    max-width: 456px;
    flex: 0 1 456px;
  }

  @media (max-width: 834px) {
    flex-direction: column;
    gap: 32px;
    padding: 32px 24px;

    & > div {
      max-width: 600px;
      flex: 1;
    }
  }
`

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (max-width: 834px) {
    gap: 10px;
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  h2 {
    font-size: 28px;
    font-weight: 600;
    line-height: 32px;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: ${colors.c_grey[900]};
  }

  @media (max-width: 834px) {
    h2 {
      font-size: 24px;
      text-align: center;
    }
  }
`

export const PlanCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 992px;
`

export const PlanCard = styled.div`
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 0px 12px 0px #1c1e2129;
`

export const PlanCardHeader = styled.div`
  display: flex;
  gap: 8px;
  padding: 12px 16px;
  color: ${colors.c_primary[900]};
  background: ${colors.c_secondary[50]};

  h4 {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: ${colors.c_primary[900]};
  }
`

export const PlanCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px;
  background-color: ${colors.c_white[500]};

  h3 {
    font-size: 22px;
    font-weight: 600;
    line-height: 24px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: ${colors.c_grey[800]};
  }
`

export const PlanCardFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;

  p {
    font-size: 16px;
    line-height: 20px;
    color: ${colors.c_grey[700]};
  }

  b {
    font-weight: 600;
    color: ${colors.c_primary[600]};
  }

  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${colors.c_grey[700]};
  }
`

export const DesktopButtonContainer = styled.div`
  display: block;
  @media (max-width: 834px) {
    display: none;
  }
`

export const MobileButtonContainer = styled.div`
  display: none;
  @media (max-width: 834px) {
    display: block;
    width: 100%;
  }
`
