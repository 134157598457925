import React, { useState, useEffect, useRef, FC } from "react"
import {
  IconArrowDown,
  Option,
  Options,
  SelectContainer,
  StateValue,
  HoziBar,
  SearchBarVector,
} from "./styled"
import ArrowDownSVG from "./images/dropdown-arrow.svg"
import SearchVector from "./images/searchbar.svg"
import {
  therapistsStateNames,
  prescriberStateNames,
} from "../../CareTeamPages/constants"
import { navigate } from "@reach/router"

interface ISelectLocationProps {
  stateSelected: string
  onChange: (value: string) => void
  carePersonType: string
}

export const Dropdown: FC<ISelectLocationProps> = ({
  stateSelected,
  onChange,
  carePersonType,
}) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const wrapperRef = useRef(null)

  const states =
    carePersonType === "therapists"
      ? therapistsStateNames
      : prescriberStateNames

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !(wrapperRef.current as Node).contains(event.target as Node)
      ) {
        setShowDropdown(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [wrapperRef])

  const handleToggleDropdown = () => {
    setShowDropdown(!showDropdown)
  }

  const stateNameToUrl = (state: string) => {
    return state.replace(/\s+/g, "-").toLowerCase()
  }

  return (
    <SelectContainer ref={wrapperRef} onClick={handleToggleDropdown}>
      <SearchBarVector src={SearchVector} />
      <StateValue title={stateSelected}>{stateSelected}</StateValue>
      <IconArrowDown src={ArrowDownSVG} />
      {showDropdown && (
        <Options>
          <HoziBar />
          {states.map(item => (
            <Option
              key={item}
              onClick={() => {
                onChange(item)
                navigate(
                  `${
                    process.env.GATSBY_BASE_URL
                  }/${carePersonType}/${stateNameToUrl(item)}`
                )
              }}
            >
              {item}
            </Option>
          ))}
        </Options>
      )}
    </SelectContainer>
  )
}
