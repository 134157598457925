import styled from "styled-components"
import { colors } from "../../themes/colors"

export const StatsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1024px;
  margin: 0px auto;
  padding: 0 32px 32px 32px;
  gap: 48px;
  @media (max-width: 834px) {
    flex-direction: column;
  }
`

export const Stat = styled.div`
  text-align: center;
  font-family: Poppins;
  h3 {
    font-size: 64px;
    justify-content: center;
    color: ${colors.c_primary[600]};
    font-weight: 600;
    line-height: 100%;
    margin-bottom: 24px;
    @media (max-width: 834px) {
      font-size: 40px;
    }
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
`

export const Disclaimer = styled.div`
  text-align: center;
  font-family: Poppins;
  margin-bottom: 64px;
  span {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
  }
`

export const StatsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
