import { colors } from "../../../themes/colors"
import styled from "styled-components"

export const MedicationsWeOfferContainer = styled.div`
  padding: 64px 24px;
  background: ${colors.c_secondary[100]};

  @media (max-width: 834px) {
    padding: 32px 24px;
  }
`

export const InnerContainer = styled.div`
  max-width: 992px;
  margin: 0 auto;
`

export const DesktopMedicationsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;

  @media (max-width: 940px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 724px) {
    display: none;
  }
`

export const HeaderSection = styled.div`
  margin-bottom: 40px;
  h2 {
    color: ${colors.c_black[900]};
    text-align: center;
    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);
    font-family: Poppins;
    font-size: 28px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 16px;
  }
  p {
    color: ${colors.c_black[900]};
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    max-width: 800px;
    margin: 0 auto;
  }
  b {
    font-weight: 600;
  }

  @media (max-width: 834px) {
    margin-bottom: 24px;

    h2 {
      font-size: 24px;
    }
  }
`

export const MobileMedicationsGrid = styled.div`
  display: none;
  @media (max-width: 724px) {
    display: block;
  }
`

export const DisclaimerText = styled.p`
  color: #4d515a;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 40px auto 24px;
  max-width: 638px;

  @media (max-width: 600px) {
    font-size: 12px;
    margin-top: 24px;
  }
`

export const ButtonContainer = styled.div`
  margin: 0 auto;
  width: fit-content;
`
export const MedicationCarouselWrapper = styled.div<{ hasSteps?: boolean }>`
  width: calc(100vw - 48px);
  text-align: center;
  overflow: hidden;

  & > div > div:last-child {
    margin-top: 16px;
    justify-content: space-between;
  }

  & > div > div:last-child button:first-child {
    margin-left: 16px;
  }

  & > div > div:last-child button:last-child {
    margin-right: 16px;
  }

  & > div > div:last-child div div#indicator-active {
    width: 16px;
  }

  @media (min-width: 834px) {
    display: none;
  }
`

export const GetStartedButtonCustom = styled.button`
  display: flex;
  width: 100%;
  height: 56px;
  padding: 12px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 28px;
  background: #51459e;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  border: none;
  cursor: pointer;
`
