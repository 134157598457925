import React from "react"
import styled from "styled-components"
import Link from "./Link"

const Button = ({
  className,
  children,
  to,
  target,
  rel,
  onClick,
}: {
  className?: string
  children?: React.ReactNode
  to: string
  target?: string
  rel?: string
  onClick?:
    | ((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void)
    | (() => void)
}) => {
  return (
    <Link
      to={to}
      className={className}
      target={target}
      rel={rel}
      onClick={onClick}
    >
      {children}
    </Link>
  )
}

const StyledButton = styled(Button)`
  background: linear-gradient(90deg, #6786ff 6.2%, #85abee 100%);
  box-shadow: 0px 7px 14px rgba(34, 34, 52, 0.13);
  border-radius: 30px;
  border: none;
  color: #fff;
  padding: 13px 40px;
  font-weight: 600;
  font-size: 14px;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;

  &:active {
    box-shadow: inset 0px 7px 14px rgba(34, 34, 52, 0.13);
  }
`

export default StyledButton
