import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  ImageDataLike,
} from "gatsby-plugin-image"
import StyledCta from "../ui/StyledCta"
import { scrollToElement } from "../../lib/utils"

interface WrapperProps {
  from?: string
  to?: string
  type: string
  color?: string
}

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  height: auto;
  width: 100%;
  background-color: ${({ color }) => (color ? color : "white")};
  background-image: linear-gradient(
    135deg,
    ${props =>
        props.type === "gradient"
          ? props.from
            ? props.from
            : "rgba(207, 242, 240, 0.4)"
          : "rgba(0,0,0,0)"}
      0%,
    ${props =>
        props.type === "gradient"
          ? props.to
            ? props.to
            : "rgba(199, 191, 255, 0.4)"
          : "rgba(0,0,0,0)"}
      100%
  );
`

const ContentWrapper = styled.div<{ imagePosition: string }>`
  width: calc(100% - 32px);
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: auto;

  @media (min-width: 834px) {
    padding: 64px 16px;
    flex-direction: ${({ imagePosition }) =>
      imagePosition === "right" ? "row" : "row-reverse"};
    gap: 16px;
  }

  @media (min-width: 928px) {
    width: 896px;
  }
`

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: left;
  @media (min-width: 834px) {
    max-width: 516px;
  }
`

const ImageWrapper = styled.div`
  display: block;
  width: 100%;
  @media (min-width: 834px) {
    width: 364px !important;
  }

  picture img {
    object-position: center center !important;
    object-fit: cover;
  }
`

const Header = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  font-size: 28px;
  color: #250044;
`

const SubHeader = styled.div`
  * {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #250044;
  }

  p:not(:last-child) {
    margin-bottom: 16px;
  }

  strong {
    font-weight: 600;
  }

  span {
    display: inline-block !important;
  }

  span.strikethrough {
    text-decoration: line-through;
  }

  span.underline {
    text-decoration: underline;
  }

  a {
    color: "#b172e6";
  }
`

interface TitleAndCopyWithImageProps {
  slice: {
    primary: {
      slice_id?: {
        text: string
      }
      background_type: string
      background_color?: string
      background_gradient_from?: string
      background_gradient_to?: string
      header: {
        text: string
      }
      sub_header: {
        html: string
      }
      image1: ImageDataLike
      image_position: string
      show_cta_button: boolean
      cta_text: {
        text: string
      }
      cta_link?: {
        url?: string
      }
      cta_style: string
      cta_background_color: string
      cta_text_color: string
    }
  }
}

const TitleAndCopyWithImage = ({ slice }: TitleAndCopyWithImageProps) => {
  const { primary } = slice
  const {
    header,
    sub_header,
    image1,
    image_position,
    show_cta_button,
    cta_link,
    cta_text,
    cta_style,
    cta_background_color,
    cta_text_color,
    slice_id,
    background_type,
    background_color,
    background_gradient_from,
    background_gradient_to,
  } = primary

  const images = getImage(image1) as IGatsbyImageData

  const getCtaLink = (): string => {
    if (cta_link?.url?.includes("https://#")) {
      return cta_link.url.replace("https://#", "#")
    }
    return cta_link?.url || ""
  }

  return (
    <Wrapper
      type={background_type}
      color={background_color}
      from={background_gradient_from}
      to={background_gradient_to}
      id={slice_id?.text || ""}
    >
      <ContentWrapper imagePosition={image_position}>
        <TextWrapper>
          <Header>{header.text}</Header>
          <SubHeader
            dangerouslySetInnerHTML={{ __html: sub_header.html }}
          ></SubHeader>
          {show_cta_button && (
            <StyledCta
              style={cta_style}
              link={getCtaLink()}
              text={cta_text.text}
              backgroundColor={cta_background_color}
              textColor={cta_text_color}
              onClick={() => scrollToElement(getCtaLink().replace("#", ""))}
            />
          )}
        </TextWrapper>
        <ImageWrapper>
          <GatsbyImage image={images} alt="" loading="eager" />
        </ImageWrapper>
      </ContentWrapper>
    </Wrapper>
  )
}

export const query = graphql`
  fragment PageTitleAndCopyWithImageSlice on PrismicPageDataBodyTitleAndCopyWithImage {
    id
    slice_type
    primary {
      slice_id {
        text
      }
      background_type
      background_color
      background_gradient_from
      background_gradient_to
      header {
        text
      }
      sub_header {
        html
      }
      image1 {
        gatsbyImageData(width: 760)
      }
      image_position
      show_cta_button
      cta_link {
        url
      }
      cta_text {
        text
      }
      cta_background_color
      cta_text_color
      cta_style
    }
  }
`

export default TitleAndCopyWithImage
