import { colors } from "../../themes/colors"

export const insuranceStates = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
]

export interface InsuranceWidgetStyle {
  backgroundColor: string
  titleFontSize: string
  subTitleColor: string
  showMiddleLogos: boolean
  learnMoreTextFontSize: string
  learMoreTextColor: string
  learnMoreTextLineHeight: string
  externalSource?: string // default is "insurance_widget"
}

export const InsuranceWidgetPlanStyle: InsuranceWidgetStyle = {
  backgroundColor: colors.c_white[100],
  titleFontSize: "40px",
  subTitleColor: colors.c_black[900],
  showMiddleLogos: true,
  learnMoreTextFontSize: "16px",
  learMoreTextColor: colors.c_black[900],
  learnMoreTextLineHeight: "24px",
}

export const InsuranceWidgetHomePageStyle: InsuranceWidgetStyle = {
  backgroundColor: colors.c_secondary[100],
  titleFontSize: "32px",
  subTitleColor: colors.c_grey[800],
  showMiddleLogos: false,
  learnMoreTextFontSize: "12px",
  learMoreTextColor: colors.c_grey[800],
  learnMoreTextLineHeight: "16px",
  externalSource: "homepage_insurance_widget",
}
