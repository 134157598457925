import { graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import { FlexWrapper } from "./LandingPageStyles"
import Button from "../../common/Button"

interface BackgroundColorProps {
  from?: string
  to?: string
  type: string
  color?: string
}

const Wrapper = styled(FlexWrapper)<BackgroundColorProps>`
  background: linear-gradient(
    135deg,
    rgba(207, 242, 240, 0.4) 0%,
    rgba(199, 191, 255, 0.4) 100%
  );
  padding: 64px 36px 92px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;

  @media (min-width: 834px) {
    gap: 64px;
    padding: 60px;
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 16px;

  @media (min-width: 834px) {
    flex-direction: row;
    gap: 36px;
  }
`

const Header = styled.h2`
  font-family: "Poppins";
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  color: #250044;

  @media (min-width: 834px) {
    font-size: 45px;
  }
`

const PurpleButton = styled(Button)`
  min-width: 240px;
  padding: 23px;
  background: #6786ff;
  box-shadow: 0px 7px 14px rgba(34, 34, 52, 0.13);
  border-radius: 30px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);
  text-decoration: none;
`

interface ButtonColumnsProps {
  slice: {
    primary: {
      slice_id?: {
        text: string
      }
      background_type: string
      background_color?: string
      background_gradient_from?: string
      background_gradient_to?: string
      header: {
        text: string
      }
    }
    items: Array<Item>
  }
}

interface Item {
  button_text: {
    text: string
  }
  button_link: {
    url: string
  }
}

const ButtonsColumns = ({ slice }: ButtonColumnsProps) => {
  const { primary, items } = slice

  return (
    <Wrapper
      type={primary.background_type}
      color={primary.background_color}
      from={primary.background_gradient_from}
      to={primary.background_gradient_to}
      id={primary.slice_id?.text || ""}
    >
      <Header>{primary.header.text}</Header>
      <ButtonsWrapper>
        {items.map(item => (
          <PurpleButton key={item.button_text.text} to={item.button_link.url}>
            {item.button_text.text}
          </PurpleButton>
        ))}
      </ButtonsWrapper>
    </Wrapper>
  )
}

export const query = graphql`
  fragment PageButtonsColumnsSlice on PrismicPageDataBodyButtonsColumns {
    id
    slice_type
    primary {
      slice_id {
        text
      }
      background_type
      background_color
      background_gradient_from
      background_gradient_to
      header {
        text
      }
    }
    items {
      button_text {
        text
      }
      button_link {
        url
      }
    }
  }
`

export default ButtonsColumns
