import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { LeftItalicHeading } from "./LandingPageStyles"
import { TextContentStyle } from "./Text"
import Button from "../../common/Button"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 30px;
`

const TextAndColumnsWrapper = styled.div`
  display: flex;
  text-align: left;
  column-gap: 30px;
  ${TextContentStyle}

  & ul, ol {
    padding: 0;
    margin: 0;
    margin-left: 30px;
  }

  & ul {
    list-style-type: disc;
  }

  & ol {
    list-style-type: decimal;
  }

  & li {
    margin: 0;
  }

  @media (max-width: 834px) {
    flex-direction: column;
  }
`

const TextWrapper = styled.div`
  flex: 3;
`

const ColumnsWrapper = styled.div`
  display: flex;
  flex: 4;
  column-gap: 10px;

  @media (max-width: 600px) {
    flex-direction: column;
    row-gap: 20px;
    margin-left: 5px;
  }
`

const ColumnWrapper = styled.div`
  flex: 1;
`

const ButtonAbove834pxWrapper = styled.div`
  display: flex;

  @media (max-width: 834px) {
    display: none;
  }
`

const ButtonBelow834pxWrapper = styled.div`
  display: none;

  @media (max-width: 834px) {
    margin-top: 10px;
    display: flex;
  }
`

const CtaButton = styled(Button)`
  margin: 20px 0 0 !important;
  color: white !important;
`

interface TextAndTwoColumnsListInterface {
  slice: {
    primary: {
      slice_id: { text: string }
      heading: { text: string }
      text: { html: string }
      column_1: { html: string }
      column_2: { html: string }
      cta_link: { url: string }
      cta_text: { text: string }
    }
  }
}

const TextAndTwoColumnsList = ({ slice }: TextAndTwoColumnsListInterface) => {
  const { heading, text, column_1, column_2, cta_link, cta_text } =
    slice.primary

  const CtaButtonElement = (
    <CtaButton to={cta_link.url || "#"}>{cta_text.text || ""}</CtaButton>
  )

  return (
    <Wrapper id={slice.primary.slice_id?.text || ""}>
      {heading?.text && <LeftItalicHeading text={heading.text} />}

      <TextAndColumnsWrapper>
        <TextWrapper>
          <div dangerouslySetInnerHTML={{ __html: text.html }}></div>

          <ButtonAbove834pxWrapper>{CtaButtonElement}</ButtonAbove834pxWrapper>
        </TextWrapper>

        <ColumnsWrapper>
          {[column_1, column_2].map(column => (
            <ColumnWrapper dangerouslySetInnerHTML={{ __html: column.html }} />
          ))}
        </ColumnsWrapper>
      </TextAndColumnsWrapper>

      <ButtonBelow834pxWrapper>{CtaButtonElement}</ButtonBelow834pxWrapper>
    </Wrapper>
  )
}

export const query = graphql`
  fragment PageTextAndTwoColumnsListSlice on PrismicPageDataBodyTextAndTwoColumnsList {
    id
    slice_type
    primary {
      slice_id {
        text
      }
      heading {
        text
      }
      text {
        html
      }
      column_1 {
        html
      }
      column_2 {
        html
      }
      cta_link {
        url
      }
      cta_text {
        text
      }
    }
  }

  fragment PostTextAndTwoColumnsListSlice on PrismicPostDataBodyTextAndTwoColumnsList {
    id
    slice_type
    primary {
      heading {
        text
      }
      text {
        html
      }
      column_1 {
        html
      }
      column_2 {
        html
      }
      cta_link {
        url
      }
      cta_text {
        text
      }
    }
  }
`

export default TextAndTwoColumnsList
