import { ImageDataLike } from "gatsby-plugin-image"

interface PostCTA {
  title: string
  subtitle: string
  text: string
  link: string
  show_cta_button?: boolean
}

interface PostAuthorReviewer {
  name: string
  position: string
  picture: ImageDataLike
  link: string
  description?: string
  hide_info: boolean
}

class StringVariation {
  singularNormal //prescriber
  pluralNormal //prescribers
  singularCapitalize //Prescriber
  pluralrCapitalize //Prescribers
  singularUppercase //PRESCRIBER
  pluralUppercase //PRESCRIBERS

  constructor(string: string) {
    this.singularNormal = string
    this.pluralNormal = `${string}s`
    this.singularCapitalize = `${string.charAt(0).toUpperCase()}${string.slice(
      1
    )}`
    this.pluralrCapitalize = `${string.charAt(0).toUpperCase()}${string.slice(
      1
    )}s`
    this.singularUppercase = `${string.toUpperCase()}`
    this.pluralUppercase = `${string.toUpperCase()}S`
  }
}

const prescriberVariant = new StringVariation("prescriber")

/* eslint-disable */
const get = (value: object, path: string, defaultValue?: any): any => {
  return path.split(".").reduce((acc: any, v: string) => {
    try {
      acc = acc[v] !== undefined ? acc[v] : defaultValue
    } catch (e) {
      return defaultValue
    }
    return acc
  }, value)
}

const groupBy = (
  arr: Array<{ [key: string | number]: Array<{}> }>,
  criteria: string | ((param: object) => string)
) => {
  return arr.reduce((obj, item) => {
    // Check if the criteria is a function to run on the item or a property of it
    var key: string = (
      typeof criteria === "function" ? criteria(item) : item[criteria]
    ) as string

    // If the key doesn't exist yet, create it
    if (!obj.hasOwnProperty(key)) {
      obj[key] = []
    }

    // Push the value to the object
    obj[key].push(item)

    // Return the object to the next item in the loop
    return obj
  }, {})
}

const edgeToBody = ({ edge }: { edge: object }) =>
  get(edge, "node.data.body", "")

function edgeToPostPreview({ edge }: { edge: { node: { uid: string } } }) {
  const sliceContents = get(edge, "node.data.body", [])
  const firstTextSlice: any = sliceContents.find((item: any) =>
    Boolean(item.primary)
  )

  const subtitle = firstTextSlice?.primary.text.text

  return {
    title: get(edge, "node.data.title.text", ""),
    subtitle: get(edge, "node.data.content.text", subtitle),
    url: `/blog/${edge.node.uid}`,
    uid: edge.node.uid,
    categories: get(edge, "node.data.categories", []).map(
      (c: { category: string }) => c.category
    ),
    image: get(edge, "node.data.previewimage.gatsbyImageData", {}),
    date: get(edge, "node.data.date", ""),
  }
}

function extractCta({ edge }: { edge: object }) {
  const url = get(edge, "node.data.ctalink.url", "")
  const target = get(edge, "node.data.ctalink.target", "_blank")
  const text = get(edge, "node.data.ctatext.text", "")
  const size = get(edge, "node.data.ctasize.text", "big")

  const cta = url && text ? { url, text, target, size } : null

  return cta
}

function extractImage({ edge }: { edge: object }) {
  return get(edge, "node.data.image", null)
}

function extractPreviewImage({ edge }: { edge: object }) {
  return get(edge, "node.data.previewimage", null)
}

function extractSEO({
  edge,
  defaults = {},
}: {
  edge: object
  defaults: { title?: string; description?: string }
}) {
  const title = get(edge, "node.data.seotitle.text", defaults.title)
  const description = get(
    edge,
    "node.data.seodescription.text",
    defaults.description
  )
  const canonicalTag = get(edge, "node.data.seocanonicaltag.url", undefined)
  const hideFromSearchEngines = get(
    edge,
    "node.data.hide_from_search_engines",
    undefined
  )

  return { title, description, canonicalTag, hideFromSearchEngines }
}

function edgeToPost({ edge }: { edge: { node: { uid: string } } }) {
  const image = extractImage({ edge })
  const previewimage = extractPreviewImage({ edge })
  const title = get(edge, "node.data.title.text", "")
  const seo = extractSEO({ edge, defaults: { title } })
  const categories: Array<Record<string, string>> = get(
    edge,
    "node.data.categories",
    []
  )
  const contextual_cta_title = get(
    edge,
    "node.data.contextual_cta_title.text",
    ""
  )
  const contextual_cta_subtitle = get(
    edge,
    "node.data.contextual_cta_subtitle.text",
    ""
  )

  const author_picture = get(edge, "node.data.author_picture", {})
  const reviewer_picture = get(edge, "node.data.reviewer_picture", {})

  const author: PostAuthorReviewer = {
    name: get(edge, "node.data.author_name.text", ""),
    position: get(edge, "node.data.author_position.text", ""),
    picture: author_picture.url
      ? author_picture
      : author_picture.gatsbyImageData,
    link: get(edge, "node.data.author_link.url", ""),
    description: get(edge, "node.data.author_description.text", ""),
    hide_info: get(edge, "node.data.hide_author_info", false),
  }

  const reviewer: PostAuthorReviewer = {
    name: get(edge, "node.data.reviewer_name.text", ""),
    position: get(edge, "node.data.reviewer_position.text", ""),
    picture: reviewer_picture.url
      ? reviewer_picture
      : reviewer_picture.gatsbyImageData,
    link: get(edge, "node.data.reviewer_link.url", ""),
    hide_info: get(edge, "node.data.hide_reviewer_info", false),
  }

  const cta: PostCTA = {
    title: get(edge, "node.data.cta_title.text", ""),
    subtitle: get(edge, "node.data.cta_subtitle.text", ""),
    text: get(edge, "node.data.cta_text.text", ""),
    link: get(edge, "node.data.cta_link.url", ""),
  }

  return {
    title,
    html: get(edge, "node.data.content.html", ""),
    text: get(edge, "node.data.content.text", ""),
    url: `/${edge.node.uid}`,
    uid: edge.node.uid,
    cta,
    image,
    previewimage,
    date: get(edge, "node.data.date", ""),
    author,
    reviewer,
    seo,
    categories,
    contextual_cta_title,
    contextual_cta_subtitle,
  }
}

function edgeToAuthor({ edge }: { edge: object }) {
  const authorName = get(edge, "node.data.author_name.text", "")
  const authorImage = get(edge, "node.data.author_picture", {})
  const authorLink = get(edge, "node.data.author_link.url", "")
  const reviewerName = get(edge, "node.data.reviewer_name.text", "")
  const reviewerImage = get(edge, "node.data.reviewer_picture", {})
  const reviewerLink = get(edge, "node.data.reviewer_link.url", "")
  const date = get(edge, "node.data.date", "")

  return {
    authorName,
    authorImage,
    authorLink,
    reviewerName,
    reviewerImage,
    reviewerLink,
    date,
  }
}

function edgeToBio({ edge }: { edge: object }) {
  const authorName = get(edge, "node.data.author_name.text", "")
  const authorLink = get(edge, "node.data.author_link.url", "")
  const authorImage = get(edge, "node.data.author_picture.gatsbyImageData", {})
  const reviewerName = get(edge, "node.data.reviewer_name.text", "")
  const reviewerLink = get(edge, "node.data.reviewer_link.url", "")
  const reviewerImage = get(
    edge,
    "node.data.reviewer_picture.gatsbyImageData",
    {}
  )

  return {
    authorName,
    authorImage,
    reviewerName,
    reviewerImage,
    authorLink,
    reviewerLink,
  }
}

function edgeToResource({ edge }: { edge: { node: { uid: string } } }) {
  const image = extractImage({ edge })
  const cta = extractCta({ edge })
  const title = get(edge, "node.data.title.text", "")
  const seo = extractSEO({ edge, defaults: { title } })

  return {
    title,
    html: get(edge, "node.data.content.html", ""),
    url: `/${edge.node.uid}`,
    image,
    files: get(edge, "node.data.files", []),
    cta,
    seo,
  }
}

function edgeToPage({
  edge,
}: {
  edge: { node: { data: { style: string; files: string }; uid: string } }
}) {
  const image = extractImage({ edge })
  const { style, files } = edge.node.data
  const cta = extractCta({ edge })
  const title = get(edge, "node.data.title.text", "")
  const seo = extractSEO({ edge, defaults: { title } })
  const hideBanner = get(edge, "node.data.hide_banner", "")

  return {
    title,
    html: get(edge, "node.data.content.html", ""),
    cta,
    url: `/${edge.node.uid}`,
    image,
    style,
    files,
    seo,
    hideBanner,
  }
}

function edgeToFaq({ edge }: { edge: object }) {
  return {
    question: get(edge, "node.data.question.text", ""),
    answer: get(edge, "node.data.answer.html", ""),
    text: get(edge, "node.data.answer.text", ""),
    category: get(edge, "node.data.category", ""),
    parentCategory: get(edge, "node.data.parent_category", ""),
    order: get(edge, "node.data.order", ""),
  }
}

function toPage(data: { page: { edges: { 0: object } } }) {
  const {
    page: {
      edges: { 0: page },
    },
  } = data
  return {
    title: get(page, "node.data.title.text", ""),
    contents: get(page, "node.data.contents.html", ""),
  }
}

const r = new RegExp("^(?:[a-z]+:)?//", "i")

function isRelativeUrl({ to }: { to: string }): boolean {
  return !r.test(to)
}

function edgeToPromo({
  edge,
}: {
  edge: { data: { body: { primary: object }[] } }
}) {
  const base = {
    variation: get(edge, "uid", ""),

    //baner
    banner: get(edge, "data.banner_text.html", ""),
    "banner-medication-month": get(edge, "data.banner_text.html", ""),

    //under header cta
    "under-cta": get(edge, "data.under_cta_copy.html", ""),
    "medication-under-cta-month": get(edge, "data.under_cta_copy.html", ""),

    //what we offer section price
    "offer-price": get(edge, "data.under_image_copy.html", ""),
    "medication-offer-price-month": get(edge, "data.under_image_copy.html", ""),
    "strikethrough-price": get(edge, "data.strikethrough_price", false),

    //what we offer section under price
    "offer-image": get(edge, "data.text_under_price.html", ""),
    "medication-offer-image-month": get(edge, "data.text_under_price.html", ""),

    //plan component
    "plan-medication-price-month": get(edge, "data.plan_price.html", ""),
    "plan-medication-discounted-month": get(
      edge,
      "data.plan_under_price_text.html",
      ""
    ),
    "plan-therapy-2-sessions_monthly-price-month": get(
      edge,
      "data.plan_price.html",
      ""
    ),
    "plan-therapy-2-sessions_monthly-discounted-month": get(
      edge,
      "data.plan_under_price_text.html",
      ""
    ),

    //bottom cta
    "cta-button": get(edge, "data.cta_text.html", ""),

    //status
    expired: get(edge, "data.expired", false),
  }
  const slices = edge.data.body
  let sliceData = {}

  for (const index in slices) {
    const keys = Object.keys(slices[index].primary)

    if (keys.some(k => k === "therapy_plan_price")) {
      sliceData = {
        ...sliceData,
        "banner-therapy-month": get(edge, "data.banner_text.html", ""),
        "therapy-under-cta-month": get(
          slices[index],
          "primary.therapy_under_cta.text",
          ""
        ),
        "plan-therapy-price-month": get(
          slices[index],
          "primary.therapy_plan_price.text",
          ""
        ),
        "plan-therapy-discounted-month": get(
          slices[index],
          "primary.therapy_plan_under_price_text.text",
          ""
        ),
        "plan-therapy-discounted-month-split-screen": get(
          slices[index],
          "primary.therapy_plan_under_price_text.text",
          ""
        ),
      }
    }

    if (keys.some(k => k === "medication_therapy_plan_price")) {
      sliceData = {
        ...sliceData,
        "banner-medication-therapy-month": get(
          edge,
          "data.banner_text.html",
          ""
        ),
        "medication-therapy-under-cta-month": get(
          slices[index],
          "primary.medication_therapy_under_cta.text",
          ""
        ),
        "plan-medication-therapy-price-month": get(
          slices[index],
          "primary.medication_therapy_plan_price.text",
          ""
        ),
        "plan-medication-therapy-discounted-month": get(
          slices[index],
          "primary.medication_therapy_plan_under_price_text.text",
          ""
        ),
      }
    }

    if (keys.some(k => k === "coaching_plan_price")) {
      sliceData = {
        ...sliceData,
        "banner-medication-therapy-month": get(
          edge,
          "data.banner_text.html",
          ""
        ),
        "medication-therapy-under-cta-month": get(
          slices[index],
          "primary.coaching_under_cta.text",
          ""
        ),
        "plan-coaching-price-month": get(
          slices[index],
          "primary.coaching_plan_price.text",
          ""
        ),
        "plan-coaching-price-discounted-month": get(
          slices[index],
          "primary.coaching_plan_under_price_text.text",
          ""
        ),
      }
    }

    if (keys.some(k => k === "medication___coaching_plan_price")) {
      sliceData = {
        ...sliceData,
        "banner-medication-therapy-month": get(
          edge,
          "data.banner_text.html",
          ""
        ),
        "medication-therapy-under-cta-month": get(
          slices[index],
          "primary.medication___coaching_under_cta.text",
          ""
        ),
        "plan-medication-coaching-month": get(
          slices[index],
          "primary.medication___coaching_plan_price.text",
          ""
        ),
        "plan-medication-coaching-discounted-month": get(
          slices[index],
          "primary.medication___coaching_plan_under_price_text.text",
          ""
        ),
      }
    }
  }

  return {
    ...base,
    ...sliceData,
  }
}

const getVariantByName = (experimentName: string) => {
  let variationIdResult = "Disabled"

  if (typeof window !== "undefined" && window.Kameleoon && experimentName) {
    // Make sure Kameleoon is loaded and active at this point
    const experiment =
      window.Kameleoon.API.Experiments.getByName(experimentName)
    if (!experiment) {
      variationIdResult = "Disabled"
    } else if (experiment.associatedVariation.name === "Excluded") {
      // The experiment is not currently live (not launched yet, or paused). The default behavior should take place
      variationIdResult = "Excluded"
    } else {
      variationIdResult = experiment.associatedVariation.name
    }
  }
  return variationIdResult || "Reference"
}

const triggerEventKameleoon = (eventName: string) => {
  window && window.Kameleoon && window.Kameleoon.API.Events.trigger(eventName)
}

const generateLinks = (input: string[]): string => {
  let links = ""
  input.forEach((item, index) => {
    links += `<p><a href="#${index}">${item}</a></p>`
  })
  return links
}

const addIdForHeading = (input: string, id: string | number): string => {
  return `${input.substring(0, 3)} id="${id}"${input.substring(
    3,
    input.length
  )}`
}

const convertPrismicToAbleJumpLink = (html: string): string => {
  let result = html

  const regexHeading = RegExp(/(<h3>).+?(<\/h3>)/, "g")
  let matchDist
  let headings = []
  while ((matchDist = regexHeading.exec(html)) != null) {
    headings.push(matchDist[0])
  }

  headings.forEach((item, index) => {
    result = result.replace(item, addIdForHeading(item, index))
  })
  let links = generateLinks(
    headings.map(item => item.substring(12, item.length - 14))
  )
  result = result.replace("<h2>[jump links]</h2>", links)
  return result
}

const scrollToElement = (id: string) => {
  if (id.length > 0) {
    // ignore the Syntax error in case there is no #link to scroll to
    window.onerror = () => {
      document.querySelector(`#${id}`)?.scrollIntoView({
        behavior: "smooth",
      })
      return true
    }
  }
}

const paginate = (
  array: Array<any>,
  page_size: number,
  page_number: number
) => {
  return array.slice((page_number - 1) * page_size, page_number * page_size)
}

const SAFE_URL_PATTERN =
  /^(?:(?:https?|mailto|ftp|tel|file|sms):|[^&:/?#]*(?:[/?#]|$))/gi

/** A pattern that matches safe data URLs. It only matches image, video, and audio types. */
const DATA_URL_PATTERN =
  /^data:(?:image\/(?:bmp|gif|jpeg|jpg|png|tiff|webp)|video\/(?:mpeg|mp4|ogg|webm)|audio\/(?:mp3|oga|ogg|opus));base64,[a-z0-9+\/]+=*$/i

function _sanitizeUrl(url: string): string {
  url = String(url)
  if (url === "null" || url.length === 0 || url === "about:blank")
    return "about:blank"
  if (url.match(SAFE_URL_PATTERN) || url.match(DATA_URL_PATTERN)) return url

  return `unsafe:${url}`
}

function sanitizeUrl(url = "about:blank"): string {
  return _sanitizeUrl(String(url).trim())
}

function decodeHtmlCharCodes(string: string): string {
  return string
    .replace(/<\/?ul>|<li>/g, "")
    .replaceAll("&lt;", "<")
    .replaceAll("&gt;", ">")
    .split("</li>")
    .filter(Boolean)
    .join()
}

export {
  edgeToPostPreview,
  edgeToPost,
  edgeToFaq,
  isRelativeUrl,
  toPage,
  edgeToPromo,
  groupBy,
  edgeToResource,
  edgeToPage,
  edgeToBody,
  prescriberVariant,
  getVariantByName,
  triggerEventKameleoon,
  convertPrismicToAbleJumpLink,
  scrollToElement,
  paginate,
  sanitizeUrl,
  edgeToAuthor,
  edgeToBio,
  decodeHtmlCharCodes,
}
