import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

type ButtonVariant = "primary" | "outline"

export const EmailCollectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #eceffe 0%, #fef9ec 100%);
  padding: 64px;

  @media (max-width: 834px) {
    padding: 48px 24px;
  }
`

export const EmailCollectionTitle = styled.div`
  color: #1c1e21;
  text-align: center;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 16px;

  @media (max-width: 834px) {
    font-size: 24px;
    line-height: 24px;
  }
`

export const EmailCollectionSubtitle = styled.div`
  color: #353a41;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 42px;
`

export const CtaWrapper = styled.div<{ isSuccess?: boolean }>`
  display: grid;
  grid-template-columns: ${props => (props.isSuccess ? "1fr" : "1fr 1fr")};
  gap: 16px;
  width: 656px;

  @media (max-width: 834px) {
    grid-template-columns: 1fr;
    width: 100%;
  }
`

export const EmailInputContainer = styled.div`
  position: relative;
`

export const EmailInput = styled.input<{ isError?: boolean }>`
  color: #353a41;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 12px 16px 12px 48px;
  border-radius: 8px;
  border: 1px solid ${props => (props.isError ? "#d00e17" : "#e1e2e8")};
  background: #fff;
  width: 100%;
  height: 56px;

  :focus-visible {
    border: 1px solid ${props => (props.isError ? "#d00e17" : "#51459e")};
    outline: none;
  }
`

export const ErrorMessage = styled.div`
  color: #d00e17;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 4px;
`

export const EmailIcon = styled.img`
  position: absolute;
  left: 16px;
  top: 18px;
`
export const Button = styled.button<{ variant?: ButtonVariant }>`
  width: 100%;
  max-width: 320px;
  background: ${props => (props.variant === "outline" ? "#FFFFFF" : "#51459E")};
  color: ${props => (props.variant === "outline" ? "#51459E" : "#FFFFFF")};
  border: 2px solid #51459e;
  border-radius: 100px;
  height: 56px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  margin: 0 auto;

  &:disabled {
    background: #f0f1f5;
    color: #626874;
    cursor: not-allowed;
    border: none;
  }

  @media (max-width: 834px) {
    width: 100%;
    max-width: none;
  }
`

export const LatestPostWrapper = styled.div`
  width: 100%;
  background: rgb(242, 245, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 64px;

  @media (max-width: 834px) {
    padding-bottom: 48px;
  }
`

export const LatestPostContainer = styled.div`
  width: 992px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px 0 0 0;

  @media (max-width: 1024px) {
    width: 100%;
    padding: 48px 24px 0 24px;
  }
`

export const LatestPostTitle = styled.div`
  color: #1c1e21;
  text-align: center;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 40px;

  @media (max-width: 834px) {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 32px;
  }
`

export const PostsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  justify-content: space-between;
  align-items: center;
  gap: 32px;

  @media (max-width: 996px) {
    flex-direction: column;
    margin-bottom: 32px;
    justify-content: center;
  }
`

export const CardWrapper = styled.div`
  background: #ffffff;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  border-radius: 16px;
  width: 100%;
  max-width: 386px;
  cursor: pointer;

  @media (max-width: 1280px) {
    max-width: 386px;
  }
  @media (max-width: 1190px) {
    max-width: 357px;
  }
  @media (max-width: 1130px) {
    max-width: 338px;
  }
  @media (max-width: 1070px) {
    max-width: 314px;
  }
  @media (max-width: 996px) {
    margin-top: 0;
    width: calc(100vw - 48px);
    max-width: 100%;
  }
`

export const ListingCardWrapper = styled.a`
  background: #ffffff;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  border-radius: 16px;
  margin-top: 8px;
  width: 100%;
  max-width: 386px;

  @media (max-width: 1280px) {
    max-width: 386px;
  }
  @media (max-width: 1190px) {
    max-width: 357px;
  }
  @media (max-width: 1130px) {
    max-width: 338px;
  }
  @media (max-width: 1070px) {
    max-width: 314px;
  }

  @media (max-width: 996px) {
    margin-top: 0;
    width: calc(100vw - 48px);
    max-width: 100%;
  }
`

export const PostPreviewImage = styled(GatsbyImage)<{
  isListing?: boolean
}>`
  width: 100% !important;
  height: 240px !important;
  object-fit: cover;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-left-radius: ${prop => (prop.isListing ? "16px" : 0)};
  border-bottom-right-radius: ${prop => (prop.isListing ? "16px" : 0)};

  @media (max-width: 1024px) {
    height: 320px !important;
  }

  @media (max-width: 600px) {
    height: 240px !important;
  }
`

export const PostPreviewInfoWrapper = styled.div`
  padding: 24px;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const ListingPostPreviewInfoWrapper = styled.div`
  padding: 16px 24px 0px 0px;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const DateCategoriesWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  overflow: hidden;
  height: 24px;
`

export const Date = styled.div`
  color: #626874;
  font-family: Poppins;
  font-size: 14px;
  line-height: 24px;
`
export const Category = styled.div`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 12px;
  background: #e5ebff;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  color: #12106b;
`
export const PostTitle = styled.h5`
  color: #1c1e21;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  min-height: 40px;

  @media (max-width: 834px) {
    font-size: 18px;
    line-height: 24px;
  }
`

export const PostDescription = styled.div`
  color: #4d515a;
  font-family: Poppins;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 48px !important;
`
export const MentalHeathSupportWrapper = styled.div<{
  mobileGap?: string
  paddingTop?: string
}>`
  width: 992px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: ${({ paddingTop }) => (paddingTop ? paddingTop : "64px")} 0px 64px
    0px;
  gap: 80px;
  background: #ffffff;

  @media (max-width: 992px) {
    width: 100%;
    padding: ${({ paddingTop }) => (paddingTop ? paddingTop : "64px")} 32px 64px
      32px;
  }

  @media (max-width: 834px) {
    width: 100%;
    grid-template-columns: 1fr;
    padding: ${({ paddingTop }) => (paddingTop ? paddingTop : "48px")} 24px 48px
      24px;
    gap: ${({ mobileGap }) => (mobileGap !== undefined ? mobileGap : "48px")};
  }
`

export const MentalHeathSupportLeftWrapper = styled.div`
  display: flex;
  align-items: center;
  order: 0;

  @media (max-width: 834px) {
    order: 1;
  }
`

export const MentalHeathSupportContentWrapper = styled.div<{ maxW?: string }>`
  max-width: ${props => (props.maxW ? props.maxW : "418px")};
  display: flex;
  flex-direction: column;

  @media (max-width: 834px) {
    margin: 0 auto;
    text-align: center;
  }
`

export const MentalHeathSupportImage = styled.img`
  width: 100%;
  object-fit: cover;

  @media (max-width: 361px) {
    height: 312px;
  }
`

export const GBImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 834px) {
    justify-content: center;
  }
`

export const MentalHeathSupportGBImage = styled(GatsbyImage)`
  img {
    object-fit: contain !important;
  }

  @media (max-width: 1216px) {
    width: 456px !important;
    height: 436px !important;
  }

  @media (max-width: 920px) {
    width: 400px !important;
    height: 360px !important;
  }

  @media (max-width: 450px) {
    width: calc(100vw - 48px) !important;
    height: 312px !important;
  }
`

export const MentalHeathSupportTitle = styled.div`
  color: #1c1e21;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 16px;

  @media (max-width: 834px) {
    font-size: 24px;
    line-height: 24px;
  }
`

export const MentalHeathSupportSubtitle = styled.div`
  color: #353a41;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 32px;
`
export const EndBlogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SuccessWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`

export const SocialSharingWrapper = styled.div<{ isBanner?: boolean }>`
  display: flex;
  font-size: 16px;
  color: #513369;
  align-items: center;
  gap: 12px;

  @media (max-width: 1024px) {
    gap: ${props => (props.isBanner ? "5vw" : "")};
  }
`

export const SocialWrapper = styled.div<{
  isBanner?: boolean
  hasTitle?: boolean
}>`
  width: ${props => (props.hasTitle ? "fit-content" : "40px")};
  height: 40px;
  display: flex;
  padding: 0 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  border: 2px solid #51459e;
  cursor: pointer;

  @media (max-width: 1024px) {
    width: ${props => (props.isBanner ? "12vw" : "")};
    height: ${props => (props.isBanner ? "40px" : "")};
  }

  @media (max-width: 450px) {
    width: ${props => (props.isBanner ? "70px" : "")};
    height: ${props => (props.isBanner ? "32px" : "")};
    gap: ${props => (props.isBanner ? "16px" : "12px")};
  }
`
export const Copied = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
  margin: auto;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
`
export const CopyLink = styled.a<{ copied: boolean }>`
  position: relative;

  ${Copied} {
    opacity: ${({ copied }) => (copied ? "1" : "0")};
    transition: all 0.1s;
  }
`
export const SocialLink = styled.a``

export const ExpandedSocialButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`

export const SocialTitle = styled.div`
  color: #51459e;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-wrap: nowrap;
`

export const BannerWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 16px 0px;
  background: #f2f5ff;
`
export const BannerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1280px;
  align-items: center;
  gap: 179px;
  padding: 0 32px;

  @media (max-width: 1295px) {
    width: 100%;
    text-align: center;
    gap: 64px;
  }

  @media (max-width: 1165px) {
    gap: 32px;
  }

  @media (max-width: 1024px) {
    justify-content: center;
  }

  @media (max-width: 450px) {
    padding: 0;
  }
`

export const BannerText = styled.h6`
  color: #1c1e21;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  @media (max-width: 1024px) {
    display: none;
  }
`
export const LearnMore = styled.a`
  color: #51459e;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-decoration-line: underline;
  padding: 0 8px;
`

export const AuthorAndReviewerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-item: center;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 24px;
  }
`
export const AuthorAndReviewerLeftWrapper = styled.div`
  display: flex;
  gap: 48px;

  @media (max-width: 1110px) {
    gap: 24px;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 8px;
  }
`

export const TextAuthorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`

export const AuthorItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: 450px) {
    font-size: 14px;
  }
`

export const DefaultImageCircle = styled.img`
  width: 48px !important;
  height: 48px !important;
  border-radius: 100px;

  @media (max-width: 450px) {
    width: 32px !important;
    height: 32px !important;
  }
`

export const ImageCircle = styled(GatsbyImage)`
  width: 48px !important;
  height: 48px !important;
  border-radius: 100px;

  @media (max-width: 450px) {
    width: 32px !important;
    height: 32px !important;
  }
`
export const AuthorHyperlink = styled.a<{ hasLink?: boolean }>`
  color: #51459e;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-decoration-line: ${props => (props.hasLink ? "underline" : "none")};
  cursor: ${props => (props.hasLink ? "pointer" : "normal")};

  @media (max-width: 450px) {
    font-size: 14px;
  }
`
export const BioSectionWrapper = styled.div`
  display: flex;
  padding: 32px;
  margin-top: 32px;
  flex-direction: column;
  gap: 32px;
  align-self: stretch;
  border-radius: 16px;
  background: #fafafc;

  @media (max-width: 744px) {
    margin-left: 24px;
    margin-right: 24px;
  }

  @media (max-width: 450px) {
    padding: 32px 24px;
    margin-top: 50px;
  }
`
export const BioCard = styled.div`
  display: flex;
  flex-direction: column;
`

export const BioImage = styled(GatsbyImage)`
  width: 64px !important;
  height: 64px !important;
  border-radius: 100px;
`

export const BioTitle = styled.h4`
  color: #1c1e21;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 20px;
`
export const BioDescription = styled.div`
  color: #353a41;
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
  margin-top: 16px;

  @media (max-width: 600px) {
    font-size: 14px;
  }

  @media (max-width: 450px) {
    margin-top: 20px;
  }
`
export const HyperLinkWrapper = styled.div`
  max-width: 400px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ReadMore = styled.div`
  color: #51459e;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-decoration-line: underline;

  :visited {
    color: #51459e;
  }
`
export const CreatedDate = styled.span`
  color: #1c1e21;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`
export const HipaaWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 24px;
  position: relative;

  & > div:first-child {
    position: static;
    margin-bottom: 0;
    padding-left: 20px;
  }

  & > div:first-child .b-input {
    border: 1px solid #51459e;
  }
`
export const HipaaContent = styled.div`
  color: #353a41;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  @media (max-width: 834px) {
    font-size: 11px;
  }

  a {
    color: #385ade;
    text-decoration-line: underline;
  }
`
