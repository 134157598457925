import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  ImageDataLike,
} from "gatsby-plugin-image"
import StyledCta from "../ui/StyledCta"
import { scrollToElement } from "../../lib/utils"

const ContentWrapper = styled.div`
  position: relative;
  width: calc(100% - 32px);
  min-height: 240px;
  z-index: 1;
  padding: 60px 16px 16px;

  @media (min-width: 928px) {
    width: 896px;
    padding: 60px 0px;
  }
`

const TextContainer = styled.div`
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (min-width: 834px) {
    max-width: 440px;
  }
`

const Header = styled.div`
  * {
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    text-align: left;
  }

  em {
    font-style: italic;
    font-weight: 700;
  }
`

const SubHeader = styled.div`
  * {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
  p:not(:last-child) {
    margin-bottom: 16px;
  }

  strong {
    font-weight: 600;
  }

  span {
    display: inline-block !important;
  }

  span.strikethrough {
    text-decoration: line-through;
  }

  span.underline {
    text-decoration: underline;
  }
`

const CTAWrapper = styled.div`
  margin: 15px 0px;
`

const ImageContainer = styled.div<{ color: string }>`
  position: relative;
  top: auto;
  right: auto;
  width: 100%;
  max-height: 250px;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;

  * {
    height: 100%;
    width: 100%;
  }

  picture img {
    overflow: hidden;
    object-position: center top !important;
    object-fit: cover;
    @media (min-width: 834px) {
      object-position: center center !important;
    }
  }

  ::after {
    content: "";
    z-index: 1;
    height: 100%;
    width: 100%;
    position: absolute;
    background-image: linear-gradient(
      180deg,
      ${({ color }) => color || "#250044"} 0%,
      rgba(0, 0, 0, 0) 50%
    );
    @media (min-width: 834px) {
      background-image: linear-gradient(
        90deg,
        ${({ color }) => color || "#250044"} 0%,
        rgba(0, 0, 0, 0) 50%
      );
    }
  }

  @media (min-width: 834px) {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 60%;
    max-height: 100%;
  }
`

enum SliceStyleType {
  DARK_PURPLE = "Dark Purple",
  LIGHT_BLUE = "Light Blue",
  WHITE = "White",
  BLUE = "Blue",
  CUSTOM = "Custom",
}

interface SliceStyle {
  backgroundColor: string
  titleColor: string
  textColor: string
  linkColor: string
  ctaBackgroundColor: string
  ctaTextColor: string
  ctaHoverColor: string
}

const Wrapper = styled.div<SliceStyle>`
  background-color: ${({ backgroundColor }) => backgroundColor || "#250044"};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  ${Header} {
    color: ${({ titleColor }) => titleColor || "#fff"};
    strong {
      color: ${({ ctaBackgroundColor }) => ctaBackgroundColor || "#b172e6"};
    }
  }

  ${SubHeader} {
    color: ${({ textColor }) => textColor || "#fff"};
    a {
      color: ${({ linkColor }) => linkColor || "#b172e6"};
    }
  }
`

interface HeroWithCroppedBackgroundImageProps {
  slice: {
    primary: {
      slice_id?: {
        text: string
      }
      header: {
        html: string
      }
      sub_header: {
        html: string
      }
      slice_style: SliceStyleType
      background_color: string
      title_color: string
      text_color: string
      background_image: ImageDataLike
      show_cta_button: boolean
      cta_text: {
        text: string
      }
      cta_link?: {
        url?: string
      }
      cta_style: string
      cta_background_color: string
      cta_text_color: string
    }
  }
}

const HeroWithCroppedBackgroundImage = ({
  slice,
}: HeroWithCroppedBackgroundImageProps) => {
  const {
    header,
    sub_header,
    slice_style,
    background_image,
    background_color,
    title_color,
    text_color,
    show_cta_button,
    cta_link,
    cta_text,
    cta_style,
    cta_background_color,
    cta_text_color,
    slice_id,
  } = slice.primary

  const getCtaLink = (): string => {
    if (cta_link?.url?.includes("https://#")) {
      return cta_link.url.replace("https://#", "#")
    }
    return cta_link?.url || ""
  }

  const getSliceStyle = (style: SliceStyleType) => {
    switch (style) {
      case SliceStyleType.DARK_PURPLE:
        return {
          backgroundColor: "#250044",
          titleColor: "#FFF",
          textColor: "#FFF",
          linkColor: "#B172E6",
          ctaBackgroundColor: "#B172E6",
          ctaTextColor: "#FFF",
          ctaHoverColor: "#C18EEB",
        } as SliceStyle
      case SliceStyleType.LIGHT_BLUE:
        return {
          backgroundColor: "#E1E7FF",
          titleColor: "#250044",
          textColor: "#250044",
          linkColor: "#6786FF",
          ctaBackgroundColor: "#6786FF",
          ctaTextColor: "#FFF",
          ctaHoverColor: "#859EFF",
        } as SliceStyle
      case SliceStyleType.WHITE:
        return {
          backgroundColor: "#FFF",
          titleColor: "#6786FF",
          textColor: "#250044",
          linkColor: "#6786FF",
          ctaBackgroundColor: "#6786FF",
          ctaTextColor: "#FFF",
          ctaHoverColor: "#859EFF",
        } as SliceStyle
      case SliceStyleType.BLUE:
        return {
          backgroundColor: "#859EFF",
          titleColor: "#FFF",
          textColor: "#FFF",
          linkColor: "#250044",
          ctaBackgroundColor: "#FFF",
          ctaTextColor: "#6786FF",
          ctaHoverColor: "#E1E7FF",
        } as SliceStyle
      case SliceStyleType.CUSTOM:
        return {
          backgroundColor: background_color || "#250044",
          titleColor: title_color || "#FFF",
          textColor: text_color || "#FFF",
          linkColor: "#B172E6",
          ctaBackgroundColor: cta_background_color || "#B172E6",
          ctaTextColor: cta_text_color || "#FFF",
          ctaHoverColor: "#C18EEB",
        } as SliceStyle
      default:
        return {
          backgroundColor: "#250044",
          titleColor: "#FFF",
          textColor: "#FFF",
          linkColor: "#B172E6",
          ctaBackgroundColor: "#B172E6",
          ctaTextColor: "#FFF",
          ctaHoverColor: "#C18EEB",
        } as SliceStyle
    }
  }

  const sliceStyle = getSliceStyle(slice_style)
  const images = getImage(background_image) as IGatsbyImageData

  return (
    <Wrapper id={slice_id?.text || ""} {...sliceStyle}>
      <ContentWrapper>
        <TextContainer>
          <Header dangerouslySetInnerHTML={{ __html: header.html }}></Header>
          {sub_header.html && (
            <SubHeader dangerouslySetInnerHTML={{ __html: sub_header.html }} />
          )}
          {show_cta_button && (
            <CTAWrapper>
              <StyledCta
                style={cta_style}
                link={getCtaLink()}
                text={cta_text.text}
                backgroundColor={sliceStyle.ctaBackgroundColor}
                hoverColor={sliceStyle.ctaHoverColor}
                textColor={sliceStyle.ctaTextColor}
                onClick={() => scrollToElement(getCtaLink().replace("#", ""))}
              />
            </CTAWrapper>
          )}
        </TextContainer>
      </ContentWrapper>
      <ImageContainer color={sliceStyle.backgroundColor}>
        <GatsbyImage image={images} alt="" loading="eager" />
      </ImageContainer>
    </Wrapper>
  )
}

export const query = graphql`
  fragment PageHeroWithCroppedBackgroundImageSlice on PrismicPageDataBodyHeroWithCroppedBackgroundImage {
    id
    slice_type
    primary {
      slice_id {
        text
      }
      header {
        html
      }
      sub_header {
        html
      }
      slice_style
      background_color
      title_color
      text_color
      background_image {
        gatsbyImageData
      }
      show_cta_button
      cta_link {
        url
      }
      cta_text {
        text
      }
      cta_background_color
      cta_text_color
      cta_style
    }
  }
`

export default HeroWithCroppedBackgroundImage
