import styled from "styled-components"
import { graphql } from "gatsby"
import { SolidBlueCtaButton } from "./LandingPageStyles"

const Section = styled.div`
  padding: 0px 0px 136px 0px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #e1e2e8;

  @media (max-width: 1216px) {
    padding: 48px 24px;
  }

  @media (max-width: 834px) {
    padding: 0px 24px 64px;
  }
`

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  max-width: 1216px;
  background: linear-gradient(135deg, #eeecf5 0%, #c3cffc 100%);
  padding: 64px;
  border-radius: 16px;
  justify-content: space-between;

  @media (max-width: 834px) {
    padding: 48px 24px;
    flex-direction: column;
  }
`

const Title = styled.h2`
  color: #1c1e21;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;

  @media (max-width: 834px) {
    font-size: 24px;
    line-height: 28px;
  }
`

const SubTitle = styled.div`
  color: #353a41;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  max-width: 640px;
  margin-top: 16px;

  @media (max-width: 834px) {
    width: 100%;
    max-width: fit-content;
    margin: 24px 0 32px 0;
  }
`

interface HeaderWithRightCtaProps {
  slice: {
    primary: {
      heading: {
        text: string
      }
      copy: {
        text: string
      }
      cta_text: {
        text: string
      }
      cta_link: {
        url: string
      }
    }
  }
}

const HeaderWithRightCta = ({ slice }: HeaderWithRightCtaProps) => {
  const { heading, copy, cta_link, cta_text } = slice.primary

  return (
    <Section>
      <Container>
        <div>
          <Title>{heading.text}</Title>
          <SubTitle>{copy.text}</SubTitle>
        </div>

        <SolidBlueCtaButton to={cta_link.url || process.env.GATSBY_START_URL}>
          {cta_text.text}
        </SolidBlueCtaButton>
      </Container>
    </Section>
  )
}

export const query = graphql`
  fragment PageHeaderWithRightCtaSlice on PrismicPageDataBodyHeaderWithRightCta {
    slice_type
    primary {
      copy {
        text
      }
      cta_link {
        url
      }
      cta_text {
        text
      }
      heading {
        text
      }
    }
  }
`
export default HeaderWithRightCta
