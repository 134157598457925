export const contentHubQuotes = [
  {
    name: "Jacqueline",
    quote:
      "My medical team is professional, responsive, easy to reach, and I feel confident that my mental health issues will be managed properly. I have hope and don’t feel like like “just another case.” They see me as a person.",
  },
  {
    name: "Leanna",
    quote:
      "My overall experience with Cerebral has been exceptional - their customer service is great, responsive and always helpful..and my therapist has been life-changing!",
  },
  {
    name: "Mirella",
    quote:
      "I’ve spent months trying to find accessible therapy and prescribers for my medication. My provider was able to help me with my medication within our 30 min call. She was great and I just feel super grateful I found this app.",
  },
]
