import { UilCalendarAlt, UilUser } from "@iconscout/react-unicons"
import { GetStartedButton } from "../../../shared/GetStartedButton"
import CouplesTherapyIcon from "./images/couples-therapy.svg"
import {
  ButtonWrapper,
  Card,
  CardsWrapper,
  GettingStartedWrapper,
  Header,
  IconWrapper,
} from "./styled"

const Cards = [
  {
    title: "Create an account",
    description:
      "We’ll ask for your name, email, phone number, and some context about your experience with mental health care",
    icon: <UilUser />,
  },
  {
    title: "Invite your partner",
    description:
      "Provide your partner’s information so they can get relevant emails, intake assessments, session reminders, and calendar invites",
    icon: <img src={CouplesTherapyIcon} />,
  },
  {
    title: "Choose a clinician + book a session",
    description:
      "See all available clinicians in your area, read their bios, view their calendars, and book your first session",
    icon: <UilCalendarAlt />,
  },
]

export const GettingStarted = () => {
  return (
    <GettingStartedWrapper>
      <Header>
        <h2>How it works</h2>
        <p>
          Most clients are able to meet with a therapist within 3 days of
          signing up
        </p>
      </Header>
      <CardsWrapper>
        {Cards.map((card, index) => (
          <Card key={index}>
            <IconWrapper>{card.icon}</IconWrapper>
            <div>
              <h4>{card.title}</h4>
              <p>{card.description}</p>
            </div>
          </Card>
        ))}
      </CardsWrapper>
      <ButtonWrapper>
        <GetStartedButton text="Create my plan" height={56} />
      </ButtonWrapper>
    </GettingStartedWrapper>
  )
}
