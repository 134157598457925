import medicationImg from "./images/medication.webp"
import therapymedicationImg from "./images/therapy-medication.webp"
import therapyImg from "./images/therapy.webp"
import videoimg from "../../../shared/assets/video.svg"
import capsuleimg from "../../../shared/assets/capsule.svg"
import chartlineimg from "../../../shared/assets/chart-line.svg"
import couplestherapyImg from "../../../CouplesTherapyPage/images/phone-therapy.webp"
import heartpalmImg from "../../../CouplesTherapyPage/sections/StrengthenYourRelationship/images/c_heart-palm.svg"

export const contents = [
  {
    title: "Individual Therapy",
    link: "/plans/therapy/",
    subtitle: "45-minute sessions with a qualified therapist",
    body: "Meet 1-on-1 with a credentialed and compassionate therapist through video chat or over the phone (where permitted). Get a personalized treatment plan, set goals, and track your progress over time.",
    images: [
      {
        srcSet: therapyImg,
        height: "317.25",
        width: "456",
        name: "cw-therapy-desktop",
        media: "(min-width:1081px)",
      },
      {
        srcSet: therapyImg,
        width: "100%",
        height: "100%",
        name: "cw-therapy-mobile",
        media: "(max-width:1080px)",
      },
    ],
    weeklyPrice: "$74/week",
    monthlyPrice: "$295/mo",
    vector: videoimg,
    calloutBody:
      "83% of clients are able to meet with a therapist within 3 days of signing up.",
  },
  {
    title: "Medication",
    link: "/plans/medication/",
    subtitle: "Online psychiatry from a licensed prescriber",
    body: "Meet 1-on-1 with a licensed prescriber to get a clinical mental health evaluation and determine if medication is right for you. If diagnosed, you’ll discuss medication options and get a personalized treatment plan. If prescribed, prescriptions are sent to the pharmacy within 48 hours.</br><a href='https://cerebral.com/plans/medication#medications-we-offer'>Medications we offer</a>",
    images: [
      {
        srcSet: medicationImg,
        height: "auto",
        width: "456",
        name: "cw-medication-desktop",
        media: "(min-width:1081px)",
      },
      {
        srcSet: medicationImg,
        width: "100%",
        height: "100%",
        name: "cw-medication-mobile",
        media: "(max-width:1080px)",
      },
    ],
    weeklyPrice: "$24/week",
    monthlyPrice: "$95/mo",
    vector: capsuleimg,
    calloutBody:
      "Free and fast shipping available in most states through CerebralRx. Clients who use CerebralRx only spend an avg. of $20/mo on their medications.",
  },
  {
    title: "Therapy + Medication",
    subtitle: "Combine both for the most comprehensive care",
    body: "Meet 1-on-1 with a therapist and prescriber to take advantage of our most proactive offering. Medication helps reduce symptoms of mental health conditions, while therapy helps build long-term skills to manage them. Both treatments may work together to create better outcomes.",
    images: [
      {
        srcSet: therapymedicationImg,
        height: "358.43",
        width: "456",
        name: "cw-therapy-and-medication-desktop",
        media: "(min-width:1081px)",
      },
      {
        srcSet: therapymedicationImg,
        width: "100%",
        height: "100%",
        name: "cw-therapy-and-medication-mobile",
        media: "(max-width:1080px)",
      },
    ],
    monthlyPrice: "$386/mo",
    weeklyPrice: "$92/week",
    vector: chartlineimg,
  },
  {
    title: "Couples Therapy",
    link: "/plans/couples-therapy/",
    subtitle: "Safe and meaningful support for your relationship",
    body: "Work with your partner and a relationship therapist to strengthen your bond, navigate big life changes, and improve communication. Includes regular 45-minute video sessions and a personalized treatment plan.",
    images: [
      {
        srcSet: couplestherapyImg,
        height: "auto",
        width: "456",
        name: "cw-couples-therapy-desktop",
        media: "(min-width:1081px)",
      },
      {
        srcSet: couplestherapyImg,
        width: "100%",
        height: "100%",
        name: "cw-couples-therapy-mobile",
        media: "(max-width:1080px)",
      },
    ],
    weeklyPrice: "$82/week",
    monthlyPrice: "$325/mo",
    vector: heartpalmImg,
    calloutBody:
      "75% of couples come to Cerebral to get help improving communication with their partner",
  },
]
