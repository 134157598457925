import React from "react"
import { graphql } from "gatsby"
import TextLeft from "./TextLeft"

const TextRight = ({ slice }) => {
  return <TextLeft slice={slice} reversed={true} />
}

export const query = graphql`
  fragment TextRightSlice on PrismicLandingDataBodyTextRight {
    id
    slice_type
    primary {
      copy {
        html
      }
      title {
        text
      }
      image {
        gatsbyImageData(width: 190, height: 190)
      }
    }
  }
  fragment PageTextRightSlice on PrismicPageDataBodyTextRight {
    id
    slice_type
    primary {
      slice_id {
        text
      }
      copy {
        html
      }
      title {
        text
      }
      image {
        gatsbyImageData(width: 285)
      }
    }
  }
  fragment PostTextRightSlice on PrismicPostDataBodyTextRight {
    id
    slice_type
    primary {
      copy {
        html
      }
      title {
        text
      }
      image {
        gatsbyImageData(width: 285)
      }
    }
  }
  fragment TherapistTextRightSlice on PrismicTherapistDataBodyTextRight {
    id
    slice_type
    primary {
      copy {
        html
      }
      title {
        text
      }
      image {
        gatsbyImageData(width: 190, height: 190)
      }
    }
  }
  fragment ProviderTextRightSlice on PrismicProviderDataBodyTextRight {
    id
    slice_type
    primary {
      copy {
        html
      }
      title {
        text
      }
      image {
        gatsbyImageData(width: 190, height: 190)
      }
    }
  }
  fragment CounselorTextRightSlice on PrismicCounselorDataBodyTextRight {
    id
    slice_type
    primary {
      copy {
        html
      }
      title {
        text
      }
      image {
        gatsbyImageData(width: 190, height: 190)
      }
    }
  }
`

export default TextRight
