import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

interface BackgroundColorProps {
  from?: string
  to?: string
  type: string
  color?: string
}

const Wrapper = styled.div<BackgroundColorProps>`
  background-color: ${({ color }) => (color ? color : "white")};
  background-image: linear-gradient(
    135deg,
    ${props =>
        props.type === "gradient"
          ? props.from
            ? props.from
            : "rgba(207, 242, 240, 0.4)"
          : "rgba(0,0,0,0)"}
      0%,
    ${props =>
        props.type === "gradient"
          ? props.to
            ? props.to
            : "rgba(199, 191, 255, 0.4)"
          : "rgba(0,0,0,0)"}
      100%
  );
  background-color: white;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 16px;
`

const Header = styled.div`
  * {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    color: #250044;
  }

  strong {
    color: #b172e6;
  }

  em {
    font-style: italic;
    font-weight: 700;
  }
`

const SubHeader = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #250044;
  text-align: center;
  @media (min-width: 834px) {
    font-size: 19px;
  }
`

const CirclesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 16px;

  @media (min-width: 834px) {
    gap: 30px;
  }
`

const CircleHeader = styled.div`
  font-weight: 700;
  font-size: 60px;
  text-align: center;
  color: #6786ff;
`

const CircleCopy = styled.div`
  font-size: 14px;
  line-height: 20px;
  text-align: center;
`

interface CircleProps {
  percentage: number
}

const CircleWrapper = styled.div<CircleProps>`
  width: 252px;
  height: 252px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: conic-gradient(
    #e1e7ff ${({ percentage }) => `${percentage}%`},
    white 0%
  );
  border-radius: 100%;
  border: 4px solid #e1e7ff;
`

const CircleContainer = styled.div`
  box-sizing: border-box;
  background-color: white;
  border-radius: 100%;
  border: 4px solid #e1e7ff;
  width: 208px;
  height: 208px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Separator = styled.div`
  height: 128px;
  width: 3px;
  background-color: #c2cfff;
  display: none;

  @media (min-width: 940px) {
    display: block;
  }
`

interface ListOfPercentagesProps {
  slice: {
    primary: {
      slice_id?: {
        text: string
      }
      background_type: string
      background_color?: string
      background_gradient_from?: string
      background_gradient_to?: string
      header: {
        html: string
      }
      sub_header: {
        html: string
      }
    }
    items: Array<Circle>
  }
}

interface Circle {
  percentage: number
  description: {
    html: string
  }
}

const ListOfPercentages = ({ slice }: ListOfPercentagesProps) => {
  const { primary, items } = slice

  return (
    <Wrapper
      type={primary.background_type}
      color={primary.background_color}
      from={primary.background_gradient_from}
      to={primary.background_gradient_to}
      id={slice.primary.slice_id?.text || ""}
    >
      <Header dangerouslySetInnerHTML={{ __html: primary.header.html }} />
      {primary.sub_header && (
        <SubHeader
          dangerouslySetInnerHTML={{ __html: primary.sub_header.html }}
        />
      )}
      <CirclesWrapper>
        {items.map((item, i) => (
          <>
            <CircleWrapper key={item.percentage} percentage={item.percentage}>
              <CircleContainer>
                <CircleHeader>{item.percentage}%</CircleHeader>
                <CircleCopy
                  dangerouslySetInnerHTML={{ __html: item.description.html }}
                />
              </CircleContainer>
            </CircleWrapper>
            {i < items.length - 1 && <Separator />}
          </>
        ))}
      </CirclesWrapper>
    </Wrapper>
  )
}

export const query = graphql`
  fragment PageListOfPercentagesSlice on PrismicPageDataBodyListOfPercentages {
    id
    slice_type
    primary {
      slice_id {
        text
      }
      background_type
      background_color
      background_gradient_from
      background_gradient_to
      header {
        html
      }
      sub_header {
        html
      }
    }
    items {
      percentage
      description {
        html
      }
    }
  }
`

export default ListOfPercentages
