import { useState } from "react"
import { GetStartedButton } from "../../shared"
import logoImage from "../../../images/logo.svg"
import {
  InnerWrapper,
  NavBar,
  ButtonContainer,
  LoginButton,
  HamburgerIconWrapper,
  LogoWrapper,
  NavWrapper,
  MobileMenuWrapper,
} from "./styled"
import { Menu } from "./Menu"
import hamburgerMenu from "./assets/purple-hamburger.svg"
import { SiteBanner } from "./SiteBanner"
import { FTCBanner } from "./FTCBanner"
import BlogBanner from "../../../components/shared/Blog/BlogBanner"

interface NavigationProps {
  isBlog?: boolean
  isBannerShown?: boolean
  isButtonShown?: boolean
}

export const Navigation = ({
  isBlog,
  isButtonShown = true,
  isBannerShown = true,
}: NavigationProps) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [isSiteBannerLoading, setIsSiteBannerLoading] = useState(true)
  return (
    <>
      <NavWrapper>
        {!isBlog && isBannerShown && !isSiteBannerLoading && <FTCBanner />}
        {!isBlog && isBannerShown && (
          <SiteBanner setIsBannerLoading={setIsSiteBannerLoading} />
        )}
        <NavBar>
          <InnerWrapper>
            <Menu
              isMobileMenuOpen={isMobileMenuOpen}
              setIsMobileMenuOpen={setIsMobileMenuOpen}
              isBannerShown={isBannerShown}
            />
            <MobileMenuWrapper>
              <HamburgerIconWrapper onClick={() => setIsMobileMenuOpen(true)}>
                <img src={hamburgerMenu} alt="hamburger menu image" />
              </HamburgerIconWrapper>
              <LogoWrapper to="/">
                <img src={logoImage} alt="Cerebral logo" />
              </LogoWrapper>
            </MobileMenuWrapper>
            <ButtonContainer>
              {isButtonShown && (
                <>
                  <LoginButton href={process.env.GATSBY_LOGIN_URL}>
                    Log in
                  </LoginButton>
                  <GetStartedButton
                    height={40}
                    fontSize={12}
                    paddingHorizontal={14}
                  />
                </>
              )}
            </ButtonContainer>
          </InnerWrapper>
        </NavBar>
        {isBlog && isBannerShown && <BlogBanner />}
      </NavWrapper>
    </>
  )
}
