import { graphql } from "gatsby"
import styled from "styled-components"
import { IGatsbyImageData } from "gatsby-plugin-image"
import CheckIcon from "./images/check.svg"
import FiXIcon from "./images/fi_x.svg"

const Wrapper = styled.div`
  background: rgba(242, 245, 255, 0.5);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px 0;

  @media (max-width: 1024px) {
    padding: 48px 24px;
  }
`

const Container = styled.div`
  max-width: 992px;
`

const Title = styled.div`
  color: #1c1e21;
  text-align: center;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 16px;
`

const ListWrapper = styled.div`
  display: -webkit-inline-box;
  gap: 32px;
  flex-direction: column;
  margin-top: 64px;

  @media (max-width: 1024px) {
    margin-top: 24px;
    display: flex;
    gap: 24px;
  }
`

const ContentWrapper = styled.div`
  width: calc(50% - 16px);
  border-radius: 16px;
  box-shadow: 0px 0px 12px 0px rgba(28, 30, 33, 0.16);
  gap: 32px;

  @media (max-width: 1024px) {
    width: 100%;
  }
`

const Text = styled.div`
  color: #1c1e21;
  text-align: center;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;

  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 28px;
  }
`

const TextDescription = styled.div`
  color: #4d515a;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  max-width: 710px;
  margin: 0 auto;

  @media (max-width: 1024px) {
    font-size: 14px;
  }
`

const ItemTitle = styled.div`
  color: #250044;
  text-align: center;
  background-color: #e5ebff;
  padding: 12px 16px;
  border-radius: 16px 16px 0px 0px;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`

const Item = styled.div`
  display: inline-flex;
  gap: 12px;
  font-size: 16px;
`

const ItemText = styled.div`
  color: #4d515a;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  @media (max-width: 1024px) {
    font-size: 14px;
  }
`

const ItemContent = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
`

const CalloutWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  padding: 16px;
  margin-top: 48px;
  border-radius: 8px;
  background: linear-gradient(135deg, #caeaec 0%, #f6e9fe 100%);

  @media (max-width: 834px) {
    margin-top: 32px;
  }
`

const CalloutText = styled.div`
  color: #250044;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`

interface ContentHubMarrigeCounselingInterface {
  slice: {
    primary: {
      tab_id?: string
      mc_description: {
        text: string
      }
      mc_title: {
        text: string
      }
      callout_text: {
        text: string
      }
      callout_icon: {
        url: string
      }
    }
    items: {
      mc_section_title: {
        text: string
      }
      mc_session_content: {
        text: string
      }
      is_checked: boolean
    }[]
  }
}

const ContentHubMarrigeCounseling = ({
  slice,
}: ContentHubMarrigeCounselingInterface) => {
  const { items } = slice
  const { tab_id, mc_title, mc_description, callout_icon, callout_text } =
    slice.primary
  const [leftTitle, rightTitle] = new Set(
    items.map(item => item.mc_section_title.text)
  )

  const couplesTherapyItems = items.filter(
    item => item.mc_section_title.text === leftTitle
  )
  const marriageCounselingItems = items.filter(
    item => item.mc_section_title.text === rightTitle
  )

  return (
    <Wrapper id={tab_id}>
      <Container>
        <Title>
          <Text>{mc_title.text}</Text>
        </Title>
        <TextDescription>{mc_description.text}</TextDescription>
        <ListWrapper>
          <ContentWrapper>
            <ItemTitle>{leftTitle}</ItemTitle>
            <ItemContent>
              {couplesTherapyItems.map(({ mc_session_content, is_checked }) => (
                <Item>
                  <img
                    src={is_checked ? CheckIcon : FiXIcon}
                    width="24px"
                    height="24px"
                  />
                  <ItemText>{mc_session_content.text}</ItemText>
                </Item>
              ))}
            </ItemContent>
          </ContentWrapper>
          <ContentWrapper>
            <ItemTitle>{rightTitle}</ItemTitle>
            <ItemContent>
              {marriageCounselingItems.map(
                ({ mc_session_content, is_checked }) => (
                  <Item>
                    <img
                      src={is_checked ? CheckIcon : FiXIcon}
                      width="24px"
                      height="24px"
                    />
                    <ItemText>{mc_session_content.text}</ItemText>
                  </Item>
                )
              )}
            </ItemContent>
          </ContentWrapper>
        </ListWrapper>

        {callout_text?.text && (
          <CalloutWrapper>
            <img src={callout_icon.url} width="32px" height="32px" />
            <CalloutText>{callout_text.text}</CalloutText>
          </CalloutWrapper>
        )}
      </Container>
    </Wrapper>
  )
}

export const query = graphql`
  fragment PageContentHubTabMarriageCounselingSlice on PrismicPageDataBodyContentHubMarriageCounseling {
    id
    slice_type
    primary {
      tab_id
      tab_title {
        text
      }
      mc_description {
        text
      }
      mc_title {
        text
      }
      callout_text {
        text
      }
      callout_icon {
        url
      }
    }
    items {
      mc_section_title {
        text
      }
      mc_session_content {
        text
      }
      is_checked
    }
  }
`
export default ContentHubMarrigeCounseling
