import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { MintWrapper, ContentSection } from "./LandingPageStyles"
import { TextContentStyle } from "./Text"

const Content = styled(ContentSection)`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  display: block;
  color: #6789ff;
  padding: 50px;
  margin: 0;
  background: white;
  ${TextContentStyle}
`

interface CallOutBoxInterface {
  slice: {
    primary: {
      slice_id: {
        text: string
      }
      copy: {
        html: string
      }
    }
  }
}

const CallOutBox = ({ slice }: CallOutBoxInterface) => {
  const { copy } = slice.primary

  return (
    <MintWrapper id={slice.primary.slice_id?.text || ""}>
      <ContentSection>
        <Content dangerouslySetInnerHTML={{ __html: copy.html }} />
      </ContentSection>
    </MintWrapper>
  )
}

export const query = graphql`
  fragment CallOutBoxSlice on PrismicLandingDataBodyCallOutBox {
    id
    slice_type
    primary {
      copy {
        html
      }
    }
  }
  fragment PageCallOutBoxSlice on PrismicPageDataBodyCallOutBox {
    id
    slice_type
    primary {
      slice_id {
        text
      }
      copy {
        html
      }
    }
  }
  fragment PostCallOutBoxSlice on PrismicPostDataBodyCallOutBox {
    id
    slice_type
    primary {
      copy {
        html
      }
    }
  }
  fragment ProviderCallOutBoxSlice on PrismicProviderDataBodyCallOutBox {
    id
    slice_type
    primary {
      copy {
        html
      }
    }
  }
  fragment CounselorCallOutBoxSlice on PrismicCounselorDataBodyCallOutBox {
    id
    slice_type
    primary {
      copy {
        html
      }
    }
  }
  fragment TherapistCallOutBoxSlice on PrismicTherapistDataBodyCallOutBox {
    id
    slice_type
    primary {
      copy {
        html
      }
    }
  }
`

export default CallOutBox
