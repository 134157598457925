import React from "react"

import { useEffect, useRef, useState } from "react"
import {
  OurServicesWrapper,
  HeaderSection,
  Tabs,
  Tab,
  TherapyMedsWrapper,
  TabWrapper,
  InsuranceLink,
} from "./styled"
import { copy } from "./constants"
import { Section } from "./Section"
import { useWindowSize } from "../../../../components/hooks"

export enum TabEnum {
  MEDICATION = "medication",
  INDIVIDUAL_THERAPY = "individualTherapy",
  COUPLES_THERAPY = "couplesTherapy",
}

// using hardcoded values because the parallax effect makes it difficult to find the scroll position
const TAB_TOP_VALUES = {
  [TabEnum.MEDICATION]: 765,
  [TabEnum.INDIVIDUAL_THERAPY]: 1331,
  [TabEnum.COUPLES_THERAPY]: 1902,
}

const TAB_TOP_VALUES_MOBILE = {
  [TabEnum.MEDICATION]: 738,
  [TabEnum.INDIVIDUAL_THERAPY]: 1616,
  [TabEnum.COUPLES_THERAPY]: 2415,
}

export const OurServices = React.forwardRef(
  ({}, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { width } = useWindowSize()
    const isMobile = width < 769

    const medsSectionRef = useRef<HTMLDivElement | null>(null)
    const individualTherapySectionRef = useRef<HTMLDivElement | null>(null)
    const couplesTherapySectionRef = useRef<HTMLDivElement | null>(null)

    const [activeTab, setActiveTab] = useState<TabEnum>(TabEnum.MEDICATION)

    const scrollHandler = (tab: TabEnum) => {
      history.pushState(null, "", `#${tab}`)
      setActiveTab(tab)
      let top
      if (isMobile) {
        top = TAB_TOP_VALUES_MOBILE[tab]
      } else {
        top = TAB_TOP_VALUES[tab]
      }
      window.scrollTo({ top: top, behavior: "smooth" })
    }

    useEffect(() => {
      // scroll to section on page load
      if (window.location.hash) {
        const hash = window.location.hash.substring(1) as TabEnum
        if (Object.values(TabEnum).includes(hash)) {
          window.scrollTo({ top: TAB_TOP_VALUES[hash], behavior: "smooth" })
        }
      }
    }, [])

    useEffect(() => {
      const handleScroll = () => {
        if (
          medsSectionRef.current &&
          medsSectionRef.current?.getBoundingClientRect().top < 240
        ) {
          setActiveTab(TabEnum.MEDICATION)
        }

        if (
          individualTherapySectionRef.current &&
          individualTherapySectionRef.current?.getBoundingClientRect().top < 240
        ) {
          setActiveTab(TabEnum.INDIVIDUAL_THERAPY)
        }

        if (
          couplesTherapySectionRef.current &&
          couplesTherapySectionRef.current?.getBoundingClientRect().top < 240
        ) {
          setActiveTab(TabEnum.COUPLES_THERAPY)
        }
      }

      window.addEventListener("scroll", handleScroll)

      return () => {
        window.removeEventListener("scroll", handleScroll)
      }
    }, [medsSectionRef, individualTherapySectionRef, couplesTherapySectionRef])

    return (
      <div ref={ref}>
        <OurServicesWrapper>
          <HeaderSection>
            <h2>Our services</h2>
            <p>
              Paying with insurance?{" "}
              <InsuranceLink
                href="/insurance-partners?external_sources=pricing-page"
                target="_blank"
              >
                Check your coverage
              </InsuranceLink>
            </p>
          </HeaderSection>
          <TabWrapper>
            <Tabs>
              <Tab
                href={`#${TabEnum.MEDICATION}`}
                onClick={() => scrollHandler(TabEnum.MEDICATION)}
                isActiveTab={activeTab === TabEnum.MEDICATION}
              >
                Medication
              </Tab>
              <Tab
                href={`#${TabEnum.INDIVIDUAL_THERAPY}`}
                onClick={() => scrollHandler(TabEnum.INDIVIDUAL_THERAPY)}
                isActiveTab={activeTab === TabEnum.INDIVIDUAL_THERAPY}
              >
                {!isMobile && "Individual"} Therapy
              </Tab>
              <Tab
                href={`#${TabEnum.COUPLES_THERAPY}`}
                onClick={() => scrollHandler(TabEnum.COUPLES_THERAPY)}
                isActiveTab={activeTab === TabEnum.COUPLES_THERAPY}
              >
                Couples {!isMobile && "Therapy"}
              </Tab>
            </Tabs>
          </TabWrapper>
          <Section
            {...copy.medication}
            zIndex={1}
            ref={medsSectionRef}
            hideBoxShadow
          />
          <Section
            {...copy.individualTherapy}
            ref={individualTherapySectionRef}
            zIndex={2}
            hideBoxShadow={
              activeTab === TabEnum.INDIVIDUAL_THERAPY ||
              activeTab === TabEnum.COUPLES_THERAPY
            }
          />
          <Section
            {...copy.couplesTherapy}
            ref={couplesTherapySectionRef}
            zIndex={3}
            hideBoxShadow={activeTab === TabEnum.COUPLES_THERAPY}
          />
        </OurServicesWrapper>
        <TherapyMedsWrapper>
          <Section {...copy.therapyAndMedication} hideBoxShadow />
        </TherapyMedsWrapper>
      </div>
    )
  }
)
