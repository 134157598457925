import styled from "styled-components"
import {
  Backdrop,
  Buttons,
  StyledImage,
  SubscriptionPopup,
} from "../InsuranceModals/StateInsurancePopup/styled"
import subscriptionclose from "../../images/subscriptionclose.svg"
import { ModalButton } from "../InsuranceModals/StateInsurancePopup/styled"

const ModalText = styled.p`
  padding: 8px 40px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`

export const InsuranceModal = ({
  isModalOpen,
  closeModal,
}: {
  isModalOpen: boolean
  closeModal(): void
}) =>
  isModalOpen ? (
    <>
      <Backdrop
        data-testid="backdrop"
        isOpen={isModalOpen}
        onClick={closeModal}
      />
      <SubscriptionPopup data-testid="subscription-popup" isOpen={isModalOpen}>
        <div>
          <StyledImage
            data-testid="close-icon"
            src={subscriptionclose}
            onClick={closeModal}
          />
          <h4 style={{ textAlign: "left" }} data-testid="modal-title">
            What does your $30/mo subscription cost include?
          </h4>
          <ModalText data-testid="modal-text-1">
            This fee helps cover what it costs to keep our platform up and
            running — including regular maintenance of our online tools and
            unlimited messaging.
          </ModalText>
          <ModalText data-testid="modal-text-2">
            It gives you reliable access to features like easy online
            scheduling, session reminders, messaging with your therapist and
            care team, billing and account support, goal and progress tracking,
            and our knowledge resources.
          </ModalText>
        </div>
        <Buttons>
          <ModalButton data-testid="close-button" onClick={closeModal}>
            Close
          </ModalButton>
        </Buttons>
      </SubscriptionPopup>
    </>
  ) : null
