import {
  CerebralWaySection,
  Circle,
  CopySection,
  DesktopGetToKnow,
  ImageContainer,
  MobileGetToKnow,
  TheCerebralWayContainer,
  TheCerebralWayOuterContainer,
  VerticalLine,
} from "./styled"
import HomeScreen from "./images/home-screen.svg"
import ManDrinkingCoffee from "./images/man-drinking-coffee.webp"
import { BodyRegular, Button, H2, H4, H6 } from "../../../../ui"
import { SmallRatings } from "../../../../shared"

export const TheCerebralWay = () => {
  return (
    <TheCerebralWayOuterContainer>
      <TheCerebralWayContainer>
        <ImageContainer>
          <img src={HomeScreen} alt="" />
        </ImageContainer>
        <CopySection>
          <H2>Getting started</H2>
          <VerticalLine />
          <CerebralWaySection>
            <Circle>1</Circle>
            <div>
              <H4>Create an account</H4>
              <BodyRegular>
                Tell us a bit about yourself and we'll help you find the right
                plan to reach your mental health goals.
              </BodyRegular>
            </div>
          </CerebralWaySection>
          <CerebralWaySection>
            <Circle>2</Circle>
            <div>
              <H4>Choose a therapist, prescriber, or both</H4>
              <BodyRegular>
                See all therapists or prescribers available in your state — then
                see their schedules and book a session.
              </BodyRegular>
            </div>
          </CerebralWaySection>
          <DesktopGetToKnow>
            <img src={ManDrinkingCoffee} alt="" />
          </DesktopGetToKnow>
          <MobileGetToKnow>
            <img src={ManDrinkingCoffee} alt="" />
          </MobileGetToKnow>
          <CerebralWaySection>
            <Circle>3</Circle>
            <div>
              <H4>Full evaluation and treatment plan</H4>
              <BodyRegular>
                Meet with your clinician to learn and share more about your
                symptoms, set achievable goals, and build a plan to help you
                reach them.
              </BodyRegular>
            </div>
          </CerebralWaySection>
          <Button text="Start today" to={`${process.env.GATSBY_START_URL}`} />
          <SmallRatings />
        </CopySection>
      </TheCerebralWayContainer>
    </TheCerebralWayOuterContainer>
  )
}
