import styled from "styled-components"

export const BackDrop = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 1000;
  background-color: rgba(98, 104, 116, 0.6);
`

export const PopupWrapper = styled.div`
  width: 946px;
  height: 520px;
  z-index: 999;
  background: white;
  border-radius: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 20px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1001;

  @media (max-width: 768px) {
    width: 100%;
    height: 100vh;
    border-radius: 0px;
    border: none;
  }
`

export const CloseButton = styled.button`
  height: 26px;
  width: 26px;
  color: rgb(37, 0, 68);
  position: absolute;
  right: 25px;
  top: 25px;
  z-index: 99;
  cursor: pointer;
  border: none;
  background: none;
`

export const LeftBox = styled.div`
  background-color: rgb(203, 216, 255);
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0px;
  left: 0px;
  max-width: 438px;
  background-size: 180%;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;

  @media (max-width: 768px) {
    width: 100%;
    height: 250px;
    max-width: 100%;
    background-position-y: 15%;
    background-size: 100%;
  }
`

export const RightBox = styled.div`
  font-family: Poppins, sans-serif;
  position: absolute;
  right: 0;
  top: 80px;
  padding-right: 32px;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
    top: 250px;
    right: 0;
    padding: 24px;
    height: calc(100vh - 339px);
    overflow: scroll;
  }
`

export const Title = styled.div`
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
  color: #1c1e21;
  margin-bottom: 16px;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`

export const Term = styled.div`
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #353a41;
  text-align: left;
`

export const TermWrapper = styled.div`
  margin-top: 8px;

  @media (max-width: 768px) {
    position: absolute;
    bottom: 24px;
    width: calc(100% - 48px);
  }

  @media (max-width: 376px) {
    position: unset;
  }
`

export const TermWrapperCheckBox = styled.div`
  cursor: pointer;
  margin-top: 32px;

  @media (max-width: 768px) {
    position: absolute;
    bottom: 46px;
  }

  @media (max-width: 440px) {
    position: absolute;
    bottom: 60px;
  }

  @media (max-width: 376px) {
    position: unset;
  }
`

export const SubscribeButtonWrapper = styled.div`
  margin-top: 32px;

  @media (max-width: 768px) {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    padding: 16px 24px;
    border-top: 1px solid #e1e2e8;
  }
`

export const InputLineWrapper = styled.div`
  display: flex;
  gap: 10px;

  @media (max-width: 768px) {
    display: block;
  }
`

export const TermLink = styled.a`
  color: #385ade;
  text-decoration: underline;
`

export const Description = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #353a41;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`

export const FormWrapper = styled.div`
  margin-top: 32px;

  @media (max-width: 768px) {
    margin-top: 16px;
  }
`

export const InputContainer = styled.div`
  position: relative;
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }

  input,
  select {
    color: #1c1e21;
    height: 56px;
    border-radius: 8px;
  }

  img {
    top: 17px;
  }

  &:nth-child(2) > div:first-child {
    height: 56px;
    border-radius: 8px;

    & > div {
      padding: 20px 16px;
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`

export const ConfirmPolicy = styled.div`
  display: flex;
`

export const EnvelopeImage = styled.img`
  position: absolute;
  left: 16px;
  top: 11px;
`

export const Input = styled.input`
  color: var(--greyscale-cerebral-black, #250044);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  padding: 12px 16px 12px 48px;
  border-radius: 4px;
  border: 1px solid #e1e2e8;
  background: #fff;
  width: 100%;

  &:focus {
    outline: none;
  }

  /* Placeholder styles */
  &::placeholder {
    color: #626874;
  }
`

export const Button = styled.button`
  display: flex;
  width: 100%;
  height: 56px;
  padding: 12px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 28px;
  background: #51459e;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  border: none;
  cursor: pointer;
  &:disabled {
    background: #f0f1f5;
    color: #898e99;
    cursor: not-allowed;
  }
`

export const LeftText = styled.div`
  text-align: left;
`

export const ImageDescription = styled.div`
  color: #353a41;
  font-weight: 600;
  font-family: Poppins;
  display: flex;
  font-size: 11px;
  align-items: center;
  background-color: #fafafc;
  opacity: 0.95;
  border-radius: 12px;
  padding: 14px 16px;
  gap: 10px;
  width: 260px;
  position: absolute;
  bottom: 64px;
  left: 32px;

  @media (max-width: 768px) {
    bottom: 24px;
    left: 24px;
  }
`
