export const copy = [
  {
    stat: "83%",
    copy: "of clients are able to meet within 3 days of signing up",
  },
  {
    stat: "74%",
    copy: "of clients reported reduced depression in 3 months*",
  },
  {
    stat: "72%",
    copy: "of clients reported reduced anxiety in 3 months*",
  },
]
