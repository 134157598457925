import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

const QuoteContainer = styled.div`
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 100px;
  display: flex;
`
const Quote = styled.span`
  font-family: EB Garamond;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 55px;
  color: #250044;
`

const QuoteCharacter = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 900;
  font-size: 144px;
  line-height: 55px;
  color: #aabaf3;
  margin-right: -20px;
`

interface LargeQuoteInterface {
  slice: {
    primary: {
      slice_id: {
        text: string
      }
      quote: {
        text: string
      }
    }
  }
}

const LargeQuote = ({ slice }: LargeQuoteInterface) => {
  const { quote } = slice.primary

  return (
    <QuoteContainer id={slice.primary.slice_id?.text || ""}>
      <QuoteCharacter>“</QuoteCharacter>
      <Quote>{quote.text}</Quote>
    </QuoteContainer>
  )
}

export const query = graphql`
  fragment LargeQuoteSlice on PrismicLandingDataBodyLargeQuote {
    slice_type
    primary {
      quote {
        text
      }
    }
  }
  fragment PageLargeQuoteSlice on PrismicPageDataBodyLargeQuote {
    slice_type
    primary {
      slice_id {
        text
      }
      quote {
        text
      }
    }
  }
  fragment PostLargeQuoteSlice on PrismicPostDataBodyLargeQuote {
    slice_type
    primary {
      quote {
        text
      }
    }
  }
  fragment ProviderLargeQuoteSlice on PrismicProviderDataBodyLargeQuote {
    slice_type
    primary {
      quote {
        text
      }
    }
  }
  fragment CounselorLargeQuoteSlice on PrismicCounselorDataBodyLargeQuote {
    slice_type
    primary {
      quote {
        text
      }
    }
  }
  fragment TherapistLargeQuoteSlice on PrismicTherapistDataBodyLargeQuote {
    slice_type
    primary {
      quote {
        text
      }
    }
  }
`

export default LargeQuote
