import React from "react"
import { graphql } from "gatsby"
import { QuotesCarousel } from "../shared/QuotesCarousel"
import styled from "styled-components"
import { Source } from "../../components/shared/ContentHub/types"

const TestimonialsCardsWrapper = styled.div`
  background: #f2f5ff;
  padding: 96px 0;

  @media (max-width: 834px) {
    padding: 64px 0;
  }
`

const Title = styled.div`
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: #1c1e21;
  text-align: center;
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const SeeMoreButton = styled.a`
  color: #51459e;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`

interface ImageWithListTextRightProps {
  slice: {
    items: {
      name: {
        text: string
      }
      source: string
      text: {
        text: string
      }
    }[]
  }
}

const TestimonialsCards = ({ slice }: ImageWithListTextRightProps) => {
  const quotes = slice.items.map(item => ({
    quote: item.text.text,
    name: item.name.text,
    source: item.source as Source,
  }))

  return (
    <TestimonialsCardsWrapper>
      <Title>Success stories</Title>
      <QuotesCarousel quotes={quotes} isPrismic />
      <ButtonWrapper>
        <SeeMoreButton href="/testimonials">
          See what else people are saying
        </SeeMoreButton>
      </ButtonWrapper>
    </TestimonialsCardsWrapper>
  )
}

export const query = graphql`
  fragment TestimonialsCardsSlice on PrismicLandingDataBodyTestimonialsCards {
    slice_type
    items {
      name {
        text
      }
      source
      text {
        text
      }
    }
  }
`
export default TestimonialsCards
