import { prescriberVariant } from "../utils"
import { StateVariation } from "./types"

export const createPricing = (
  priceMonth: number,
  priceMonthDiscount: number
) => ({
  priceMonth,
  priceMonthDiscount,
  priceWeek: Math.ceil(priceMonth / 4.33),
  priceWeekDiscount: Math.ceil(priceMonthDiscount / 4.33),
})

export const newMedicationTherapyBullets = [
  "Choose the right therapist for your needs",
  "Deep, transformative weekly sessions with your therapist by phone or video",
  "Get an evaluation & diagnosis by a medical prescriber",
  "Develop an ongoing care plan together",
  "Medication prescribed as appropriate",
  "Regular check-ins with your prescriber by phone or video",
  "Convenient access to appointment scheduling, progress monitoring, messaging & self-care resources all in one place",
]
export const newMedication99Bullets = [
  "Get an evaluation & diagnosis by a medical prescriber",
  "Develop an ongoing care plan together",
  "Medication prescribed as appropriate",
  "Regular check-ins with your prescriber by phone or video",
  "Convenient access to appointment scheduling, progress monitoring, messaging & self-care resources all in one place",
]
export const newTherapyBullets = [
  "Choose the right therapist for your needs",
  "Deep, transformative weekly sessions with your therapist by phone or video",
  "Develop an ongoing care plan together",
  "Convenient access to appointment scheduling, progress monitoring, messaging & self-care resources all in one place",
]

const medicationTherapyPrices = createPricing(325, 139)
const medicationTherapyIncreasePrices = createPricing(365, 139)
const therapyPrices = createPricing(259, 99)
const therapyIncreasePrices = createPricing(295, 99)
const medicationCoachingPrices = createPricing(195, 79)
const coachingPrices = createPricing(145, 59)
const medicationPrices = createPricing(99, 99)
const medication99Prices = createPricing(99, 99)
const medication49Prices = createPricing(99, 49)
const medicationCoachingAbtestPrices = createPricing(210, 89)
const coachingAbtestPrices = createPricing(160, 70)
const firstAdHocTherapy = createPricing(259, 99)
const medicationTherapyM0Prices = createPricing(365, 175)
const therapyM0Prices = createPricing(295, 145)

const isPilotState = false

const defaultBanner = `Get 50% off during Mental Health Awareness Month!`

const usVariation = [
  {
    variation: "default",
    banner: defaultBanner,
    "under-cta": `Just $${medicationPrices.priceMonthDiscount} for your first month.`,
    "under-cta-without-discount": `Just $${medication99Prices.priceMonth} per month`,
    "under-cta-without-discount-49": `Just $${medication49Prices.priceMonthDiscount} for your first month`,
    "therapy-under-cta-month": `*Just $${therapyPrices.priceMonthDiscount} for your first month`,
    "therapy-under-cta-month-without-discount": `*Just $${therapyPrices.priceMonth} per month`,
    "medication-under-cta-month": `*Just $${medicationPrices.priceMonthDiscount} for your first month`,
    "medication-therapy-under-cta-month": `*Just $${medicationTherapyPrices.priceMonthDiscount} for your first month`,
    "medication-therapy-under-cta-month-without-discount": `*Just $${medicationTherapyPrices.priceMonth} per month`,
    "offer-image": `First month $${medicationPrices.priceMonthDiscount}`,
    "offer-image-99": `First month $${medicationPrices.priceMonthDiscount}`,
    "medication-offer-image-month": `First month $${medicationPrices.priceMonthDiscount}`,
    "medication-offer-image-month-99": `First month $${medicationPrices.priceMonthDiscount}`,
    "medication-offer-image-month-49": `First month $${medication49Prices.priceMonthDiscount}`,
    "medication-therapy-offer-image-month": `$${medicationTherapyPrices.priceMonthDiscount} for first month`,
    "cta-button": `Get started for only $${medicationPrices.priceMonthDiscount}`,
    "cta-button-without-discount": `Start today for $${medication99Prices.priceMonth}`,
    "cta-button-without-discount-49": `Get started for only $${medication49Prices.priceMonthDiscount}`,
    "therapy-cta-button": `Start therapy today for $${therapyPrices.priceMonthDiscount}`,
    "offer-price": `$${medicationPrices.priceMonth}/month`,
    "offer-price-99": `$${medication99Prices.priceMonth}/month`,
    "medication-offer-price-month": `$${medicationPrices.priceMonth}/month`,
    "medication-offer-price-month-99": `$${medication99Prices.priceMonth}/month`,
    "medication-offer-price-month-99-without-discount": `$${medication99Prices.priceMonth} per month`,
    "medication-therapy-offer-price-month": `$${medicationTherapyPrices.priceMonth}/month`,
    "medication-therapy-offer-increase-price-month": `$${medicationTherapyIncreasePrices.priceMonth}/month`,
    "medication-therapy-offer-price-month-without-discount": `$${medicationTherapyPrices.priceMonth} per month`,
    "strikethrough-price": true,
    "non-strikethrough-price": false,
    "banner-medication-therapy-month": `Get started for only $${medicationTherapyPrices.priceMonthDiscount}`,
    "banner-therapy-month": `Get started for only $${therapyPrices.priceMonthDiscount}`,
    "banner-medication": defaultBanner,
    "banner-medication-month": defaultBanner,
    "banner-default": defaultBanner,
    "plan-medication-therapy-price-month": `$${medicationTherapyPrices.priceMonth}/month`,
    "plan-medication-therapy-increase-price-month": `$${medicationTherapyIncreasePrices.priceMonth}/month`,
    "plan-medication-therapy-discounted-month": `$${medicationTherapyPrices.priceMonthDiscount} for first month`,
    "plan-medication-therapy-increase-price-discounted-month": `$${medicationTherapyIncreasePrices.priceMonthDiscount} for first month`,
    "plan-medication-therapy-increased-therapy-m0-price-month": `$${medicationTherapyM0Prices.priceMonth}/month`,
    "plan-medication-therapy-increased-therapy-m0-price-discounted-month": `$${medicationTherapyM0Prices.priceMonthDiscount}/month for first month`,
    "plan-therapy-increased-therapy-m0-price-month": `$${therapyM0Prices.priceMonth}/month`,
    "plan-therapy-increased-therapy-m0-price-discounted-month": `$${therapyM0Prices.priceMonthDiscount}/month for first month`,
    "plan-therapy-price-month": `$${therapyPrices.priceMonth}/month`,
    "plan-therapy-increase-price-month": `$${therapyIncreasePrices.priceMonth}/month`,
    "plan-therapy-price-month-split-screen": `$${therapyPrices.priceMonth} / mo`,
    "plan-therapy-discounted-month": `$${therapyPrices.priceMonthDiscount} for first month`,
    "plan-therapy-increase-price-discounted-month": `$${therapyIncreasePrices.priceMonthDiscount} for first month`,
    "plan-therapy-discounted-month-split-screen": `$${therapyPrices.priceMonthDiscount} for first month`,
    "plan-medication-coaching-price-month": `$${medicationCoachingPrices.priceMonth}/month`,
    "plan-medication-coaching-discounted-month": `$${medicationCoachingPrices.priceMonthDiscount}/month for first month`,
    "plan-coaching-price-month": `$${coachingPrices.priceMonth}/month`,
    "plan-coaching-discounted-month": `$${coachingPrices.priceMonthDiscount}/month for first month`,
    "plan-medication-price-month": `$${medicationPrices.priceMonth}/month`,
    "plan-medication-discounted-month": `$${medicationPrices.priceMonthDiscount} for first month`,
    "plan-medication-99-price-month": `$${medication99Prices.priceMonth}/month`,
    "plan-medication-99-discounted-month": `$${medication99Prices.priceMonthDiscount}/month for first month`,
    "plan-medication-49-discounted-month": `$${medication49Prices.priceMonthDiscount} for first month`,
    "plan-medication-coaching-abtest-price-month": `$${medicationCoachingAbtestPrices.priceMonth}/month`,
    "plan-medication-coaching-abtest-discounted-month": `$${medicationCoachingAbtestPrices.priceMonthDiscount}/month for first month`,
    "plan-coaching-abtest-price-month": `$${coachingAbtestPrices.priceMonth}/month`,
    "plan-coaching-abtest-discounted-month": `$${coachingAbtestPrices.priceMonthDiscount}/month for first month`,
    "plan-first-ad-hoc-therapy-price-month": `$${firstAdHocTherapy.priceMonth}/month`,
    "plan-first-ad-hoc-therapy-discounted-month": `$${firstAdHocTherapy.priceMonthDiscount}/month for first month`,
  },
  {
    variation: "EMPTY",
    "cta-button": "Get started",
    "offer-price": `$${therapyPrices.priceMonthDiscount}/month`,
  },
  {
    variation: "*",
    "cta-button": "Get started",
  },
]

const usStrings = {
  entity: "Cerebral Inc.",
  nationalSuicideHotlineAgency: "National Suicide Prevention Hotline",
  nationalSuicideHotlineNumber: "988",
  careTeamPage: {
    prescriberTitle: `Cerebral ${prescriberVariant.pluralrCapitalize}`,
    subTitle: `Our licensed ${prescriberVariant.pluralNormal} are experts in helping clients select the best medication for their specific needs. We believe that everyone deserves access to personalized, non-judgmental mental health care.`,
  },
  contactUsPage: {
    officeHours:
      "Office Hours:<br /><br />6 AM - 6 PM PT (Mon - Fri) <br /><br /> 7 AM - 4 PM PT (Sat - Sun)",
  },
  footer: {
    usefulLinks: [
      {
        text: "Anxiety Treatment",
        href: "/anxiety-treatment",
        additionalAttributes: {},
      },
      {
        text: "Depression Rx Treatment",
        href: "/blog/getting-antidepressants-online-what-you-need-to-know",
        additionalAttributes: {},
      },
      {
        text: "Refer A Friend",
        href: "/refer",
        additionalAttributes: {},
      },
      {
        text: "Newsroom",
        href: "https://news.cerebral.com/",
        additionalAttributes: {},
      },
    ],
  },
  what_we_offer_links: [
    { title: "Medication", path: "/plans/medication" },
    { title: "Therapy", path: "/plans/therapy" },
    { title: "Medication + Therapy", path: "/plans" },
  ],
  plansubprice: ["Billed monthly", "FSA / HSA eligible", "Cancel anytime"],
  plans: {
    "medication-therapy": {
      title: "Medication + <br /> Therapy</span>",
      bullets: [
        `Evaluation, diagnosis, and prescription by a medical ${prescriberVariant.singularNormal}`,
        "Weekly video/phone sessions with a licensed therapist",
        "Monthly medication delivery (if prescribed)",
        "Chat securely with your therapist anytime",
        `Regular progress tracking by ${prescriberVariant.singularNormal} & therapist`,
      ],
      cta: "Start Meds+Therapy today",
    },
    therapy: {
      title: "Therapy",
      bullets: [
        "Weekly video/phone sessions with a licensed therapist",
        "Chat securely with your therapist anytime",
        "Regular progress tracking by your therapist",
      ],
    },
    "medication-coaching": {
      title: "Medication + <br />Coaching</span>",
      bullets: [
        `Evaluation, diagnosis, and, if appropriate, prescription by a medical ${prescriberVariant.singularNormal}`,
        "Weekly video/phone sessions with your coach",
        "Monthly medication & delivery by mail included",
        "Text chat securely with your coach anytime",
        `Regular progress tracking by ${prescriberVariant.singularNormal} & coach`,
      ],
      cta: "Start Today",
    },
    coaching: {
      title: "Coaching",
      bullets: [
        "Weekly phone/video sessions with your coach",
        "Unlimited messaging with your coach",
        "Evidence-based behavioral health counseling",
        "Regular progress tracking by coach",
      ],
      cta: "Start Today",
    },
    medication: {
      title: "Medication + <br />Care Management</span>",
      bullets: [
        "Evaluation, diagnosis, and prescription by a medical prescriber",
        "Regular visits with your prescriber",
        "Monthly medication delivery (if prescribed)",
      ],
      cta: "Start Today",
    },
  },
  steps: [
    {
      title: "Fill out a free emotional assessment",
      description:
        "Answer a few quick questions to help us understand your symptoms.",
      imagePath: "/medication/intro-questions.webp",
    },
    {
      title: "Meet your care team",
      description:
        "Depending on which plan you choose, you'll meet virtually with a therapist, prescriber, or both.",
      imagePath: "/default/mobile-chat.webp",
    },
    {
      title: "Create your custom treatment plan",
      description: "Together, we’ll decide which treatment is right for you.",
      imagePath: "/default/person-on-tablet.webp",
    },
    {
      title: "Get your medication mailed monthly",
      description:
        "If prescribed, we'll ship your medication to your door, or to a pharmacy of your choice.",
      imagePath: "/default/pill-delivery.webp",
    },
    {
      title: "Regular check-ins",
      description:
        "We’ll monitor your progress and make sure everything is on track.",
      imagePath: "/default/mobile-care-chat.webp",
    },
    {
      title: "Keep the momentum going",
      description:
        "We'll continue to work with you on transformational behavioral strategies to help you feel like your best self again.",
      imagePath: "/default/mobile-assessments.webp",
    },
  ],
}

const usStateVariations: StateVariation = {
  default: {
    header_subtitle: `Help for anxiety, depression, and insomnia. Get ${prescriberVariant.singularNormal} visits, therapy, and prescriptions delivered to your door.`,
    header_subtitle_no_cs: `Help for anxiety, depression, and insomnia. Get ${prescriberVariant.singularNormal} visits, therapy, and prescriptions* delivered to your door.`,
    what_we_offer_description: `Regular assessments, video/phone appointments with your ${prescriberVariant.singularNormal}, ongoing Care Counselor sessions, & medication delivery (if prescribed) are all included in the price of your subscription.`,
    plan_medication_title: usStrings.plans.medication.title,
    plan_medication_therapy_title: usStrings.plans["medication-therapy"].title,
    plan_medication_coaching_title:
      usStrings.plans["medication-coaching"].title,
    plan_coaching_title: usStrings.plans.coaching.title,
  },
  pilot_state: {
    header_subtitle: `Help for anxiety & depression. Get ${prescriberVariant.singularNormal} visits, therapy and prescriptions delivered to your door.`,
    header_subtitle_no_cs: `Help for anxiety & depression. Get ${prescriberVariant.singularNormal} visits, therapy and prescriptions* delivered to your door.`,
    what_we_offer_description: `Regular assessments, video/phone appointments with your ${prescriberVariant.singularNormal}, medication management, and medication delivery are all included in the price of your subscription.`,
    plan_medication_title: "Medication &amp;<br /> Basic Care",
    plan_medication_therapy_title: usStrings.plans["medication-therapy"].title,
    plan_medication_coaching_title:
      usStrings.plans["medication-coaching"].title,
    plan_coaching_title: usStrings.plans.coaching.title,
  },
  adhd_state: {
    header_subtitle: `Help for anxiety, depression, insomnia, ADHD* and more. Get ${prescriberVariant.singularNormal} visits, therapy, and prescriptions delivered to your door.`,
    header_subtitle_no_cs: `Help for anxiety, depression, insomnia, ADHD and more. Get ${prescriberVariant.singularNormal} visits, therapy, and prescriptions* delivered to your door.`,
    what_we_offer_description: `Regular assessments, video/phone appointments with your ${prescriberVariant.singularNormal}, medication management, and medication delivery are all included in the price of your subscription.`,
    plan_medication_title: usStrings.plans.medication.title,
    plan_medication_therapy_title: usStrings.plans["medication-therapy"].title,
    plan_medication_coaching_title:
      usStrings.plans["medication-coaching"].title,
    plan_coaching_title: usStrings.plans.coaching.title,
  },
}

export { usVariation, usStrings, usStateVariations }
