import React from "react"
import { SuccessStories } from "../HomePage/sections"
import { graphql } from "gatsby"
import { Source } from "../../components/shared/ContentHub/types"

interface TestimonialsProps {
  slice: {
    primary: {}
    items: {
      item_copy: { text: string }
      item_header: { text: string }
      source: { text: Source }
      item_title: { text: string }
    }[]
  }
}

const Testimonials = ({ slice }: TestimonialsProps) => {
  const quotes = slice.items.map(item => ({
    quote: item.item_copy.text,
    name: item.item_header.text,
    source: item.source.text,
    title: item.item_title.text,
  }))

  return <SuccessStories items={quotes} variant="secondary" />
}

export const query = graphql`
  fragment PageTestimonialsSlice on PrismicPageDataBodyTestimonials {
    id
    slice_type
    items {
      item_title {
        text
      }
      item_copy {
        text
      }
      item_header {
        text
      }
      item_image {
        url
      }
      source {
        text
      }
    }
    primary {
      header {
        text
      }
    }
  }
`
export default Testimonials
