import { colors } from "../../../themes/colors"
import styled from "styled-components"

export const DialogContainer = styled.div`
  background: linear-gradient(135deg, #fef9ec 0%, #eceffe 100%);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  padding: 64px 24px;

  h2 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  }

  p {
    color: ${colors.c_grey[800]};
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }

  button {
    display: flex;
    max-width: 320px;
    width: 100%;
    height: 56px;
    padding: 0px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 28px;
    background: ${colors.c_primary[600]};
    color: ${colors.c_white[100]};
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    border: none;
    cursor: pointer;
  }
`
