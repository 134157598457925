import styled from "styled-components"

export const SectionDesc = styled.p`
  margin-top: 8px;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  @media (max-width: 834px) {
    padding: 0 24px;
  }
`
export const ContentContainer = styled.div`
  display: flex;
  margin-top: 64px;
  gap: 9%;
  & > div {
    display: flex;
    align-items: center;
  }
  & > div:last-child {
    margin: 0;
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    padding: 0 24px;
    & > div {
      margin-right: 0;
      margin-bottom: 56px;
    }
  }
`
export const TextContainer = styled.div`
  max-width: 272px;
  margin-left: 32px;
  text-align: start;
  & > h3 {
    margin-bottom: 8px;
  }
  & > p {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  }
`
