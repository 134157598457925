import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import {
  Container,
  CenterItalicHeading,
  MintWrapper,
  TopRoundedImageWrapper,
  InlineBoxContentSection,
  Headline,
  TitleSection,
} from "./LandingPageStyles"
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  ImageDataLike,
} from "gatsby-plugin-image"
import { TextContentStyle } from "./Text"

const Grid = styled.div<{ cols: number }>`
  display: grid;
  grid-gap: 18px;
  grid-template-columns: ${props =>
    props.cols
      ? `repeat(auto-fit, ${1080 / props.cols}px)`
      : "repeat(auto-fit, 200px);"};
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 50px;
`

const Wrapper = styled.div`
  display: inline-block;
  text-align: left;
  box-sizing: border-box;
`

const ContentWrapper = styled.div<{ cols: number }>`
  background-color: #fff;
  padding: 24px;
  color: #250044;
  font-family: Poppins;
  font-style: normal;
  font-size: ${props => (props.cols ? `${(3 / props.cols) * 18}px` : "18px")};
  line-height: ${props => (props.cols ? `${(3 / props.cols) * 30}px` : "30px")};
  height: 240px;
`

interface ItemProps {
  image: ImageDataLike
  copy: {
    html: string
  }
  cols: number
}

interface MultiColumnInterface {
  slice: {
    items: {
      image: ImageDataLike
      copy: {
        html: string
      }
    }[]
    primary: {
      copy: {
        html: string
      }
      title: {
        text: string
      }
    }
  }
  cols: number
}

const Item = ({ image, copy, cols }: ItemProps) => {
  const images = getImage(image) as IGatsbyImageData

  return (
    <Wrapper>
      <TopRoundedImageWrapper>
        <GatsbyImage image={images} alt="" loading="eager" />
      </TopRoundedImageWrapper>
      <ContentWrapper
        cols={cols}
        dangerouslySetInnerHTML={{ __html: copy.html }}
      />
    </Wrapper>
  )
}

const MultiColumn = ({ slice, cols }: MultiColumnInterface) => {
  const { items, primary } = slice
  const { copy, title } = primary

  const HeadLineWrap = styled(Headline)`
    ${TextContentStyle}
  `

  return (
    <MintWrapper>
      {title && title.text && (
        <TitleSection>
          <CenterItalicHeading text={title.text} />
        </TitleSection>
      )}
      {copy && copy.html && (
        <HeadLineWrap dangerouslySetInnerHTML={{ __html: copy.html }} />
      )}

      <Container>
        <InlineBoxContentSection>
          <Grid cols={cols}>
            {items.map((item, index) => (
              <Item
                key={index}
                image={item.image}
                copy={item.copy}
                cols={cols}
              />
            ))}
          </Grid>
        </InlineBoxContentSection>
      </Container>
    </MintWrapper>
  )
}

export default MultiColumn
