import { colors } from "../../../themes/colors"
import styled from "styled-components"

export const Container = styled.section`
  background-color: ${colors.c_secondary[50]};
  padding: 64px 24px;

  * {
    font-family: Poppins;
  }
`

export const ContentContainer = styled.div`
  max-width: 992px;
  margin: 0 auto;
  display: flex;
  gap: 51px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

export const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding-right: 105px;

  @media (max-width: 991px) {
    padding-right: 0px;
  }
`

export const TextSection = styled.div`
  display: flex;
  gap: 40px;
  flex-direction: column;
  @media (max-width: 768px) {
    align-items: center;
    font-size: 24px;
  }
`

export const TitleText = styled.h2`
  color: ${colors.c_black[900]};
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);
  font-family: Poppins;
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 28px; /* 116.667% */
  }
`

export const StatSection = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  @media (max-width: 768px) {
    max-width: 331px;
  }
`

export const Stat = styled.p`
  color: ${colors.c_secondary[700]};
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Poppins;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 59px; /* 105.357% */
`

export const StatDescription = styled.p`
  white-space: pre-wrap;
  line-height: 24px;
  font-size: 16px;
  font-weight: 500;
  max-width: 254px;
`

export const FinePrint = styled.p`
  color: ${colors.c_grey[700]};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-top: -16px;
  max-width: 389px;
  @media (max-width: 768px) {
    max-width: 327px;
  }
`
