import React from "react"
import { graphql } from "gatsby"
import MultiColumnsList from "./MultiColumnsList"

interface ThreeColumnsListInterface {
  slice: {
    primary: {
      column_list_1: { html: string }
      column_list_2: { html: string }
      column_list_3: { html: string }
    }
  }
}

const ThreeColumnsList = ({ slice }: ThreeColumnsListInterface) => {
  const { primary } = slice
  const columnLists = [
    primary.column_list_1?.html,
    primary.column_list_2?.html,
    primary.column_list_3?.html,
  ]

  return <MultiColumnsList slice={slice} columnLists={columnLists} />
}

export const query = graphql`
  fragment PostThreeColumnsListSlice on PrismicPostDataBodyThreeColumnsList {
    id
    slice_type
    primary {
      heading {
        text
      }
      column_list_1 {
        html
      }
      column_list_2 {
        html
      }
      column_list_3 {
        html
      }
      cta_link {
        url
      }
      cta_text {
        text
      }
      custom_bullet_glyph {
        url
      }
      use_custom_bullet_glyph
    }
  }

  fragment PageThreeColumnsListSlice on PrismicPageDataBodyThreeColumnsList {
    id
    slice_type
    primary {
      slice_id {
        text
      }
      heading {
        text
      }
      column_list_1 {
        raw
      }
      column_list_2 {
        raw
      }
      column_list_3 {
        raw
      }
      cta_link {
        url
      }
      cta_text {
        text
      }
      custom_bullet_glyph {
        gatsbyImageData
      }
      use_custom_bullet_glyph
    }
  }
`

export default ThreeColumnsList
