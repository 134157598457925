import React, { ReactNode } from "react"
import { H2, Button } from "../../ui"
import { Container } from "./styled"
import plantSvg from "../../../images/plant.svg"

interface RediscoverProps {
  backgroundColor?: string
  children?: ReactNode
  hidePlant?: boolean
  heading?: string
  padding?: string
  utm?: string
  link?: string
}
export const Rediscover = ({
  backgroundColor,
  children,
  hidePlant,
  heading = "Ready to rediscover your best self?",
  padding,
  utm,
  link = `${process.env.GATSBY_START_URL}${utm || ""}`,
}: RediscoverProps): JSX.Element => {
  return (
    <Container backgroundColor={backgroundColor} padding={padding}>
      <H2>{heading}</H2>
      <Button to={link} text="Get started" />
      {!hidePlant && <img src={plantSvg} loading="lazy" alt="" />}
      {children}
    </Container>
  )
}
