import styled from "styled-components"
import { colors } from "../../themes/colors"

export const Container = styled.div`
  display: flex;
  overflow-x: scroll;
  padding: 24px 40px 24px;
  gap: 16px;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  & > * {
    flex-shrink: 0;
    scroll-snap-align: center;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`

export const Indicators = styled.div`
  display: flex;
  justify-content: center;
  & > div {
    margin-right: 8px;
  }
  & > div:last-child {
    margin-right: 0;
  }
`

interface IndicatorDotProps {
  isActive: boolean
}
export const IndicatorDot = styled.div<IndicatorDotProps>`
  width: 8px;
  height: 8px;
  background: ${({ isActive }) =>
    isActive ? colors.c_primary[600] : "#aabaf9"};
  border-radius: 50%;
  display: block;
`
