import styled from "styled-components"
import { graphql } from "gatsby"
import {
  GatsbyImage,
  IGatsbyImageData,
  ImageDataLike,
  getImage,
} from "gatsby-plugin-image"
import InsuranceModals from "../InsuranceModals"

const Section = styled.div`
  padding: 96px;
  display: flex;
  justify-content: center;
  background: #f2f5ff;

  @media (max-width: 1216px) {
    padding: 48px 24px;
  }

  @media (max-width: 834px) {
    padding: 0px;
  }
`

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  max-width: 1216px;
  gap: 66px;
  margin-bottom: 56px;

  > div {
    flex: 1;
  }

  @media (max-width: 834px) {
    flex-direction: column;
    gap: 48px;
  }
`

const FlexItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 834px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1216px;
  padding: 0 64px;
  border-radius: 16px;
  justify-content: space-between;
  flex-direction: column;

  @media (max-width: 834px) {
    flex-direction: column;
    height: 100%;
    padding: 64px 24px;
  }
`

const Title = styled.h2`
  color: #1c1e21;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 56px;

  @media (max-width: 834px) {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 48px;
  }
`

const SubTitle = styled.h4`
  color: #1c1e21;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
`

const SubContent = styled.div`
  color: #4d515a;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  @media (max-width: 834px) {
    width: 100%;
    max-width: fit-content;
  }
`

const IconImageWrapper = styled.div`
  width: 48px;
  height: 48px;
  margin-bottom: 20px;
  max-width: 50px;

  @media (max-width: 834px) {
    width: 50px;
    height: 50px;
  }
`

const ItemContentWrapper = styled.div`
  text-align: center;
  @media (max-width: 834px) {
    text-align: left;
    width: 100%;
    max-width: fit-content;
    > h4 {
      text-align: left;
    }
    > div {
      text-align: left;
    }
  }
`

const IconImage = styled(GatsbyImage)`
  width: 100%;
  object-fit: contain;
  > div {
    max-width: 48px !important;
  }

  @media (max-width: 834px) {
    max-width: 40px !important;
  }
`

interface Step {
  sub_title: {
    text: string
  }
  sub_content: {
    text: string
  }
  sub_image: ImageDataLike
}

interface HeaderWithStepsProps {
  slice: {
    primary: {
      heading: {
        text: string
      }
      cta_text: {
        text: string
      }
      cta_link: {
        url: string
      }
    }
    items: Array<Step>
  }
}

const HeaderWithSteps = ({ slice }: HeaderWithStepsProps) => {
  const { heading, cta_link, cta_text } = slice.primary

  return (
    <Section>
      <Container>
        <div>
          <Title>{heading.text}</Title>
        </div>

        <FlexContainer>
          {slice.items.map((item: Step, index: number) => (
            <FlexItemContainer key={index}>
              <IconImageWrapper>
                <IconImage
                  image={getImage(item.sub_image) as IGatsbyImageData}
                  alt={heading.text}
                  loading="eager"
                />
              </IconImageWrapper>
              <ItemContentWrapper>
                <SubTitle>{item.sub_title.text}</SubTitle>
                <SubContent>{item.sub_content.text}</SubContent>
              </ItemContentWrapper>
            </FlexItemContainer>
          ))}
        </FlexContainer>
        <InsuranceModals cta_text={cta_text.text} cta_link={cta_link.url} />
      </Container>
    </Section>
  )
}

export const query = graphql`
  fragment PageHeaderWithStepsSlice on PrismicPageDataBodyHeaderWithSteps {
    slice_type
    primary {
      cta_link {
        url
      }
      cta_text {
        text
      }
      heading {
        text
      }
    }
    items {
      sub_title {
        text
      }
      sub_content {
        text
      }
      sub_image {
        gatsbyImageData
      }
    }
  }
`
export default HeaderWithSteps
