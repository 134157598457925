import React, { useEffect, useState } from "react"
import { Provider } from "react-redux"
import { store } from "../store"
import { VariationProvider } from "../lib/variations"
import Acquisition from "../lib/acquisition"
import { Footer } from "./sections/Footer"
import "./reset.css"
import Head from "./Head"
import { SiteWrapper } from "./styled"

function Layout({
  children,
  showFooter = true,
  useKameleoon = false,
  isShow = true,
}) {
  return (
    <Provider store={store}>
      <Head useKameleoon={useKameleoon} />
      <Acquisition />
      <VariationProvider>
        <SiteWrapper isShow={isShow}>
          {children}
          {showFooter && <Footer />}
        </SiteWrapper>
      </VariationProvider>
    </Provider>
  )
}

export default Layout
