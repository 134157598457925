import { SubItemList, SubMenuItem } from "../SubMenu/styled"
import RightChevron from "../assets/fi_chevron-right.svg"
import { Divider } from "../shared/styled"
import { CommunityWrapper } from "../Community/styled"
import { SubNavOptions } from "../types"

const mentalHealthTestItems: SubNavOptions[] = [
  {
    subMenuTitle: "Online anxiety test",
    link: "/online/free-online-anxiety-test",
  },
  {
    subMenuTitle: "Online ADHD test",
    link: "/online/online-adhd-self-test",
  },
  {
    subMenuTitle: "Online depression test",
    link: "/online/free-online-depression-test",
  },
  {
    subMenuTitle: "Online bipolar test",
    link: "/online/online-bipolar-disorder-screener",
  },
]

export const MentalHealthTest = () => {
  return (
    <CommunityWrapper>
      <h6>Free mental health tests</h6>
      <Divider showOnDesktop />
      <SubItemList>
        {mentalHealthTestItems.map(item => (
          <a href={item.link}>
            <SubMenuItem key={item.subMenuTitle}>
              <button>{item.subMenuTitle}</button>
              <img src={RightChevron} alt="right chevron arrow" />
            </SubMenuItem>
          </a>
        ))}
      </SubItemList>
    </CommunityWrapper>
  )
}
