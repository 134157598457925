import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image"
import { Carousel } from "../../components/ui"
import { GetStartedButton } from "../../components/shared"

const Wrapper = styled.div<{ bgColor?: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px 0;
  background-color: ${props => props.bgColor || "#fff"};

  @media (max-width: 1024px) {
    padding: 48px 24px;
  }
`

const Container = styled.div`
  max-width: 992px;
`

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
`
const SupportingText = styled.div<{ color?: string }>`
  max-width: 710px;
  font-family: Poppins;
  text-align: center;
  color: ${props => props.color || "#4d515a"};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 16px;

  strong {
    font-weight: 600;
  }

  @media (max-width: 1024px) {
    font-size: 14px;
    margin-top: 16px;
    max-width: 100%;
  }
`

const Title = styled.div`
  color: #1c1e21;
  text-align: center;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
`

const ListWrapper = styled.div<{ hasSubTitle: boolean }>`
  display: flex;
  gap: 32px;
  flex-direction: column;
  margin-top: ${({ hasSubTitle }) => (hasSubTitle ? "40px" : "32px")};
`

const ContentWrapper = styled.div`
  display: flex;
  gap: 32px;
`

const ListIconWrapper = styled.div`
  width: 40px;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #f6f4ff;
`

const Icon = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-family: Poppins;

  p,
  li {
    color: #4d515a;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;

    @media (max-width: 1024px) {
      font-size: 14px;
    }
  }

  p:not:first-child {
    margin-top: 8px;
  }

  strong {
    color: #4d515a;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
  }
`

const CallOutWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 32px;
  padding: 16px;
  border-radius: 8px;
  background: linear-gradient(135deg, #eceffe 0%, #fef9ec 100%);
`

const CallOutIconWraper = styled.div`
  width: 24px;
  height: 24px;
`

const CallOutText = styled.div`
  color: #4d515a;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  width: calc(100% - 40px);

  a {
    color: #385ade;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    text-decoration-line: underline;
  }
`

const MedicationWrapper = styled.div<{ hasSteps: boolean }>`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  margin-top: ${props => (props.hasSteps ? "48px" : "40px")};

  @media (max-width: 834px) {
    display: none;
  }
`

const MedicationItemWrapper = styled.div<{ bg_color?: string }>`
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 10px;
  background: ${props => props.bg_color};

  @media (max-width: 834px) {
    width: calc(100vw - 48px);
    min-height: 220px;
  }
`

const ServiceLineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  color: #1c1e21;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
`

const Medication = styled.div`
  color: #4d515a;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: start;

  a {
    color: #385ade;
    text-decoration-line: underline;
  }
  @media (max-width: 834px) {
    width: calc(100vw - 80px);
  }
`

const Note = styled.div<{ hasSteps: boolean }>`
  color: #4d515a;
  text-align: center;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);
  font-family: Poppins;
  font-size: ${props => (props.hasSteps ? "14px" : "12px")};
  font-weight: 400;
  line-height: ${props => (props.hasSteps ? "24px" : "16px")};
  margin-top: ${props => (props.hasSteps ? "32px" : "40px")};
  max-width: ${props => (props.hasSteps ? "687px" : "640px")};

  strong {
    font-weight: 600;
  }

  @media (max-width: 834px) {
    margin-top: 24px;
  }
`

export const CarouselWrapper = styled.div<{ hasSteps?: boolean }>`
  width: calc(100vw - 48px);
  text-align: center;
  overflow: hidden;
  margin-top: ${props => (props.hasSteps ? "48px" : "24px")};

  & > div > div:last-child {
    margin-top: 16px;
    justify-content: space-between;
  }

  & > div > div:last-child button:first-child {
    margin-left: 16px;
  }

  & > div > div:last-child button:last-child {
    margin-right: 16px;
  }

  & > div > div:last-child div div#indicator-active {
    width: 16px;
  }

  @media (min-width: 834px) {
    display: none;
  }
`

const NoteWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const ButtonWrapper = styled.div`
  margin: 0 auto;
  max-width: 250px;
  margin-top: 24px;

  @media (max-width: 834px) {
    max-width: fit-content;
  }
`

const MedicationTitle = styled.div<{ color?: string }>`
  color: ${props => props.color || "#1c1e21"};
`

const MedicationDescription = styled.div`
  color: #353a41;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 16px;
  white-space: break-spaces;
  text-align: start;
`

const StepText = styled(Text)`
  li {
    list-style-type: disc;
    margin-left: 24px;
  }
`

interface Item {
  overview_section_content: {
    html: string
  }
  overview_section_img: {
    gatsbyImageData: IGatsbyImageData
  }
  bg_color?: {
    text: string
  }
  item_key?: string
  item_title?: {
    text: string
  }
  item_description?: {
    text: string
  }
  title_color?: string
}

interface ContentHubTabOverviewInterface {
  slice: {
    primary: {
      overview_callout_text: {
        html: string
      }
      overview_callout: {
        url: string
      }
      tab_title: {
        text: string
      }
      tab_id?: string
      note?: {
        html: string
      }
      sub_title: {
        html: string
      }
      background_color: string
      subtitle_color: string
      cta_text: {
        text: string
      }
      cta_link: {
        url: string
      }
    }
    items: Item[]
  }
}

const MedicationCarousel = ({
  medications,
  steps,
}: {
  medications: Item[]
  steps: Item[]
}) => {
  return (
    <CarouselWrapper hasSteps={steps.length > 0}>
      <Carousel startingIndex={0}>
        {medications.map(
          ({
            overview_section_content,
            bg_color,
            overview_section_img,
            item_title,
            item_description,
            title_color,
          }) => (
            <MedicationItemWrapper bg_color={bg_color?.text}>
              <ServiceLineWrapper>
                <MedicationTitle color={title_color}>
                  {item_title?.text}
                </MedicationTitle>
                <Icon
                  image={getImage(overview_section_img) as IGatsbyImageData}
                  alt=""
                />
              </ServiceLineWrapper>
              {item_description?.text && (
                <MedicationDescription>
                  {item_description?.text}
                </MedicationDescription>
              )}
              <Medication>
                <Text
                  dangerouslySetInnerHTML={{
                    __html: overview_section_content.html,
                  }}
                />
              </Medication>
            </MedicationItemWrapper>
          )
        )}
      </Carousel>
    </CarouselWrapper>
  )
}

const ContentHubTabOverview = ({ slice }: ContentHubTabOverviewInterface) => {
  const {
    overview_callout_text,
    overview_callout,
    tab_title,
    tab_id,
    sub_title,
    note,
    background_color,
    subtitle_color,
    cta_text,
    cta_link,
  } = slice.primary
  const { items } = slice
  const medications = items.filter(({ item_key }) => item_key === "medication")
  const steps = items.filter(({ item_key }) => item_key === "step")

  return (
    <Wrapper id={tab_id} bgColor={background_color}>
      <Container>
        <Title>{tab_title.text}</Title>
        {medications?.length ? (
          <TextContainer>
            <SupportingText
              dangerouslySetInnerHTML={{ __html: sub_title.html }}
              color={subtitle_color}
            />
          </TextContainer>
        ) : (
          <></>
        )}

        {steps?.length ? (
          <ListWrapper hasSubTitle={!!sub_title.html}>
            {steps.map(({ overview_section_content, overview_section_img }) => (
              <ContentWrapper>
                <ListIconWrapper>
                  <Icon
                    image={getImage(overview_section_img) as IGatsbyImageData}
                    alt=""
                  />
                </ListIconWrapper>
                <StepText
                  dangerouslySetInnerHTML={{
                    __html: overview_section_content.html,
                  }}
                />
              </ContentWrapper>
            ))}
          </ListWrapper>
        ) : (
          <></>
        )}

        {overview_callout_text?.html && (
          <CallOutWrapper>
            <CallOutIconWraper>
              <Icon
                image={getImage(overview_callout) as IGatsbyImageData}
                alt=""
              />
            </CallOutIconWraper>
            <CallOutText
              dangerouslySetInnerHTML={{
                __html: overview_callout_text.html,
              }}
            />
          </CallOutWrapper>
        )}

        {medications?.length ? (
          <MedicationWrapper hasSteps={steps?.length > 0}>
            {medications.map(
              ({
                overview_section_content,
                bg_color,
                overview_section_img,
                item_title,
                title_color,
                item_description,
              }) => (
                <MedicationItemWrapper bg_color={bg_color?.text}>
                  <ServiceLineWrapper>
                    <MedicationTitle color={title_color}>
                      {item_title?.text}
                    </MedicationTitle>
                    {overview_section_img && (
                      <Icon
                        image={
                          getImage(overview_section_img) as IGatsbyImageData
                        }
                        alt=""
                      />
                    )}
                  </ServiceLineWrapper>
                  {item_description?.text && (
                    <MedicationDescription>
                      {item_description?.text}
                    </MedicationDescription>
                  )}
                  <Medication>
                    <Text
                      dangerouslySetInnerHTML={{
                        __html: overview_section_content.html,
                      }}
                    />
                  </Medication>
                </MedicationItemWrapper>
              )
            )}
          </MedicationWrapper>
        ) : (
          <></>
        )}

        {medications?.length ? (
          <MedicationCarousel medications={medications} steps={steps} />
        ) : (
          <></>
        )}

        {note?.html && (
          <NoteWrapper>
            <Note
              dangerouslySetInnerHTML={{ __html: note.html }}
              hasSteps={steps.length > 0}
            />
          </NoteWrapper>
        )}

        {cta_text.text && (
          <ButtonWrapper>
            <GetStartedButton text={cta_text.text} href={cta_link.url} />
          </ButtonWrapper>
        )}
      </Container>
    </Wrapper>
  )
}

export const query = graphql`
  fragment PageContentHubTabOverviewSlice on PrismicPageDataBodyContentHubTabOverview {
    id
    slice_type
    primary {
      overview_callout_text {
        html
      }
      overview_callout {
        gatsbyImageData
      }
      tab_title {
        text
      }
      tab_id
      note {
        html
      }
      sub_title {
        html
      }
      background_color
      subtitle_color
      cta_text {
        text
      }
      cta_link {
        url
      }
    }
    items {
      overview_section_content {
        html
      }
      overview_section_img {
        gatsbyImageData(placeholder: BLURRED, layout: FIXED)
      }
      bg_color {
        text
      }
      item_key
      item_title {
        text
      }
      title_color
      item_description {
        text
      }
    }
  }
`

export default ContentHubTabOverview
