import styled from "styled-components"

export const EmergencyInfoWrapper = styled.section`
  background: #f7f8fc;
  display: flex;
  justify-content: space-around;
  padding: 48px 24px;
  font-family: Poppins;
  @media (max-width: 499px) {
    padding: 48px 24px;
  }
`

export const EmergencyInfoInnerWrapper = styled.div`
  display: inline-flex;
  max-width: 992px;
  width: 100%;

  & > div {
    flex: 1;
  }
  & > div:last-child {
    margin-right: 0;
  }
  @media (max-width: 834px) {
    flex-direction: column;
    align-items: center;
    & > div {
      margin-right: 0;
      margin-bottom: 1.81em;
    }
    & > div:last-child {
      margin-bottom: 0;
    }
  }
`

export const BodyWrapper = styled.div`
  text-align: center;
  line-height: 20px;
  font-weight: 300;
  font-size: 14px;
  strong {
    font-weight: 500;
    color: #51459e;
  }
  @media (max-width: 834px) {
    & > p br {
      display: none;
    }
  }
`
