import React from "react"
import { graphql } from "gatsby"

interface SpaceInterface {
  slice: {
    primary: {
      slice_id: {
        text: string
      }
      amount: number
      unit: string
    }
  }
}

const Space = ({ slice }: SpaceInterface) => {
  const amount = slice.primary.amount || 50
  const unit = slice.primary.unit || "px"
  const style = {
    height: amount + unit,
  }

  return <div id={slice.primary.slice_id?.text || ""} style={style}></div>
}

export const query = graphql`
  fragment PageSpaceSlice on PrismicPageDataBodySpace {
    id
    slice_type
    primary {
      slice_id {
        text
      }
      amount
      unit
    }
  }

  fragment PostSpaceSlice on PrismicPostDataBodySpace {
    id
    slice_type
    primary {
      amount
      unit
    }
  }
`

export default Space
