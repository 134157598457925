import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  ImageDataLike,
} from "gatsby-plugin-image"

interface BackgroundColorProps {
  from?: string
  to?: string
  type: string
  color?: string
}

const Wrapper = styled.div<BackgroundColorProps>`
  background-color: ${({ color }) => (color ? color : "white")};
  background-image: linear-gradient(
    135deg,
    ${props =>
        props.type === "gradient"
          ? props.from
            ? props.from
            : "rgba(207, 242, 240, 0.4)"
          : "rgba(0,0,0,0)"}
      0%,
    ${props =>
        props.type === "gradient"
          ? props.to
            ? props.to
            : "rgba(199, 191, 255, 0.4)"
          : "rgba(0,0,0,0)"}
      100%
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 36px;
  padding: 48px 24px;

  @media (min-width: 834px) {
    padding: 72px min(340px, 10%);
    gap: 72px;
  }
`

const Header = styled.h2`
  width: 100%;
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  text-align: center;
  color: #250044;

  @media (min-width: 834px) {
    font-size: 35px;
    line-height: 50px;
  }
`

const DesktopImageContainer = styled.div<{ has_mobile_image: boolean }>`
  position: relative;
  display: ${has_mobile_image => (has_mobile_image ? "none" : "flex")};
  justify-content: center;
  align-items: center;

  picture img {
    object-position: center center !important;
    object-fit: contain;
  }

  @media (min-width: 834px) {
    display: flex;
  }
`

const MobileImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  picture img {
    object-position: center center !important;
    object-fit: contain;
  }

  @media (min-width: 834px) {
    display: none;
  }
`

interface ImageWithHeaderProps {
  slice: {
    primary: {
      slice_id?: {
        text: string
      }
      background_type: string
      background_color?: string
      background_gradient_from?: string
      background_gradient_to?: string
      header: {
        text: string
      }
      desktop_image: ImageDataLike
      mobile_image?: ImageDataLike
    }
  }
}

const ImageWithHeader = ({ slice }: ImageWithHeaderProps) => {
  const {
    background_gradient_from,
    background_gradient_to,
    background_type,
    background_color,
    header,
    desktop_image,
    mobile_image,
  } = slice.primary

  const desktopImages = getImage(desktop_image) as IGatsbyImageData
  const mobileImages =
    mobile_image && (getImage(mobile_image) as IGatsbyImageData)

  return (
    <Wrapper
      type={background_type}
      color={background_color}
      from={background_gradient_from}
      to={background_gradient_to}
      id={slice.primary.slice_id?.text || ""}
    >
      <Header>{header.text}</Header>
      <DesktopImageContainer has_mobile_image={mobileImages != undefined}>
        <GatsbyImage image={desktopImages} alt="" loading="eager" />
      </DesktopImageContainer>
      {mobileImages && (
        <MobileImageContainer>
          <GatsbyImage image={mobileImages} alt="" loading="eager" />
        </MobileImageContainer>
      )}
    </Wrapper>
  )
}

export const query = graphql`
  fragment PageImageWithHeaderSlice on PrismicPageDataBodyImageWithHeader {
    id
    slice_type
    primary {
      slice_id {
        text
      }
      background_type
      background_color
      background_gradient_from
      background_gradient_to
      header {
        text
      }
      desktop_image {
        gatsbyImageData
      }
      mobile_image {
        gatsbyImageData
      }
    }
  }
`

export default ImageWithHeader
