import styled from "styled-components"
import { colors } from "../../themes/colors"
import { InsuranceWidgetStyle } from "./constants"

export const InsuranceWidgetWrapper = styled.div<{
  style: InsuranceWidgetStyle
}>`
  display: flex;
  justify-content: center;
  padding: 64px 0 40px;
  background-color: ${({ style }) => style.backgroundColor};
  * {
    font-family: Poppins;
  }

  @media (max-width: 768px) {
    padding: 64px 0 32px;
  }
`

export const InsuranceWidgetInnerWrapper = styled.div<{
  style: InsuranceWidgetStyle
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px;
  max-width: 1024px;
  width: 100%;
  text-align: center;
`

export const InsuranceWidgetTitle = styled.h2<{ style: InsuranceWidgetStyle }>`
  color: ${colors.c_black[900]};
  text-align: center;
  font-size: ${({ style }) => style.titleFontSize};
  font-weight: 600;
  line-height: 125%;
  margin-bottom: 16px;

  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 30px;
  }
`

export const InsuranceWidgetSubTitle = styled.p<{
  style: InsuranceWidgetStyle
}>`
  color: ${({ style }) => style.subTitleColor};
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  max-width: 600px;
  margin: 0 auto 32px;
`

export const LearnMoreText = styled.p<{ style: InsuranceWidgetStyle }>`
  color: ${({ style }) => style.learMoreTextColor};
  font-size: ${({ style }) => style.learnMoreTextFontSize};
  font-style: normal;
  font-weight: 400;
  line-height: ${({ style }) => style.learnMoreTextLineHeight};
  text-align: center;
  ${({ style }) => style.showMiddleLogos && "margin-bottom: 40px;"}
  a {
    color: ${colors.c_secondary[600]};
    font-size: ${({ style }) => style.learnMoreTextFontSize};
    text-decoration-line: underline;
  }
`
