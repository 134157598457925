import { colors } from "../../../themes/colors"

const RightArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={colors.c_primary[900]}
    >
      <path
        d="M9.16994 6.99981C8.98369 7.18717 8.87915 7.44062 8.87915 7.70481C8.87915 7.96899 8.98369 8.22244 9.16994 8.40981L12.7099 11.9998L9.16994 15.5398C8.98369 15.7272 8.87915 15.9806 8.87915 16.2448C8.87915 16.509 8.98369 16.7624 9.16994 16.9498C9.26291 17.0435 9.37351 17.1179 9.49537 17.1687C9.61723 17.2195 9.74793 17.2456 9.87994 17.2456C10.012 17.2456 10.1427 17.2195 10.2645 17.1687C10.3864 17.1179 10.497 17.0435 10.5899 16.9498L14.8299 12.7098C14.9237 12.6168 14.9981 12.5062 15.0488 12.3844C15.0996 12.2625 15.1257 12.1318 15.1257 11.9998C15.1257 11.8678 15.0996 11.7371 15.0488 11.6152C14.9981 11.4934 14.9237 11.3828 14.8299 11.2898L10.5899 6.99981C10.497 6.90608 10.3864 6.83169 10.2645 6.78092C10.1427 6.73015 10.012 6.70401 9.87994 6.70401C9.74793 6.70401 9.61723 6.73015 9.49537 6.78092C9.37351 6.83169 9.26291 6.90608 9.16994 6.99981Z"
        fill="#706897"
      />
    </svg>
  )
}

export default RightArrow
