import {
  BlogRepoHeaderWrapper,
  DateCategoriesWrapper,
  Header,
  HeaderSubscript,
  HeaderTitle,
  PostPreviewImage,
  PostTitle,
  Posts,
  Date,
  RightPost,
  Category,
  CerebralBlogBody,
  LeftPost,
  RightPostTitle,
  RightPostPreviewImage,
  RightDateCategoriesWrapper,
  RightPosts,
  RightPostContent,
} from "./styled"

import { Post } from "../Blog/types"
import { formatDate } from "../Blog/utils"

interface BlogRepoHeaderProps {
  posts?: Post[]
}

const BlogRepoHeader = ({ posts }: BlogRepoHeaderProps) => {
  const getHeaderText = () => {
    return (
      <Header>
        <HeaderTitle>Cerebral Blog</HeaderTitle>
        <HeaderSubscript>
          Resources and articles to help on your mental health journey
        </HeaderSubscript>
      </Header>
    )
  }

  if (!posts || posts.length === 0) {
    return (
      <BlogRepoHeaderWrapper>
        <CerebralBlogBody>{getHeaderText()}</CerebralBlogBody>
      </BlogRepoHeaderWrapper>
    )
  }

  const maxLength = posts.length < 4 ? posts.length : 4
  const latest4Posts = posts.slice(0, maxLength)
  const latestPost = latest4Posts[0]
  const latest3Posts = posts.length > 1 ? latest4Posts.slice(1, maxLength) : []

  return (
    <BlogRepoHeaderWrapper>
      <CerebralBlogBody>
        {getHeaderText()}
        <Posts>
          <LeftPost href={`${process.env.GATSBY_BASE_URL}${latestPost.url}`}>
            <PostPreviewImage image={latestPost.image} alt="" />
            <DateCategoriesWrapper>
              <Date>{formatDate(latestPost.date)}</Date>
              {latestPost.categories.map(
                category =>
                  !!category && <Category key={category}>{category}</Category>
              )}
            </DateCategoriesWrapper>
            <PostTitle>{latestPost.title}</PostTitle>
          </LeftPost>
          <RightPosts>
            {latest3Posts.map((post, index) => (
              <RightPost
                key={index}
                href={`${process.env.GATSBY_BASE_URL}${post.url}`}
              >
                <RightPostPreviewImage image={post.image} alt="" />
                <RightPostContent>
                  <RightDateCategoriesWrapper>
                    <Date>{formatDate(post.date)}</Date>
                    {post.categories.map(
                      category =>
                        !!category && (
                          <Category key={category}>{category}</Category>
                        )
                    )}
                  </RightDateCategoriesWrapper>
                  <RightPostTitle>{post.title}</RightPostTitle>
                </RightPostContent>
              </RightPost>
            ))}
          </RightPosts>
        </Posts>
      </CerebralBlogBody>
    </BlogRepoHeaderWrapper>
  )
}

export default BlogRepoHeader
