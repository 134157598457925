import {
  GBImageWrapper,
  MentalHeathSupportContentWrapper,
  MentalHeathSupportGBImage,
  MentalHeathSupportImage,
  MentalHeathSupportLeftWrapper,
  MentalHeathSupportSubtitle,
  MentalHeathSupportTitle,
  MentalHeathSupportWrapper,
} from "./styled"
import MentalHeathImage from "../../HomePage/sections/TheCerebralWay/images/cw-image.webp"
import { CtaButton } from "../../prismic/LandingPageStyles"
import { IGatsbyImageData, getImage } from "gatsby-plugin-image"

interface MentalHeathSupportProps {
  title?: string
  subtitle?: string
  cta_text?: string
  cta_link?: string
  maxW?: string
  image?: IGatsbyImageData
  mobileGap?: string
  paddingTop?: string
}

const MentalHeathSupport = ({
  title,
  subtitle,
  cta_text,
  cta_link,
  maxW,
  image,
  mobileGap,
  paddingTop,
}: MentalHeathSupportProps) => {
  return (
    <MentalHeathSupportWrapper mobileGap={mobileGap} paddingTop={paddingTop}>
      <MentalHeathSupportLeftWrapper>
        <MentalHeathSupportContentWrapper maxW={maxW}>
          <MentalHeathSupportTitle>
            {title || "Mental health care made for you"}
          </MentalHeathSupportTitle>
          <MentalHeathSupportSubtitle>
            {subtitle ||
              "Find a therapist or prescriber to get the care you need."}
          </MentalHeathSupportSubtitle>
          <CtaButton
            to={cta_link || process.env.GATSBY_START_URL}
            width="250px"
            boxShadow="none"
          >
            {cta_text || "Get started"}
          </CtaButton>
        </MentalHeathSupportContentWrapper>
      </MentalHeathSupportLeftWrapper>
      {image ? (
        <GBImageWrapper>
          <MentalHeathSupportGBImage
            image={getImage(image) as IGatsbyImageData}
            alt="mental-heath-support"
          />
        </GBImageWrapper>
      ) : (
        <MentalHeathSupportImage
          src={MentalHeathImage}
          alt="mental-heath-support"
          loading="lazy"
        />
      )}
    </MentalHeathSupportWrapper>
  )
}

export default MentalHeathSupport
