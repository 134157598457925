import styled from "styled-components"
import { colors } from "../../../themes/colors"

export const NavItem = styled.li`
  list-style: none;
  height: 72px;
  display: flex;
  justify-content: space-between;
  padding: 0 24px;

  img {
    width: 24px;
  }

  &:hover {
    background: ${colors.c_secondary[100]};
  }

  &:focus {
    border: 2px solid ${colors.c_secondary[600]};
  }

  &:active {
    background: ${colors.c_secondary[200]};
  }

  button {
    background: none;
    border: none;
    font-size: 20px;
    font-weight: 600;
    color: ${colors.c_black[900]};
    line-height: 24px;
    text-align: left;
    cursor: pointer;
    padding: 0;
  }

  @media (min-width: 1024px) {
    margin: 0px;
    padding: 0 40px;
    height: 72px;
    white-space: nowrap;

    button {
      padding: 0;
    }
  }
`

export const Divider = styled.hr<{ showOnDesktop?: boolean }>`
  border: none;
  background: ${colors.c_grey[200]};
  height: 1px;
  margin: 0;

  @media (min-width: 1024px) {
    display: ${({ showOnDesktop }) => (showOnDesktop ? "block" : "none")};
  }
`

export const ItemListWrapper = styled.div<{
  isOpen: boolean
  disableTransition: boolean
}>`
  position: fixed;
  top: 0;
  left: ${({ isOpen }) => (isOpen ? 0 : "-100%")};
  transition: ${({ disableTransition }) =>
    disableTransition ? "none" : "left 0.3s ease-in-out"};
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  overflow-y: overlay;
  background-color: ${colors.c_white[50]};
  padding-bottom: 100px;

  @media (min-width: 1024px) {
    z-index: -1;
    display: none;
  }
`

export const MobileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 16px 24px;
  height: 72px;
  align-items: center;
`

export const CloseIcon = styled.img`
  cursor: pointer;

  &:active {
    background: ${colors.c_secondary[100]};
    border-radius: 50%;
  }
`

export const DesktopMenuWrapper = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
  }
`
