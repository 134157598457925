import { Link } from "gatsby"
import {
  ButtonContainer,
  HeaderText,
  SectionButton,
  TherapistPrescriberWrapper,
} from "./styled"
import SmallPlant from "./assets/small-plant.webp"

export const TherapistPrescriber = () => {
  return (
    <TherapistPrescriberWrapper>
      <HeaderText>Find a care team you'll love</HeaderText>
      <ButtonContainer>
        <Link to="/care-team/therapists">
          <SectionButton>Find a therapist</SectionButton>
        </Link>
        <Link to="/care-team/prescribers">
          <SectionButton>Find a prescriber</SectionButton>
        </Link>
      </ButtonContainer>
      <img src={SmallPlant} alt="" height="123px" width="auto" />
    </TherapistPrescriberWrapper>
  )
}
