import { Splash } from "../Splash"
import WomanOnPhone from "./images/woman-on-phone.webp"
import { FindYourPathWrapper } from "./styled"
import { useSignupUrl } from "../../../hooks/useSignupUrl"

interface FindYourPathProps {
  backgroundColor: string
}

export const FindYourPath = ({ backgroundColor }: FindYourPathProps) => {
  const signupUrl = useSignupUrl()
  return (
    <FindYourPathWrapper>
      <Splash
        titleText="Find your path forward"
        subtitleText="Complete a free and confidential emotional assessment to get your care plan today"
        image={WomanOnPhone}
        background={backgroundColor}
        buttons={[{ text: "Get started", link: signupUrl }]}
      />
    </FindYourPathWrapper>
  )
}
