export default function LocationPoint({ fill = "#51459E" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={fill}
    >
      <g id="u:postcard">
        <path
          id="Vector"
          d="M14.1667 9.16683H15C15.2211 9.16683 15.433 9.07903 15.5893 8.92275C15.7456 8.76647 15.8334 8.55451 15.8334 8.3335V7.50016C15.8334 7.27915 15.7456 7.06719 15.5893 6.91091C15.433 6.75463 15.2211 6.66683 15 6.66683H14.1667C13.9457 6.66683 13.7337 6.75463 13.5774 6.91091C13.4212 7.06719 13.3334 7.27915 13.3334 7.50016V8.3335C13.3334 8.55451 13.4212 8.76647 13.5774 8.92275C13.7337 9.07903 13.9457 9.16683 14.1667 9.16683ZM5.00004 10.0002H9.16671C9.38772 10.0002 9.59968 9.91236 9.75596 9.75608C9.91224 9.5998 10 9.38784 10 9.16683C10 8.94582 9.91224 8.73385 9.75596 8.57757C9.59968 8.42129 9.38772 8.3335 9.16671 8.3335H5.00004C4.77903 8.3335 4.56707 8.42129 4.41078 8.57757C4.2545 8.73385 4.16671 8.94582 4.16671 9.16683C4.16671 9.38784 4.2545 9.5998 4.41078 9.75608C4.56707 9.91236 4.77903 10.0002 5.00004 10.0002ZM18.3334 3.3335H1.66671C1.44569 3.3335 1.23373 3.42129 1.07745 3.57757C0.921171 3.73385 0.833374 3.94582 0.833374 4.16683V15.8335C0.833374 16.0545 0.921171 16.2665 1.07745 16.4228C1.23373 16.579 1.44569 16.6668 1.66671 16.6668H18.3334C18.5544 16.6668 18.7663 16.579 18.9226 16.4228C19.0789 16.2665 19.1667 16.0545 19.1667 15.8335V4.16683C19.1667 3.94582 19.0789 3.73385 18.9226 3.57757C18.7663 3.42129 18.5544 3.3335 18.3334 3.3335ZM17.5 15.0002H2.50004V5.00016H17.5V15.0002ZM5.00004 13.3335H9.16671C9.38772 13.3335 9.59968 13.2457 9.75596 13.0894C9.91224 12.9331 10 12.7212 10 12.5002C10 12.2791 9.91224 12.0672 9.75596 11.9109C9.59968 11.7546 9.38772 11.6668 9.16671 11.6668H5.00004C4.77903 11.6668 4.56707 11.7546 4.41078 11.9109C4.2545 12.0672 4.16671 12.2791 4.16671 12.5002C4.16671 12.7212 4.2545 12.9331 4.41078 13.0894C4.56707 13.2457 4.77903 13.3335 5.00004 13.3335Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}
