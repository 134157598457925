import {
  HeroWrapper,
  TextContainer,
  Header,
  Text,
  ArrowDown,
  LeftSide,
  RightSide,
  HeroImage,
  LearnMoreText,
  LearnMoreAnimation,
  LearnMoreWrapper,
} from "./styled"
import { GetStartedButton } from "../../../shared/GetStartedButton/GetStartedButton"
import Hero from "../../assets/hero.webp"
import Arrow from "../../assets/u_arrow_down.svg"

export const HeroSection = ({
  learnMoreClicked,
}: {
  learnMoreClicked: () => void
}) => {
  return (
    <HeroWrapper>
      <LeftSide>
        <TextContainer>
          <Header>
            Invest in <b>yourself</b>
          </Header>
          <Text>
            Personalized therapy and medication treatment plans designed to help
            you make real progress over time
          </Text>
          <GetStartedButton width={320} />
        </TextContainer>
      </LeftSide>

      <RightSide>
        <HeroImage src={Hero} alt="pricing hero image" />
        <LearnMoreWrapper onClick={learnMoreClicked}>
          <LearnMoreAnimation>
            <LearnMoreText>Learn More</LearnMoreText>
            <ArrowDown src={Arrow} />
          </LearnMoreAnimation>
        </LearnMoreWrapper>
      </RightSide>
    </HeroWrapper>
  )
}
