import React from "react"
import styled from "styled-components"
import { LeftItalicHeading } from "./LandingPageStyles"
import { TextContentStyle } from "./Text"
import Button from "../../common/Button"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 30px;
`

const ColumnListWrapper = styled.div`
  display: flex;
  column-gap: 10px;

  @media (max-width: 600px) {
    flex-direction: column;
    row-gap: 20px;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
`

const MultiColumnsButton = styled(Button)`
  margin: 30px 0 0 !important;
`

function generateColumnWrapper(listStyleImageUrl) {
  const listStyleImage = `list-style-image: ${
    listStyleImageUrl ? `url(${listStyleImageUrl})` : "none"
  }`

  return styled.div`
    display: flex;
    flex: 1;
    text-align: left;
    ${TextContentStyle}

    & ul, ol {
      padding: 0;
      margin: 0;
      margin-left: 30px;
      ${listStyleImage};
    }

    & ul {
      list-style-type: disc;
    }

    & ol {
      list-style-type: decimal;
    }

    & li {
      margin: 0;
    }
  `
}

const MultiColumnsList = ({ slice, columnLists }) => {
  const { primary } = slice
  const { use_custom_bullet_glyph, custom_bullet_glyph, heading } = primary
  const ctaLink = primary.cta_link?.url || "#"
  const ctaText = primary.cta_text?.text || ""

  const ColumnWrapper = generateColumnWrapper(
    use_custom_bullet_glyph ? custom_bullet_glyph.url : undefined
  )

  return (
    <Wrapper>
      {heading?.text && <LeftItalicHeading text={heading.text} />}

      <ColumnListWrapper>
        {columnLists.map((columnList, index) => (
          <ColumnWrapper
            key={index}
            dangerouslySetInnerHTML={{ __html: columnList }}
          />
        ))}
      </ColumnListWrapper>

      <ButtonWrapper>
        <MultiColumnsButton to={ctaLink}>{ctaText}</MultiColumnsButton>
      </ButtonWrapper>
    </Wrapper>
  )
}

export default MultiColumnsList
