import { UilBabyCarriage, UilCommentsAlt } from "@iconscout/react-unicons"
import { GetStartedButton } from "../../../shared/GetStartedButton"
import {
  ButtonWrapper,
  Card,
  CardsWrapper,
  GettingStartedWrapper,
  Header,
  IconWrapper,
} from "./styled"
import HeartPalm from "./images/c_heart-palm.svg"

const Cards = [
  {
    title: "Resolve Conflicts",
    description:
      "Work through disagreements, align on important decisions, and learn how to communicate effectively",
    icon: <UilCommentsAlt />,
  },
  {
    title: "Navigate life changes",
    description:
      "Get support through major life transitions – finances, relocation, grief, loss, career changes, and more",
    icon: <img src={HeartPalm} />,
  },
  {
    title: "Parenting and family support",
    description:
      "Manage changes like a new baby,  pregnancy challenges, an empty nest, or becoming a caregiver",
    icon: <UilBabyCarriage />,
  },
]

export const StrengthenYourRelationship = () => {
  return (
    <GettingStartedWrapper>
      <Header>
        <h2>Strengthen any relationship</h2>
        <p>
          Therapy can help you and your partner connect and thrive.&nbsp;
          <a href="/h/couples-therapy">Learn more</a>
        </p>
      </Header>
      <CardsWrapper>
        {Cards.map((card, index) => (
          <Card key={index}>
            <IconWrapper>{card.icon}</IconWrapper>
            <div>
              <h4>{card.title}</h4>
              <p>{card.description}</p>
            </div>
          </Card>
        ))}
      </CardsWrapper>
      <ButtonWrapper>
        <GetStartedButton text="Get started" height={56} />
      </ButtonWrapper>
    </GettingStartedWrapper>
  )
}
