import styled from "styled-components"
import { colors } from "../themes/colors"
import { Link } from "gatsby"

export const WelcomeBackPageWrapper = styled.main<{ insurance: boolean }>`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  flex-grow: 1;

  @media (max-width: 834px) {
    ${props =>
      props.insurance
        ? "background: linear-gradient(135deg, #fef9ec 0%, #eceffe 100%);"
        : ""}
    display: flex;
    flex-direction: column;
  }
`

export const WelcomeBackContainer = styled.section<{ insurance: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 96px;
  width: 100%;
  align-self: center;
  justify-content: center;
  height: 100%;
  ${props =>
    props.insurance
      ? "background: linear-gradient(135deg, #fef9ec 0%, #eceffe 100%);"
      : ""}

  @media (max-width: 834px) {
    text-align: center;
    justify-content: center;
    padding: 32px 24px 90px 24px;
    align-items: center;
  }
`

export const ImageContainer = styled.section`
  > picture {
    > img {
      object-fit: cover;
    }
  }
`

export const WelcomeBackText = styled.h4`
  color: ${colors.c_primary[900]};
  font-size: 18px;
  line-height: 24px;
`

export const InsuranceHeader = styled.h1`
  color: ${colors.c_primary[500]};
  font-family: Poppins;
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  margin-bottom: 40px;
  max-width: 480px;
  margin-top: 16px;

  b {
    font-weight: 700;
    color: ${colors.c_primary[600]};
  }

  @media (max-width: 834px) {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 32px;
  }
`

export const HeadlineText = styled.h1`
  font-size: 40px;
  line-height: 50px;
  color: ${colors.c_primary[600]};
  margin-top: 16px;
  margin-bottom: 40px;
  max-width: 480px;

  @media (max-width: 834px) {
    font-size: 32px;
    margin-bottom: 32px;
  }
`

export const SignUpText = styled.p`
  font-size: 14px;
  line-height: 24px;
  font-family: Poppins;
`

export const SignUpLink = styled(Link)`
  font-size: 14px;
  line-height: 16px;
  text-decoration: underline;
  font-family: Poppins;
  color: #385ade;
`

export const SignUpContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 24px;
`

export const ButtonContainer = styled.span`
  max-width: 400px;
  width: 100%;
`

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const InsuranceLogos = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  max-width: 400px;
  padding-top: 32px;
  gap: 24px;

  @media (max-width: 834px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px 48px;
  }
`
