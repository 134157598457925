import {
  BannerContainer,
  BannerText,
  BannerImage,
  DataDeletionButton,
} from "./styled"
import Info from "./assets/u_info-circle.svg"
import segment, { segmentEvents } from "../../../../lib/segment"

export const FTCBanner = () => {
  const trackSegment = (link: string) => {
    segment.track(
      segmentEvents.LEGAL_BANNER_CLICKED,
      { link },
      { integrations: { All: false, Heap: true } }
    )
  }
  return (
    <BannerContainer>
      <BannerText>
        <BannerImage src={Info} alt="info" />
        <a
          href="https://cerebral.com/ftc_notice.pdf"
          onClick={() => trackSegment("FTC notice")}
          target="_blank"
        >
          FTC notice
        </a>{" "}
        and{" "}
        <DataDeletionButton
          key="sharing-settings"
          onClick={() => {
            ;(window as any).ketch("showPreferences", {
              showOverviewTab: false,
              tab: "consentsTab",
            })
            trackSegment("Data deletion requests")
          }}
        >
          Data deletion requests
        </DataDeletionButton>
      </BannerText>
    </BannerContainer>
  )
}
