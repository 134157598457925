import styled from "styled-components"

export const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 3em;
  font-family: Poppins;
  @media (max-width: 800px) {
    flex-direction: column;
    margin-bottom: 32px;
    padding-right: 0;
  }
`

export const MenuColumn = styled.div`
  justify-content: center;
  display: flex;
  padding: 0 8px;
  @media (max-width: 800px) {
    justify-content: flex-start;
    margin-bottom: 3em;
    order: 1;
  }
`

export const MenuTitle = styled.span`
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.022em;
  color: #ffffff;
  display: block;
`

export const SubMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > a,
  span {
    margin-top: 1em;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.022em;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  button {
    margin-top: 1em;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.022em;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
    text-align: left;
    background-color: transparent;
    padding: 0px;
    border: none;
    font-family: Poppins;

    &:hover {
      text-decoration: underline;
    }
  }
`
