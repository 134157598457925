export const exceptionalCareBullets = [
  {
    title: "Qualified professionals",
    description:
      "Our compassionate therapists and medication prescribers are chosen and qualified to provide high quality care for a wide variety of mental health conditions.",
  },
  {
    title: "Convenient and flexible",
    description:
      "Get the care you need from the comfort of your home. Meet on your schedule—even at night or on the weekend.",
  },
  {
    title: "Budget friendly",
  },
  {
    title: "All your care in one place",
    description:
      "See a therapist, schedule sessions, discuss medication with a licensed prescriber, and manage prescriptions online or in the app.",
  },
]
