import styled from "styled-components"
import { colors } from "../../../themes/colors"
import { NavItem, MobileHeader, ItemListWrapper } from "../shared/styled"

export const SubMenuItem = styled(NavItem)<{
  noLink?: boolean
}>`
  &:hover {
    ${({ noLink }) => noLink && `background: none;`}
  }

  button {
    ${({ noLink }) => noLink && `cursor: default;`}
  }

  @media (min-width: 1024px) {
    button {
      font-size: 22px;
    }
  }
`

export const SubMenuWrapper = styled(ItemListWrapper)<{
  isBannerShown?: boolean
  isOpen?: boolean
}>`
  @media (min-width: 1024px) {
    display: block;
    top: ${({ isBannerShown }) => (isBannerShown ? "158px" : "92px")};
    width: 540px;
    height: calc(100vh - 88px);
    ${({ isOpen }) => `left: ${isOpen ? "0" : "-540px"};`}
    transition: ${({ disableTransition }) =>
      disableTransition ? "none" : "left 0.3s ease-in-out"};
  }
`

export const SubItemList = styled.ul`
  flex-direction: column;

  a {
    text-decoration: none;
  }

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
`

export const BackIcon = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
  height: 40px;
  align-items: center;
  justify-content: center;

  img {
    height: 24px;
    width: 24px;
    margin-right: 16px;
  }

  p {
    padding-right: 8px;
  }
  &:active {
    background: ${colors.c_secondary[100]};
    border-radius: 6px;
  }
`

export const SubMenuHeader = styled(MobileHeader)`
  @media (min-width: 1024px) {
    display: none;
  }
`
