import styled from "styled-components"
import { colors } from "../../themes/colors"

export const TherapistCardContainer = styled.div`
  height: 388px;
  width: 406px;
  background: ${colors.c_white[500]};
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 14px rgba(81, 69, 158, 0.16);
  border-radius: 8px;
  padding: 24px;
  text-align: start;
  & > h4 {
    font-size: 13px;
    line-height: 16px;
  }
  & > p {
    margin: 16px 0 8px;
    font-size: 15px;
  }
  & > a {
    margin-top: auto;
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
    max-width: fit-content;
  }
  @media (max-width: 1242px) {
    max-width: 308px;
    height: 468px;
  }
`
export const GeneralInfoContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  font-family: "Poppins";
  & > img {
    margin-right: 24px;
    max-width: 79px;
    max-height: 79px;
  }
`
export const GeneralInfoTextContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`

export const GeneralInfoInnerTextContainer = styled.div`
  display: flex;
  color: ${colors.c_secondary[600]};
  font-size: 12px;
  line-height: 15px;
  margin-top: 8px;
  & > img {
    margin-right: 8px;
  }

  & > span strong {
    font-weight: 500;
  }
  & > span:last-child {
    margin-left: 16px;
  }
`

export const BlueText = styled.span`
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
`
