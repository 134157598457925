import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  ImageDataLike,
} from "gatsby-plugin-image"

interface BackgroundColorProps {
  from?: string
  to?: string
  type: string
  color?: string
}

const Wrapper = styled.div<BackgroundColorProps>`
  background-color: ${({ color }) => (color ? color : "white")};
  background-image: linear-gradient(
    135deg,
    ${props =>
        props.type === "gradient"
          ? props.from
            ? props.from
            : "rgba(207, 242, 240, 0.4)"
          : "rgba(0,0,0,0)"}
      0%,
    ${props =>
        props.type === "gradient"
          ? props.to
            ? props.to
            : "rgba(199, 191, 255, 0.4)"
          : "rgba(0,0,0,0)"}
      100%
  );
  background-color: white;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 42px 32px 0px;

  @media (min-width: 834px) {
    padding: 72px min(200px, 10%) 0px;
  }
`

const Header = styled.div`
  * {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    color: #250044;
  }

  strong {
    color: #b172e6;
  }

  em {
    font-style: italic;
    font-weight: 700;
  }
`

const SubHeader = styled.div`
  font-size: 16px;
  line-height: 30px;
  color: #250044;
  text-align: center;
  @media (min-width: 834px) {
    font-size: 19px;
  }
  p:not(:last-child) {
    margin-bottom: 16px;
  }
`

const CardsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  align-items: flex-start;
`

const CardHeader = styled.div`
  font-weight: 600;
  text-align: left;
  color: #250044;
  vertical-align: middle;
`

const CardCopy = styled.div`
  font-size: 14px;
  line-height: 20px;
  text-align: center;
`

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 290px;
  @media (min-width: 834px) {
    padding-bottom: 72px;
  }
`

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  width: 290px;
  height: 160px;
  border-radius: 12px;
  overflow: hidden;
  * {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  picture img {
    object-fit: cover !important;
    object-position: center center !important;
  }
`

const CardLink = styled.a`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  cursor: pointer;
`

interface ListOfCardsProps {
  slice: {
    primary: {
      slice_id?: {
        text: string
      }
      background_type: string
      background_color?: string
      background_gradient_from?: string
      background_gradient_to?: string
      header: {
        html: string
      }
      sub_header: {
        html: string
      }
    }
    items: Array<Card>
  }
}

interface Card {
  card_header: {
    html: string
  }
  card_copy: {
    html: string
  }
  card_link: {
    url: string
  }
  card_image: ImageDataLike
}

const ListOfCards = ({ slice }: ListOfCardsProps) => {
  const { primary, items } = slice

  return (
    <Wrapper
      type={primary.background_type}
      color={primary.background_color}
      from={primary.background_gradient_from}
      to={primary.background_gradient_to}
    >
      <Header dangerouslySetInnerHTML={{ __html: primary.header.html }} />
      {primary.sub_header && (
        <SubHeader
          dangerouslySetInnerHTML={{ __html: primary.sub_header.html }}
        />
      )}
      <CardsWrapper>
        {items.map(card => (
          <CardContainer key={card.card_header.html}>
            <ImageContainer>
              <CardLink href={card.card_link.url} />
              <GatsbyImage
                image={getImage(card.card_image) as IGatsbyImageData}
                alt=""
                loading="eager"
              />
            </ImageContainer>
            <CardHeader
              dangerouslySetInnerHTML={{ __html: card.card_header.html }}
            />
            <CardCopy
              dangerouslySetInnerHTML={{ __html: card.card_copy.html }}
            />
          </CardContainer>
        ))}
      </CardsWrapper>
    </Wrapper>
  )
}

export const query = graphql`
  fragment PageListOfCardsSlice on PrismicPageDataBodyListOfCards {
    id
    slice_type
    primary {
      slice_id {
        text
      }
      background_type
      background_color
      background_gradient_from
      background_gradient_to
      header {
        html
      }
      sub_header {
        html
      }
    }
    items {
      card_header {
        html
      }
      card_copy {
        html
      }
      card_link {
        url
      }
      card_image {
        gatsbyImageData
      }
    }
  }
`

export default ListOfCards
