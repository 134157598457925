import {
  TheCerebralWayWrapper,
  ContentWrapper,
  ContentBlock,
  CopySection,
  IconImage,
  CWImageWrapper,
} from "./styled"
import CWImage from "./images/cw-image.webp"
import HeartPalmImage from "./images/heart_palm_icon.svg"
import DollarSignImage from "./images/dollar_sign_icon.svg"
import MedicalHeartImage from "./images/heart_icon.svg"
import ScheduleImage from "./images/schedule_icon.svg"
import styled from "styled-components"
import { colors } from "../../../themes/colors"
import { Header, Subheader } from "../YourPersonalPath/styled"

const defaultCopy: {
  header: string
  description: JSX.Element
  icon?: JSX.Element
}[] = [
  {
    icon: <IconImage src={ScheduleImage} />,
    header: "Schedule sessions online",
    description: (
      <p>
        No phone calls, emails, or back-and-forths. See your clinician’s full
        calendar and easily schedule, cancel, or reschedule a session right from
        your account.
      </p>
    ),
  },
  {
    icon: <IconImage src={HeartPalmImage} />,
    header: "Switch therapists or prescribers anytime",
    description: (
      <p>
        Most clients are happy with their first choice, but you can choose to
        meet with someone else anytime for any reason – right from your account
        profile.
      </p>
    ),
  },
  {
    icon: <IconImage src={DollarSignImage} />,
    header: "Pay 50% less than in-person care",
    description: (
      <p>
        In-person therapy can{" "}
        <a
          href="https://www.healthline.com/health/how-much-does-therapy-cost-a-deep-dive-into-prices#faq"
          style={{ color: "black", fontWeight: "600" }}
        >
          cost $100-200 per session
        </a>
        . We’ve made our costs as low as possible to make quality mental
        healthcare accessible to more people.
      </p>
    ),
  },
  {
    icon: <IconImage src={MedicalHeartImage} />,
    header: "Support when you need it",
    description: (
      <p>
        Message us anytime you have a question or concern between sessions. We
        have a whole support team available to take care of your needs.
      </p>
    ),
  },
]

const Counter = styled.div`
  font-family: "Poppins";
  font-weight: 300;
  font-size: 48px;
  line-height: 40px;
  text-align: center;
  color: ${colors.c_primary[600]};
`

export const TheCerebralWay = ({
  copy = defaultCopy,
  header = "Managing your mental health online is easy",
}: {
  copy?: typeof defaultCopy
  header?: string
}) => (
  <TheCerebralWayWrapper>
    <Header>{header}</Header>
    <ContentWrapper>
      <div>
        {copy.map(({ header, description, icon }, index) => (
          <ContentBlock key={header}>
            <div>{icon ? icon : <Counter>{index + 1}</Counter>}</div>
            <CopySection>
              <h3>{header}</h3>
              <p>{description}</p>
            </CopySection>
          </ContentBlock>
        ))}
      </div>
      <div>
        <CWImageWrapper src={CWImage} />
      </div>
    </ContentWrapper>
  </TheCerebralWayWrapper>
)
