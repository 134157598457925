import { graphql } from "gatsby"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"

const Wrapper = styled.div`
  width: 100%;
  padding: 64px 0px;
  background: linear-gradient(135deg, #fef9ec 0%, #eceffe 100%);

  @media (max-width: 1024px) {
    padding: 48px 24px;
    background: linear-gradient(135deg, #eceffe 0%, #fef9ec 100%);
  }
`

const Header = styled.div`
  color: #1c1e21;
  text-align: center;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin: 0px auto 40px;
  max-width: 992px;

  @media (max-width: 992px) {
    margin-bottom: 32px;
    font-family: Poppins;
    font-size: 24px;
    line-height: 28px;
  }
`

const Content = styled.div`
  width: 992px;
  margin: auto;
  display: flex;
  gap: 80px;

  @media (max-width: 1024px) {
    width: 100%;
    gap: 40px;
  }

  @media (max-width: 834px) {
    flex-direction: column;
    gap: 32px;
  }
`

const RightBox = styled.div`
  width: calc(992px / 2 - 40px);
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;

  @media (max-width: 1024px) {
    width: calc(100vw / 2 - 44px);
  }

  @media (max-width: 834px) {
    width: calc(100vw - 48px);
  }
`

const LeftBox = styled.div`
  position: relative;
  width: calc(992px / 2 - 40px);
  display: flex;

  @media (max-width: 1024px) {
    width: calc(100vw / 2 - 44px);
  }

  @media (max-width: 834px) {
    width: calc(100vw - 48px);
  }
`

const Block = styled.div`
  display: flex;
  gap: 16px;
`

const MainImage = styled(GatsbyImage)`
  img {
    border-radius: 16px;
    object-fit: contain !important;
  }

  @media (max-width: 1024px) {
    width: calc(100vw - 48px);

    img {
      width: calc(100vw - 48px);
    }
  }
`

const IconWrapper = styled.div``

const BlockContent = styled.div`
  line-height: 24px;
  font-family: Poppins;

  p {
    margin-bottom: 4px;
    color: #4d515a;
    &:last-child {
      margin-bottom: 0px;
    }

    strong {
      color: #1c1e21;
      font-weight: 600;
      font-size: 18px;
    }

    @media (max-width: 992px) {
      font-size: 14px;
    }
  }
`

export const MainTitle = styled.div`
  width: 240px;
  height: 60px;
  border-radius: 16px;
  opacity: 0.95;
  background: rgb(250, 250, 252);
  padding: 14px 16px;
  overflow: hidden;
  color: #353a41;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  gap: 12.21px;
  font-weight: 600;
  line-height: 19px;
  position: absolute;
  display: flex;
  display: flex;
  align-items: center;
  bottom: 64px;
  left: -24px;
  box-shadow: 0px 4px 14px rgba(81, 69, 158, 0.16);
  filter: drop-shadow(0px 3.133px 15.04px rgba(28, 30, 33, 0.08))
    drop-shadow(0px 8.147px 11.907px rgba(28, 30, 33, 0.1))
    drop-shadow(0px 4.387px 5.013px rgba(28, 30, 33, 0.16));
  @media (max-width: 500px) {
    left: -12px;
    bottom: 48px;
  }
`

export const Button = styled(Link)<{ disabled?: boolean }>`
  border-radius: 100px;
  background: ${props => (props.disabled ? "#F0F1F5" : "#51459e")};
  display: flex;
  width: 320px;
  height: 56px;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: ${props => (props.disabled ? "#626874" : "#ffffff")};
  margin: auto;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  margin-top: 40px;
  cursor: ${props => (props.disabled ? "inherit" : "pointer")};

  @media (max-width: 500px) {
    width: 100%;
    margin-top: 32px;
  }
`

interface ContentHubHeaderInterface {
  slice: {
    primary: {
      tab_id: string
      background_color: string
      cta_link: {
        url: string
      }
      cta_text: {
        text: string
      }
      treatment_title: {
        text: string
      }
      treatment_image: {
        gatsbyImageData: IGatsbyImageData
      }
      banner_icon?: {
        gatsbyImageData: IGatsbyImageData
      }
      banner_title?: {
        text: string
      }
    }
    items: {
      icon: {
        url: string
      }
      treatment_description: {
        html: string
      }
    }[]
  }
}

const ContentHubOnlineTreatment = ({
  slice,
}: ContentHubHeaderInterface): JSX.Element => {
  const {
    tab_id,
    background_color,
    cta_link,
    cta_text,
    treatment_title,
    treatment_image,
    banner_icon,
    banner_title,
  } = slice.primary

  return (
    <Wrapper id={tab_id} style={{ background: background_color }}>
      <Header>{treatment_title.text}</Header>
      <Content>
        <LeftBox>
          <MainImage
            image={getImage(treatment_image) as IGatsbyImageData}
            alt=""
          />
          <MainTitle>
            {banner_icon && (
              <GatsbyImage
                image={getImage(banner_icon) as IGatsbyImageData}
                alt=""
                loading="eager"
              />
            )}
            {banner_title?.text}
          </MainTitle>
        </LeftBox>
        <RightBox>
          {slice.items.map(item => (
            <Block>
              <IconWrapper>
                <img src={item.icon.url} />
              </IconWrapper>
              <BlockContent
                dangerouslySetInnerHTML={{
                  __html: item.treatment_description.html,
                }}
              ></BlockContent>
            </Block>
          ))}
        </RightBox>
      </Content>
      <Button to={cta_link.url}>{cta_text.text}</Button>
    </Wrapper>
  )
}

export const query = graphql`
  fragment PageContentHubOnlineTreatmentSlice on PrismicPageDataBodyContentHubOnlineTreatment {
    id
    slice_type
    primary {
      tab_id
      background_color
      tab_title {
        text
      }
      treatment_title {
        text
      }
      cta_link {
        url
      }
      cta_text {
        text
      }
      treatment_image {
        gatsbyImageData(placeholder: BLURRED)
      }
      banner_icon {
        gatsbyImageData
      }
      banner_title {
        text
      }
    }
    items {
      icon {
        url
      }
      treatment_description {
        html
      }
    }
  }
`

export default ContentHubOnlineTreatment
