import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

interface WrapperProps {
  from?: string
  to?: string
  type?: string
  color?: string
}

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  height: auto;
  width: 100%;
  background-color: ${({ color }) => (color ? color : "white")};
  background-image: linear-gradient(
    135deg,
    ${props =>
        props.type === "gradient"
          ? props.from
            ? props.from
            : "rgba(207, 242, 240, 0.4)"
          : "rgba(0,0,0,0)"}
      0%,
    ${props =>
        props.type === "gradient"
          ? props.to
            ? props.to
            : "rgba(199, 191, 255, 0.4)"
          : "rgba(0,0,0,0)"}
      100%
  );
`

const VideoContainer = styled.div`
  width: 100%;
  max-width: 720px;
  margin: auto;
  padding-top: 48px;
  aspect-ratio: 16 / 9;

  @media (max-width: 744px) {
    padding: 32px 24px 0px;
  }

  iframe {
    width: 100% !important;
    height: 100% !important;
  }
`

interface VideoSliceProps {
  slice: {
    primary: {
      slice_id?: {
        text: string
      }
      video: {
        html: string
      }
      background_type?: string
      background_color?: string
      background_gradient_from?: string
      background_gradient_to?: string
    }
  }
}

const Video = ({ slice }: VideoSliceProps) => {
  const {
    slice_id,
    video,
    background_type,
    background_color,
    background_gradient_from,
    background_gradient_to,
  } = slice.primary

  return (
    <Wrapper
      type={background_type}
      color={background_color}
      from={background_gradient_from}
      to={background_gradient_to}
      id={slice_id?.text || ""}
    >
      <VideoContainer dangerouslySetInnerHTML={{ __html: video.html }} />
    </Wrapper>
  )
}

export const query = graphql`
  fragment VideoSlice on PrismicLandingDataBodyVideo {
    id
    slice_type
    primary {
      video {
        html
      }
    }
  }
  fragment PageVideoSlice on PrismicPageDataBodyVideo {
    id
    slice_type
    primary {
      slice_id {
        text
      }
      video {
        html
      }
      background_type
      background_color
      background_gradient_from
      background_gradient_to
    }
  }
  fragment PostVideoSlice on PrismicPostDataBodyVideo {
    id
    slice_type
    primary {
      video {
        html
      }
    }
  }
  fragment ProviderVideoSlice on PrismicProviderDataBodyVideo {
    id
    slice_type
    primary {
      video {
        html
      }
    }
  }
  fragment CounselorVideoSlice on PrismicCounselorDataBodyVideo {
    id
    slice_type
    primary {
      video {
        html
      }
    }
  }
  fragment TherapistVideoSlice on PrismicTherapistDataBodyVideo {
    id
    slice_type
    primary {
      video {
        html
      }
    }
  }
`

export default Video
