import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  ContentSection,
  ReversedContentSection,
  CenterItalicHeading,
  SubTitle,
  MintWrapper,
  TitleSection,
  FirstSection,
  SecondSection,
} from "./LandingPageStyles"
import { TextContentStyle } from "./Text"

const TextLeftTitleSection = styled(TitleSection)`
  max-width: 1140px;

  @media (max-width: 834px) {
    margin-top: 20px;
  }
`

const TextLeftFirstSection = styled(FirstSection)`
  flex: 3;
`

const TextLeftSecondSection = styled(SecondSection)`
  flex: 1;
`

const TextLeft = ({ slice, reversed }: { slice: any; reversed?: boolean }) => {
  const { image, title, copy } = slice.primary
  const images = getImage(image)

  const Content = styled(reversed ? ReversedContentSection : ContentSection)`
    column-gap: 30px;
  `

  const SubTitleWrap = styled(SubTitle)`
    ${TextContentStyle}
  `

  return (
    <MintWrapper id={slice.primary.slice_id?.text || ""}>
      {title && title.text && title.text.length > 0 && (
        <TextLeftTitleSection>
          <CenterItalicHeading text={title.text} />
        </TextLeftTitleSection>
      )}

      <Content>
        <TextLeftFirstSection>
          <SubTitleWrap dangerouslySetInnerHTML={{ __html: copy.html }} />
        </TextLeftFirstSection>

        <TextLeftSecondSection>
          <GatsbyImage image={images} alt={title.text || ""} loading="eager" />
        </TextLeftSecondSection>
      </Content>
    </MintWrapper>
  )
}

export const query = graphql`
  fragment TextLeftSlice on PrismicLandingDataBodyTextLeft {
    id
    slice_type
    primary {
      title {
        text
      }
      copy {
        html
      }
      image {
        gatsbyImageData(width: 190, height: 190)
      }
    }
  }
  fragment PageTextLeftSlice on PrismicPageDataBodyTextLeft {
    id
    slice_type
    primary {
      slice_id {
        text
      }
      title {
        text
      }
      copy {
        html
      }
      image {
        gatsbyImageData(width: 285)
      }
    }
  }
  fragment PostTextLeftSlice on PrismicPostDataBodyTextLeft {
    id
    slice_type
    primary {
      title {
        text
      }
      copy {
        html
      }
      image {
        gatsbyImageData(width: 285)
      }
    }
  }
  fragment ProviderTextLeftSlice on PrismicProviderDataBodyTextLeft {
    id
    slice_type
    primary {
      title {
        text
      }
      copy {
        html
      }
      image {
        gatsbyImageData(width: 190, height: 190)
      }
    }
  }
  fragment CounselorTextLeftSlice on PrismicCounselorDataBodyTextLeft {
    id
    slice_type
    primary {
      title {
        text
      }
      copy {
        html
      }
      image {
        gatsbyImageData(width: 190, height: 190)
      }
    }
  }
  fragment TherapistTextLeftSlice on PrismicTherapistDataBodyTextLeft {
    id
    slice_type
    primary {
      title {
        text
      }
      copy {
        html
      }
      image {
        gatsbyImageData(width: 190, height: 190)
      }
    }
  }
`

export default TextLeft
