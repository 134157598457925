import { useEffect, useState } from "react"
import { ICarePerson } from "../../CarePersonPage/types"
import axios from "axios"

export const useCarePersonAvailability = (carePersonData: ICarePerson) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const [availableDates, setAvailableDates] = useState([])
  const [timeSlots, setTimeSlots] = useState([])
  const [timeSelected, setTimeSelected] = useState("")
  const [dateSelected, setDateSelected] = useState<Date>()
  const [hasBeenLoaded, setHasBeenLoaded] = useState(false)
  const [isSlotLoading, setIsSlotLoading] = useState(false)

  const DURATION = 45

  const getAvailableDates = async () => {
    const params = `email=${carePersonData.email}&timezone=${timezone}`
    const url = `${process.env.GATSBY_API_SERVER_URL}/api/public/calendar/availability?${params}&duration=${DURATION}`

    try {
      const data = await axios.get(`${url}`)
      setAvailableDates(data.data)
    } catch (e) {
      console.log(e)
    }
    setHasBeenLoaded(true)
  }

  useEffect(() => {
    if (carePersonData.accepting_new_client) {
      getAvailableDates()
    } else {
      setHasBeenLoaded(true)
    }
  }, [carePersonData.accepting_new_client])

  const handleChangeDate = async (date: Date) => {
    const dateSelected = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`
    try {
      setIsSlotLoading(true)
      const param = `email=${carePersonData.email}&date=${dateSelected}&duration=${DURATION}&timezone=${timezone}`
      const data = await axios.get(
        `${process.env.GATSBY_API_SERVER_URL}/api/public/calendar/slots_by_date?${param}`
      )
      setTimeSlots(data.data)
      setTimeSelected("")
      setDateSelected(date)
    } catch (e) {
      console.log(e)
    } finally {
      setIsSlotLoading(false)
    }
  }

  const dateString = dateSelected?.toLocaleDateString("en-us", {
    weekday: "long",
    month: "numeric",
    day: "numeric",
  })

  return {
    availableDates,
    timeSlots,
    timeSelected,
    dateString,
    setTimeSelected,
    handleChangeDate,
    hasBeenLoaded,
    isSlotLoading,
  }
}
