import React from "react"
import styled from "styled-components"
import { QuotesCarousel } from "../QuotesCarousel"
import { contentHubQuotes } from "./constants"

const TestimonialsCardsWrapper = styled.div`
  width: 100%;
  max-width: 992px;
  padding: 64px 0;

  & > div:nth-child(2) {
    margin-bottom: 40px;
  }

  & > div > div > div > div > div#indicator-active {
    width: 16px;
  }

  & > div:nth-child(2) > div > div > div:nth-child(2) > div > p:first-child {
    color: #250044;
  }

  & > div:nth-child(2) > div > div > div:nth-child(2) > div > p:last-child {
    font-weight: 600;
  }

  & > div > div:last-child button:first-child {
    margin-left: 16px;
  }

  & > div > div:last-child button:last-child {
    margin-right: 16px;
  }

  @media (max-width: 834px) {
    padding: 48px 0;

    & > div:nth-child(2) > div > div:last-child {
      justify-content: space-between;
    }
  }
`

const Title = styled.div`
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: #1c1e21;
  text-align: center;
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const SeeMoreButton = styled.a`
  color: #51459e;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`

const ContentHubTestimonials = () => {
  return (
    <TestimonialsCardsWrapper>
      <Title>Success stories</Title>
      <QuotesCarousel quotes={contentHubQuotes} />
      <ButtonWrapper>
        <SeeMoreButton href="/testimonials">
          See what else people are saying
        </SeeMoreButton>
      </ButtonWrapper>
    </TestimonialsCardsWrapper>
  )
}

export default ContentHubTestimonials
