import {
  AlternatingSplashContainer,
  SectionBody,
  SectionContainer,
  SectionHeader,
  SplashImage,
} from "./styled"

export interface IApproachesWeUseCopy {
  section: {
    heading: string
    body: {
      heading: string
      description: string
    }[]
    image: string
  }
}

export interface IApproachesWeUseProps {
  copy: IApproachesWeUseCopy
}

export const ApproachesWeUse = ({ copy }: IApproachesWeUseProps) => {
  const { section } = copy
  return (
    <AlternatingSplashContainer>
      <SectionContainer className="top">
        <div>
          <SectionHeader>{section.heading}</SectionHeader>
          {section.body.map((item, index) => (
            <SectionBody key={index}>
              <div>
                <h3>{item.heading}</h3>
                {item.heading === "Highly rated" ? (
                  <p>
                    We thoroughly vet and only invite top clinical experts to
                    join Cerebral. 99% of our therapists have a{" "}
                    <span style={{ fontWeight: "600" }}>
                      4.8 approval rating or higher
                    </span>{" "}
                    from their clients.
                  </p>
                ) : (
                  <p>{item.description}</p>
                )}
              </div>
            </SectionBody>
          ))}
        </div>
        <div>
          <SplashImage src={section.image} alt="" />
        </div>
      </SectionContainer>
    </AlternatingSplashContainer>
  )
}
