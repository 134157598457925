import React from "react"
import starSvg from "../../../images/star.svg"
import forbesSvg from "../../../images/forbes.svg"
import plantSvg from "../../../images/plant.svg"

import {
  Container,
  RatingsContainer,
  RatingContainer,
  Star,
  Forbes,
  ForbesRatingContainer,
  FiveHundredKContainer,
} from "./styled"

interface RatingsProps {
  showPlant?: boolean
}
export const Ratings = ({ showPlant }: RatingsProps): JSX.Element => {
  return (
    <Container showPlant={showPlant}>
      <RatingsContainer>
        <RatingContainer>
          <div>
            <p>
              <strong>
                Best Telehealth <br /> Platform 2023
              </strong>
            </p>
          </div>
          <span>
            MedTech <br /> Breakthrough
          </span>
        </RatingContainer>
        <RatingContainer>
          <div>
            <Star src={starSvg} alt="" />
            <h3>4.6</h3>
          </div>
          <span>
            rating in the <br />
            App Store
          </span>
        </RatingContainer>
        <ForbesRatingContainer>
          <Forbes src={forbesSvg} alt="forbes" loading="lazy" />
          <span>
            Best Online Therapy <br />
            Services Of 2024
          </span>
        </ForbesRatingContainer>
        <FiveHundredKContainer>
          <div>
            <h3>500k+</h3>
          </div>
          <span>
            clients <br />
            helped
          </span>
        </FiveHundredKContainer>
      </RatingsContainer>
      {showPlant && <img src={plantSvg} loading="lazy" alt="" />}
    </Container>
  )
}
