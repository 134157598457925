import { useState } from "react"
import { Formik, Form, useField, FormikErrors, FormikTouched } from "formik"
import * as Yup from "yup"
import segment from "../../../../../lib/segment"
import {
  StayInTouchText,
  DetailsText,
  Submit,
  Input,
  StayInTouchWrapper,
  FormContainer,
  ErrorMessage,
  SuccessMessage,
  HipaaWrapper,
  HipaaContent,
} from "./styled"
import CheckBox from "../../../../ui/Checkbox"

type FormValues = {
  name: string
  email: string
}

export const LetsStayInTouch = () => {
  const [isChecked, setIsChecked] = useState(false)
  const [success, setSucess] = useState(false)
  const onSubmit = ({ name, email }: FormValues) => {
    segment.addUserToIterableNewsletter(email, name, "")
    setSucess(true)
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter your name"),
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Please enter a valid email"),
  })

  const InputField = (props: { name: string; placeholder: string }) => {
    const [field] = useField(props.name)
    return <Input data-testid={props.name} {...field} {...props} />
  }

  const FormErrorMessage = ({
    errors,
    touched,
  }: {
    errors: FormikErrors<FormValues>
    touched: FormikTouched<FormValues>
  }) =>
    (errors.name && touched.name) || (errors.email && touched.email) ? (
      <ErrorMessage data-testid="errorMessage">
        {errors.name || errors.email}
      </ErrorMessage>
    ) : null

  return (
    <StayInTouchWrapper>
      <StayInTouchText>Let's stay in touch</StayInTouchText>
      <DetailsText>
        Get the latest in mental health, free events, feature updates and more.
      </DetailsText>
      {success || (
        <Formik
          initialValues={{
            name: "",
            email: "",
          }}
          validationSchema={validationSchema}
          onSubmit={values => onSubmit(values)}
        >
          {({ errors, touched }) => (
            <Form style={{ width: "100%" }}>
              <FormContainer>
                <InputField name="name" placeholder="Name" />
                <InputField name="email" placeholder="Email" />
                <FormErrorMessage errors={errors} touched={touched} />
                <HipaaWrapper>
                  <CheckBox
                    variant="secondary"
                    onChange={() => setIsChecked(!isChecked)}
                    checked={isChecked}
                  />
                  <HipaaContent>
                    I accept the{" "}
                    <a
                      href="https://cerebral.com/hipaa-marketing-authorization"
                      target="_blank"
                    >
                      Cerebral HIPAA Marketing Authorization.
                    </a>
                  </HipaaContent>
                </HipaaWrapper>
                <Submit type="submit" disabled={!isChecked}>
                  Submit
                </Submit>
              </FormContainer>
            </Form>
          )}
        </Formik>
      )}
      {success && (
        <SuccessMessage data-testid="successMessage">
          You have successfully been subscribed to our newsletter
        </SuccessMessage>
      )}
    </StayInTouchWrapper>
  )
}
