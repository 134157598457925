import styled from "styled-components"
import { colors } from "../../themes/colors"

export const ExceptionalCareWrapper = styled.section`
  background-color: ${colors.c_secondary[50]};
  display: flex;
  flex-direction: column;
  padding-top: 40px;

  @media (max-width: 834px) {
    align-items: center;
  }
`

export const HeadlineContainer = styled.div`
  text-align: center;
  margin: 24px 60px 40px 60px;

  p {
    margin-top: 30px;
    font-size: 14px;
    line-height: 20px;
  }

  @media (max-width: 834px) {
    margin-bottom: 0px;
    max-width: 360px;
  }

  @media (max-width: 290px) {
    margin: 24px 10px 40px;
  }
`

export const InnerContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 10% 0 10%;

  @media (max-width: 834px) {
    flex-direction: column;
    align-items: center;
  }

  & > div:last-child {
    margin-bottom: 60px;
  }
`

export const TextColumn = styled.div`
  flex: 1;
  max-width: 360px;
  margin-top: 40px;

  @media (max-width: 834px) {
    margin-top: 10px;
  }
`

export const ImageColumn = styled.div`
  position: relative;
  overflow: auto;
  flex: 1.3;
  max-width: 400px;
  margin: 0 40px;

  @media (max-width: 834px) {
    order: 3;
    margin: 0;
  }
`

export const InnerImageColumn = styled.div`
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  bottom: 0;

  img {
    width: 100%;
  }

  @media (max-width: 834px) {
    max-width: 300px;
    max-height: 300px;
    position: static;
  }
`

export const ExceptionalCareText = styled.section`
  margin-top: 30px;
`

export const Title = styled.h3`
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
`

export const Description = styled.p`
  font-size: 14px;
  line-height: 24px;
  color: ${colors.c_grey[700]};
  font-weight: 400;
  padding-top: 24px;
`
