import styled from "styled-components"
import { colors } from "../../../themes/colors"
import Blush from "../assets/blush.svg"
import { NavItem } from "../shared/styled"
import { Link } from "gatsby"

export const ItemList = styled.ul`
  flex-direction: column;

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    margin: 0;
    gap: 8px;

    & li:first-child {
      margin-left: -12px;
    }
  }
`

const handleTextColor = (isSelected: boolean, anySelectedItems: boolean) => {
  if (isSelected) return `${colors.c_black[900]}`
  if (anySelectedItems) return `${colors.c_grey[700]}`
  return `${colors.c_black[900]}`
}

export const Item = styled(NavItem)<{
  isSelected: boolean
  anySelectedItems: boolean
}>`
  align-content: center;
  cursor: pointer;
  padding: 0 24px;

  &:focus {
    border-radius: 8px;
  }

  button {
    cursor: pointer;
  }

  @media (min-width: 1024px) {
    img {
      display: none;
    }

    button {
      font-size: 16px;
      color: ${({ isSelected, anySelectedItems }) =>
        handleTextColor(isSelected, anySelectedItems)};
      transition: color 0.3s ease;
      text-decoration: ${({ isSelected }) =>
        isSelected ? "underline" : "none"};
      text-decoration-color: ${colors.c_secondary[400]};
      text-decoration-style: solid;
      text-underline-offset: 16px;
      text-decoration-thickness: 2px;
      padding: 12px;
    }

    button:hover {
      background: url(${Blush});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      outline: none;
      background-color: white;
    }

    padding: 0;
  }
`

export const SubMenuBackground = styled.div<{
  isBannerShown?: boolean
}>`
  position: fixed;
  top: ${({ isBannerShown }) => (isBannerShown ? "127px" : "91px")};
  left: 0;
  width: 100vw;
  height: ${({ isBannerShown }) =>
    isBannerShown ? "calc(100vh - 127px)" : "calc(100vh - 91px)"};
  background-color: rgb(0, 0, 0);
  opacity: 0.5;
  transition: opacity 0.3s ease;
  z-index: -2;
  &.fade-enter {
    opacity: 0;
  }

  &.fade-enter {
    opacity: 0;
  }

  &.fade-enter-active {
    opacity: 0.5;
    transition: all 0.3s ease;
  }

  &.fade-exit {
    opacity: 0.5;
    transition: all 0.3s ease;
  }

  &.fade-exit-active {
    opacity: 0;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

export const Logo = styled(Link)`
  img {
    width: 120px;
  }
`
