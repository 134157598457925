import { CarePersonDetails } from "./types"
import { ICarePerson } from "../../CarePersonPage/types"
import { IInsuranceAcceptedItem } from "../../../components/CarePersonPage/sections/Availability/Availability"

export const getCarePersonDetails = (
  licensedIn: string[],
  specialties: string[],
  degrees: string[],
  insurance_accepted: Record<string, IInsuranceAcceptedItem[]>,
  role: string
): CarePersonDetails[] => {
  const carePersonDetails: CarePersonDetails[] = []
  if (licensedIn.length > 0) {
    carePersonDetails.push({ title: "Licensed in", content: licensedIn })
  }
  if (role === "Therapist") {
    if (specialties.length > 0) {
      carePersonDetails.push({
        title: "Specialties",
        content: specialties,
      })
    }
  } else if (role === "Prescriber") {
    if (degrees.length > 0) {
      carePersonDetails.push({
        title: "Degrees",
        content: degrees,
      })
    }
  }

  if (insurance_accepted) {
    const payers = Object.values(insurance_accepted).reduce(
      (newPayers: string[], currentPayers) => {
        const mappedPayers = currentPayers
          .map(item => item?.payer)
          .filter(item => Boolean(item))
        newPayers = [...newPayers, ...mappedPayers]

        return newPayers
      },
      []
    )

    if (payers.length > 0) {
      carePersonDetails.push({
        title: "Insurance accepted",
        content: [...new Set(payers)].sort((a, b) => a.localeCompare(b)),
      })
    }
  }

  return carePersonDetails
}

const pronounLookup = {
  "she/her": "Her",
  "he/him": "His",
  "they/them": "Their",
}

const formatLongList = (list: string[], skipLowercase: boolean = false) => {
  const lowerCased = list.map(speciality => {
    return speciality
      .split(" ")
      .map(word => (skipLowercase ? word : word.toLowerCase()))
      .join(" ")
  })
  if (lowerCased.length > 3) {
    return [...lowerCased.slice(0, 3), "and more"].join(", ")
  }
  return lowerCased.join(", ")
}

export const createCarePersonBio = (
  carePerson: ICarePerson,
  city?: string,
  state?: string,
  pageType?: string
) => {
  const {
    name,
    pronouns,
    specialties,
    ethnicity,
    languages,
    licensed_states,
    role,
    degrees,
  } = carePerson

  const formatter = new Intl.ListFormat("en", {
    style: "long",
    type: "conjunction",
  })
  const formattedDegrees = role === "Prescriber" ? `${degrees.join(", ")} ` : ""

  let carePersonBio =
    pageType === "specialty"
      ? `${name} is a ${formattedDegrees}${role.toLowerCase()} based in ${
          licensed_states[0]
        }.`
      : `${name} is a ${formattedDegrees}${role.toLowerCase()} based in ${
          city ? `${city}, ${state}.` : `${state}.`
        }`

  const carePersonSingularPronoun =
    (pronouns && pronouns.slice(0, pronouns.indexOf("/"))) || "they"

  if (specialties?.length) {
    if (pronouns)
      carePersonBio += ` ${pronounLookup[pronouns]} pronouns are ${pronouns}${
        role === "Therapist"
          ? ` and ${carePersonSingularPronoun} ${
              carePersonSingularPronoun === "they"
                ? `specialize`
                : `specializes`
            } in ${formatLongList(specialties)}`
          : ""
      }. `
    else carePersonBio += `They specialize in ${formatLongList(specialties)}. `
  }

  if (ethnicity && ethnicity.length) {
    if (languages)
      carePersonBio += ` ${
        carePerson.name
      }'s ethnic background is ${formatter.format(ethnicity)}, and ${
        carePersonSingularPronoun === "they"
          ? `they speak`
          : `${carePersonSingularPronoun} speaks`
      } ${formatter.format(languages)}. `
    else
      carePersonBio += ` ${
        carePerson.name
      }'s ethnic background is ${formatter.format(ethnicity)}. `
  } else if (languages?.length) {
    carePersonBio += `${carePerson.name} speaks ${formatter.format(languages)}.`
  }

  return carePersonBio
}
