import React from "react"

import { Footer as FooterWrapper, StoresWrapper } from "./styled"
import {
  Menu,
  Stores,
  CopyRight,
  EmergencyInformation,
  SocialMedia,
} from "./components"

export const Footer = (): JSX.Element => {
  return (
    <div>
      <EmergencyInformation />
      <FooterWrapper>
        <Menu />
        <SocialMedia />
        <StoresWrapper>
          <Stores />
          <CopyRight />
        </StoresWrapper>
      </FooterWrapper>
    </div>
  )
}
