import { createContext, ReactNode } from "react"
import {
  PageCategory,
  LocationPageRawData,
  LocationPageBodyData,
} from "./types"

const locationPageInitialState = {
  associatedLocations: {},
  city: undefined,
  state: undefined,
  showPage: true,
  specialty: undefined,
  carePersons: [],
  totalCarePersons: 0,
  carePersonType: undefined,
}

export const LocationPageContext = createContext<LocationPageBodyData>(
  locationPageInitialState
)

export const LocationPageProvider = ({
  children,
  locationPageData,
}: {
  children: ReactNode
  locationPageData: LocationPageRawData
}) => {
  const {
    state_name: state,
    city_name: city,
    carePersons: carePersons,
    specialty,
    show_page: showPage,
    associated_locations: associatedLocations,
    carePersonType,
  } = locationPageData

  let pageType
  if (state && !city && !specialty) pageType = PageCategory.STATE
  if (state && city && !specialty) pageType = PageCategory.CITY
  if (!state && !city && specialty) pageType = PageCategory.SPECIALTY
  if (state && city && specialty) pageType = PageCategory.CITY_SPECIALTY
  if (state && !city && !specialty) pageType = PageCategory.STATE_SPECIALTY

  const value = {
    state,
    city,
    specialty,
    carePersons,
    totalCarePersons: carePersons.length,
    pageType,
    showPage,
    associatedLocations,
    carePersonType,
  }

  return (
    <LocationPageContext.Provider value={value}>
      {children}
    </LocationPageContext.Provider>
  )
}
