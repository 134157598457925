import { css } from "styled-components"
import { colors } from "./colors"

export const borderRadius = {
  xs: "4px",
  sm: "8px",
  md: "12px",
  lg: "16px",
  xl: "24px",
} as const

export const spacing = {
  none: "0px",
  xs: "4px",
  sm: "8px",
  smd: "12px",
  md: "16px",
  mlg: "20px",
  lg: "24px",
  xl: "32px",
  xxl: "40px",
  exl: "48px",
} as const

export const sizes = {
  sm: "8px",
  smd: "12px",
  md: "16px",
  mlg: "20px",
  lg: "24px",
  mxl: "26px",
  xl: "32px",
  xxl: "40px",
  exl: "44px",
  elg: "48px",
  sl: "64px",
} as const

export const focusShadow = css`
  box-shadow: 0 0 0 2px ${colors.c_white[500]},
    0 0 0 4px ${colors.c_secondary[300]};
  outline: none;
`

export const hoverShadow = css`
  border-color: ${colors.c_primary[900]};
  box-shadow: 0 0 12px rgba(92, 78, 131, 0.16);
`
