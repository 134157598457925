import { colors } from "../../../themes/colors"
import styled from "styled-components"

export const CalendarTitle = styled.p`
  margin: 16px 0px 32px;
  color: #250044;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`
export const AvailabilityHeading = styled.p`
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;

  @media (max-width: 1248px) {
    max-width: 292px;
  }

  @media (max-width: 1024px) {
    max-width: fit-content;
  }

  @media (max-width: 600px) {
    padding: 0px 32px;
    font-size: 20px;
  }

  @media (max-width: 460px) {
    max-width: 324px;
  }
`

export const InnerContentContainer = styled.div`
  align-items: center;
  display: flex;
  max-width: 704px;
  min-width: 275px;
  flex-direction: column;
  margin: 0 auto;
  @media (max-width: 1024px) {
    width: 80%;
  }
  @media (max-width: 600px) {
    width: 100%;
    padding: 28px 0px;
  }
`

export const BookButton = styled.button`
  width: 320px;
  height: 56px;
  background: ${colors.c_primary[600]};
  border-radius: 28px;
  color: ${colors.c_white[500]};
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-top: 16px;
  cursor: pointer;
  border: none;

  &:disabled {
    opacity: 0.5;
    background: #f0f1f5;
    color: #898e99;
    cursor: not-allowed;
    border: none;
  }
`

export const FooterText = styled.p`
  padding-top: 16px;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  a {
    color: ${colors.c_secondary[600]};
    text-decoration: underline;
  }
`

export const SelectedDateText = styled.p`
  margin: 16px 0 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #250044;
`

export const SelectGroupWrapper = styled.div<{
  direction: "column" | "row" | "none"
}>`
  margin-bottom: 32px;
  width: 320px;

  & > div:nth-child(1) {
    border-bottom-left-radius: ${props =>
      props.direction === "column" ? 0 : ""};
    border-bottom-right-radius: ${props =>
      props.direction === "column" ? 0 : ""};
    border-bottom: ${props => (props.direction === "column" ? "none" : "")};

    & > div {
      border-top-left-radius: ${props =>
        props.direction === "column" ? 0 : ""};
      border-top-right-radius: ${props =>
        props.direction === "column" ? 0 : ""};
    }
  }

  & > div:nth-child(2) {
    border-top-left-radius: ${props => (props.direction === "column" ? 0 : "")};
    border-top-right-radius: ${props =>
      props.direction === "column" ? 0 : ""};

    & > div {
      border-top-left-radius: ${props =>
        props.direction === "column" ? 0 : ""};
      border-top-right-radius: ${props =>
        props.direction === "column" ? 0 : ""};
    }
  }

  @media (max-width: 600px) {
    width: 100%;
    padding: 0px 32px;
  }
`

export const CalendarWrapper = styled.div`
  width: 320px;
  border-radius: var(--base-s-05, 4px);
  background: var(--White-900, #fff);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);

  @media (max-width: 600px) {
    width: calc(100% - 16px);
  }
`

export const DescriptionText = styled.div`
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 16px;
`

export const DescriptionTitle = styled.div`
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`
export const UnavailabilityWrapper = styled.div<{ mt?: string }>`
  margin-top: ${props => props.mt || 0};
`
