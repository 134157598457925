import React, { useRef, useEffect } from "react"
import { createWidget } from "@typeform/embed"
import "@typeform/embed/build/css/widget.css"
import styled from "styled-components"
import Link from "./Link"
import logoImage from "../images/logo.svg"

const Logo = styled(Link)`
  display: block;
  margin: 20px;
  width: 112px;
  height: 25px;
  background-size: cover;
  background-image: url(${logoImage});
  z-index: 1;

  @media (min-width: 834px) {
    width: 178px;
    height: 40px;
  }

  img {
    height: 100%;
    width: 100%;
  }
`

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Container = styled.div`
  flex: 1;
`

const TypeformEmbed = ({ id }) => {
  const container = useRef()

  useEffect(() => {
    createWidget(id, { container: container.current })
  }, [])

  return (
    <Wrapper>
      <Logo to="/" />
      <Container ref={container} />
    </Wrapper>
  )
}

export default TypeformEmbed
