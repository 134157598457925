import React from "react"
import {
  GradientCtaButton,
  SolidCtaButton,
  StrokeCtaButton,
} from "../prismic/LandingPageStyles"

interface CtaStyleProps {
  style: string
  link: string
  text: string
  backgroundColor?: string
  hoverColor?: string
  textColor?: string
  onClick?: () => void
}

const StyledCta = ({
  style,
  link,
  text,
  backgroundColor,
  hoverColor,
  textColor,
  onClick,
}: CtaStyleProps) => {
  switch (style) {
    case "Solid":
      return (
        <SolidCtaButton
          to={link || process.env.GATSBY_START_URL}
          onClick={onClick}
          backgroundColor={backgroundColor}
          hoverColor={hoverColor}
          textColor={textColor}
        >
          {text}
        </SolidCtaButton>
      )
    case "Gradient":
      return (
        <GradientCtaButton
          to={link || process.env.GATSBY_START_URL}
          onClick={onClick}
        >
          {text}
        </GradientCtaButton>
      )
    case "Stroke":
      return (
        <StrokeCtaButton
          to={link || process.env.GATSBY_START_URL}
          onClick={onClick}
          backgroundColor={backgroundColor}
          hoverColor={hoverColor}
          textColor={textColor}
        >
          {text}
        </StrokeCtaButton>
      )
    default:
      return (
        <SolidCtaButton
          to={link || process.env.GATSBY_START_URL}
          onClick={onClick}
          backgroundColor={backgroundColor}
          textColor={textColor}
        >
          {text}
        </SolidCtaButton>
      )
  }
}

export default StyledCta
