import styled from "styled-components"

export const CardWrapper = styled.div`
  border-radius: 20px;
  border: 1px solid #a8baff;
  padding: 24px;
  width: 297px;
  height: 210px;
  display: flex;
  flex-direction: column;
  & > h3 {
    font-size: 20px;
    line-height: 27px;
    color: #222128;
    font-weight: 500;
    @media (max-width: 834px) {
      font-size: 16px;
      line-height: 19px;
    }
  }
  & > a {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-decoration: underline;
    margin-top: auto;
    @media (max-width: 834px) {
      font-weight: 400;
      font-size: 12px;
      line-height: 30px;
    }
  }
  &:hover {
    border: 1px solid #1a0000;
  }

  @media (max-width: 834px) {
    width: 163px;
    height: 173px;
    padding: 16px;
  }

  @media (max-width: 499px) {
    width: 136px;
  }

  @media (max-width: 383px) {
    width: 126px;
  }
`
