import { colors } from "../../../themes/colors"

const LeftArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={colors.c_primary[900]}
    >
      <path
        d="M14.8301 17.0002C15.0163 16.8128 15.1208 16.5594 15.1208 16.2952C15.1208 16.031 15.0163 15.7776 14.8301 15.5902L11.2901 12.0002L14.8301 8.46019C15.0163 8.27283 15.1208 8.01938 15.1208 7.75519C15.1208 7.49101 15.0163 7.23756 14.8301 7.05019C14.7371 6.95646 14.6265 6.88207 14.5046 6.8313C14.3828 6.78053 14.2521 6.75439 14.1201 6.75439C13.988 6.75439 13.8573 6.78053 13.7355 6.8313C13.6136 6.88207 13.503 6.95646 13.4101 7.05019L9.17006 11.2902C9.07633 11.3832 9.00193 11.4938 8.95117 11.6156C8.9004 11.7375 8.87426 11.8682 8.87426 12.0002C8.87426 12.1322 8.9004 12.2629 8.95117 12.3848C9.00193 12.5066 9.07633 12.6172 9.17006 12.7102L13.4101 17.0002C13.503 17.0939 13.6136 17.1683 13.7355 17.2191C13.8573 17.2699 13.988 17.296 14.1201 17.296C14.2521 17.296 14.3828 17.2699 14.5046 17.2191C14.6265 17.1683 14.7371 17.0939 14.8301 17.0002Z"
        fill="#706897"
      />
    </svg>
  )
}

export default LeftArrow
