import MedicationImage from "../../shared/LeadCapturePopup/images/medication.png"
import PickupMeds from "../../shared/LeadCapturePopup/images/pickup-meds.svg"
import { segmentEvents } from "../../../lib/segment"

export const leadCapturePopupVariables = [
  {
    experiment: "Show lead capture popup",
    variant: "SHOW",
    storageKey: "leadCaptureCollection",
    url: "/care-resources",
    segmentKey: segmentEvents.LEAD_CAPTURE_RESOURCES_COLLECTION,
  },
  {
    experiment: "Lead Capture pop-up on Prescriptions LP",
    variant: "SHOW",
    storageKey: "leadCapturePrescriptionsLP",
    url: "/prescription-medication",
    segmentKey: segmentEvents.LEAD_CAPTURE_MEDICATION_COLLECTION,
    image: {
      url: MedicationImage,
      backgroundSize: "contain",
      icon: PickupMeds,
      text: "Save up to 80% on medication",
    },
  },
  {
    experiment: "Lead Capture pop-up on Prescriptions LP",
    variant: "SHOW",
    storageKey: "leadCapturePrescriptionsLP",
    url: "/sertraline-zoloft",
    segmentKey: segmentEvents.LEAD_CAPTURE_MEDICATION_COLLECTION,
    image: {
      url: MedicationImage,
      backgroundSize: "contain",
      icon: PickupMeds,
      text: "Save up to 80% on medication",
    },
  },
]
