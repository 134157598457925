import starSvg from "./assets/star-cw.svg"
import forbesSvg from "./assets/forbes-logo-cw.svg"

import {
  StatsWrapper,
  StatContainer,
  StatsContainer,
  ContentWrapper,
} from "./styled"

interface StatsProps {
  bgColor?: string
}

export const StatsBlueBackground = ({ bgColor }: StatsProps): JSX.Element => {
  return (
    <StatsWrapper>
      <StatsContainer bgColor={bgColor}>
        <ContentWrapper>
          <StatContainer>
            <p>
              <strong>
                Best Telehealth <br /> Platform 2023
              </strong>
            </p>
            <span>
              MedTech <br /> Breakthrough
            </span>
          </StatContainer>
          <StatContainer>
            <h3>500k+</h3>
            <span>
              clients <br />
              helped
            </span>
          </StatContainer>
          <StatContainer>
            <div>
              <img src={starSvg} alt="" />
              <h3>4.6</h3>
            </div>
            <span>
              rating in the <br />
              App Store
            </span>
          </StatContainer>
          <StatContainer>
            <img src={forbesSvg} alt="forbes" loading="lazy" />
            <span>
              Best Online Therapy <br />
              Services Of 2024
            </span>
          </StatContainer>
        </ContentWrapper>
      </StatsContainer>
    </StatsWrapper>
  )
}
