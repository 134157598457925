export const formatDate = (date: Date) => {
  return new Date(date).toLocaleDateString("en-us", {
    year: "numeric",
    month: "short",
    day: "numeric",
  })
}

export const capitalizeFirstLetter = (text: string) => {
  return (
    text.toLocaleLowerCase()[0].toUpperCase() +
    text.toLocaleLowerCase().slice(1)
  )
}
