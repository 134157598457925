import styled from "styled-components"
import { colors } from "../../../themes/colors"
import { borderRadius } from "../../../themes/tokens"

export const TheCerebralWayWrapper = styled.section`
  max-width: 1040px;
  margin: 0 auto;
  padding: 80px 24px 64px 24px;
  @media (max-width: 834px) {
    padding: 32px 24px 0px 24px;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 56px;
  gap: 80px;
  align-items: center;
  @media (max-width: 834px) {
    flex-direction: column-reverse;
    max-width: 600px;
    gap: 32px;
    margin: 56px auto 0;
    align-items: center;
  }
`

export const ContentBlock = styled.div`
  display: flex;
`

export const CopySection = styled.div`
  margin-bottom: 32px;
  max-width: 456px;
  margin-left: 16px;
  h3 {
    color: ${colors.c_black[900]};
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 4px;
  }
  p {
    color: ${colors.c_grey[900]};
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
`
export const IconWrapper = styled.div`
  min-width: 32px;
  height: 32px;
  justify-content: center;
  display: flex;
  div {
    color: ${colors.c_primary[900]};
    background: ${colors.c_secondary[100]};
    border-radius: ${borderRadius.sm};
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 8px;
    border: 4px;
  }
`
export const IconImage = styled.img`
  width: 40px;
  height: 40px;
`

export const CWImageWrapper = styled.img`
  width: 456px;
  height: auto;
  @media (max-width: 1000px) {
    width: 333px;
  }
`
