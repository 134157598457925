import { useEffect, useMemo, useState } from "react"
import { SpinnerWrapper } from "./styled"
import Spinner from "../ui/Spinner"

interface IMicroFrontEndLoaderProps {
  serviceName: string
  serviceHost: string
  prefix: string
  props?: any
}

const MicroFrontEndLoader = ({
  serviceName,
  serviceHost,
  prefix,
  props,
}: IMicroFrontEndLoaderProps) => {
  const containerId = `microservice-${serviceName}`
  const manifestUrl = `${serviceHost}/asset-manifest.json`
  const [isLoading, setIsLoading] = useState(true)

  const fetchManifest = async (maxRetries = 2) => {
    let retries = 0

    const fetchWithRetry = async () => {
      try {
        const res = await fetch(manifestUrl)
        if (!res.ok) {
          throw new Error(
            `Failed to fetch screener MFE manifest: ${res.statusText}`
          )
        }
        return await res.json()
      } catch (error) {
        console.error(
          `Error fetching screener MFE (attempt ${retries + 1}):`,
          error
        )
        if (retries < maxRetries) {
          retries++
          return fetchWithRetry()
        } else {
          console.error("Screener MFE reached max retries. Returning null.")
          return null
        }
      }
    }

    return fetchWithRetry()
  }

  const renderMicroService = (
    serviceContainerId: string,
    callback: () => void,
    props: any
  ) => {
    ;(window as { [key: string]: any })[`render${serviceName}`] &&
      (window as { [key: string]: any })[`render${serviceName}`](
        serviceContainerId,
        prefix,
        serviceHost
      )
    callback()
  }

  const handleLoadingComplete = () => {
    setTimeout(() => setIsLoading(false), 200)
  }

  const loadScript = async () => {
    const manifest = await fetchManifest()
    if (!manifest) {
      setIsLoading(false)
      return
    }

    const scriptId = `${containerId}-script`
    const cssId = `${containerId}-css`

    const script = document.createElement("script")
    script.id = scriptId
    script.src = `${serviceHost}${manifest.files["main.js"]}`
    script.onload = () => {
      renderMicroService(containerId, handleLoadingComplete, props)
    }
    document.head.appendChild(script)

    if (manifest.files["main.css"]) {
      const link = document.createElement("link")
      link.id = cssId
      link.rel = "stylesheet"
      link.type = "text/css"
      link.media = "all"
      link.href = `${serviceHost}${manifest.files["main.css"]}`
      document.head.appendChild(link)
    }
  }

  const handleUnmount = () => {
    // Clean up dynamically added script and link tags
    const script = document.getElementById(`${containerId}-script`)
    if (script) {
      script.remove()
    }

    const link = document.getElementById(`${containerId}-css`)
    if (link) {
      link.remove()
    }
  }

  useEffect(() => {
    loadScript()

    return handleUnmount()
  }, [serviceName, serviceHost, prefix])

  return (
    <>
      {isLoading && (
        <SpinnerWrapper>
          <Spinner className="" />
        </SpinnerWrapper>
      )}
      <section id={containerId} />
    </>
  )
}

export default MicroFrontEndLoader
