import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FAQSection } from "../shared/FAQSection"

const FAQ_SLICE_QUERY = graphql`
  query FaqSliceQuery {
    allPrismicFaqWidget {
      edges {
        node {
          data {
            page_type
            faqs {
              question {
                document {
                  ... on PrismicFaqRedesign {
                    id
                    data {
                      question {
                        text
                      }
                      answer {
                        html
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

interface FAQProps {
  slice: {
    primary: {
      slice_id?: {
        text: string
      }
      title: {
        text: string
      }
      page_type: string
    }
    items: {
      faq_question: {
        document: {
          data: {
            question: {
              text: string
            }
            answer: {
              html: string
            }
          }
        }
      }
    }[]
  }
}

const FAQ = ({ slice }: FAQProps) => {
  const { page_type } = slice.primary
  const response = useStaticQuery(FAQ_SLICE_QUERY)

  const getFaqs = () => {
    if (page_type) {
      const data = response.allPrismicFaqWidget.edges.filter(
        (edge: any) => edge.node.data.page_type === page_type
      )

      return data[0]?.node.data.faqs || []
    }

    return slice.items
      .map(item => ({
        question: item.faq_question,
      }))
      .filter(item => item.question.document)
  }

  return (
    <FAQSection faqs={getFaqs()} title={slice.primary.title.text} id="faq" />
  )
}

export const query = graphql`
  fragment FAQSlice on PrismicLandingDataBodyFaq {
    slice_type
    primary {
      title {
        text
      }
      page_type
    }
    items {
      faq_question {
        document {
          ... on PrismicFaqRedesign {
            data {
              answer {
                html
              }
              question {
                text
              }
            }
          }
        }
      }
    }
  }

  fragment PageFAQSlice on PrismicPageDataBodyFaq {
    slice_type
    primary {
      title {
        text
      }
      tab_id
      page_type
    }
    items {
      faq_question {
        document {
          ... on PrismicFaqRedesign {
            data {
              answer {
                html
              }
              question {
                text
              }
            }
          }
        }
      }
    }
  }
`
export default FAQ
