import { useEffect, useState } from "react"
import segment, { segmentEvents } from "../../../../lib/segment"
import { ModalState, useModalContext } from "../../../../contexts/ModalContext"
import { CustomDropdown } from "../../CustomDropdown"
import LocationPoint from "../assets/LocationPoint"
import Postcard from "../assets/Postcard"
import { insuranceStates } from "../constants"
import { InsuranceSuccessDialog } from "../InsuranceSuccessDialog"
import { convertCSVToInsuranceMap, RichText } from "../utils"
import { FormSubmitButton, FormWrapper } from "./styled"

// TODO: deprecate this component when the insurance_rte_release_state_provider_config flag is removed
export const InsuranceCarrierSelect = ({
  insuranceCsv,
  externalSource = "insurance_widget",
}: {
  insuranceCsv: RichText[]
  externalSource?: string
}) => {
  const [selectedState, setSelectedState] = useState<string | null>(null)
  const [selectedCarrier, setSelectedCarrier] = useState<string | null>(null)
  const [stateFormError, setStateFormError] = useState<string>()
  const [insuranceFormError, setInsuranceFormError] = useState<string>()

  const { setModalState, setModalContent } = useModalContext()
  const insuranceMap = convertCSVToInsuranceMap(insuranceCsv)

  useEffect(() => {
    setSelectedCarrier(null)
  }, [selectedState])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault()
      if (!selectedState) {
        throw new Error("FormError:StateNotSelected")
      }
      if (!selectedCarrier) {
        throw new Error("FormError:CarrierNotSelected")
      }
      segment.track(
        segmentEvents.INSURANCE_WIDGET_SUBMIT,
        {},
        { integrations: { All: false, Heap: true } }
      )
      setModalContent(
        <InsuranceSuccessDialog
          carrier={selectedCarrier}
          state={selectedState}
          externalSource={externalSource}
        />,
        {
          displayCloseIcon: true,
          modalWidth: "wide",
          fullScreenOnMobile: false,
        }
      )
      setModalState(ModalState.OPEN)
    } catch (e) {
      if (e instanceof Error) {
        if (e.message === "FormError:StateNotSelected") {
          setStateFormError("Please select your state")
        }
        if (e.message === "FormError:CarrierNotSelected") {
          setInsuranceFormError("Please select your insurance carrier")
        }
      }
    }
  }

  useEffect(() => {
    if (selectedState && stateFormError) {
      setStateFormError(undefined)
    }
    if (selectedCarrier && insuranceFormError) {
      setInsuranceFormError(undefined)
    }
  }, [selectedState, selectedCarrier])

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <CustomDropdown
        options={insuranceStates}
        onSelect={e => setSelectedState(e)}
        LeftIcon={LocationPoint}
        value={selectedState}
        error={stateFormError}
        placeholder="Your state"
      />
      <CustomDropdown
        options={insuranceMap.get(selectedState || "") || []}
        onSelect={e => setSelectedCarrier(e)}
        LeftIcon={Postcard}
        value={selectedCarrier}
        error={insuranceFormError}
        isDisabled={!selectedState}
        placeholder="Insurance carrier"
      />
      <FormSubmitButton>Check my coverage</FormSubmitButton>
    </FormWrapper>
  )
}
