import { GetStartedButton } from "../GetStartedButton"
import {
  ButtonContainer,
  EndCapWrapper,
  GetStartedButtonCustom,
} from "./styled"
import { H2 } from "../../ui"

interface EndCapProps {
  title: string
  isMedsOrTherapy?: boolean
  btnMaxWidth?: string
  onGetStarted?: () => void
  hideGetStartedBtn?: boolean
}

export const EndCap = ({
  title,
  isMedsOrTherapy,
  btnMaxWidth,
  onGetStarted,
  hideGetStartedBtn,
}: EndCapProps): JSX.Element => {
  return (
    <EndCapWrapper isMedsOrTherapy={isMedsOrTherapy}>
      <H2>{title}</H2>
      {!hideGetStartedBtn && (
        <ButtonContainer maxWidth={btnMaxWidth}>
          {onGetStarted ? (
            <GetStartedButtonCustom onClick={onGetStarted}>
              Get started
            </GetStartedButtonCustom>
          ) : (
            <GetStartedButton />
          )}
        </ButtonContainer>
      )}
    </EndCapWrapper>
  )
}
