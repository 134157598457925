import styled from "styled-components"

interface ContainerProps {
  backgroundColor?: string
  padding?: string
}
export const Container = styled.section<ContainerProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: ${({ padding }) => padding || "96px 24px 112px"};
  position: relative;
  text-align: center;
  background: ${({ backgroundColor }) => backgroundColor};
  & > a {
    margin-top: 32px;
    min-width: 252px;
  }
  & > img {
    position: absolute;
    right: 10%;
    bottom: 0;

    @media (max-width: 500px) {
      right: 24px;
      max-width: 73px;
      max-height: 115px;
    }
  }
  @media (max-width: 834px) {
    & > img {
      right: 20px;
    }
  }
`
