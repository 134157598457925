import { graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image"
import { decodeHtmlCharCodes } from "../../lib/utils"

const Wrapper = styled.div`
  max-width: 992px;
  width: 100%;
  margin: auto;
  padding: 64px 0px;
  font-family: Poppins;

  @media (max-width: 1024px) {
    padding: 32px 24px;
  }
`

const Title = styled.div`
  color: #1c1e21;
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
`

const Body = styled.div`
  padding: 40px 0px;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;

  @media (max-width: 992px) {
    padding: 32px 0px;
    display: inline-grid;
  }
`

const Footer = styled.div``
const PrimaryButton = styled.a`
  padding: 20px 24px;
  background: #51459e;
  width: 320px;
  height: 56px;
  color: white;
  border-radius: 100px;
  margin: auto;
  text-align: center;
  cursor: pointer;
  display: block;
  text-decoration: none;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;

  @media (max-width: 992px) {
    width: 100%;
  }
`

const ColumnWrapper = styled.div`
  flex: 1 1 calc(33.33% - 25px);

  @media (max-width: 992px) {
    display: flex;
  }
`

const ImageWrapper = styled.span`
  background-color: #f6f4ff;
  padding: 8px;
  display: inline-block;
  border-radius: 4px;

  @media (max-width: 992px) {
    width: 36px;
    height: 36px;
    margin-right: 16px;
  }
`

const ColumnTitle = styled.div`
  color: #250044;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding-top: 16px;

  @media (max-width: 992px) {
    padding-top: 0px;
  }
`

const ColumnContent = styled.div`
  padding-top: 8px;
  color: #4d515a;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  strong {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-decoration-line: underline;
  }

  @media (max-width: 500px) {
    span.tooltiptext {
      left: -150% !important;
    }
  }

  span.tooltip {
    position: relative;
    display: inline-block;
    text-decoration: underline;
  }

  span.tooltiptext {
    visibility: hidden;
    width: 246px;
    background-color: #fafafc;

    color: #1c1e21;
    text-align: left;
    border-radius: 6px;
    padding: 8px 16px;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: -10%;
    margin-left: -70px;
    margin-top: 10px;
    font-size: 12px;
    line-height: 16px;
  }

  span.tooltip:hover {
    span.tooltiptext {
      visibility: visible;
      border: 1px solid #d1d3d7;

      &::before {
        content: "";
        display: block;
        position: absolute;
        left: 120px;
        bottom: 100%;
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-bottom-color: #d1d3d7;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        left: 121px;
        bottom: 100%;
        width: 0;
        height: 0;
        border: 9px solid transparent;
        border-bottom-color: #fafafc;
      }
    }
  }
`

const ContentWrapper = styled.div``

const SecondaryButton = styled.a`
  color: #51459e;
  padding: 20px 24px;
  width: 320px;
  height: 56px;
  border-radius: 100px;
  margin: auto;
  text-align: center;
  cursor: pointer;
  display: block;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 992px) {
    width: 100%;
  }
`

interface ThreeColumnLpInterface {
  slice: {
    primary: {
      main_title: {
        text: string
      }
      cta_primary_button_link: {
        url: string
      }
      cta_secondary_button_link: {
        url: string
      }
      cta_primary_button_text: {
        text: string
      }
      cta_secondary_button_text: {
        text: string
      }
    }
    items: {
      column_title: {
        text: string
      }
      column_content: {
        html: string
      }
      icon: {
        gatsbyImageData: IGatsbyImageData
      }
    }[]
  }
}

const ThreeColumnLp = ({ slice }: ThreeColumnLpInterface): JSX.Element => {
  const {
    main_title,
    cta_primary_button_text,
    cta_primary_button_link,
    cta_secondary_button_link,
    cta_secondary_button_text,
  } = slice.primary

  return (
    <Wrapper>
      <Title>{main_title.text}</Title>
      <Body>
        {slice.items.map(item => {
          return (
            <ColumnWrapper>
              <div>
                <ImageWrapper>
                  <GatsbyImage
                    image={getImage(item.icon) as IGatsbyImageData}
                    alt=""
                  />
                </ImageWrapper>
              </div>
              <ContentWrapper>
                <ColumnTitle>{item.column_title.text}</ColumnTitle>
                <ColumnContent
                  dangerouslySetInnerHTML={{
                    __html: decodeHtmlCharCodes(item.column_content.html),
                  }}
                ></ColumnContent>
              </ContentWrapper>
            </ColumnWrapper>
          )
        })}
      </Body>
      <Footer>
        <PrimaryButton href={cta_primary_button_link.url}>
          {cta_primary_button_text.text}
        </PrimaryButton>
        <SecondaryButton href={cta_secondary_button_link.url}>
          {cta_secondary_button_text.text}
        </SecondaryButton>
      </Footer>
    </Wrapper>
  )
}

export const query = graphql`
  fragment PageThreeColumnLPSlice on PrismicPageDataBodyThreeColumnLp {
    id
    slice_type
    primary {
      cta_primary_button_link {
        url
      }
      cta_primary_button_text {
        text
      }
      cta_secondary_button_link {
        url
      }
      cta_secondary_button_text {
        text
      }
      main_title {
        text
      }
    }
    items {
      column_title {
        text
      }
      column_content {
        html
      }
      icon {
        gatsbyImageData
      }
    }
  }
`

export default ThreeColumnLp
