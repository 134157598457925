import styled from "styled-components"

export const StyledImage = styled.img`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin-left: 430px;
  cursor: pointer;

  @media (max-width: 1023px) {
    margin-left: 280px;
  }
`
export const SubscriptionPopup = styled.div<{ isOpen: boolean }>`
  display: ${props => (props.isOpen ? "flex" : "none")};
  display: flex;
  flex-direction: column;
  width: 480px;
  padding: var(--base-S2, 16px) 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: var(--bn-w-white-900, #fff);
  box-shadow: 0px 7px 8px -4px rgba(28, 30, 33, 0.16),
    0px 13px 19px 2px rgba(28, 30, 33, 0.1),
    0px 5px 24px 4px rgba(28, 30, 33, 0.08);
  position: fixed;
  top: 30%;
  left: 50%;
  z-index: 999;
  transform: translate(-50%);

  @media (max-width: 1023px) {
    display: flex;
    width: 327px;
    padding: var(--base-S2, 16px) 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    background: var(--bn-w-white-900, #fff);
    box-shadow: 0px 7px 8px -4px rgba(28, 30, 33, 0.16),
      0px 13px 19px 2px rgba(28, 30, 33, 0.1),
      0px 5px 24px 4px rgba(28, 30, 33, 0.08);
    z-index: 999;
  }

  h4 {
    margin-top: 8px;
    color: var(--bn-w-black-900, #1c1e21);
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    align-self: stretch;
    padding-right: 40px;
    padding-left: 40px;
  }
`
export const Backdrop = styled.div<{ isOpen: boolean }>`
  display: ${props => (props.isOpen ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 16px;
  padding: 16px;
  text-align: center;
`

export const ModalButton = styled.button`
  display: flex;
  height: 56px;
  width: 327px;
  max-width: 100%;
  padding: var(--base-s-25, 20px) var(--base-S3, 24px);
  justify-content: center;
  align-items: center;
  gap: var(--base-S1, 8px);
  border-radius: 100px;
  border: 2px solid var(--Primary-600, #51459e);
  background: var(--bn-w-white-900, #fff);
  cursor: pointer;
  color: var(--Primary-600, #51459e);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;

  &:hover {
    background: var(--Primary-50, #f6f4ff);
  }

  &:active {
    background: var(--Primary-100, #eceaff);
  }
`
