import { Button } from "../../../../ui"
import { Therapists } from "../../../../shared/Therapists"
import { CliniciansButtonContainer, CliniciansContainer } from "./styled"

export const Clinicians = () => {
  return (
    <CliniciansContainer>
      <Therapists variant="clinicians" background="transparent" hideViewBio />
      <h3>Find your care team</h3>
      <CliniciansButtonContainer>
        <Button to="/care-team/therapists" text="Find a therapist" />
        <Button to="/care-team/prescribers" text="Find a prescriber" />
      </CliniciansButtonContainer>
    </CliniciansContainer>
  )
}
