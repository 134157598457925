export const quotes = [
  {
    quote:
      "Wonderful lady to talk with. She always finds ways to find activities to help with anxiety, depression etc. She listens and gives her full undivided attention and makes me feel like I have someone who is listening and actually trying to help.",
    name: "Anya T.",
    source: "5stars",
  },
  {
    quote:
      "Wow! absolutely wow! I was so worried about this appointment and Sean was able to put me at ease. We were able to communicate effectively and he even has specialties in some of my worry areas like sensory issues and executive dysfunction!",
    name: "Bethany T.",
    source: "5stars",
  },
  {
    quote:
      "The ladies who have been helping me are amazing. I can't tell you how much I appreciate Justine and Angelique. They are so kind, and tremendously helpful. Their genuine concern and desire to help is incredible. Cerebral has been a game changer for me. I'm so grateful",
    name: "Ashlee H.",
    source: "5stars",
  },
]
