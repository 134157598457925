import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import styled from "styled-components"

const Wrapper = styled.div`
  box-shadow: 0px 0px 12px 0px rgba(28, 30, 33, 0.16);
  border-radius: 8px;
  background-color: #ffffff;
`

const HeaderWrapper = styled.div`
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 8px 8px 0px 0px;
  background: #f2f5ff;
`

const HeaderTitle = styled.div`
  color: #250044;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
`

const CardContentWrapper = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  align-items: flex-start;
`

const PlanName = styled.div`
  color: #1c1e21;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
`

const Bullets = styled.div`
  color: #4d515a;
  font-family: Poppins;
  font-size: 14px;
  line-height: 24px;
  margin-top: 8px;
`

const Price = styled.div`
  color: #626874;
  font-family: Poppins;
  font-size: 16px;
  line-height: 20px;
  margin-top: 24px;

  strong {
    color: #51459e;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }
`

const Button = styled.a`
  color: #51459e;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-decoration-line: underline;
  margin-top: 24px;
`

const PriceOption = styled.div`
  color: #626874;
  font-family: Poppins;
  font-size: 12px;
  line-height: 16px;
  margin-top: 8px;
`

interface PlanCardProps {
  headerIcon: IGatsbyImageData
  headerTitle: string
  planName: string
  bullets: string
  price: string
  priceOption: string
  ctaText: string
  ctaLink: string
}

const PlanCard = ({
  headerIcon,
  headerTitle,
  planName,
  bullets,
  price,
  priceOption,
  ctaText,
  ctaLink,
}: PlanCardProps) => {
  return (
    <Wrapper>
      <HeaderWrapper>
        <GatsbyImage
          image={headerIcon as IGatsbyImageData}
          alt=""
          loading="lazy"
        />
        <HeaderTitle>{headerTitle}</HeaderTitle>
      </HeaderWrapper>
      <CardContentWrapper>
        <PlanName>{planName}</PlanName>
        <Bullets>{bullets}</Bullets>
        <Price dangerouslySetInnerHTML={{ __html: price }} />
        <PriceOption>{priceOption}</PriceOption>
        <Button href={ctaLink}>{ctaText}</Button>
      </CardContentWrapper>
    </Wrapper>
  )
}

export default PlanCard
