import { useEffect, useState } from "react"
import segment, { segmentEvents } from "../../../../lib/segment"
import { ModalState, useModalContext } from "../../../../contexts/ModalContext"
import LocationPoint from "../assets/LocationPoint"
import Postcard from "../assets/Postcard"
import { InsuranceSuccessDialog } from "../InsuranceSuccessDialog"
import { FormSubmitButton, FormWrapper } from "./styled"
import { createApiInstance } from "@cerebral-inc/api"
import { IRteConfigs, IStateConfig, IInsurancePayer } from "./types"
import { StateDropdown, CarrierDropdown } from "./CustomDropdown"

export const InsuranceCarrierSelectV2 = ({
  externalSource = "insurance_widget",
}: {
  externalSource?: string
}) => {
  const api = createApiInstance({
    baseURL: process.env.GATSBY_API_SERVER_URL,
  })
  const [isGlobalRteEnabled, setIsGlobalRteEnabled] = useState<boolean>(false)
  const [stateConfigs, setStateConfigs] = useState<IStateConfig[]>([])
  const [isFetchingStateConfigs, setIsFetchingStateConfigs] =
    useState<boolean>(false)

  const fetchRteStateConfigs = async () => {
    setIsFetchingStateConfigs(true)
    try {
      const response = await api.get<IRteConfigs>(`api/insurance/rte_configs`)
      setStateConfigs(response.data.states)
      setIsGlobalRteEnabled(response.data.global_rte_enabled)
    } catch (error) {}
    setIsFetchingStateConfigs(false)
  }

  useEffect(() => {
    fetchRteStateConfigs()
  }, [])

  const [selectedState, setSelectedState] = useState<IStateConfig | null>(null)
  const [selectedCarrier, setSelectedCarrier] =
    useState<IInsurancePayer | null>(null)
  const [stateFormError, setStateFormError] = useState<string>()
  const [insuranceFormError, setInsuranceFormError] = useState<string>()
  const { setModalState, setModalContent } = useModalContext()

  useEffect(() => {
    setSelectedCarrier(null)
  }, [selectedState])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault()
      if (!selectedState) {
        throw new Error("FormError:StateNotSelected")
      }
      if (!selectedCarrier) {
        throw new Error("FormError:CarrierNotSelected")
      }
      segment.track(
        segmentEvents.INSURANCE_WIDGET_SUBMIT,
        {},
        { integrations: { All: false, Heap: true } }
      )
      setModalContent(
        <InsuranceSuccessDialog
          carrier={selectedCarrier.display_name || selectedCarrier.label}
          state={selectedState.name}
          externalSource={externalSource}
          isRteEnabled={
            isGlobalRteEnabled &&
            selectedState.rte_enabled &&
            selectedCarrier.rte_enabled
          }
          isOpenFromCarrierSelectV2={true}
        />,
        {
          displayCloseIcon: true,
          modalWidth: "wide",
          fullScreenOnMobile: false,
        }
      )
      setModalState(ModalState.OPEN)
    } catch (e) {
      if (e instanceof Error) {
        if (e.message === "FormError:StateNotSelected") {
          setStateFormError("Please select your state")
        }
        if (e.message === "FormError:CarrierNotSelected") {
          setInsuranceFormError("Please select your insurance carrier")
        }
      }
    }
  }

  useEffect(() => {
    if (selectedState && stateFormError) {
      setStateFormError(undefined)
    }
    if (selectedCarrier && insuranceFormError) {
      setInsuranceFormError(undefined)
    }
  }, [selectedState, selectedCarrier])

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <StateDropdown
        options={stateConfigs}
        onSelect={setSelectedState}
        LeftIcon={LocationPoint}
        value={selectedState}
        error={stateFormError}
        isDisabled={isFetchingStateConfigs}
        placeholder="Your state"
      />
      <CarrierDropdown
        options={selectedState?.insurance_payers || []}
        onSelect={setSelectedCarrier}
        LeftIcon={Postcard}
        value={selectedCarrier}
        error={insuranceFormError}
        isDisabled={!selectedState}
        placeholder="Insurance carrier"
      />
      <FormSubmitButton>Check my coverage</FormSubmitButton>
    </FormWrapper>
  )
}
