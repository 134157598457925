import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { FlexWrapper } from "./LandingPageStyles"
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  ImageDataLike,
} from "gatsby-plugin-image"
import StyledCta from "../ui/StyledCta"
import { scrollToElement } from "../../lib/utils"

interface BackgroundColorProps {
  from?: string
  to?: string
  type: string
  color?: string
}

const Wrapper = styled.div<BackgroundColorProps>`
  width: 100%;
  background-color: ${({ color }) => (color ? color : "white")};
  background-image: linear-gradient(
    135deg,
    ${props =>
        props.type === "gradient"
          ? props.from
            ? props.from
            : "rgba(207, 242, 240, 0.4)"
          : "rgba(0,0,0,0)"}
      0%,
    ${props =>
        props.type === "gradient"
          ? props.to
            ? props.to
            : "rgba(199, 191, 255, 0.4)"
          : "rgba(0,0,0,0)"}
      100%
  );
`

const ContentWrapper = styled.div`
  width: calc(100% - 32px);
  position: relative;
  margin: auto;
  display: flex;
  gap: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px 16px;

  @media (min-width: 928px) {
    padding: 64px 0px;
    width: 896px;
  }
`

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 834px) {
    max-width: 744px;
  }
`

const Header = styled.div`
  * {
    font-family: "Poppins";
    font-style: normal;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #250044;
    @media (min-width: 834px) {
      font-size: 28px;
      line-height: 32px;
    }
  }

  strong {
    color: #b172e6;
  }

  em {
    font-style: italic;
  }
`

const SubHeader = styled.div`
  * {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #250044;
  }

  p:not(:last-child) {
    margin-bottom: 16px;
  }

  strong {
    font-weight: 600;
  }

  span {
    display: inline-block !important;
  }

  span.strikethrough {
    text-decoration: line-through;
  }

  span.underline {
    text-decoration: underline;
  }

  a {
    color: "#b172e6";
  }
`

const ItemsWrapper = styled.div`
  max-width: 896px;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
`

const ItemHeader = styled.h3`
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  color: #250044;
  vertical-align: middle;
`

const ItemCopy = styled.div`
  font-size: 14px;
  line-height: 24px;
  p:not(:last-child) {
    margin-bottom: 16px;
  }

  strong {
    font-weight: 600;
  }

  span {
    display: inline-block !important;
  }

  span.strikethrough {
    text-decoration: line-through;
  }

  span.underline {
    text-decoration: underline;
  }

  a {
    color: "#b172e6";
  }
`

const IconContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  overflow: hidden;
  align-items: center;
  * {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  script {
    display: none;
  }

  picture img {
    object-fit: contain !important;
    object-position: center center !important;
  }
`

interface ItemProps {
  size: string
  aligment: string
}

const ItemContainer = styled.div<ItemProps>`
  max-width: ${({ size }) =>
    size === "small" ? "212px" : size === "medium" ? "288px" : "364px"};
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 16px;

  align-items: ${({ aligment }) =>
    aligment === "right" ? "end" : aligment === "center" ? "center" : "start"};

  @media (min-width: 834px) {
    width: ${({ size }) =>
      size === "small" ? "212px" : size === "medium" ? "288px" : "364px"};
  }

  * {
    text-align: ${({ aligment }) => aligment};
  }

  ${IconContainer} {
    height: ${({ size }) =>
      size === "small" ? "40px" : size === "medium" ? "80px" : "120px"};
    width: ${({ size }) =>
      size === "small" ? "40px" : size === "medium" ? "80px" : "120px"};
  }

  ${ItemCopy} {
    text-align: ${({ aligment }) => aligment || "left"};
  }
`

interface ListOfItemsWithIconProps {
  slice: {
    primary: {
      slice_id?: {
        text: string
      }
      background_type: string
      background_color?: string
      background_gradient_from?: string
      background_gradient_to?: string
      header: {
        html: string
      }
      sub_header: {
        html: string
      }
      items_size: string
      items_aligment?: string
      show_cta_button: boolean
      cta_text: {
        text: string
      }
      cta_link?: {
        url?: string
      }
      cta_style: string
      cta_background_color: string
      cta_text_color: string
    }
    items: Array<Item>
  }
}

interface Item {
  item_header: {
    text: string
  }
  item_copy: {
    html: string
  }
  item_icon: ImageDataLike
}

const ListOfItemsWithIcon = ({ slice }: ListOfItemsWithIconProps) => {
  const { primary, items } = slice

  const getCtaLink = (): string => {
    if (primary.cta_link?.url?.includes("https://#")) {
      return primary.cta_link.url.replace("https://#", "#")
    }
    return primary.cta_link?.url || ""
  }

  return (
    <Wrapper
      type={primary.background_type}
      color={primary.background_color}
      from={primary.background_gradient_from}
      to={primary.background_gradient_to}
      id={slice.primary.slice_id?.text || ""}
    >
      <ContentWrapper>
        <HeaderWrapper>
          <Header dangerouslySetInnerHTML={{ __html: primary.header.html }} />
          {primary.sub_header && (
            <SubHeader
              dangerouslySetInnerHTML={{ __html: primary.sub_header.html }}
            />
          )}
        </HeaderWrapper>
        <ItemsWrapper>
          {items.map(item => {
            return (
              <ItemContainer
                key={item.item_header.text}
                size={primary.items_size}
                aligment={primary.items_aligment || "left"}
              >
                <IconContainer>
                  <GatsbyImage
                    image={getImage(item.item_icon) as IGatsbyImageData}
                    alt=""
                    loading="eager"
                  />
                </IconContainer>
                <ItemHeader>{item.item_header.text}</ItemHeader>
                <ItemCopy
                  dangerouslySetInnerHTML={{ __html: item.item_copy.html }}
                />
              </ItemContainer>
            )
          })}
        </ItemsWrapper>
        {primary.show_cta_button && (
          <StyledCta
            style={primary.cta_style}
            link={getCtaLink()}
            text={primary.cta_text.text}
            backgroundColor={primary.cta_background_color}
            textColor={primary.cta_text_color}
            onClick={() => scrollToElement(getCtaLink().replace("#", ""))}
          />
        )}
      </ContentWrapper>
    </Wrapper>
  )
}

export const query = graphql`
  fragment PageListOfItemsWithIconSlice on PrismicPageDataBodyListOfItemsWithIcon {
    id
    slice_type
    primary {
      slice_id {
        text
      }
      background_type
      background_color
      background_gradient_from
      background_gradient_to
      items_size
      items_aligment
      header {
        html
      }
      sub_header {
        html
      }
      show_cta_button
      cta_link {
        url
      }
      cta_text {
        text
      }
      cta_background_color
      cta_text_color
      cta_style
    }
    items {
      item_header {
        text
      }
      item_copy {
        html
      }
      item_icon {
        gatsbyImageData
      }
    }
  }
`

export default ListOfItemsWithIcon
