exports.linkResolver = doc => {
  // URL for a category type
  if (doc.type === "landing") {
    return `/online/${doc.uid}`
  }

  if (doc.type === "redirector") {
    return `/${doc.uid}`
  }

  if (doc.type === "post") {
    return `/blog/${doc.uid}`
  }

  if (doc.type === "faq") {
    return "/faqs"
  }

  if (
    doc.type === "provider" ||
    doc.type === "therapist" ||
    doc.type === "counselor"
  ) {
    const type = doc.type === "provider" ? "prescribers" : `${doc.type}s`
    return `/care-team/${type}/${doc.uid}`
  }

  if (doc.type === "page") {
    // This means user is previewing from Prismic server
    if (doc.data) {
      const parentPathData = doc.data.parent_path
      const parentPath =
        parentPathData[0] && parentPathData[0].text.replace("/", "")
      const path = "/" + [parentPath, doc.uid].filter(Boolean).join("/")

      return path.replaceAll("//", "/")
    } else {
      // This means the page is referenced from a CTA link
      return `/${doc.slug}/${doc.uid}`
    }
  }

  // Backup for all other types
  return "/"
}
