import styled from "styled-components"

export const H1 = styled.h1<{ color?: string }>`
  color: ${props => props.color || "#1C1E21"};
  font-size: 32px;
  line-height: 42px;
  @media (max-width: 499px) {
    font-size: 22px;
    line-height: 30px;
  }
`

export const H2 = styled.h2`
  font-size: 28px;
  line-height: 42px;
  @media (max-width: 499px) {
    font-size: 18px;
    line-height: 26px;
  }
`

export const H3 = styled.h3`
  font-size: 22px;
  line-height: 30px;
  display: flex;
  align-items: flex-start;
  gap: 8px;

  @media (max-width: 499px) {
    font-size: 16px;
    line-height: 22px;
  }
`

export const H4 = styled.h4`
  font-size: 16px;
  line-height: 23px;
  @media (max-width: 499px) {
    font-size: 16px;
    line-height: 24px;
  }
`
export const H5 = styled.h5`
  font-size: 16px;
  line-height: 20px;
  @media (max-width: 499px) {
    font-family: Inter;
    font-size: 14px;
    line-height: 24px;
  }
`

export const H6 = styled.h6`
  font-size: 14px;
  line-height: 20px;
`

export const HightLightText = styled.h3`
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  font-family: Poppins;
  margin-bottom: 16px;
`
