import { useEffect, useRef, useState } from "react"
import { UilAngleUp, UilAngleDown } from "@iconscout/react-unicons"
import {
  BulletText,
  ComparisonItemWrapper,
  GridWrapper,
  OfferringTitleWrapper,
  OfferringWrapper,
  OffringTitle,
} from "./styled"
import { decodeHtmlCharCodes } from "../../../../lib/utils"

interface OfferingDetail {
  id: number
  text?: string
  description?: string
  img: string
}

interface OfferingDetailProps {
  details: OfferingDetail[]
  isLastItem?: boolean
}

export const OfferingDetail = ({
  details,
  isLastItem,
}: OfferingDetailProps) => {
  const offeringRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(73)
  const [isOpened, setIsOpened] = useState(false)

  const handleScroll = () => {
    if (offeringRef.current) {
      setHeight(offeringRef.current.clientHeight)
    }
  }

  const handleOnClick = () => {
    setIsOpened(prev => !prev)
    handleScroll()
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    handleScroll()

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [isOpened])

  return (
    <GridWrapper hasBorder>
      <OfferringWrapper ref={offeringRef} onClick={handleOnClick}>
        <OfferringTitleWrapper>
          <OffringTitle>{details[0].text}</OffringTitle>
          {isOpened ? (
            <UilAngleUp size={20} color="#1C1E21" />
          ) : (
            <UilAngleDown size={20} color="#1C1E21" />
          )}
        </OfferringTitleWrapper>
        {isOpened && (
          <BulletText
            dangerouslySetInnerHTML={{
              __html: decodeHtmlCharCodes(details[0].description || ""),
            }}
          />
        )}
      </OfferringWrapper>
      <ComparisonItemWrapper
        minH={height}
        bg="#ffffff"
        borderRadiusBottom={isLastItem ? "10px" : "0"}
      >
        {details[1].img ? <img src={details[1].img} /> : details[1].description}
      </ComparisonItemWrapper>
      <ComparisonItemWrapper minH={height}>
        {details[2].img ? <img src={details[2].img} /> : details[2].description}
      </ComparisonItemWrapper>
    </GridWrapper>
  )
}
