import { Navigation } from "../ui/Navigation"
import plantSvg from "../../images/plant.svg"
import { useWindowSize } from "../hooks"
import { Heading, GeneralInfo, Availability } from "./sections"
import {
  CarePersonBody,
  PlantContainer,
  CarePersonInfo,
  LeftColumn,
  RightColumn,
  PlantContent,
} from "./styled"
import { ICarePerson } from "./types"
import SEO from "../SEO"

interface ICarePersonPageBodyProps {
  carePersonData: ICarePerson
}
export const CarePersonPageBody = ({
  carePersonData,
}: ICarePersonPageBodyProps): JSX.Element => {
  const hasCarePersonInfo =
    carePersonData.gender ||
    carePersonData.ethnicity.length > 0 ||
    carePersonData.specialties.length > 0 ||
    carePersonData.languages.length > 0 ||
    carePersonData.insurance_providers.length > 0 ||
    carePersonData.bio_approach_to_therapy ||
    carePersonData.bio_experience ||
    carePersonData.bio_hobbies_int_ ||
    carePersonData.quote ||
    Object.keys(carePersonData.insurance_accepted).length > 0

  const { isMobile } = useWindowSize()

  const showAvailability =
    (isMobile && carePersonData.accepting_new_client) || !isMobile

  return (
    <>
      <CarePersonBody>
        <CarePersonInfo>
          <LeftColumn>
            <Heading
              hasCarePersonInfo={!!hasCarePersonInfo}
              carePersonData={carePersonData}
            />
            {!!hasCarePersonInfo && (
              <GeneralInfo carePersonData={carePersonData} />
            )}
          </LeftColumn>
          <RightColumn>
            <Availability carePersonData={carePersonData} />
          </RightColumn>
        </CarePersonInfo>
      </CarePersonBody>
    </>
  )
}

interface ICarePersonPageProps {
  carePersonData: ICarePerson
}
const CarePersonPage = ({
  carePersonData,
}: ICarePersonPageProps): JSX.Element => {
  const { name, role, licensed_states } = carePersonData

  const licensedStateCopy = (openingWord: string) => {
    if (licensed_states.length === 0) return ""
    if (licensed_states.length === 1)
      return ` ${openingWord} ${licensed_states[0]}`
    if (licensed_states.length === 2)
      return ` ${openingWord} ${licensed_states[0]} and ${licensed_states[1]}`
    return ` ${openingWord} ${licensed_states[0]}, ${licensed_states[1]}, and More States`
  }

  return (
    <>
      <SEO
        title={`${name}, Licensed ${role}${licensedStateCopy("in")}`}
        description={`Meet ${name}, ${role}${licensedStateCopy(
          "of"
        )} – your ally in mental health. Learn more about what ${name} specializes in and how they can help you empower yourself and prioritize your well-being. Schedule your appointment now.`}
      />
      <Navigation />
      <CarePersonPageBody carePersonData={carePersonData} />
      <PlantContainer>
        <PlantContent>
          <img alt="" src={plantSvg} height={187} width={107} loading="lazy" />
        </PlantContent>
      </PlantContainer>
    </>
  )
}

export default CarePersonPage
