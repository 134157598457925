import styled from "styled-components"

export const InsuranceLogoWrapper = styled.div<{ backgroundColor?: string }>`
  display: flex;
  justify-content: space-around;
  width: 992px;
  margin-bottom: 8px;

  @media (max-width: 700px) {
    display: flex;
    flex-wrap: wrap;
    max-width: 420px;
  }
`

export const LogoImage = styled.img`
  height: 64px;
`

export const InsuranceLogoContainer = styled.div<{ backgroundColor?: string }>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  self-align: center;
  width: 100%;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "transparent"};
`
