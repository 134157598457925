import { Link } from "@reach/router"
import styled from "styled-components"
import { colors } from "../../../themes/colors"

export const AlternatingSplashContainer = styled.section`
  padding: 0 24px;
  margin-bottom: 64px;
`

export const SectionContainer = styled.div`
  max-width: 992px;
  margin: 64px auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 80px;
  margin-bottom: 80px;
  align-items: center;

  @media (max-width: 834px) {
    max-width: 600px;
    margin: 32px auto 64px;
    display: flex;
    align-items: center;
    gap: 32px;
    &.top {
      flex-direction: column-reverse;
    }
    &.bottom {
      flex-direction: column;
    }
  }
`

export const SplashImage = styled.img`
  width: 100%;
`

export const PrescriberLink = styled(Link)`
  color: ${colors.c_primary[600]};
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  margin: 20px 0;
  display: block;
  text-decoration: none;
`

export const GetStartedContainer = styled.div`
  text-align: center;
  margin: 0 auto;
  max-width: 320px;
  @media (max-width: 834px) {
    max-width: 600px;
  }
`

export const SectionHeader = styled.h2`
  color: ${colors.c_black[900]};
  font-family: Poppins;
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;

  @media (max-width: 834px) {
    font-size: 24px;
    text-align: center;
  }
`

export const SectionBody = styled.div`
  margin-top: 32px;
  display: flex;
  gap: 16px;
  align-items: flex-start;
  h3 {
    color: ${colors.c_black[900]};
    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 4px;
  }
  p {
    color: ${colors.c_grey[800]};
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
  img {
    width: 20px;
  }
`
