export default function Postcard({ fill = "#51459E" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M14.6666 9.16634H15.4999C15.7209 9.16634 15.9329 9.07854 16.0892 8.92226C16.2455 8.76598 16.3333 8.55402 16.3333 8.33301V7.49967C16.3333 7.27866 16.2455 7.0667 16.0892 6.91042C15.9329 6.75414 15.7209 6.66634 15.4999 6.66634H14.6666C14.4456 6.66634 14.2336 6.75414 14.0773 6.91042C13.921 7.0667 13.8333 7.27866 13.8333 7.49967V8.33301C13.8333 8.55402 13.921 8.76598 14.0773 8.92226C14.2336 9.07854 14.4456 9.16634 14.6666 9.16634ZM5.49992 9.99967H9.66658C9.8876 9.99967 10.0996 9.91188 10.2558 9.7556C10.4121 9.59932 10.4999 9.38735 10.4999 9.16634C10.4999 8.94533 10.4121 8.73337 10.2558 8.57709C10.0996 8.42081 9.8876 8.33301 9.66658 8.33301H5.49992C5.2789 8.33301 5.06694 8.42081 4.91066 8.57709C4.75438 8.73337 4.66658 8.94533 4.66658 9.16634C4.66658 9.38735 4.75438 9.59932 4.91066 9.7556C5.06694 9.91188 5.2789 9.99967 5.49992 9.99967ZM18.8333 3.33301H2.16659C1.94557 3.33301 1.73361 3.42081 1.57733 3.57709C1.42105 3.73337 1.33325 3.94533 1.33325 4.16634V15.833C1.33325 16.054 1.42105 16.266 1.57733 16.4223C1.73361 16.5785 1.94557 16.6663 2.16659 16.6663H18.8333C19.0543 16.6663 19.2662 16.5785 19.4225 16.4223C19.5788 16.266 19.6666 16.054 19.6666 15.833V4.16634C19.6666 3.94533 19.5788 3.73337 19.4225 3.57709C19.2662 3.42081 19.0543 3.33301 18.8333 3.33301ZM17.9999 14.9997H2.99992V4.99967H17.9999V14.9997ZM5.49992 13.333H9.66658C9.8876 13.333 10.0996 13.2452 10.2558 13.0889C10.4121 12.9326 10.4999 12.7207 10.4999 12.4997C10.4999 12.2787 10.4121 12.0667 10.2558 11.9104C10.0996 11.7541 9.8876 11.6663 9.66658 11.6663H5.49992C5.2789 11.6663 5.06694 11.7541 4.91066 11.9104C4.75438 12.0667 4.66658 12.2787 4.66658 12.4997C4.66658 12.7207 4.75438 12.9326 4.91066 13.0889C5.06694 13.2452 5.2789 13.333 5.49992 13.333Z"
        fill={fill}
      />
    </svg>
  )
}
