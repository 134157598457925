import { useEffect, useState } from "react"
import { plans as defaultPlans, words, Plan } from "./constants"
import {
  CardContainer,
  CardHeader,
  CardIcon,
  CardText,
  Chip,
  Header,
  LearnMore,
  LinkContainer,
  Pill,
  InsurancePlansContainer,
  Subheader,
  Wrapper,
} from "./styled"
import InsuranceModals from "../../../InsuranceModals"

const PlanCard = ({
  plan: { icon, title, description, link, linkText, chipText },
  disableLinks,
}: {
  disableLinks: boolean
  plan: Plan
}) => (
  <CardContainer href={link} disableLinks={disableLinks}>
    <CardIcon src={icon} alt={title} />
    <CardHeader>{title}</CardHeader>
    <CardText>{description}</CardText>
    <LinkContainer>
      <LearnMore>{linkText}</LearnMore>
    </LinkContainer>

    {!!chipText && <Chip>{chipText}</Chip>}
  </CardContainer>
)

export const YourInsurancePersonalPath = ({
  headerText,
  description = "Work with a therapist, prescriber, or both in a safe and judgment-free online space",
  plans = defaultPlans,
  showCta = false,
  disableLinks = false,
}: {
  headerText?: string
  description?: string
  plans?: Plan[]
  showCta?: boolean
  disableLinks?: boolean
}) => {
  const [pillIndex, setPillIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      if (pillIndex < words.length - 1) {
        setPillIndex(pillIndex + 1)
      } else {
        setPillIndex(0)
      }
    }, 1500)
    return () => clearInterval(interval)
  })

  return (
    <Wrapper>
      <Header>
        {headerText ? (
          headerText
        ) : (
          <>
            We make it easy to start feeling <Pill>{words[pillIndex]}</Pill>
          </>
        )}
      </Header>

      <Subheader>{description}</Subheader>

      <InsurancePlansContainer gridCount={plans.length}>
        {plans.map(plan => (
          <PlanCard key={plan.title} plan={plan} disableLinks={disableLinks} />
        ))}
      </InsurancePlansContainer>

      {showCta ? (
        <div style={{ margin: "40px auto 0", textAlign: "center" }}>
          <InsuranceModals cta_text="Check my coverage" disableCTALink />
        </div>
      ) : null}
    </Wrapper>
  )
}
