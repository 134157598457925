import styled from "styled-components"

const PrismicText = styled.div`
  font-size: 16px;
  line-height: 30px;
  color: #250044;
  text-align: left;

  h1 {
    line-height: 32px;
    font-size: 25px;
    font-weight: 600;
  }

  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }

  h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
  }

  h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
  }

  b,
  strong {
    font-weight: 800;
  }

  em,
  i {
    font-style: italic;
  }

  p {
    min-height: 30px;
    font-size: 14px;
    line-height: 25px;
  }

  ul {
    list-style: disc;
    padding: 0 0 0 20px;
    font-size: 14px;
    line-height: 30px;
    margin-left: 30px;
  }

  ol {
    padding: 10px 0 0 20px;
    font-size: 14px;
    line-height: 30px;
    list-style-type: decimal;
    margin-left: 30px;
  }

  p,
  ul,
  ol,
  h2,
  h3,
  h4 {
    margin-bottom: 30px;
  }

  a {
    color: #6786ff;
  }

  li {
    margin: 0 0 20px;
  }

  .block-img {
    img {
      width: 100%;
    }
  }

  span {
    display: block;
    &.center-align {
      text-align: center;
    }
    &.right-align {
      text-align: right;
    }
  }

  @media (min-width: 834px) {
    h1 {
      font-size: 32px;
      line-height: 32px;
    }

    h2 {
      font-size: 25px;
      line-height: 30px;
    }

    h3 {
      font-size: 18px;
      line-height: 30px;
    }

    h4 {
      font-size: 16px;
      line-height: 30px;
    }

    b,
    strong {
      font-weight: 800;
    }

    p {
      min-height: 30px;
      font-size: 16px;
      line-height: 30px;
    }

    ul {
      list-style: disc;
      padding: 0 0 0 20px;
      font-size: 16px;
      line-height: 30px;
    }

    ol {
      padding: 10px 0 0 20px;
      font-size: 16px;
      line-height: 30px;
    }

    p,
    ul,
    ol,
    h2,
    h3 {
      margin-bottom: 30px;
    }

    a {
      color: #6786ff;
    }
  }
`

export default PrismicText
