import styled from "styled-components"
import ReactCalendar from "react-calendar"
import { colors } from "../../themes/colors"
import { sizes, spacing, borderRadius } from "../../themes/tokens"
import { typographyStyles } from "../../themes/typography"

export const Calendar = styled(ReactCalendar)<{ removeShadow?: boolean }>`
  min-width: 320px;
  color: ${colors.c_primary[900]};
  ${typographyStyles.s};
  padding: ${spacing.lg} ${spacing.lg};
  box-shadow: ${({ removeShadow }) =>
    removeShadow ? "" : "0 5px 24px 4px rgba(81, 69, 158, 0.1)"};
  border-radius: ${borderRadius.lg};
  background: ${colors.c_white[500]};
  box-sizing: border-box;
  .react-calendar {
    &__navigation {
      display: flex;
      justify-content: center;
      margin-bottom: ${spacing.xl};
      align-items: center;
      &__label {
        margin: 0 ${spacing.mlg};
        font-family: inherit;
        font-weight: 600;
        flex-grow: unset !important;
        background: ${colors.c_secondary[100]};
        border: none;
        padding: ${spacing.sm} ${spacing.md};
        border-radius: ${borderRadius.xl};
        color: #49356e;
      }
      &__arrow {
        background: none;
        outline: none;
        border: none;
        -webkit-appearance: none;
        height: ${sizes.mlg};
        width: ${sizes.mlg};
        padding: 0;
        &:focus {
          outline: none;
          -webkit-appearance: none;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }

    &__month-view__weekdays__weekday {
      abbr[title] {
        text-decoration: none;
        font-weight: 600;
        cursor: default;
        color: #706897;
      }
    }

    &__viewContainer {
      text-align: center;
    }

    &__month-view__days__day {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 34px;
      border: none;
      background: transparent;
      outline: none;
      -webkit-appearance: none;
      &:hover {
        cursor: pointer;
      }

      &:focus {
        outline: none;
        -webkit-appearance: none;
      }

      &.react-calendar__month-view__days__day--neighboringMonth {
        pointer-events: none;
        transition: none;
        opacity: 0;
      }

      abbr {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        line-height: 24px;
        font-size: 12px;
        margin: 0 auto;
        box-sizing: content-box;
      }
    }

    &__month-view__weekdays {
      margin-bottom: ${spacing.md};
    }

    &__tile {
      margin-bottom: 2px;
      box-sizing: border-box;
      border: 2px;

      &--now {
        abbr {
          font-weight: 400;
        }
        &::after {
          content: "";
          width: 4px;
          height: 4px;
          background: ${colors.c_primary[600]};
          border-radius: 50%;
          margin-top: -4px;
        }
      }

      &--active {
        abbr {
          background: ${colors.c_primary[600]};
          font-weight: 600;
          color: ${colors.c_white[500]};
        }
      }

      &.available-day {
        abbr {
          font-weight: 600;
          border: 2px ${colors.c_primary[600]} solid;
          &:hover {
            cursor: pointer;
          }
        }
        ::after {
          background: ${colors.c_white[500]};
          z-index: -10;
        }
      }

      &.unavailable-day {
        font-weight: 400;
        border: 2px transparent solid;
        &:hover {
          cursor: default;
        }
      }
    }
  }
`

export const CalendarContainer = styled.div<{ disabled: boolean }>`
  ${({ disabled }) => (disabled ? "pointer-events: none;" : "")}
`

export const CalendarFadeout = styled.div`
  position: absolute;
  width: 320px;
  height: 324px;
  background: linear-gradient(
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 1) 95%
  );
  border-radius: ${borderRadius.lg};
`

export const CalendarV2 = styled(ReactCalendar)<{
  removeShadow?: boolean
  disabled?: boolean
}>`
  min-width: 320px;
  color: ${colors.c_primary[900]};
  ${typographyStyles.s};
  padding: 8px 15px;
  box-shadow: ${({ removeShadow }) =>
    removeShadow ? "" : "0px 4px 4px 0px rgba(0, 0, 0, 0.05)"};
  border-radius: 4px;
  background: ${colors.c_white[500]};
  box-sizing: border-box;

  .react-calendar {
    &__navigation {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      align-items: center;
      &__label {
        margin: 0 ${spacing.mlg};
        font-family: inherit;
        font-weight: 600;
        flex-grow: unset !important;
        background: none;
        border: none;
        padding: ${spacing.sm} ${spacing.md};
        border-radius: ${borderRadius.xl};
        color: #49356e;
        font-size: 14px;
        font-family: "Poppins";
      }
      &__arrow {
        background: none;
        outline: none;
        border: none;
        -webkit-appearance: none;
        height: ${sizes.mlg};
        width: ${sizes.mlg};
        padding: 0;
        &:focus {
          outline: none;
          -webkit-appearance: none;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }

    &__month-view__weekdays__weekday {
      abbr[title] {
        text-decoration: none;
        font-weight: 600;
        cursor: default;
        font-family: "Poppins";
        color: #706897;
        font-size: 12px;
      }
    }

    &__viewContainer {
      text-align: center;
    }

    &__month-view__days__day {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 34px;
      border: none;
      background: transparent;
      outline: none;
      -webkit-appearance: none;
      font-size: 10px;
      font-family: "Poppins";
      &:hover {
        cursor: pointer;
      }

      &:focus {
        outline: none;
        -webkit-appearance: none;
      }

      &.react-calendar__month-view__days__day--neighboringMonth {
        pointer-events: none;
        transition: none;
        opacity: 0;
      }

      abbr {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        line-height: 24px;
        font-size: 12px;
        margin: 0 auto;
        box-sizing: content-box;
        color: #aaaeb8;
      }
    }

    &__month-view__weekdays {
      margin-bottom: ${spacing.md};
    }

    &__tile {
      margin-bottom: 2px;
      box-sizing: border-box;
      border: 2px;

      &--now {
        abbr {
          font-weight: 400;
        }
        &::after {
          content: "";
          width: 4px;
          height: 4px;
          border-radius: 50%;
          margin-top: -4px;
        }
      }

      &--active {
        abbr {
          background: ${({ disabled }) =>
            disabled ? "none" : colors.c_primary[600]};
          font-weight: 600;
          color: ${({ disabled }) =>
            disabled ? "#9E9DBF" : "#FFF"} !important;
        }
      }

      &.available-day {
        abbr {
          font-weight: ${({ disabled }) => (disabled ? "400" : `600`)};
          border: ${({ disabled }) =>
            disabled ? "none" : `1px ${colors.c_primary[600]} solid`};
          color: ${({ disabled }) => (disabled ? "#9E9DBF" : "#49356E")};
          &:hover {
            cursor: pointer;
          }
        }
        ::after {
          background: ${colors.c_white[500]};
          z-index: -10;
        }
      }

      &.unavailable-day {
        font-weight: 400;
        border: 2px transparent solid;
        color: #9e9dbf;
        &:hover {
          cursor: default;
        }
      }
    }
  }
`
