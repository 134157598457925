import styled from "styled-components"
import { ArrowProps } from "./types"
import { colors } from "../../themes/colors"

export const CarouselContainer = styled.div`
  overflow: hidden;
`

export const InnerContainer = styled.div`
  white-space: nowrap;
  transition: transform 0.3s;
  display: flex;
  align-items: stretch;
  gap: 0;
  width: 300%;
`

export const CarouselItem = styled.div`
  display: inline-flex;
  align-items: stretch;
  justify-content: center;
  color: ${colors.c_white[500]};
  min-height: fit-content;
  width: 100%;
`

export const Indicators = styled.div`
  display: flex;
  justify-content: center;
  & > div {
    margin-right: 8px;
  }
  & > div:last-child {
    margin-right: 0;
  }
`

interface IndicatorDotProps {
  $isActive: boolean
}
export const IndicatorDot = styled.div<IndicatorDotProps>`
  width: ${({ $isActive }) => ($isActive ? "16px" : "8px")};
  height: 8px;
  background: ${({ $isActive }) =>
    $isActive ? colors.c_primary[600] : "#aabaf9"};

  border-radius: ${({ $isActive }) => ($isActive ? "8px" : "50%")};
  display: block;
`

const Arrow = styled.button`
  border-radius: 50%;
  height: 56px;
  width: 56px;
  background: transparent;
  position: absolute;
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  padding: 0;
  border: 0;
  @media (max-width: 834px) {
    height: 36px;
    width: 36px;
  }
`

export const ArrowLeft = styled(Arrow)<ArrowProps>`
  position: ${({ desktop }) => (desktop?.x ? "absolute" : "relative")};
  left: ${({ desktop }) => desktop?.x};
  top: ${({ desktop }) => desktop?.y};

  @media (max-width: 834px) {
    display: ${({ desktop }) => (desktop?.x ? "none" : "block")};
  }

  @media (min-width: 835px) {
    display: ${({ desktop }) => (desktop?.x ? "block" : "none")};
  }
`

export const ArrowRight = styled(Arrow)<ArrowProps>`
  position: ${({ desktop }) => (desktop?.x ? "absolute" : "relative")};
  right: ${({ desktop }) => desktop?.x};
  top: ${({ desktop }) => desktop?.y};

  @media (max-width: 834px) {
    display: ${({ desktop }) => (desktop?.x ? "none" : "block")};
  }

  @media (min-width: 835px) {
    display: ${({ desktop }) => (desktop?.x ? "block" : "none")};
  }
`
export const IndicatorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`
export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`
