import SocialSharing from "./SocialSharing"
import { BannerContainer, BannerText, BannerWrapper, LearnMore } from "./styled"

interface BlogBannerProps {
  bannerText?: string
  bannerLink?: string
}

const BlogBanner = ({ bannerText, bannerLink }: BlogBannerProps) => {
  const DEFAULT_BANNER_TEXT =
    "Managing depression alone can be difficult. Find a therapist, prescriber, or both who meets your needs. "
  const DEFAULT_LINK = "https://cerebral.com/app/welcome"

  return (
    <BannerWrapper>
      <BannerContainer>
        <BannerText>
          {bannerText || DEFAULT_BANNER_TEXT}
          <LearnMore href={bannerLink || DEFAULT_LINK}>Learn more</LearnMore>
        </BannerText>
        <SocialSharing isBanner />
      </BannerContainer>
    </BannerWrapper>
  )
}

export default BlogBanner
