import styled from "styled-components"
import { colors } from "../../themes/colors"
import { Link } from "gatsby"

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 64px 64px 112px;
  background: transparent;
  & > img {
    margin: 0 auto;
  }
  @media (max-width: 834px) {
    flex-direction: column;
    align-items: center;
    padding: 96px 40px;
  }
`

export const TestimonialWrapper = styled.div`
  display: flex;
  text-align: center;
  line-height: 24px;
  justify-content: space-around;
  font-weight: 300;
  font-size: 16px;
  margin-top: 40px;
  & > strong {
    font-weight: 500;
  }
  @media (max-width: 834px) {
    flex-direction: column;
    & > p {
      margin-bottom: 16px;
    }
    & > p:last-child {
      margin-bottom: 0;
    }
    & > p br {
      display: none;
    }
  }
`
interface TextWrapperProps {
  center?: boolean
  theme?: string
}
export const TextWrapper = styled.p<TextWrapperProps>`
  display: flex;
  justify-content: center;
  font-size: 20px;
  line-height: 32px;
  font-weight: ${({ center }) => (center ? "300" : "700")};
  color: ${({ theme }) =>
    theme === "secondary" ? colors.c_white[500] : colors.c_grey[900]};
  font-style: italic;
  text-align: center;
  align-items: center;
  max-width: 277px;
  @media (max-width: 834px) {
    font-size: 16px;
    max-width: unset;
  }
`

export const TestimonialsContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 224px;
  text-align: center;
  h1 {
    font-size: 28px;
    line-height: 32px;
  }
  background-color: ${colors.c_secondary[50]};
  @media (max-width: 834px) {
    padding: 24px;
    gap: 0;
    h1 {
      font-size: 24px;
    }
  }
`

export const TextLink = styled(Link)<{ textDecoration?: string }>`
  width: 320px;
  height: 56px;
  font-size: 14px;
  font-weight: 600;
  font-family: Poppins;
  margin-top: 48px;
  align-items: center;
  color: ${colors.c_primary[600]};
  line-height: 16px;
  text-decoration: ${props => props.textDecoration || "none"};
`
