import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { ContentSection, CtaButton } from "./LandingPageStyles"
import {
  GatsbyImage,
  getImage,
  getSrc,
  getSrcSet,
  IGatsbyImageData,
  ImageDataLike,
  StaticImage,
} from "gatsby-plugin-image"
import TrustpilotImage from "../shared/assets/trustpilot.svg"
const People = "../shared/assets/people.webp"

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`

const Container = styled(ContentSection)`
  display: flex;
  max-width: 1216px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto;
  height: 100%;
  padding: 0;

  @media (max-width: 1216px) {
    padding: 0 24px;
  }
`

const Title = styled.div`
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  color: #1c1e21;

  @media (max-width: 834px) {
    font-size: 28px;
    line-height: 30px;
  }
`

const SubTitle = styled.div`
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #353a41;
  max-width: 600px;
  margin-top: 16px;

  @media (max-width: 1023px) {
    width: 100%;
    max-width: fit-content;
  }
`

const TrustpilotContainer = styled.div`
  display: flex;
  margin-top: 24px;
  gap: 16px;
  align-items: center;
`

const TrustpilotText = styled.div`
  color: #1c1e21;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
`

const TotalScore = styled.div`
  color: #1c1e21;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
`

const HeaderTrustpilotWrapper = styled.div`
  background-color: #ffffff;
  padding-top: 96px;
  padding-bottom: 32px;

  @media (max-width: 834px) {
    padding-top: 64px;
  }
`

const TrustpilotInfo = styled.div`
  display: flex;
  gap: 13px;
  align-items: center;
`

const ScoreWrapper = styled.div`
  display: flex;
  gap: 4px;
`

const RightSideWrapper = styled.div`
  width: 320px;
  align-items: center;
  display: flex;
  flex-direction: column;

  @media (max-width: 1023px) {
    margin-top: 32px;
    width: 100%;
  }
`

const TrustpilotWrapper = styled.div`
  display: flex;
  gap: 4px;
`

const MainImageWrapper = styled.div`
  margin-top: 64px;
  width: 100%;

  & > div {
    width: 100% !important;
    height: 516px !important;

    @media (max-width: 834px) {
      height: 400px !important;
    }

    @media (max-width: 600px) {
      height: 240px !important;
    }
  }
`

export const MainImage = styled(GatsbyImage)`
  width: 100%;
`

interface HeaderWithTrustpilotProps {
  slice: {
    primary: {
      slice_id?: {
        text: string
      }
      title: {
        text: string
      }
      copy: {
        text: string
      }
      cta_text: {
        text: string
      }
      cta_link: {
        url: string
      }
      user_reviews_image: ImageDataLike
      review: { text: string }
      score: { text: string }
      total_score: { text: string }
      trustpilot_text: { text: string }
      image: ImageDataLike
    }
  }
}

const HeaderWithTrustpilot = ({ slice }: HeaderWithTrustpilotProps) => {
  const {
    image,
    title,
    copy,
    cta_link,
    cta_text,
    user_reviews_image,
    review,
    score,
    total_score,
    trustpilot_text,
    slice_id,
  } = slice.primary
  const srcSet = getSrcSet(image)
  const src = getSrc(image)

  return (
    <HeaderTrustpilotWrapper>
      <Container>
        <Wrapper>
          <div>
            <Title>{title.text}</Title>
            <SubTitle>{copy.text}</SubTitle>
          </div>
          <RightSideWrapper>
            <CtaButton to={cta_link.url || process.env.GATSBY_START_URL}>
              {cta_text.text}
            </CtaButton>

            <TrustpilotContainer>
              <StaticImage
                src={People}
                alt={title.text}
                placeholder="blurred"
                width={72}
                height={32}
              />

              <TrustpilotInfo>
                <TrustpilotText>{review.text}</TrustpilotText>

                <ScoreWrapper>
                  <TrustpilotText>{score.text}</TrustpilotText>
                  <TotalScore>{total_score.text}</TotalScore>
                </ScoreWrapper>

                <TrustpilotWrapper>
                  <img src={TrustpilotImage} alt="trustpilot" />
                  <TrustpilotText>{trustpilot_text.text}</TrustpilotText>
                </TrustpilotWrapper>
              </TrustpilotInfo>
            </TrustpilotContainer>
          </RightSideWrapper>
        </Wrapper>

        <MainImageWrapper>
          <div>
            <img
              src={src}
              srcSet={srcSet}
              alt=""
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
              fetchPriority="high"
            />
          </div>
        </MainImageWrapper>
      </Container>
    </HeaderTrustpilotWrapper>
  )
}

export const query = graphql`
  fragment HeaderWithTrustpilotSlice on PrismicLandingDataBodyHeaderWithTrustpilot {
    slice_type
    primary {
      copy {
        text
      }
      cta_link {
        url
      }
      cta_text {
        text
      }
      title {
        text
      }
      image {
        gatsbyImageData(placeholder: BLURRED)
      }
      user_reviews_image {
        gatsbyImageData(placeholder: BLURRED, height: 32)
      }
      review {
        text
      }
      score {
        text
      }
      total_score {
        text
      }
      trustpilot_text {
        text
      }
    }
  }
`
export default HeaderWithTrustpilot
