import styled from "styled-components"
import { colors } from "../../themes/colors"

interface SelectProps {
  showDropdown: boolean
}

export const IconArrowDown = styled.img`
  width: 16px;
  margin-left: 16px;
`

export const SearchBarVector = styled.img`
  width: 13px;
  height: 13px;
`

export const SelectContainer = styled.div`
  position: relative;
  margin-bottom: 8px;
  padding: 16px;
  cursor: pointer;
  border: 1px solid rgba(229, 235, 255, 1);
  border-radius: 15px;
  & > span {
    font-size: 14px;
    font-weight: 400;
    font-family: Poppins;
    color: rgba(98, 104, 116, 1);
  }
  @media (max-width: 470px) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
`

export const StateValue = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  width: calc(100% - 90px);
  height: 19px;
  display: inline-block;
  position: relative;
  top: 4px;
  margin: 0px 16px;
`

export const Options = styled.div`
  padding-top: 5px;
  padding-bottom: 10px;
  position: absolute;
  top: 100%;
  left: -1px;
  width: calc(100% + 2px);
  background-color: ${colors.c_white[500]};
  border: 1px solid #e1e7ff;
  border-top: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-sizing: border-box;
  max-height: 340px;
  overflow-y: auto;
  z-index: 99;
`

export const Option = styled.div`
  color: ${colors.c_primary[900]};
  line-height: 32px;
  font-weight: 400;
  font-size: 18px;
  padding-left: 10px;
  padding-right: 10px;
  &:hover {
    background-color: #e1e7ff;
  }
`

export const HoziBar = styled.div`
  width: calc(100% - 18px);
  margin-left: 9px;
  margin-bottom: 5px;
  height: 1px;
  background-color: #e1e7ff;
`
