import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { StaticGirlWrapper, GirlImg } from "../sections/headers/headerStyles"
import {
  HeaderWrapper,
  MintCtaButton,
  ContentSection,
  GreenCtaButton,
} from "./LandingPageStyles"
import { getImage, IGatsbyImageData, ImageDataLike } from "gatsby-plugin-image"

const Texts = styled.div`
  text-align: center;

  @media (min-width: 600px) {
    margin: 0 0 0 30px;
    padding: 70px 0 100px;
    width: 45%;
    text-align: left;
  }
`

const Container = styled(ContentSection)`
  display: flex;
  max-width: 1140px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto;
  height: 100%;

  @media (max-width: 600px) {
    padding-top: 40px;
  }
`

const Title = styled.div`
  font-family: "Poppins", sans-serif;
  font-style: italic;
  font-weight: 600;
  font-size: 40px;
  line-height: 55px;
  color: #ffffff;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);

  @media (max-width: 834px) {
    font-size: 28px;
    line-height: 40px;
  }
`

const SubTitle = styled.div`
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.375px;
  margin-top: 15px;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);

  @media (max-width: 834px) {
    font-size: 16px;
  }
`

interface HeroHeaderProps {
  slice: {
    primary: {
      slice_id?: {
        text: string
      }
      title: {
        text: string
      }
      copy: {
        html: string
      }
      cta_text: {
        text: string
      }
      cta_link: {
        url: string
      }
      cta_color: string
      image: ImageDataLike
    }
  }
}

const HeroHeader = ({ slice }: HeroHeaderProps) => {
  const { image, title, copy, cta_link, cta_text, cta_color, slice_id } =
    slice.primary
  const images = getImage(image) as IGatsbyImageData

  return (
    <HeaderWrapper id={slice_id?.text || ""}>
      <Container>
        <Texts>
          <Title>{title.text}</Title>

          <SubTitle dangerouslySetInnerHTML={{ __html: copy.html }} />
          {cta_color && cta_color == "green" ? (
            <GreenCtaButton to={cta_link.url || process.env.GATSBY_START_URL}>
              {cta_text.text}
            </GreenCtaButton>
          ) : (
            <MintCtaButton to={cta_link.url || process.env.GATSBY_START_URL}>
              {cta_text.text}
            </MintCtaButton>
          )}
        </Texts>
      </Container>

      <StaticGirlWrapper>
        <GirlImg image={images} alt={title.text} loading="eager" />
      </StaticGirlWrapper>
    </HeaderWrapper>
  )
}

export const query = graphql`
  fragment HeroHeaderSlice on PrismicLandingDataBodyHeroHeader {
    slice_type
    primary {
      copy {
        html
      }
      cta_link {
        url
      }
      cta_text {
        text
      }
      title {
        text
      }
      image {
        gatsbyImageData(placeholder: BLURRED, layout: FIXED, height: 646)
      }
    }
  }
  fragment PageHeroHeaderSlice on PrismicPageDataBodyHeroHeader {
    slice_type
    primary {
      slice_id {
        text
      }
      copy {
        html
      }
      cta_link {
        url
      }
      cta_text {
        text
      }
      cta_color
      title {
        text
      }
      image {
        gatsbyImageData(placeholder: BLURRED, layout: FIXED, height: 646)
      }
    }
  }
  fragment PostHeroHeaderSlice on PrismicPostDataBodyHeroHeader {
    slice_type
    primary {
      copy {
        html
      }
      cta_link {
        url
      }
      cta_text {
        text
      }
      title {
        text
      }
      image {
        gatsbyImageData(placeholder: BLURRED, layout: FIXED, height: 646)
      }
    }
  }
  fragment ProviderHeroHeaderSlice on PrismicProviderDataBodyHeroHeader {
    slice_type
    primary {
      copy {
        html
      }
      cta_link {
        url
      }
      cta_text {
        text
      }
      title {
        text
      }
      image {
        gatsbyImageData(placeholder: BLURRED, layout: FIXED, height: 646)
      }
    }
  }
  fragment CounselorHeroHeaderSlice on PrismicCounselorDataBodyHeroHeader {
    slice_type
    primary {
      copy {
        html
      }
      cta_link {
        url
      }
      cta_text {
        text
      }
      title {
        text
      }
      image {
        gatsbyImageData(placeholder: BLURRED, layout: FIXED, height: 646)
      }
    }
  }
  fragment TherapistHeroHeaderSlice on PrismicTherapistDataBodyHeroHeader {
    slice_type
    primary {
      copy {
        html
      }
      cta_link {
        url
      }
      cta_text {
        text
      }
      title {
        text
      }
      image {
        gatsbyImageData(placeholder: BLURRED, layout: FIXED, height: 646)
      }
    }
  }
`
export default HeroHeader
