import { content } from "./constant"
import { CopyGrid, CopyGridSection, QualityContainer } from "./styled"
import { BodyRegular, H2, H4 } from "../../../../ui"

export const QualityDefinesUs = () => {
  return (
    <QualityContainer>
      <H2>{content.header}</H2>
      <CopyGrid>
        {content.sections.map(({ id, title, content, icon }) => (
          <CopyGridSection key={id} id={id}>
            <img src={icon} alt="" height="24px" width="24px" />
            <div>
              <H4>{title}</H4>
              <BodyRegular>{content}</BodyRegular>
            </div>
          </CopyGridSection>
        ))}
      </CopyGrid>
    </QualityContainer>
  )
}
