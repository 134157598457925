import React, { useState, ReactNode } from "react"
import { useSwipeable } from "react-swipeable"

import {
  CarouselContainer,
  InnerContainer,
  CarouselItem as CarouselItemWrapper,
  Indicators,
  IndicatorDot,
  ArrowLeft,
  ArrowRight,
  IndicatorWrapper,
} from "./styled"
import leftArrow from "./assets/left-arrow.svg"
import rightArrow from "./assets/right-arrow.svg"
import { ArrowProps } from "./types"

interface CarouselItemProps {
  children: ReactNode
}
export const CarouselItem = ({ children }: CarouselItemProps): JSX.Element => {
  return <CarouselItemWrapper>{children}</CarouselItemWrapper>
}

interface CarouseProps {
  children: ReactNode
  width?: string
  ariaLabel?: string
  indicatorStyles?: ArrowProps
  startingIndex?: number
}

const defaultIndicatorStyles = {
  desktop: {
    x: "5%",
    y: "30%",
  },
  mobile: {
    x: "0",
    y: "55%",
  },
}

export const Carousel = ({
  children,
  indicatorStyles = defaultIndicatorStyles,
  startingIndex = 0,
}: CarouseProps): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState(startingIndex)

  const updateIndex = (newIndex: number) => {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = 0
    }

    setActiveIndex(newIndex)
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1),
  })

  return (
    <CarouselContainer {...handlers} style={{ position: "relative" }}>
      <InnerContainer
        style={{ transform: `translateX(-${activeIndex * 33.333}%)` }}
      >
        {children}
      </InnerContainer>
      <ArrowLeft
        desktop={indicatorStyles.desktop}
        mobile={indicatorStyles.mobile}
        data-testid="indicator-left"
        onClick={() => {
          updateIndex(
            activeIndex === 0
              ? React.Children.toArray(children).length - 1
              : activeIndex - 1
          )
        }}
      >
        <img alt="see previous" loading="lazy" src={leftArrow} />
      </ArrowLeft>
      <ArrowRight
        desktop={indicatorStyles.desktop}
        mobile={indicatorStyles.mobile}
        data-testid="indicator-right"
        onClick={() => {
          updateIndex(
            activeIndex === React.Children.toArray(children).length - 1
              ? 0
              : activeIndex + 1
          )
        }}
      >
        <img alt="see next" loading="lazy" src={rightArrow} />
      </ArrowRight>
      <IndicatorWrapper>
        <ArrowLeft
          onClick={() => {
            updateIndex(
              activeIndex === 0
                ? React.Children.toArray(children).length - 1
                : activeIndex - 1
            )
          }}
        >
          <img alt="see previous" loading="lazy" src={leftArrow} />
        </ArrowLeft>
        <Indicators>
          {React.Children.map(children, (_item, index) => {
            return (
              <IndicatorDot
                key={index}
                data-testid={
                  index === activeIndex
                    ? `indicator-active-${index}`
                    : `indicator-deactive-${index}`
                }
                $isActive={index === activeIndex}
                id={
                  index === activeIndex
                    ? `indicator-active`
                    : `indicator-deactive-${index}`
                }
              />
            )
          })}
        </Indicators>
        <ArrowRight
          onClick={() => {
            updateIndex(
              activeIndex === React.Children.toArray(children).length - 1
                ? 0
                : activeIndex + 1
            )
          }}
        >
          <img alt="see next" loading="lazy" src={rightArrow} />
        </ArrowRight>
      </IndicatorWrapper>
    </CarouselContainer>
  )
}
