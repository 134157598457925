import { useEffect, useState } from "react"
import ArrowRight from "./assets/arrow-right.svg"
import { plans, words } from "./constants"
import {
  ArrowIcon,
  CardContainer,
  CardHeader,
  CardIcon,
  CardText,
  Chip,
  Header,
  LearnMore,
  LinkContainer,
  Pill,
  PillContainer,
  PillHeader,
  PillLeft,
  PlansContainer,
  PlansContainerMobile,
  Subheader,
  HomeWrapper,
  InsuranceWrapper,
  InsuranceLink,
  InsuranceText,
} from "./styled"
import { InsuranceWidget } from "../../../PricingPage/sections/index"
import { getVariantByName } from "../../../../lib/utils"
import { InsuranceWidgetHomePageStyle } from "../../../shared/InsuranceWidget/constants"
import { InsuranceLogos } from "../../../shared"

const PlanCard = ({
  icon,
  title,
  description,
  link,
  linkText,
  chipText,
}: {
  icon: string
  title: string
  description: string
  link: string
  linkText?: string
  chipText?: string
}) => (
  <CardContainer href={link}>
    <CardIcon src={icon} alt={title} />
    <CardHeader>{title}</CardHeader>
    <CardText>{description}</CardText>
    <LinkContainer>
      <LearnMore>{linkText}</LearnMore>
    </LinkContainer>

    {!!chipText && <Chip>{chipText}</Chip>}
  </CardContainer>
)

const PlanPill = ({
  icon,
  title,
  link,
  chipText,
}: {
  icon: string
  title: string
  link: string
  chipText?: string
}) => (
  <PillContainer href={link}>
    <PillLeft>
      <CardIcon src={icon} alt={title} />
      <PillHeader>{title}</PillHeader>
    </PillLeft>
    {!!chipText && <Chip>{chipText}</Chip>}
    <ArrowIcon src={ArrowRight} />
  </PillContainer>
)

export const YourPersonalPath = () => {
  const [pillIndex, setPillIndex] = useState(0)
  const [shouldShowInsuranceWidget, setShouldShowInsuranceWidget] =
    useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      if (pillIndex < words.length - 1) {
        setPillIndex(pillIndex + 1)
      } else {
        setPillIndex(0)
      }
    }, 1500)
    return () => clearInterval(interval)
  })

  useEffect(() => {
    const checkInsuranceVariant = () => {
      const isInsuranceWidgetActive =
        getVariantByName("Insurance Widget by State") === "Active"
      setShouldShowInsuranceWidget(isInsuranceWidgetActive)
    }
    checkInsuranceVariant()
  }, [])

  return (
    <HomeWrapper>
      <Header>
        We make it easy to start feeling <Pill>{words[pillIndex]}</Pill>
      </Header>

      <Subheader>
        Work with a therapist, prescriber, or both in a safe and judgment-free
        online space
      </Subheader>

      <PlansContainerMobile>
        {plans.map(plan => (
          <PlanPill key={plan.title} {...plan} />
        ))}
      </PlansContainerMobile>

      <PlansContainer gridCount={plans.length}>
        {plans.map(plan => (
          <PlanCard key={plan.title} {...plan} />
        ))}
      </PlansContainer>
      <InsuranceWrapper>
        {shouldShowInsuranceWidget ? (
          <>
            <InsuranceLogos backgroundColor="#FFFFFF" />
            <InsuranceWidget style={InsuranceWidgetHomePageStyle} />
          </>
        ) : (
          <>
            <InsuranceLogos />
            <InsuranceText>
              Paying with insurance?{" "}
              <InsuranceLink href="/insurance-partners">
                Check your coverage
              </InsuranceLink>
            </InsuranceText>
          </>
        )}
      </InsuranceWrapper>
    </HomeWrapper>
  )
}
