import styled from "styled-components"
import { colors } from "../../../themes/colors"

export const TherapistPrescriberWrapper = styled.div`
  text-align: center;
  padding: 80px 24px 0px;

  @media (max-width: 834px) {
    padding: 32px 24px 0px;
  }
`
export const HeaderText = styled.h2`
  color: ${colors.c_black[900]};
  text-align: center;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);
  font-family: Poppins;
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
  @media (max-width: 834px) {
    font-size: 24px;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 32px;
  margin-top: 32px;
  margin-bottom: 8px;
  @media (max-width: 834px) {
    flex-direction: column;
    gap: 16px;
  }
`

export const SectionButton = styled.button`
  border-radius: 100px;
  background: ${colors.c_primary[600]};
  border: none;
  min-width: 320px;
  color: ${colors.c_white[100]};
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  height: 56px;
  cursor: pointer;
  @media (max-width: 834px) {
    max-width: 600px;
    min-width: 0;
    width: 100%;
  }
`
