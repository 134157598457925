import { graphql } from "gatsby"
import styled from "styled-components"
import { getImage, IGatsbyImageData } from "gatsby-plugin-image"
import { CtaButton } from "./LandingPageStyles"
import PlanCard from "../../components/shared/ContentHub/PlanCard"

const Wrapper = styled.div`
  background: linear-gradient(135deg, #fef9ec 0%, #eceffe 100%);
  width: 100%;
  padding: 64px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    padding: 48px 24px;
  }
`

const Container = styled.div`
  max-width: 992px;
  gap: 80px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 1024px) {
    gap: 32px;
  }

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`

const LeftBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 1024px) {
    a {
      display: none;
    }
  }
`

const Title = styled.div`
  color: #1c1e21;
  font-family: Poppins;
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 24px;

  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 16px;
  }
`

const Description = styled.div`
  color: #4d515a;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 40px;

  @media (max-width: 1024px) {
    font-size: 14px;
    margin-bottom: 0;
  }
`

const RightBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const RightBoxButton = styled.div`
  display: none;

  @media (max-width: 1024px) {
    display: flex;
    margin-top: 16px;
    justify-content: center;
  }
`

interface ContentHubPlanInterface {
  slice: {
    primary: {
      tab_id: string
      plan_title: {
        text: string
      }
      plan_description: {
        text: string
      }
      cta_text: {
        text: string
      }
      cta_link: {
        url: string
      }
    }
    items: {
      header_icon: {
        gatsbyImageData: IGatsbyImageData
      }
      header_title: {
        text: string
      }
      plan_name: {
        text: string
      }
      bullets: {
        text: string
      }
      price: {
        html: string
      }
      price_option: {
        text: string
      }
      cta_text: {
        text: string
      }
      cta_link: {
        url: string
      }
    }[]
  }
}

const ContentHubPlan = ({ slice }: ContentHubPlanInterface): JSX.Element => {
  const { tab_id, cta_link, cta_text, plan_title, plan_description } =
    slice.primary
  const { items } = slice

  return (
    <Wrapper id={tab_id}>
      <Container>
        <LeftBox>
          <Title>{plan_title.text}</Title>
          <Description>{plan_description.text}</Description>
          <CtaButton
            to={cta_link?.url || process.env.GATSBY_START_URL}
            width="250px"
            boxShadow="none"
          >
            {cta_text?.text}
          </CtaButton>
        </LeftBox>
        <RightBox>
          {items.map(
            ({
              header_icon,
              header_title,
              plan_name,
              bullets,
              price,
              price_option,
              cta_text,
              cta_link,
            }) => (
              <PlanCard
                headerIcon={getImage(header_icon) as IGatsbyImageData}
                headerTitle={header_title.text}
                planName={plan_name.text}
                bullets={bullets.text}
                price={price.html}
                priceOption={price_option.text}
                ctaText={cta_text.text}
                ctaLink={cta_link.url}
              />
            )
          )}
          <RightBoxButton>
            <CtaButton
              to={cta_link?.url || process.env.GATSBY_START_URL}
              width="250px"
              boxShadow="none "
            >
              {cta_text?.text}
            </CtaButton>
          </RightBoxButton>
        </RightBox>
      </Container>
    </Wrapper>
  )
}

export const query = graphql`
  fragment PageContentHubPlanSlice on PrismicPageDataBodyContentHubPlan {
    id
    slice_type
    primary {
      tab_id
      tab_title {
        text
      }
      plan_title {
        text
      }
      plan_description {
        text
      }
      cta_text {
        text
      }
      cta_link {
        url
      }
    }
    items {
      header_icon {
        gatsbyImageData(placeholder: BLURRED, width: 16, height: 16)
      }
      header_title {
        text
      }
      plan_name {
        text
      }
      bullets {
        text
      }
      price {
        html
      }
      price_option {
        text
      }
      cta_text {
        text
      }
      cta_link {
        url
      }
    }
  }
`

export default ContentHubPlan
