import React from "react"
import styled from "styled-components"
import Button from "../../common/Button"

const Cta = styled.div`
  text-align: center;
  margin: 30px auto 0;
  ${Button} {
    ${({ size }) =>
      size === "big"
        ? `padding: 22px 12px; min-width: 240px;`
        : `min-width: 120px;`}
  }

  @media (min-width: 834px) {
    margin-top: 50px;
  }
`

export default function BottomCta({ cta }) {
  if (!cta) {
    return null
  }

  return (
    <Cta size={cta.size}>
      <Button to={cta.url} target={cta.target}>
        {cta.text}
      </Button>
    </Cta>
  )
}
