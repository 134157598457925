import styled from "styled-components"
import ItalicHeadingDynamic from "../ui/ItalicHeadingDynamic"
import Button from "../../common/Button"

export const ButtonBase = styled(Button)`
  width: fit-content;
  box-shadow: 0px 7px 14px rgba(34, 34, 52, 0.13);
  border-radius: 30px;
  font-family: Poppins;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  padding: 18px 30px 18px 30px;
  text-align: center;
  margin-top: 30px;

  &:active {
    box-shadow: inset 0px 7px 14px rgba(34, 34, 52, 0.13);
  }
`

export const MintCtaButton = styled(ButtonBase)`
  background: #d2eff6;
  color: #6786ff;
`

export const GreenCtaButton = styled(ButtonBase)`
  background: #59e3b3;
  color: white;
`

export const BlueCtaButton = styled(ButtonBase)`
  background: linear-gradient(90deg, #6786ff 6.2%, #85abee 100%);
  color: #ffffff;
`

export const SolidCtaButton = styled(ButtonBase)<{
  backgroundColor?: string
  hoverColor?: string
  textColor?: string
}>`
  margin-top: 0px;
  padding: 8px 32px;
  box-shadow: none;
  background: ${({ backgroundColor }) => backgroundColor || "#6786ff"};
  color: ${({ textColor }) => textColor || "#fff"};

  &:hover {
    background: ${({ hoverColor }) => hoverColor || "#859EFF"};
  }
`

export const SolidBlueCtaButton = styled(ButtonBase)`
  background: #51459e;
  color: #ffffff;
  margin-top: 0px !important;
  width: 240px;

  @media (max-width: 834px) {
    width: 100%;
  }
`

export const GradientCtaButton = styled(ButtonBase)`
  background: linear-gradient(180deg, #859eff 0%, #6786ff 100%);
  margin-top: 0px;
  padding: 8px 32px;
  box-shadow: none;
  &:hover {
    background: linear-gradient(180deg, #a4b6ff 0%, #859eff 100%);
  }
`

export const StrokeCtaButton = styled(ButtonBase)<{
  backgroundColor?: string
  hoverColor?: string
  textColor?: string
}>`
  border: 2px solid #250044;
  border-color: ${({ backgroundColor }) => backgroundColor || "#250044"};
  background: ${({ backgroundColor }) =>
    backgroundColor ? "transparent" : "white"};
  color: ${({ backgroundColor }) => backgroundColor || "white"};
  margin-top: 0px;
  box-sizing: border-box;
  padding: 8px 32px;
  box-shadow: none;

  &:hover {
    border-color: ${({ hoverColor }) => hoverColor || "#350060"};
    color: ${({ hoverColor }) => hoverColor || "white"};
  }
`

export const LeftItalicHeading = styled(ItalicHeadingDynamic)`
  margin: 0;
  text-align: left;
  margin-bottom: 22px;

  @media (max-width: 834px) {
    margin: auto;
    text-align: center;
    margin-bottom: 22px;
  }
`

export const CenterItalicHeading = styled(ItalicHeadingDynamic)`
  margin: auto;
  margin-bottom: 30px;
`

export const HeroHeaderTitle = `
  font-family: "Poppins", sans-serif;
  font-style: italic;
  font-weight: 600;
  font-size: 40px;
  line-height: 55px;

  @media (max-width: 834px) {
    font-size: 28px;
    line-height: 40px;
  }
`

export const SubTitle = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-size: 18px;
  line-height: 30px;
  color: #250044;

  @media (max-width: 834px) {
    text-align: center;
  }
`

export const Wrapper = styled.div`
  position: relative;

  @media (max-width: 834px) {
    height: auto;
  }
`

export const FlexWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const MintWrapper = styled(FlexWrapper)`
  background-color: #e1f9fe;
  height: auto;
`

export const HeaderWrapper = styled(Wrapper)`
  height: auto;
  background: linear-gradient(190deg, #8ba3ff 19.5%, #a8baff 72.7%);

  @media (min-width: 834px) {
    background: linear-gradient(190deg, #8ba3ff 0%, #a8baff 100%);
    height: 560px;
  }
`

export const ContentSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 896px;
  padding: 0px 30px;
  margin-top: 50px;
  margin-bottom: 50px;

  @media (max-width: 834px) {
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`

export const ReversedContentSection = styled(ContentSection)`
  flex-direction: row-reverse;

  @media (max-width: 834px) {
    flex-direction: column-reverse;
  }
`

export const TitleSection = styled.div`
  align-items: center;
  width: 100%;
  margin-top: 50px;

  @media (max-width: 834px) {
    margin-top: 20px;
  }
`

export const FirstSection = styled.div`
  flex: 5;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  @media (max-width: 834px) {
    margin-bottom: 20px;
  }
`

export const SecondSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 2;
`

export const BigRoundedWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 190px;
  height: 190px;

  picture img {
    object-position: center center !important;
  }

  @media (min-width: 834px) {
    padding-bottom: unset;
    width: 285px;
    height: 285px;
  }
`

export const Container = styled.div`
  width: 100%;
`

export const InlineBoxContentSection = styled(ContentSection)`
  display: block;
  margin: 0 auto;
  margin-top: -50px;
`

export const TopRoundedImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 30px 30px 0px 0px;

  picture img {
    object-position: center center !important;
  }
`

export const Headline = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;

  display: flex;
  align-items: center;
  text-align: center;
  max-width: 1140px;
`

export const SmallRoundedWrapper = styled(BigRoundedWrapper)`
  width: 190px;
  height: 190px;
  border-radius: 100%;
`
export const CtaButton = styled(ButtonBase)<{
  width?: string
  variant?: "primary" | "outline"
  boxShadow?: string | "none"
}>`
  background: ${props => (props.variant === "outline" ? "#ffffff" : "#51459e")};
  color: ${props => (props.variant === "outline" ? "#51459e" : "#ffffff")};
  margin: 0;
  width: ${props => props.width || "272px"};
  border: 2px solid #51459e;
  box-shadow: ${props => props.boxShadow || ""};

  @media (max-width: 834px) {
    width: 100%;
  }
`

export const LeftTitle = styled.div`
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: #1c1e21;

  @media (max-width: 834px) {
    font-size: 24px;
    line-height: 28px;
  }

  text-align: left;
`
