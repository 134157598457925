import styled from "styled-components"

export const BannerContainer = styled.div`
  background-color: #eceaff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
`

export const BannerImage = styled.img`
  vertical-align: middle;
  margin: 0 4px;
`

export const BannerText = styled.p`
  color: #1c1e21;
  font-size: 14px;
  font-family: Poppins, sans-serif;
  line-height: 24px;

  a {
    color: inherit;
  }
`

export const DataDeletionButton = styled.button`
  border: none;
  background: none;
  font-family: inherit;
  text-decoration: underline;
  font-size: inherit;
  padding: 0;
  cursor: pointer;
  color: inherit;
`
