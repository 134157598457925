import React from "react"
import { CopyRight as CopyRightCopy } from "./styled"

export const CopyRight = (): JSX.Element => {
  return (
    <CopyRightCopy>
      <span>
        © {new Date().getFullYear()} Cerebral Inc. All rights reserved.{" "}
      </span>
      <span>2093 Philadelphia Pike #9898 Claymont, DE 19703</span>
    </CopyRightCopy>
  )
}
