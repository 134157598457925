import styled from "styled-components"
import { colors } from "../../themes/colors"

export const QuotesContainer = styled.div<{ isPrismic?: boolean }>`
  margin: 32px auto 56px;
  max-width: 1216px;

  @media (max-width: 834px) {
    padding: 24px;
    margin: 0;
  }
`

export const QuotesContentContainer = styled.div<{
  isPrismic?: boolean
}>`
  background-color: ${props =>
    props.isPrismic ? "#FFFFFF" : colors.c_secondary[50]};
  white-space: normal;
  text-align: center;
  padding: 32px;
  width: 85%;
  margin-bottom: 24px;
  border-radius: 10px;
  @media (max-width: 834px) {
    width: 100%;
    padding: 40px;
  }
`

export const Quote = styled.p`
  color: #250044;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 16px 0;
`

export const Name = styled.p`
  color: #250044;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 16px;
`
