import { useEffect, useState } from "react"
import { validPromos } from "../constants"
import Cookies from "js-cookie"
export const usePromoCode = () => {
  const [promo, setPromo] = useState<string | null>()

  useEffect(() => {
    const search = new URLSearchParams(window.location.search)
    const promoFromURL = search.get("promo")
    const promoFromStorage = localStorage.getItem("promo")
    const promoFromCookie = Cookies.get("promo")
    const promo = promoFromURL ?? promoFromStorage ?? promoFromCookie ?? ""

    if (validPromos.includes(promo)) {
      setPromo(promo)
      if (promo) {
        localStorage.setItem("promo", promo)
      }
    }
  }, [])

  return promo
}
