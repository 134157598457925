import React, { useState } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { LeftItalicHeading } from "./LandingPageStyles"
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  ImageDataLike,
} from "gatsby-plugin-image"
import ShowMoreButton from "../../common/ShowMoreButton"

const NewsLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 150px;
  margin-right: 150px;
  max-width: 1140px;
  align-items: center;

  @media (max-width: 834px) {
    margin-left: 50px;
    margin-right: 50px;
  }

  @media (min-width: 1440px) {
    margin: auto;
  }
`

const NewsHeadline = styled.div`
  margin-top: 30px;
  margin-bottom: 15px;
  width: 100%;
`

const NewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ShowMoreButtonContainer = styled.div`
  margin: 15px 0;
`

const NewsWrapper = styled.a`
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid #e1e5f5;
  }

  &:-webkit-any-link {
    text-decoration: none;
  }
`

const ImageSection = styled.div`
  display: flex;
  justify-content: center;
  width: 285px;
  height: 160px;
`

const NewsInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-left: 30px;
  white-space: nowrap;
  overflow: hidden;
  flex: 1;
`

const Heading = styled(LeftItalicHeading)`
  margin-bottom: 0;
`

const DatePosted = styled.div`
  text-align: left;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 48px;
  color: #6786ff;
`

const NewsInfoHeadline = styled.div`
  text-align: left;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 48px;
  letter-spacing: -1px;
  color: #250044;
  overflow: hidden;
  text-overflow: ellipsis;
`

const CtaLink = styled.span`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 48px;
  text-decoration-line: underline;
  color: #513369;
`

interface NewsInterface {
  image: ImageDataLike
  headline: string
  cta_link: string
  cta_text: string
  date_posted: string
}

interface NewsLinksInterface {
  slice: {
    id: string
    items: {
      image_1: ImageDataLike
      date_posted: string
      headline: { text: string }
      cta_link: { url: string }
      cta_text: { text: string }
    }[]
    primary: {
      headline: { text: string }
      call_to_see_more_link: { url: string }
      enable_show_more: boolean
      enable_see_more: boolean
      number_of_articles_default: number
      number_of_articles_show_more: number
    }
  }
}

const News = ({
  image,
  headline,
  cta_link,
  cta_text,
  date_posted,
}: NewsInterface) => {
  return (
    <NewsWrapper href={cta_link}>
      <ImageSection>
        <GatsbyImage
          image={getImage(image) as IGatsbyImageData}
          alt=""
          loading="eager"
        />
      </ImageSection>

      <NewsInfoSection>
        <DatePosted>Posted {date_posted}</DatePosted>
        <NewsInfoHeadline>{headline}</NewsInfoHeadline>
        <CtaLink>{cta_text}</CtaLink>
      </NewsInfoSection>
    </NewsWrapper>
  )
}

const NewsLinks = ({ slice }: NewsLinksInterface) => {
  const { items, primary } = slice
  const { headline, call_to_see_more_link, enable_show_more, enable_see_more } =
    primary
  const number_of_articles_default = primary.number_of_articles_default || 3
  const number_of_articles_show_more = primary.number_of_articles_show_more || 3

  const [articles, setArticles] = useState(
    items.slice(0, number_of_articles_default)
  )
  const [isShown, setIsShown] = useState(false)

  const showMoreArticles = ({ isShown }: { isShown: boolean }) => {
    setArticles(
      items.slice(0, number_of_articles_default + number_of_articles_show_more)
    )
    setIsShown(isShown)
  }

  // Always show `See More` button after user clicked on `Shore More` button (if user does not disable `See More` button)
  let isShowButton =
    enable_show_more && (isShown || articles.length < items.length)

  return (
    <NewsLinksWrapper id={slice.id}>
      {headline && headline.text && (
        <NewsHeadline>
          <Heading text={headline.text} />
        </NewsHeadline>
      )}

      <NewsContainer>
        {articles.map((article, index) => (
          <News
            key={index}
            image={article.image_1}
            date_posted={article.date_posted}
            headline={article.headline.text}
            cta_link={article.cta_link.url}
            cta_text={article.cta_text.text}
          />
        ))}
      </NewsContainer>

      {isShowButton && (
        <ShowMoreButtonContainer>
          <ShowMoreButton
            to={call_to_see_more_link.url}
            onShow={showMoreArticles}
            enableSeeMore={enable_see_more}
          />
        </ShowMoreButtonContainer>
      )}
    </NewsLinksWrapper>
  )
}

export const query = graphql`
  fragment PageNewsLinksSlice on PrismicPageDataBodyNewsLinks {
    id
    slice_type
    primary {
      slice_id {
        text
      }
      enable_show_more
      enable_see_more
      number_of_articles_default
      number_of_articles_show_more
      headline {
        text
      }
      call_to_see_more_link {
        url
      }
    }
    items {
      date_posted(formatString: "YYYY.MM.DD")
      image_1 {
        gatsbyImageData(
          placeholder: BLURRED
          layout: FIXED
          width: 285
          height: 160
        )
      }
      headline {
        text
      }
      cta_link {
        url
      }
      cta_text {
        text
      }
    }
  }
`

export default NewsLinks
