export const EndCapTitleData = {
  therapist: "Start meeeting with a therapist",
  prescriber: "Meet with a prescriber online",
}

export const SeoData = {
  therapist: {
    title: "Find Licensed Therapists Near You with Cerebral",
    description:
      "Book an affordable online therapy session with a highly rated therapist near you to gain skills for managing your mental health from the comfort of your home.",
    url: "/find-a-therapist",
  },
  prescriber: {
    title: "Find Prescribers Near You with Cerebral",
    description:
      "Book a session with an online prescriber near you for a mental health evaluation, diagnosis, and ongoing check-ins. Free shipping included with subscription.",
    url: "/find-a-prescriber",
  },
}
