import React from "react"

export interface Image {
  name: string
  srcSet: string
  height?: string
  width?: string
  type?: string
  media?: string
}

interface PictureProps {
  images: Image[]
  fallBack: string
  alt: string
  noLazy?: boolean
}

export const Picture = ({ images, fallBack, alt, noLazy }: PictureProps) => {
  return (
    <picture>
      {images.map(image => (
        <source
          key={image.name}
          srcSet={image.srcSet}
          type={image.type}
          media={image.media}
          width={image.width}
          height={image.height}
        />
      ))}
      <img
        src={fallBack}
        alt={alt}
        style={{ verticalAlign: "middle" }}
        loading={noLazy ? "eager" : "lazy"}
      />
    </picture>
  )
}
