import { H1, HightLightText, BodyRegular, Button } from "../../ui"
import {
  Container,
  InnerContainer,
  LeftSide,
  PhoneImage,
  ButtonWrapper,
} from "./style"

interface ISplashProps {
  titleText: string
  subtitleText: string
  image: string
  buttons?: {
    text: string
    link: string
    onClick?: () => void
    theme?: "primary" | "secondary" | "tertiary"
  }[]
  buttonsDirection?: "row" | "column"
  background: string
  shouldFlipMobileSections?: boolean
  shouldHideMobileImage?: boolean
  isHeroImage?: boolean
  highlightText?: string
}

export const Splash = ({
  titleText,
  subtitleText,
  image,
  background,
  buttons = [
    {
      text: "Get Started",
      link: process.env.GATSBY_START_URL,
      theme: "primary",
    },
  ],
  buttonsDirection = "row",
  shouldFlipMobileSections = false,
  shouldHideMobileImage = false,
  isHeroImage = false,
  highlightText,
}: ISplashProps) => {
  return (
    <Container background={background} className="take-control-variant">
      <InnerContainer shouldHideMobileImage={shouldHideMobileImage}>
        <LeftSide>
          <H1 color="#250044">{titleText}</H1>
          {highlightText && <HightLightText>{highlightText}</HightLightText>}
          <BodyRegular fontWeight={400}>{subtitleText}</BodyRegular>
          <ButtonWrapper direction={buttonsDirection}>
            {buttons.map(({ text, link, theme, onClick }) => (
              <Button
                theme={theme}
                key={link}
                to={link}
                onClick={onClick}
                text={text}
              />
            ))}
          </ButtonWrapper>
        </LeftSide>

        <div>
          <PhoneImage
            src={image}
            alt=""
            shouldHideMobileImage={shouldHideMobileImage}
            shouldFlipMobileSections={shouldFlipMobileSections}
            isHeroImage={isHeroImage}
          />
        </div>
      </InnerContainer>
    </Container>
  )
}
