import React from "react"
import { Link } from "gatsby"

import { CardWrapper } from "./styled"
import { H3 } from "../Heading/styled"
interface LookAheadCardsProps {
  title?: string
  to: string
}

export const LookAheadCards = ({
  title,
  to,
}: LookAheadCardsProps): JSX.Element => {
  return (
    <CardWrapper>
      <H3>{title}</H3>
      <Link to={to}>Read Article</Link>
    </CardWrapper>
  )
}
