import { Picture, H1, H2, H3, BodyRegular, H5, Button } from "../../../ui"
import {
  OurServicesWrapper,
  CWAlternatingImageTextContent,
  CWAlternatingImageTextContainer,
  PricingContainer,
  CallOutContainer,
  GetStartedContainer,
  FSAHSAText,
  Popover,
  TriangleContainer,
  FSAHSATextContainer,
  ButtonContainer,
} from "./styled"
import { contents } from "./consts"
import { GetStartedButton } from "../../../shared/GetStartedButton"
import Info from "../../../../images/Info.svg"

export const OurServices = (): JSX.Element => {
  return (
    <OurServicesWrapper>
      <H1>Our services</H1>
      <FSAHSATextContainer>
        <FSAHSAText>FSA/HSA Eligible </FSAHSAText>
        <Popover tabIndex={0}>
          <img src={Info} alt="" />
          <div className="popover-content">
            <TriangleContainer>
              <div className="inner-triangle" />
            </TriangleContainer>
            <p>
              Cerebral accepts FSA/HSA as a form of payment. You may enter that
              card information as your payment method on file. Most FSA/HSA
              plans cover some or all costs for mental health care services.
              Please contact your plan administrator for details.
            </p>
          </div>
        </Popover>
      </FSAHSATextContainer>
      {contents.map((content: any, i: number) => {
        return (
          <CWAlternatingImageTextContainer index={i} key={content.title}>
            <Picture
              images={content.images}
              fallBack={content.images[0].srcSet}
              alt=""
            />
            <CWAlternatingImageTextContent>
              <H2>{content.title}</H2>
              <H5>{content.subtitle}</H5>
              <BodyRegular
                style={{ marginBottom: "32px" }}
                dangerouslySetInnerHTML={{ __html: content.body }}
              />

              {content.title !== "Therapy + Medication" && (
                <PricingContainer>
                  <ButtonContainer>
                    <Button
                      text="Learn more"
                      to={`${content.link}`}
                      height={56}
                    />
                  </ButtonContainer>
                </PricingContainer>
              )}
              <CallOutContainer>
                <img src={content.vector} alt="" />
                {content.calloutBody ? (
                  <BodyRegular style={{ fontWeight: "500" }}>
                    {content.calloutBody}
                  </BodyRegular>
                ) : (
                  <BodyRegular style={{ fontWeight: "500" }}>
                    In a{" "}
                    <a href="https://www.health.harvard.edu/staying-healthy/medication-or-therapy-for-depression-or-both">
                      recent study
                    </a>
                    , 80% of people with mild to moderate symptoms who were
                    treated with both therapy and medication avoided a
                    recurrence of depression.
                  </BodyRegular>
                )}
              </CallOutContainer>
            </CWAlternatingImageTextContent>
          </CWAlternatingImageTextContainer>
        )
      })}
      <GetStartedContainer>
        <H3>Make meaningful improvements to your mental health</H3>
        <ButtonContainer desktopWidth={"327px"}>
          <GetStartedButton paddingHorizontal={32} />
        </ButtonContainer>
      </GetStartedContainer>
    </OurServicesWrapper>
  )
}
