import Cookies from "js-cookie"
import { useEffect } from "react"
import segment from "../../lib/segment"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../SEO"
import { Navigation } from "../ui/Navigation"
import { HomePageWrapper } from "./styled"
import {
  TheCerebralWay,
  SuccessStories,
  OurServices,
  TherapistPrescriber,
  YourPersonalPath,
  AppFeatures,
} from "./sections"
import { FAQSection, EndCap, StatsBlueBackground } from "../shared"
import { organizationSchemaMarkup } from "../../common/OrganizationSeoSchema"
import { CTAHomePage } from "./sections/CTAHomePage"
import { getVariantByName } from "../../lib/utils"

const HOME_PAGE_QUERY = graphql`
  query HomePageQuery {
    allPrismicFaqWidget(filter: { data: { page_type: { eq: "homepage" } } }) {
      edges {
        node {
          data {
            faqs {
              question {
                document {
                  ... on PrismicFaqRedesign {
                    data {
                      answer {
                        html
                      }
                      question {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const HomePageBody = (): JSX.Element => {
  const response = useStaticQuery(HOME_PAGE_QUERY)
  const faqs = response.allPrismicFaqWidget.edges[0].node.data.faqs

  return (
    <HomePageWrapper>
      <YourPersonalPath />
      <TheCerebralWay />
      <StatsBlueBackground />
      <OurServices />
      <AppFeatures />
      <TherapistPrescriber />
      <SuccessStories />
      <FAQSection faqs={faqs} />
      <EndCap title="Mental health care made for you" />
      {getVariantByName("Chatbot Widget") !== "Active" && <CTAHomePage />}
    </HomePageWrapper>
  )
}

export const HomePage = (): JSX.Element => {
  useEffect(() => {
    // To track Impact conversions
    segment.setImpactClickID()

    // Sets cookie for testing segment on DEV enviroments
    const params = new URLSearchParams(window.location.search)
    if (
      params.get("enable_segment_track") &&
      !Cookies.get("enable_segment_track")
    ) {
      Cookies.set("enable_segment_track", "true", { expires: 1 })
    }
  }, [])

  const showNewMetaTags =
    getVariantByName("New Meta Tags") === "enable_new_tags"

  const variantTitle =
    "Online Mental Health Care That Caters to You | Cerebral | Online Therapy | Virtual Counseling | Telehealth Therapy"
  const variantDescription =
    "Cerebral experts offer online mental healthcare and online therapy for anxiety, depression, insomnia, and more. Learn about the care you can get today."

  const controlTitle = "Online Mental Health Care That Caters to You | Cerebral"
  const controlDescription =
    "Cerebral experts can treat anxiety, depression, insomnia, and more. Learn about the care you can get today."

  return (
    <>
      <SEO
        title={showNewMetaTags ? variantTitle : controlTitle}
        description={showNewMetaTags ? variantDescription : controlDescription}
        url="/"
        schemaMarkup={organizationSchemaMarkup}
        titleOverride
      />
      <Navigation />
      <HomePageBody />
    </>
  )
}
