import { GetStartedButton } from "../../../shared"
import {
  AlternatingSplashContainer,
  GetStartedContainer,
  PrescriberLink,
  SectionBody,
  SectionContainer,
  SectionHeader,
  SplashImage,
} from "./styled"

export interface IIsCouplesRightToMeCopy {
  section: {
    heading: string
    body: {
      heading: string
      description: string
      icon: string
    }[]
    image: string
  }
}

export interface IIsCouplesRightToMeProps {
  copy: IIsCouplesRightToMeCopy
}

export const IsCouplesRightToMe = ({ copy }: IIsCouplesRightToMeProps) => {
  const { section } = copy
  return (
    <AlternatingSplashContainer>
      <SectionContainer className="bottom">
        <div>
          <SplashImage src={section.image} alt="" />
        </div>
        <div>
          <SectionHeader>{section.heading}</SectionHeader>
          {section.body.map((item, index) => (
            <SectionBody key={index}>
              <img src={item.icon} alt="" />
              <div>
                <h3>{item.heading}</h3>
                <p>{item.description}</p>
              </div>
            </SectionBody>
          ))}
        </div>
      </SectionContainer>
      <GetStartedContainer>
        <GetStartedButton height={56} />
      </GetStartedContainer>
    </AlternatingSplashContainer>
  )
}
