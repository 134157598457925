import {
  Forbes,
  ForbesRatingContainer,
  RatingContainer,
  RatingsContainer,
} from "./styled"
import forbesSvg from "../../../images/light-forbes-logo.svg"

export const SmallRatings = () => {
  return (
    <RatingsContainer>
      <RatingContainer>
        <div>
          <p>
            <strong>
              Best Telehealth <br /> Platform 2023
            </strong>
          </p>
        </div>
        <span>
          MedTech <br /> Breakthrough
        </span>
      </RatingContainer>
      <ForbesRatingContainer>
        <Forbes src={forbesSvg} alt="forbes" loading="lazy" />
        <span>
          Best Online <br /> Therapy Services <br /> Of 2023
        </span>
      </ForbesRatingContainer>
    </RatingsContainer>
  )
}
