import styled from "styled-components"

export const QualityContainer = styled.div`
  padding: 74px 10%;
  h2,
  p {
    text-align: center;
    margin: 0px auto 24px;
    max-width: 480px;
  }
  @media (max-width: 834px) {
    h2,
    p {
      text-align: left;
    }
  }
`

export const CopyGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 16px;
  margin: 48px auto;
  max-width: 1300px;
  grid-auto-rows: auto;
  @media (max-width: 834px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
`

export const CopyGridSection = styled.div`
  display: flex;
  gap: 8px;
  justify-self: center;
  flex-wrap: nowrap;
  width: 100%;
  align-items: flex-start;

  img {
    flex-shrink: 0; /* Prevent the image from shrinking */
  }

  & > div {
    display: flex;
    flex-direction: column;
  }

  p {
    text-align: left;
  }
`
