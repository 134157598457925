import styled from "styled-components"
import { colors } from "../../../themes/colors"

export const GettingStartedWrapper = styled.section<{
  bgColor?: string
  padding?: string
  gap?: string
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${props => props.gap || "60px"};
  padding: 64px 24px;
  background: ${props => props.bgColor || "#f6f8ff"};

  * {
    font-family: Poppins;
  }

  @media (max-width: 834px) {
    padding: ${props => props.padding || "32px 24px"};
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;

  h2 {
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    line-height: 32px;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: ${colors.c_grey[900]};
    line-height: 24px;
  }
`

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 32px;
  max-width: 992px;

  @media (max-width: 834px) {
    flex-direction: column;
    gap: 24px;
  }
`

export const Card = styled.div`
  min-width: 200px;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  h4 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: ${colors.c_grey[900]};
  }

  @media (max-width: 834px) {
    max-width: 600px;
    flex-direction: row;
    gap: 16px;
  }
`

export const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-bottom: 8px;
  background-color: ${colors.c_white[50]};

  & > * {
    width: 20px;
    height: 20px;
  }

  @media (max-width: 834px) {
    min-width: 40px;
  }
`

export const ButtonWrapper = styled.div<{ maxWidth?: string; mt?: string }>`
  max-width: ${props => props.maxWidth || "320px"};
  margin: 0 auto;
  width: 100%;
  @media (max-width: 834px) {
    max-width: ${props => props.maxWidth || "600px"};
  }
  margin-top: ${props => props.mt || "0px"};
`
